.top-banner {
    background-color: $header-top-bannet-bg;
    position: relative;

    @include respond-to(mobile) {
        z-index: 10;
    }

    @include respond-to(mobile_strong_over) {
        z-index: 5;
    }

    .primary-logo {
        position: relative;
        top: auto;
        padding: 0;
        margin: 30px auto;
        font-size: rem(22);
        text-align: center;
        z-index: 1;

        @include respond-to(mobile_min) {
            width: 15rem;
            font-size: rem(17);
        }

        @include respond-to(mobile_strong) {
            margin: 0 auto;
            padding: 10px 0;
        }

        @include respond-to(desktop_plus) {
            font-size: rem(33);
        }

        a {
            position: relative;
            width: 100%;
            text-align: center;
            color: $header-logo-color;

            &:hover {
                color: $header-logo-hover-color;
            }
        }
    }

    #wrapper & a {
        -webkit-transition: none;
        transition: none;
    }
}

.header-banner {
    display: block;
    text-align: center;
    position: relative;
    z-index: 5;

    .slot {
        color: $header-promo-banner-text-color;
        background-color: $header-promo-banner-bg;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        .slot-panels-container {
            margin-left: 0;
        }
    }

    .asset {
        display: inline-block;
        padding: 0 2rem;
        margin: 0.8rem 0;
        border-left: 1px solid $header-promo-banner-divider-color;
        text-transform: uppercase;
        font-weight: 700;
        font-size: rem(10);
        line-height: 1;
        letter-spacing: 0.1rem;

        &:first-child {
            border: none;
        }

        @include respond-to(mobile_strong) {
            display: none;

            &.mobile-primary {
                display: block;
                margin: 0 auto;
                padding: 0.8rem 2rem;
                border: none;
            }
        }
    }

    a {
        color: $header-promo-banner-link-color;
    }

    .main-title {
        display: none;
    }

    .header-promotion {
        padding: 0.5rem 0;
        background-color: $header-promo-banner-bg;
        color: $header-promo-banner-text-color;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.js-close-category-menu {
    position: fixed;
    z-index: -1;
    opacity: 0;
    height: 6rem;
    left: 0;
    width: 100%;
    transform: translateX(0);

    @include respond-to(mobile_strong) {
        .menu-active & {
            display: block;
            position: fixed;
            z-index: 9999;
            opacity: 1;
            transform: translateX($header-nav-width-mobile);
            transition: transform 500ms ease;

            &:after {
                content: "\e901";
                position: absolute;
                z-index: 2;
                height: 6rem;
                top: 0;
                left: 0;
                right: 0;
                padding: 1.8rem;
                font-family: $font-icons !important;
                speak: none;
                font-size: rem(18);
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: $header-nav-button-close-color;
                background-color: $header-nav-button-close-bg;
                cursor: pointer;

                &hover {
                    color: $header-nav-button-close-hover-color;
                }
            }

            span {
                display: none;
            }
        }
    }
}

.menu-active .is-sticky {
    .js-close-category-menu:after,
    .header-wishlist {
        display: none;
    }
}

.mini-cart-active .is-sticky {
    .primary-logo {
        display: none;
    }

    .close-minicart {
        right: 0;
        height: 60px;

        &:after {
            display: none;
        }
    }
}

.header-wishlist {
    position: relative;
    float: right;
    z-index: 7;
    margin: -1.5rem 5rem 0 1rem;

    @include respond-to(mobile) {
        margin: -4rem 4.5rem 0 1rem;
    }

    @include respond-to(mobile_min) {
        margin-top: -3.6rem;
    }

    @include respond-to(mobile_strong_over) {
        margin: 0 0 0 2rem;
    }

    > a {
        i {
            display: inline-block;
            font-size: rem(14);
            vertical-align: sub;
        }

        span {
            display: inline-block;
            font-size: rem(10);
            letter-spacing: 0.1rem;
        }
    }
}

.header-search-icon {
    display: none;
}

.header-search {
    display: block;
    position: relative;
    color: $header-search-text-color;
    width: 100%;
    padding: 0.8rem;
    background-color: $header-search-bg;
    border: 1px solid $header-search-border-color;
    border-left-width: 0;
    border-right-width: 0;

    @include respond-to(mobile_strong_over) {
        float: right;
        padding: 0;
        width: 200px;
        z-index: 1;
        background-color: $header-search-bg-desktop;
        border: none;
    }

    .is-sticky & {
        top: auto;
    }

    form {
        @extend %clearfix;
        margin-bottom: 1px;
        position: relative;
        pointer-events: none;

        @include respond-to(mobile_strong) {
            height: 2.9rem;
        }
    }

    input[type="text"] {
        background-color: $header-search-field-bg;
        border: none;
        float: right;
        margin: 0;
        padding: 0.6rem 3rem 0.6rem 0.6rem;
        height: auto;
        font-size: rem(11);
        line-height: 1.6rem;
        width: $header-search-field-width-mobile;
        border: $header-search-field-border-width solid $header-search-field-border-color;
        border-radius: $header-search-field-border-radius;
        pointer-events: auto;

        @include placeholder {
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            border-radius: $header-search-field-border-radius;
        }

        @include respond-to(mobile_strong_over) {
            background-color: $header-search-field-bg-desktop;
            width: $header-search-field-width;
            border: $header-search-field-border-width-desktop solid $header-search-field-border-color-desktop;
            border-radius: $header-search-field-border-radius-desktop;

            &:focus {
                width: $header-search-field-width-desktop;

                @include respond-to(tablet-regular) {
                    width: $header-search-field-width-tablet;
                }
            }

            @include placeholder {
                color: $nobel;
                border-radius: $header-search-field-border-radius-desktop;
            }
        }

        @include respond-to(mobile_stronger) {
            &:focus {
                width: $header-search-field-width-tablet;
            }
        }

        @include respond-to(mobile_strong) {
            float: none;
            width: 100%;
            display: inline-block;

            @include placeholder {
                text-transform: none;
            }

            &:focus {
                width: 75%;

                @include placeholder {
                    text-transform: none;
                }
            }

            &:focus ~ .clear-button {
                @include respond-to(mobile_strong) {
                    opacity: 1;
                    right: 3%;
                    transition: all .35s ease;
                }
            }
        }
    }

    .search-button {
        position: absolute;
        z-index: 1;
        top: 0.6rem;
        right: 1.4rem;
        background-color: transparent;
        color: $header-search-button-color;
        padding: 0;
        width: auto;

        @include respond-to(mobile_strong) {
            display: none;
        }

        &:hover {
            color: $header-search-button--hover-color;
        }

        i {
            font-size: rem(16);
        }
    }

    .clear-button {
        position: absolute;
        top: 50%;
        right: -60px;
        transform: translateY(-50%);
        opacity: 0;
        color: $black02;
        font-size: rem(14);
        text-transform: capitalize;

        &.clear-button-icon {
            @include hidden;
        }
    }
}

.top-banner .header-search input[type="text"] {
    transition: width .20s ease;

    &:not(:focus) {
        text-transform: uppercase;
        letter-spacing: .1rem;

        @include respond-to(mobile) {
            color: $tutu;
        }

        @include respond-to(mobile_strong) {
            text-transform: none;
        }
    }
}

.header-store {
    .toggle-content {
        display: none;
        position: absolute;
        top: 5rem;
        left: 0.8rem;
        width: 95%;
        border: 1px solid $charcoal;
        background-color: $white;

        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 6px;
            border-color: transparent transparent white transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -1.2rem;
            left: 1rem;
            z-index: 3;
        }

        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 7px;
            border-color: transparent transparent $charcoal transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -1.4rem;
            left: 0.9rem;
            z-index: 2;
        }

        @include respond-to(mobile_strong_over) {
            top: 3rem;
            left: 2rem;
            width: 200px;
        }

        &.expanded {
            display: block;
        }

        a {
            display: block;
            float: left;
            clear: both;
            padding: 0.8rem;
            font-size: rem(11);
            letter-spacing: 0.1rem;
            color: $mid-gray;

            @include respond-to(mobile_strong_over) {
                text-transform: capitalize;
            }
        }
    }
}

#header-wrapper .sticky-wrapper .containter-cart-wish-search.expanded,
#header-wrapper.sticky-wrapper .containter-cart-wish-search.expanded  {
    width: 100%;
}


.header-search.toggle-content {
    @include respond-to(mobile_strong_over) {
        display: block;
    }
}

.sticky-wrapper {
    height: auto !important;

    .header-search.toggle-content {
        display: block;
    }

    &.is-sticky {
        .header-search {
            position: absolute;
            top: 35px;
            float: none;
            width: 100%;
            padding: 0.8rem;
            background-color: $white-smoke;
            border: 1px solid $very-light-gray;
            border-left-width: 0;
            border-right-width: 0;

            @include respond-to(mobile_strong) {
                position: static;
                margin-top: 10px;
            }

            @include respond-to(mobile_strong_over) {
                position: fixed;
                top: 70px;
                left: 0;
            }

            &.toggle-content {
                display: none;

                @include respond-to(mobile_strong) {
                    display: block;
                }

                &.expanded {
                    display: block;
                }
            }

            form {
                position: relative;
                width: 430px;
                margin: 0 auto;

                @include respond-to(mobile_strong) {
                    width: auto;
                }
            }

            input[type="text"] {
                float: none;
                border: 1px solid $tutu;
                border-radius: 0.4rem;

                @include respond-to(mobile_strong_over) {
                    width: 100%;
                }

                &::-webkit-input-placeholder {
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    border-radius: 0.4rem;
                }

                &::-moz-placeholder {
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    border-radius: 0.4rem;
                }

                &:-moz-placeholder {
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    border-radius: 0.4rem;
                }

                @include respond-to(mobile_strong) {
                    @include placeholder {
                        text-transform: none;
                    }
                }
            }

            .bticons-search {
                @include respond-to(mobile_strong) {
                    display: none;
                }
            }
        }

        .top-banner {
            background-color: $nobel;
            z-index: $z-header-sticky;
            border-bottom: 1px solid $very-light-gray;

            @include respond-to(touch) {
                width: 100% !important;
                position: fixed !important;
                z-index: 9996 !important;
                bottom: auto !important;
                top: 0;
            }

            // iPad fix block
            @include respond-to(mobile_strong_over) {
                position: fixed;
                width: 100%;
                top: 0;
            }

            // end of iPad fix block

            @include respond-to(desktop_over) {
                height: auto;
            }

            &.is-sticky {
                position: fixed;
                top: 0;
            }

            .primary-logo {
                position: relative;
                left: auto;
                top: auto;
                margin: 1.3rem auto 0 auto;
                width: 17rem;
                font-size: rem(19);
                color: $white;
                transform: none;

                @include respond-to(mobile_min) {
                    margin: 1.7rem auto 0 auto;
                    width: 15rem;
                    font-size: rem(17);
                }

                @include respond-to(mobile_strong_over) {
                    float: left;
                    margin: .8rem 0 0 .7rem;
                }

                @include respond-to(mobile_stronger) {
                    display: block;
                    float: none;
                    font-size: rem(27);
                    text-align: center;
                    width: 100%;
                }

                @include respond-to(widedesktop_over) {
                    margin-top: 1rem;
                }

                a {
                    color: $white;
                }
            }
        }

        .containter-cart-wish-search {
            @include respond-to(mobile_stronger) {
                position: absolute;
                top: 5px;
            }
        }

        .header-search-icon {
            position: relative;
            display: none;
            float: right;
            margin: 1.3rem 0 0 0rem;
            color: $white;

            @include respond-to(mobile_strong_over) {
                margin-top: 0.9rem;
                display: block;
                z-index: 3;
            }
        }

        .header-wishlist {
            top: 0;
            margin: -4.5rem 5rem 0 2rem;

            @include respond-to(mobile_strong_over) {
                margin: 0.4rem 0 0 2rem;
            }

            a {
                color: $white;
            }
        }

        #mini-cart {
            @include respond-to(mobile_strong_over) {
                top: 0;
                margin: .4rem 1rem 0 2rem;
            }

            @include respond-to(mobile_strong) {
                margin-top: -5.5rem;
            }

            .mini-cart-total,
            .mini-cart-total .mini-cart-link .minicart-quantity {
                color: $white;
            }
        }
    }
}

.is-sticky {
    right: 0 !important;
    margin: 0 !important;
}

/* Header cookies banner */
.header-cookies {
    background: $cirkon;
    position: relative;
    z-index: 9998;

    &-inner {
        max-width: 1180px;
        margin: 0 auto;
        padding: 30px;
        display: none;
        @include align-items(center);
        @extend %clearfix;

        &.visible {
            @include flexbox();
        }

        @include respond-to(mobile_tablet) {
            @include flex-vertical();
        }

        @include respond-to(mobile) {
            padding-bottom: 15px;
        }
    }

    &__left {
        float: left;
        width: 50%;

        @include respond-to(mobile_tablet) {
            width: 90%;
            text-align: center;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        h3 {
            font-size: rem(17);
            font-weight: 500;
        }

        p {
            margin: 8px 0 0 0;
            line-height: 1.5;
            font-size: rem(12);
        }
    }

    &__right {
        position: relative;
        float: right;
        width: 50%;
        padding-left: 5%;

        @include respond-to(mobile_tablet) {
            width: auto;
            padding-left: 0;
            text-align: center;
        }

        .button {
            @include respond-to(touch) {
                width: auto;
            }

            @include respond-to(tablet) {
                margin: 30px auto 0;
                width: 250px;
            }

            @include respond-to(mobile) {
                margin: 30px auto 0;
                width: 72%;
            }
        }

        .header-cookies__link-wrap {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;

            @include respond-to(mobile_tablet) {
                display: block;
                position: static;
                margin: 10px auto;
            }
        }

        .link {
            display: block;
            font-size: rem(12);
            text-decoration: none;
            color: $hel-gray2;

            @include underlined_link;
        }
    }
}

.ios8.no-desktop,
.ios7.no-desktop {
    #wrapper {
        position: relative;
        left: 0;
        transition: left 500ms ease; // initialize animation

        // CART SPECIFIC
        &.mini-cart-active {
            transform: none;
            left: -$minicart-mobile-width;
        }

        &:before,
        #main,
        .top-banner,
        .footer,
        .header-banner {
            transition: none;
        }

        .mini-cart-content{
            top: 0;
            left: auto;
            right: -$minicart-mobile-width;
            transition: right 500ms ease; // initialize animation
        }

        .js-close-minicart,
        .js-close-category-menu {
            display: block;
            right: 0;
            top: -2000px; // hide it but now with display:none to preserve animation
            transition: right 500ms ease; // initialize animation
        }

        &:before {
            display: block;
            transform: none;
            width: 100%;
            left: auto;
            right: 0;
            transition: right 500ms ease; // initialize animation
        }

        @include respond-to(mobile_strong_over) {
            .footer-links {
                position: fixed;
                width: 100%;
                left: auto;
                transition: right 500ms ease; // initialize animation
            }
        }

        &.mini-cart-active {
            .containter-cart-wish-search {
                z-index: 0;
            }

            #main,
            .top-banner,
            .footer,
            .header-banner {
                transform: none;
            }

            .mini-cart-content {
                right: 0;
            }

            .is-sticky .top-banner {
                height: 60px;

                .primary-logo {
                    display: none;
                }
            }

            .js-close-minicart {
                right: $minicart-mobile-width;
                top: 0;
                transform: none;
            }

            &:before  {
                right: $minicart-mobile-width;
                top: 60px;
            }

            @include respond-to(mobile_strong_over) {
                .footer-links {
                    right: $minicart-mobile-width;
                }
            }
        }

        .js-close-category-menu {
            position: fixed;
        }

        // MENU SPECIFIC
        @include respond-to(mobile) {
            .js-close-category-menu {
                width: 100%;
                left: auto;
            }

            #navigation {
                left: auto;
                top: 0;
            }

            &.menu-active {
                left: $header-nav-width-mobile;

                #main,
                .top-banner,
                .footer,
                .header-banner {
                    transform: none; // reset transform3d - we use left\right here
                }

                &::before {
                    right: -$header-nav-width-mobile;
                }

                .js-close-category-menu {
                    right: -$header-nav-width-mobile;
                    top: 0;
                }

                .is-sticky .js-close-category-menu {
                    &:before {
                        display: block;
                        content: "\e901";
                        width: 100%;
                        height: 6rem;
                        position: absolute;
                        top: 0;
                        padding: 1.8rem;
                        z-index: 9999;
                        font-size: rem(18);
                        color: $white;
                        background-color: $nobel;
                        cursor: pointer;
                        @include font-icomoon;
                    }
                }
            }
        }
    }
}

[data-slot-id="header-slider-panel"] {
    .asset {
        font-size: rem(12);
        font-weight: 700;
        padding: 15px 20px;
        text-align: center;
    }

    a {
        color: inherit;
    }

    // Fix for slow js loading (when selectors load after content)
    & > div {
        display: none;
    }

    .slick-slider {
        display: block;
    }
}

.header-slider-panel {
    position: relative;
    font-size: rem(10);
    z-index: 4;

    .timer-container {
        display: inline;
    }

    .content-asset {
        font-weight: 700;
        padding: 0 20px;
        text-align: center;
        height: 48px;
        line-height: 48px;
    }

    .header-slide {
        display: inline-block;
        position: relative;
        max-width: 100%;
    }

    .header-slide-inner {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 70ch;
        margin: 0 auto;
    }

    .overflow-handler-tooltip {
        display: none;
        line-height: 1.5;
        position: absolute;
        top: 110%;
        color: $black01;

        @include respond-to(touch) {
            transform: translateX(-50%);
            width: 90%;
            left: 50%;
            overflow: hidden;
            background: $white;
            border-radius: 15px;
            padding: 0 10px;
            word-break: break-all;
            white-space: normal;
            padding: 10px;
            border: 1px solid $black01;
        }

        @include respond-to(touch_over) {
            width: 60%;
            left: 40%;
            padding: 5px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-size: 12px;
            background-color: #E8E8E8;
            box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
        }
    }

    .overflow-handler {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        opacity: 0;
        cursor: pointer;

        &:hover {
            ~ .overflow-handler-tooltip {
                display: block;
            }
        }
    }

    .no-truncate { // Custom CSS class to hide ellipsis
        .header-slide-inner {
            text-overflow: initial;
            max-width: none;
            overflow: visible;
        }

        .overflow-handler {
            display: none !important;
        }
    }

    svg {
        display: inline-block;
        vertical-align: middle;
    }

    a {
        color: inherit;
    }

    // Fix for slow js loading (when selectors load after content)
    & > div {
        display: none;
    }

    .slick-list {
        overflow: visible; // show tooltip
    }

    .slick-track {
        max-height: 48px;

        @include respond-to(mobile_strong_over) {
            width: auto !important;
            display: flex;
            flex-wrap: nowrap;
        }
    }

    .slick-slide {
        max-height: 48px;

        @include respond-to(mobile_strong_over) {
            border-left: 2px solid rgba($color: #ffffff, $alpha: 0.2);
            flex: 1 1 auto;

            &:first-child {
                border-left: none;
            }
        }

        & > div {
            display: flex;
            justify-content: center;
            height: 100%;
            max-height: 48px;
        }
    }

    .slick-slider {
        display: block;
        max-width: var(--mx-width); // value from Config JSON in BM
        margin: 0 auto;
    }

    .slick-arrow {
        transform: translateY(-50%);
        margin: 0;
        padding: 8px 0;
        color: rgba($color: #ffffff, $alpha: 0.5);

        &:hover {
            opacity: 1;
        }

        &::before {
            font-size: rem(18);
            color: rgba($color: #ffffff, $alpha: 0.5);
        }
    }

    .slick-prev {
        @include icon(simple-arrow-left);

        left: 5px;
    }

    .slick-next {
        @include icon(simple-arrow-right);
    }

    &.removable {
        @include respond-to(m_mobile_down) {
            padding-right: 15px;
        }

        .content-asset {
            @include respond-to(m_mobile_down) {
                padding-right: 25px
            }
        }

        .slick-next {
            @include respond-to(m_mobile_down) {
                right: 7px;
            }
        }
    }

    .remove {
        font-size: rem(30);
        line-height: 24px;
        position: absolute;
        right: 2px;
        top: 0;
        z-index: 10;
        padding: 0 0 20px 0;
        cursor: pointer;
        opacity: 0.5;
    }
}

/* Workaround for https://jira.ontrq.com/browse/BTHSUP-452 on Brown Thomas.
   Plugin jquery.sticky.js makes wrong height calculation for header ('.js-header')
   on iPad and this adds wrong inline classes to header block (like "bottom: 0px;")
   which occurs expanding header to unexpected height on iPad on scrolling.
   This fix overlaps plugin's styles to make header workable on iPad.
 */
.ios.no-desktop .is-sticky .top-banner.is-tablet {
    position: fixed !important;
    top: 0 !important;
    bottom: auto !important;

    @include respond-to(print) {
        position: static !important;
    }
}

.noscroll .sticky-wrapper.is-sticky .top-banner { // tablet fix for noscroll (overwrite js)
    @include respond-to(mobile_strong_over) {
        position: static !important;
    }
}

.b-orderdetails {
    &-title_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &-title {
        font-size: rem(24);
        font-weight: 500;
        margin-bottom: 16px;
    }

    .cancelled {
        .m-estimate {
            display: none;
        }
    }

    .b-order_status {
        padding: 5px 10px;
        margin: 0;
    }

    &-data,
    &-shipment {
        padding-bottom: 20px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &-shipment {
        font-size: rem(14);

        @include respond-to(m_tablet_down) {
            line-height: 25px;

            .b-shipping_address {
                margin-top: 20px;

                .mini-address-name {
                    color: #999999;
                    margin: 0;
                }
            }

            .mini-address-location {
                display: flex;
                flex-direction: column;
            }
        }

        .b-shipping_method,
        .b-shipping_address {
            @include respond-to(m_desktop) {
                display: none;
            }
        }
    }

    &-row {
        line-height: 26px;

        .label {
            color: #1D1D1D;
        }

        .value {
            color: #999999;
        }
    }

    .m-tracking_number {
        text-decoration: underline;

        &::after {
            content: "";
            display: inline-block;
            margin-left: 5px;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            background-image: url(../img/icons/tracking.svg);
        }
    }

    &-item,
    &-dates {
        display: flex;
        justify-content: space-between;
    }

    &-item_details {
        display: flex;
        max-width: 84%;
        font-size: rem(14);

        @include respond-to(mobile_mid) {
            font-size: rem(12);
        }
    }

    &-dates {
        @include respond-to(m_tablet_down) {
            flex-direction: column;
        }
    }

    &-item {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @include respond-to(m_tablet_down) {
            flex-wrap: nowrap;
        }
    }

    .b-item_image {
        @include respond-to(m_desktop) {
            max-width: 180px;
            margin-right: 30px;
            width: 80%;
        }

        @include respond-to(m_tablet_down) {
            max-width: 44%;
            margin-right: 20px;
        }

        img {
            width: 100%;
            display: block;
        }
    }

    .b-item_name {
        font-size: rem(16);
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    .b-item_price {
        font-weight: 500;
        line-height: 20px;
        text-align: right;

        @include respond-to(mobile_mid) {
            font-size: rem(14);
        }
    }

    .line-item-adjusted-price {
        color: #B22E2E;
    }

    .line-item-unadjusted-price {
        color: #999;
    }

    .b-item_gift_message {
        color: #999;
    }

    .b-item_gift_title {
        color: #1D1D1D;
    }

    .b-item_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 7px;

        @include respond-to(m_tablet_down) {
            max-width: 50%;
        }

        @include respond-to(mobile_mid) {
            word-break: break-word;
        }
    }

    .m-bonus {
        .b-item_info {
            @include respond-to(m_tablet_down) {
                max-width: none;
            }
        }
    }

    .b-item_info-details {
        display: flex;
        flex-direction: column;
    }

    // --------------- SUMMARY ----------------

    .b-orderdetails-summary {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 40px;
    }

    // Cover styles from cart
    .order-totals-table {
        td {
            color: #000;
            font-size: rem(16);
            line-height: 26px;
            padding: 0;
            font-weight: inherit;
        }

        .order-discount td {
            color: #FF0000;
        }

        .order-subtotal {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            td {
                padding-bottom: 20px;
            }
        }

        .order-total {
            border-top: 0;

            td {
                padding: 25px 0 20px;
            }
        }

        .order-shipment td {
            padding-top: 30px;
        }

        .order-total td,
        .order-subtotal td {
            font-weight: 500;
        }

        .order-sales-tax td,
        .order-shipment td {
            font-weight: 400;
        }
    }

    .order-totals-table-container {
        margin: 0;
    }

// --------------- SHIPPING / BILLING ----------------
    &-shipping {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include respond-to(m_tablet_down) {
            flex-direction: column;
        }

        .b-shipping_method {
            font-weight: 500;
        }
    }

    &-billing,
    &-shipping {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 35px;
        margin-bottom: 35px;

        .b-orderdetails-title {
            flex-basis: 100%;
        }
    }

    .b-shipping_method {
        @include respond-to(m_tablet_down) {
            margin-bottom: 25px;
        }
    }

    .b-shipping_method,
    .b-shipping_address {
        font-size: rem(14);

        @include respond-to(m_desktop) {
            width: 49%;
        }

        .label {
            font-weight: 500;
            display: inline-block;
        }
    }

    .b-shipping_description {
        line-height: 20px;
    }

    .mini-address-location {
        line-height: 22px;
    }

    .b-shipping_description,
    .mini-address-name,
    .mini-address-location {
        color: #999999;
        font-size: rem(14);
    }

    &-billing {
        font-weight: 500;

        .mini-address-name {
            color: #1D1D1D;
            margin-bottom: 10px;
            display: inline-block;
        }

        .mini-address-location {
            display: flex;
            flex-direction: column;
        }
    }

    .order-payments-title {
        font-weight: 500;
        
        td {
            padding-top: 30px;
        }
    }
}
.checkout-main {
    position: relative;

    @include respond-to(tablet_plus) {
        padding-top: 20rem;
    }

    @include respond-to(mobile) {
        padding-top: 7rem;
    }
}

.breadcrumb-checkout {
    margin: 0 auto;
    color: $tutu;

    a {
        color: $tutu;
    }
}

.checkout-breadcrumbs-block {
    margin: 0 auto;
}

.checkout-progress-indicator {
    overflow: hidden;
    padding: 0 0 2rem;

    @include respond-to(tablet_plus) {
        height: 10rem;
        margin-right: auto;
        margin-left: auto;
        padding: 2rem 0 0;
        color: $tutu;
    }

    .checkout-progress-step {
        display: inline-block;
        position: relative;
        height: 4rem;
        min-width: 6rem;
        margin-right: 1rem;
        text-align: center;
        vertical-align: top;

        &:before {
            content: "";
            position: absolute;
            top: 15px;
            left: 0;
            right: -1rem;
            height: 1px;
            border-top: 1px dashed $tutu;
        }

        &.step-1 {
            &:before {
                left: 50%;
            }
        }

        &.step-3 {
            margin-right: 0;
            &:before {
                right: 50%;
            }
        }

        i {
            display: block;
            position: relative;
            z-index: 1;
            width: 30px;
            height: 30px;
            margin: 0 auto;
            border: 2px solid $tutu;
            border-radius: 15px;
            font-size: rem(16);
            font-style: normal;
            line-height: 26px;
            background-color: $white;
        }

        &.active {
            color: $night-rider;

            i {
                border: 2px solid $night-rider;
            }
        }

        @include respond-to(tablet_plus) {
            min-width: 12rem;
        }

        @include respond-to(mobile) {
            span {
                display: none;
            }
        }
    }

    &.multishipprogress {
        .checkout-progress-step {
            &.step-3 {
                margin-right: 1rem;

                &:before {
                    right: -1rem;
                }
            }

            &.step-4 {
                margin-right: 0;

                &:before {
                    right: 50%;
                }
            }
        }
    }

    a {
        color: $night-rider;
    }
}

.checkout-title {
    padding: 0 0 2rem;
    margin-bottom: 25px;
    font-size: rem(28);
    text-transform: uppercase;
    text-align: center;
    color: $nobel;

    @include respond-to(tablet_plus) {
        font-size: rem(32);
        padding: 0;
        margin-bottom: 15px;
        text-align: center;
    }
}

.checkout-shipping,
.checkout-multi-shipping,
.checkout-billing {

    .legend {
        font-size: rem(17);
        font-weight: 700;
        margin: 0 0 2rem;
        padding: 0;
        position: relative;
        width: 100%;
    }

    .description {
        margin: 0 0 2rem;
    }

    h4 {
        font-size: rem(17);
        font-weight: 700;
        margin: 0 0 2rem;
        text-transform: none;
    }

    .select-address {
        margin: 0;

        .field-wrapper {
            float: none;
            margin: 0;
            padding-left: 0;
            width: auto;
        }
    }

    .field-wrapper {
        width: 75%;

        @include respond-to(mobile) {
            width: auto;
        }
    }

    .label-inline {
        .field-wrapper {
            width: auto;
        }
    }
}

.payment-mobile-accordion {
    overflow: hidden;

    @include respond-to(mobile) {
        margin-bottom: 38px;
    }

    .billing-sub-title {
        font-size: rem(22);
        font-weight: 400;
        text-transform: uppercase;

        @include respond-to(mobile) {
            font-size: rem(16);
            font-weight: 500;
        }
    }

    &-title {
        border-bottom: 1px solid $very-light-gray;
        border-top: 1px solid $very-light-gray;
        cursor: pointer;
        font-weight: 500;
        padding: 14px 0;
        text-transform: uppercase;

        @include respond-to(tablet_plus) {
            display: none;
        }

        i {
            display: block;
            float: right;
            font-size: rem(12);
            line-height: 21px;
            padding-right: 10px;
        }
    }

    @include respond-to(mobile) {
        &.expanded {
            margin-bottom: 0;

            .payment-mobile-accordion-title {
                border-bottom: none;

                i {
                    &:before {
                        content: "\e90f";
                    }
                }
            }

            .payment-mobile-accordion-content {
                display: block;

                .shipping-method-info {
                    @include respond-to(mobile) {
                        display: none;
                    }
                }
            }
        }
    }
}

.gift-message {
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    &-text {
        overflow: visible;
        opacity: 1;
        transition: all 1s ease;

        &.hidden {
            height: 0;
            opacity: 0;
            overflow: hidden;
        }

        textarea {
            width: 100%;
        }
    }

    .pt_cart & {
        margin-bottom: 10px;
    }
}

.billing-coupon-code {
    .form-row,
    .coupon-apply,
    .giftcert-apply {
        display: inline-block;
    }

    .form-row {
        margin: 0;
        width: 45%;
    }

    .tooltip {
        padding-left: 1rem;
    }
}

.redemption {
    color: $sangria;

    .success {
        color: $islamic-green;
    }

    span.error {
        margin-left: 0;
    }
}

.payment-method-options {
    border-top: 1px solid $very-light-gray;
    font-size: 0;
    text-align: center;
    margin-bottom: 28px;

    .form-row {
        display: none;
    }
}

.payment-method {
    display: none;
}

.form-row {
    &.month,
    &.year,
    &.day {
        clear: none;
        float: left;
        margin: 0 0 0 2%;
        width: 20%;

        @include respond-to(mobile) {
            width: 40%;
        }

        .field-wrapper {
            float: none;
            width: auto;

            @include respond-to(tablet_plus) {
                padding-left: 0;
            }
        }

        label {
            @extend .visually-hidden;
        }
    }
}

.form-row {
    &.month {
        @include respond-to(mobile) {
            margin-left: 0;
        }
    }
}

.month .form-caption {
    margin-left: 0;
}

.cvn {
    clear: left;

    .field-wrapper {
        width: 127px;
    }
}

.ssn {
    clear: left;
}

.bml-terms-and-conditions {
    border: 1px solid $dark-gray;
    font-size: 0.8em;
    height: 400px;
    overflow: auto;
    padding: 1em;
}

.bml-table-col-1 {
    width: 25%;
}

.checkout-order-summary {
    padding: 30px;
    border: 1px solid $nobel;

    @include respond-to(mobile) {
        padding: 0;
        border: 0;
    }

    .toggle {
        display: none;

        @include respond-to(mobile) {
            display: block;
            position: relative;
            margin-bottom: 0;
            padding: 18px 0 18px 18px;
            border-top: 1px solid $dim-gray;
            border-bottom: 1px solid $dim-gray;
            font-size: rem(17);
            font-weight: 500;
            line-height: 1;

            &:after {
                position: absolute;
                content: '+';
                right: 10px;
                top: 50%;
                margin-top: -11px;
                font-size: rem(23);
                font-weight: 300;
                line-height: 1;
            }

            &.expanded:after {
                content: '-';
                margin-top: -13px;
                right: 12px;
            }
        }

        &-content {
            display: block;

            @include respond-to(mobile) {
                display: none;
            }

            &.expanded {
                @include respond-to(mobile) {
                    display: block;
                }
            }
        }
    }

    &-contactnum {
        display: none;
    }

    .section-header {
        border: 0;
        padding: 0 0 10px 0;
        font-size: rem(21);

        @include respond-to(mobile) {
            padding-top: 40px;
        }
    }

    .section-header-note {
        display: none;
    }

    .mini-cart-image {
        padding: 0;
        width: 38%;
    }

    .mini-cart-offer {
        margin-top: 20px;
    }

    .mini-cart-product {
        padding: 30px 0;
    }

    .mini-cart-data {
        width: 62%;
        padding-right: 0;
    }

    .mini-cart-subtitle {
        font-size: rem(17);
        color: $nobel;
    }

    .mini-cart-pricing {
        float: right;
        width: 62%;
        font-size: rem(14);
        font-weight: 700;
    }

    .mini-cart-price {
        display: block;
        margin-top: 5px;
    }

    .order-total {
        border-top: 1px solid $very-light-gray;
        border-bottom: 1px solid $very-light-gray;

        .order-value {
            padding-right: 10px;
            color: $nobel;
            text-align: right;
        }

        td {
            padding: 8px 0;
            text-transform: none;

            &:first-child {

                &:after {
                    display: none;
                }
            }

            & + td {
                text-align: right;
            }
        }
    }

    .mini-shipment {
        .section-header-note {
            display: inline;
            font-size: rem(15);
        }

        .details {
            background: none;
            padding-left: 0;
            color: $nobel;
            font-size: rem(14);

            .minishipments-method {
                display: none;
            }
        }
    }

    &-btplus {
        margin-top: 40px;

        p {
            margin-top: 0;
            font-size: rem(13);
            line-height: 18px;
            color: $hel-gray;
        }

        a {
            text-decoration: underline;
            color: $hel-gray;
            font-size: rem(14);
        }

        .slot-panels-container {
            margin-left: 0;
        }
    }
}


.checkout-mini-cart {
    background-color: $white;
    overflow: auto;

    .mini-cart-col-1,
    .mini-cart-col-2,
    .mini-cart-pricing {
        float: none;
        width: auto;
    }

    .mini-cart-pricing {
        text-align: left;
        padding: 0;
    }

    .mini-cart-name {
        margin-bottom: 0;
        width: auto;
    }

    .mini-cart-price {
        margin-top: 0;
        color: $nobel;
    }

    .mini-cart-edit-link {
        color: $hel-gray;
        text-decoration: underline;
    }
}

.checkout-order-totals {
    padding: 0.3rem .5rem;
}

.place-order-totals {
    clear: both;

    .order-totals-table {
        float: right;
        width: 50%;
    }
}

.order-component-block {
    margin: 1.67em 0;

    .section-header a {
        float: right;
        font-weight: normal;
    }

    .details {
        background: none repeat scroll 0 0 $gainsboro;
        color: $dark-gray;
        padding: 0.5em 1em;
    }
}

.order-summary-footer {
    position: relative;

    h2 {
        font-size: 2em;
        font-family: $font-second;
        font-weight: normal;
    }
}

.summary-help {
    float: right;
    font-size: 0.8em;
    margin: 1em 0;
}

.submit-order {
    clear: both;
    display: block;
    padding: 1.67em 0 0;
    text-align: right;

    .back-to-cart {
        font-size: 1.1em;

        &:hover {
            text-decoration: underline;
        }
    }

    button {
        margin-left: 1.5em;
    }
}

.gift-cert-used {
    padding: .83em 0;
}

.summary .edit {
    position: absolute;
    text-transform: uppercase;
    right: 10px;
    font-size: 0.9em;
    line-height: 1.5em;
}

.pt_cart .breadcrumb {
    padding-left: 0;
}

.pt_cart  {
    .item-stock-info-wrapper {
        @include respond-to(mobile_strong_over) {
            display: block;
        }
    }
}

.billing-error {
    color: red;
    margin: 0 0 .83em 24%;
}

.cvn-tooltip {
    font-weight: bold;
    margin: 5px;
    text-align: center;

    img {
        height: 150px;
        width: 110px;
    }
}

.standard-shipping.shipping-price-was {
    text-decoration: line-through;
    margin-right: 5px;
    color: $very-light-gray;
}

.shipping-method-list {
    position: relative;

    .label-inline {
        position: relative;
        padding-left: 3rem;

        label {
            font-weight: 700;

            .tooltip {
                display: none;
            }
        }

        .field-wrapper {
            position: absolute;
            left: 0;
            top: 2px;
        }

        .form-caption {
            display: inline;
            margin-left: 0;

            @include respond-to(mobile) {
                display: none;
            }

            &:before {
                content: "- ";
            }
        }
    }
}

.shipping-btplus {
    width: 65%;

    @include respond-to(mobile) {
        width: 100%;
        margin-top: 20px;
    }

    .slot-panels-container {
        margin-left: 0;
    }

    .section-header {
        border: 0;
        padding: 0 0 10px;
        text-transform: none;
        font-weight: 700;
        font-size: rem(17);
    }

    p {
        display: inline;
        font-size: rem(16);
        line-height: 18px;
        color: $hel-gray;
    }

    a {
        display: inline;
        padding-left: 10px;
        text-decoration: underline;
    }
}

.header-shipping {
    &-item {
        @extend %clearfix;
        border-top: 1px solid $very-light-gray;
        padding-top: 15px;

        &:first-child {
            border-top: none;
            padding-top: 0;
        }

        .action {
            float: right;
        }
    }
}

.delivery-choose {
    margin-bottom: 30px;

    @include respond-to(mobile) {
        margin-bottom: 15px;
    }

    &-title {
        font-size: rem(22);
        margin-bottom: 15px;

        @include respond-to(mobile) {
            font-size: rem(16);
            font-weight: 500;
        }

        .link {
            display: block;

            i {
                display: none;
            }
        }
    }

    &-form {
        @extend %clearfix;
    }

    a {
        color: $nobel;

        .delivery-choose-label-text {
            opacity: 1;

            .fa {
                font-size: rem(13);
            }
        }
    }

    &-label {
        float: left;
        padding-right: 15px;
        width: 33.333%;

        @include respond-to(mobile) {
            float: none;
            padding-right: 0;
            width: auto;
            margin-bottom: 5px;
        }

        &:hover {
            color: $nobel;
        }

        &-box {
            display: inline-block;
            border: 1px solid transparent;
            padding: 5px 10px;

            @include respond-to(mobile) {
                display: block;
            }
        }

        &-text {
            opacity: 0.45;
        }
    }

    .active &-label-box {
        background-color: $white-smoke2;
        border-color: $tutu;
        font-weight: 500;
    }
}

.checkoutlogin {
    padding-top: 20px;
    text-align: center;

    .primary-content {
        float: none;
        width: auto;
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
    }

    #secondary {
        display: none;
    }

    .checkout-title {
        position: static;
        margin-bottom: 40px;
    }

    &-content {
        margin: 0 auto;
        max-width: 400px;
        text-align: center;
    }

    .accountvalidator {
        text-align: left;

        & +.form-row.required label:after {
            display: none;
        }
    }

    label {
        text-align: left;
        margin-bottom: 5px;
    }

    p {
        margin-top: 0;
    }

    .center {
        text-align: center;
    }
}

.checkoutmultishipping {
    .title-choose-delivery {
        font-size: rem(18);
        font-weight: 700;
        margin-bottom: 15px;
    }

    .selectbox {
        max-width: 100%;
    }

    .choose-an-address-mobile {
        display: none;
        margin-bottom: 4px;

        @include respond-to(mobile) {
            display: block;
        }
    }

    .table-div {
        .section-header-line,
        .cart-row {
            @extend %clearfix;
            margin-bottom: 30px;
        }

        .section-header-line {
            @include respond-to(mobile) {
                display: none;
            }
        }

        .cart-row {
            border-bottom: 1px solid $sections-body-border;
            padding-bottom: 30px;
        }

        .section-header {
            float: left;

            &-product {
                padding-left: 20px;
                padding-right: 30px;
                width: 56%;
            }

            &-qty {
                width: 14%;
            }

            &-location {
                width: 30%;
            }
        }

        .item-image {
            float: left;
            padding-right: 15px;
            width: 20%;

            @include respond-to(mobile) {
                width: 40%;
            }

            img {
                max-width: 100%;
            }

            .bonus-item {
                display: none;
            }
        }

        .bonus-item {
            display: inline;
        }

        .item-details {
            float: left;
            padding-right: 15px;
            width: 36%;

            @include respond-to(mobile) {
                float: right;
                padding-right: 0;
                width: 60%;
            }
        }

        .item-quantity {
            float: left;
            width: 14%;

            @include respond-to(mobile) {
                display: none;
            }
        }

        .item-shipping-address {
            float: left;
            width: 30%;

            @include respond-to(mobile) {
                clear: both;
                float: none;
                width: auto;
            }
        }

        .over18sproduct_i {
            display: inline-block;
        }

        .product-price {
            display: inline-block;
        }
    }
}

.main-payment {
    @extend %clearfix;
    position: relative;

    @include respond-to(tablet_plus) {
        &:before {
            content: "";
            position: absolute;
            top: -47px;
            bottom: 0;
            left: 71%;
            border-right: 1px solid $very-light-gray;
        }
    }
}

.payment,
.confirmation {
    .delivery-address,
    .billing-address,
    .shipping-method-list {
        > .address {
            > div {
                &:after {
                    content: ",";
                }

                br {
                    display: none;
                }

                .visible-mobile {
                    display: none;
                }

                &:last-child::after {
                    content: none;
                }
            }

            .adress-city-state-code {
                &:after {
                    content: none;
                }

                span::after {
                    content: ",";
                }
            }
        }
    }
}

.payment {
    .primary-content {
        width: auto;
        float: none;
        margin-bottom: 0;
    }

    .select-address {
        margin: 10px 0 40px;
    }

    .form-field-tooltip {
        display: none;
    }

    &-btplus {
        background-color: $white-smoke;
        border: 1px solid $very-light-gray;
        font-size: rem(18);
        margin-bottom: 30px;
        padding: 10px 15px;

        &-bold {
            font-weight: 700;
        }
    }

    .shipment-title {
        font-size: rem(18);
        font-weight: 700;
        margin-bottom: 15px;

        @include respond-to(mobile) {
            margin-bottom: 5px;
        }
    }

    .shipping-method-list {
        margin-bottom: 47px;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }

        .form-row {
            margin-left: 0;
        }

        .label-inline {
            .fa {
                margin-left: 5px;
                cursor: pointer;
            }

            .form-caption {
                display: none;
                font-size: rem(14);

                &:before {
                    display: none;
                }
            }

            .tooltip {
                display: inline;
                font-weight: 400;
            }
        }
    }

    .delivery-address,
    .billing-address,
    .shipping-method-list {
        .link {
            display: inline-block;
            margin-top: 10px;
            color: $sections-header-border;
        }

        @include respond-to(mobile) {
            margin-bottom: 10px;

            div {
                display: inline;

                > div {
                    br {
                        display: none;
                    }
                }
            }

            .link {
                display: inline;
                margin-top: 0;
            }

            .address-comma-mobile::after {
                content: ',';
            }
        }
    }

    .cart-loyality-points,
    .checkout-giftcard-pm-forbidden {
        margin-top: 15px;
    }
}

.primary-content-payment {
    @include respond-to(tablet_plus) {
        float: left;
        width: 68%;
    }
}

.secondary-payment {
    @include respond-to(tablet_plus) {
        float: right;
        width: 27%;
    }
}

.main-payment-steps {
    .secondary-payment {
        display: none;
    }

    .primary-content-payment {
        float: none;
        width: auto;
    }

    @include respond-to(tablet_plus) {
        &:before {
            display: none;
        }
    }
}

.main-payment-productlines {
    padding-bottom: 30px;

    .billing-title + & {
        .shipping-method-list {
            @include respond-to(tablet_plus) {
                margin-top: -47px;
            }
        }
    }
}

.main-payment-summary {
    .billing-address {
        margin-top: 13px;

        @include respond-to(mobile) {
            margin: 0 0 25px;
        }

        .billing-title {
            @include respond-to(mobile) {
                display: inline-block;
                font-size: rem(16);
                text-transform: none;
                margin-bottom: 0;

                &:after {
                    content: ":";
                }
            }
        }
    }
}

.billing-title {
    font-size: rem(22);
    margin-bottom: 15px;
}

.billing-sub-title {
    font-size: rem(18);
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: none;
}

.billingproductlines {
    @include respond-to(mobile) {
        margin-top: 20px;
    }

    .table-div {
        .section-header-line,
        .cart-row {
            @extend %clearfix;
            margin-bottom: 30px;
        }

        .section-header-line {
            @include respond-to(mobile) {
                display: none;
            }
        }

        .cart-row {
            border-bottom: 1px solid $sections-body-border;
            padding-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .gift-wrap-products {
            border-bottom: 1px solid $sections-body-border;
            margin-bottom: 30px;
            padding-bottom: 30px;

            .cart-row {
                border-bottom: none;
                margin-bottom: 10px;
                padding-bottom: 0;
            }
        }

        .gift-wrap {
            overflow: hidden;

            &-message {
                word-wrap: break-word;
            }

            &-info {
                float: left;
                margin-right: 15px;
                width: 40%;
                margin-bottom: 10px;

                @include respond-to(mobile) {
                    float: none;
                    margin-right: 0;
                    width: auto;
                    margin-bottom: 0;
                }

                &-title {
                    font-weight: 700;
                }

                .price-standard {
                    font-weight: 400;
                }
            }

            &-actions {
                @extend %clearfix;

                @include respond-to(desktop) {
                    padding-bottom: 30px;
                    border-bottom: 1px solid $sections-body-border;
                }

                @include respond-to(mobile) {
                    float: none;
                    margin-top: 0;
                    border-bottom: 1px solid $sections-body-border;
                    padding-bottom: 30px;
                    margin-bottom: 20px;
                    text-align: center;
                }

                .button {
                    margin-bottom: 10px;

                    @include respond-to(mobile) {
                        margin: 20px auto 0;
                        float: none;
                    }
                }
            }
        }

        .section-header {
            float: left;

            &-product {
                padding-left: 20px;
                padding-right: 30px;
                width: 56%;
            }

            &-qty {
                padding-left: 0;
                text-align: center;
                width: 14%;
            }

            &-total {
                width: 30%;
            }
        }

        .item-image {
            float: left;
            padding-right: 15px;
            width: 20%;

            @include respond-to(mobile) {
                width: 40%;
            }

            img {
                max-width: 100%;
            }
        }

        .item-details {
            float: left;
            padding-right: 15px;
            width: 36%;

            @include respond-to(mobile) {
                float: right;
                padding-right: 0;
                width: 60%;
            }
        }

        .item-quantity {
            float: left;
            width: 14%;
            text-align: center;

            @include respond-to(mobile) {
                float: right;
                width: 60%;
                margin-top: 5px;
                text-align: left;

                &:before {
                    content: "Qty: ";
                }
            }
        }

        .item-total {
            float: left;
            padding-left: 10px;
            width: 30%;

            @include respond-to(mobile) {
                float: right;
                padding-left: 0;
                width: 60%;
            }
        }

        .price-unadjusted {
            @include respond-to(mobile) {
                order:2;
            }
        }

        .product-price {
            display: inline-block;
        }

        .product-brand {
            text-transform: uppercase;
            font-size: rem(18);
            font-weight: 500;
        }

        .name {
            font-size: rem(14);
        }

        .price-standard {
            font-size: rem(15);
            font-weight: 700;
        }

        .price-sales + .label {
            display: none;
        }

        .attribute {
            font-size: rem(14);
            color: $dim-gray;
        }

        .edit {
            font-size: rem(14);

            a {
                color: $dim-gray;
            }
        }

        .price-adjusted-total {
            font-weight: 500;
        }

        .cart-row-bundle {
            @extend %clearfix;
            margin-bottom: 10px;
            padding-bottom: 10px;
            clear: both;

            &:last-child {
                border-bottom: 1px solid $sections-body-border;
                margin-bottom: 0;

                .primary-content-payment & {
                    border-bottom: none;
                }
            }

            img {
                max-width: 80px;
                margin-bottom: 5px;

                @include respond-to(mobile) {
                    float: left;
                    margin-left: -90px;
                }
            }

            .product-brand {
                font-size: rem(16);
            }

            .item-details,
            .item-quantity {
                @include respond-to(mobile) {
                    padding-left: 90px;
                    margin-top: 0;
                }
            }
        }
    }
}

.main-payment-summary {
    .primary-content-payment {
        text-align: right;

        @include respond-to(mobile) {
            text-align: left;
        }
    }

    .order-summary {
        margin: 40px 0;

        .couponcode-link {
            display: inline-block;
            margin-bottom: 10px;
        }
    }

    .order-totals-table {
        float: right;
        min-width: 60%;
        position: relative;
        width: auto;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }

        &:after {
            border-bottom: 1px solid $very-light-gray;
            bottom: 68px;
            content: "";
            left: 0;
            position: absolute;
            right: 0;

            @include respond-to(mobile) {
                display: none;
            }
        }

        &-container {
            margin-bottom: 30px;
            overflow: hidden;
            position: relative;
        }

        tr {
            > td {
                &:first-child:after {
                    display: none;
                }

                + td {
                    font-weight: 400;

                    @include respond-to(mobile) {
                        text-align: right;
                    }
                }

                @include respond-to(mobile) {
                    text-align: left;
                }
            }
        }

        td {
            color: $nobel;
            font-size: rem(17);
            text-align: right;
            width: 70%;
        }

        .order-total {
            .order-value {
                color: $nobel;
            }
        }

        .remove-redemption {
            color: $sections-header-border;
            text-transform: lowercase;
        }
    }
}

.loyalty-container,
.gift-container {
    clear: both;
    margin-top: 15px;

    &-text {
        display: inline-block;
        line-height: 39px;
        margin-right: 25px;

        @include respond-to(mobile) {
            display: block;
            margin-right: 0;
            text-align: center;
        }
    }

    .button {
        padding: 11px 23px;
        min-width: 180px;
    }
}

.loyalty-gift-dialog {
    text-align: center;

    .error-form {
        text-align: center;
    }

    .description {
        font-size: rem(24);
        margin-bottom: 10px;
    }

    label {
        text-align: left;
    }

    .button {
        margin-top: 15px;
    }
}

.loyalti-terms {
    color: $sections-header-border;
    font-size: rem(14);
    margin-top: 25px;
}

.checkout-timeout {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $second-gray;
    background-position: center;
    background-repeat: no-repeat;

    @include respond-to(mobile) {
        background-size: cover;
    }

    &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 640px;
        margin: -115px 0 0 -320px;
        background: rgba(255,255,255,0.8);
        padding: 30px;
        text-align: center;

        @include respond-to(mobile) {
            max-width: 260px;
            margin: -125px 0 0 -130px;
            padding: 15px;
        }

        &-title {
            color: $second-black;
            font-size: rem(34);
            margin-bottom: 7px;

            @include respond-to(mobile) {
                font-size: rem(24);
            }
        }

        &-description {
            margin-bottom: 15px;
            color: $second-black;
        }
    }
}

#giftwrap-addgiftwrap-page,
#giftwrap-handleaction-page {
    .checkout-title {
        display: none;
    }
}

.giftwrap {
    &-banner {
        @include out-of-wrapper;
        background: $black url(../images/giftwrap-banne.jpg) no-repeat center center;
        height: 232px;
        margin-bottom: 40px;
        color: $white;
        text-align: center;

        h1,
        h2 {
            color: $white;
        }

        .checkout_header {
            margin: 40px 0 15px 0;
            padding-bottom: 15px;
            font-size: rem(25);
            position: relative;
            display: inline-block;

            &:after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                border-bottom: 1px solid $white;
                width: 60%;
                content: '';
            }

            @include respond-to(mobile_mid) {
                margin-top: 15px;
            }
        }

        .gift-wrapping_header {
            font-family: $font-third;
            font-size: rem(50);
            letter-spacing: 2px;
            text-transform: uppercase;
            line-height: 1;
            text-align: center;

            @include respond-to(mobile_mid) {
                font-size: rem(40);
            }

            span {
                font-family: $font-second;
                font-size: rem(32);
                letter-spacing: 0;
                text-transform: lowercase;
                line-height: 1;
                position: relative;
                z-index: 1;
                top: -5px;
                display: inline-block;

                @include respond-to(mobile_mid) {
                    font-size: rem(26);
                }
            }
        }
    }

    &-title-add {
        color: $black;
        font-size: rem(28);
        margin-bottom: 20px;
        text-align: center;
    }

    &-form {
        @extend %clearfix;
        margin-bottom: 35px;
    }

    &-table {

        @include respond-to(tablet_plus) {
            margin: 0 0 35px;
            table-layout: fixed;
        }

        &-list {
            @include respond-to(tablet_plus) {
                @include flexbox();
            }
        }

        &-item {
            @include respond-to(tablet_plus) {
                @include flexbox();
                @include flex-basis(50%);
                padding: 0 5px;
            }

            @include respond-to(mobile) {
                display: block;
                margin-bottom: 30px;
                padding: 0;
            }
        }
    }

    &-product {
        @include respond-to(tablet_plus) {
            @include flexbox();
            @include flex-vertical();
            @include flex-direction(column);
            @include justify-content(space-between);
            @include flex-basis(100%);
            text-align: center;
            border: 1px solid $second-gray;
            padding: 25px 25px 0;

            &:hover {
                background-color: $whisper;
            }
        }

        @include respond-to(mobile) {
            @include flexbox();
            flex-direction: column;
        }

        &-image {
            display: block;
            font-size: 0;
            margin: 0 auto;

            @include respond-to(tablet_plus) {
                margin-bottom: 25px;
                height: 260px;
            }

            @include respond-to(mobile) {
                display: none;
                order: 4;
                height: 200px;
            }

            &-img {
                max-height: 100%;
                border-radius: 50%;
            }
        }

        &-name {
            color: $black;
            display: block;
            font-size: rem(26);
            margin-bottom: 11px;
            text-transform: uppercase;

            @include respond-to(mobile) {
                display: none;
            }
        }

        &-description {
            color: $mid-gray;
            display: block;

            @include respond-to(tablet_plus) {
                height: 41px;
                overflow: hidden;
                margin-bottom: 33px;
            }

            @include respond-to(mobile) {
                order: 2;
                margin-bottom: 15px;
                padding-left: 24px;
            }
        }

        &-show-hide {
            @include respond-to(mobile) {
                display: block;
                order: 3;
                text-align: right;
                text-decoration: underline;
                text-transform: uppercase;

                .h {
                    display: none;
                }
            }
        }

        &.expanded {
            .giftwrap-product-show-hide {
                @include respond-to(mobile) {
                    margin-bottom: 20px;

                    .s {
                        display: none;
                    }

                    .h {
                        display: block;
                    }
                }
            }

            .giftwrap-product-image {
                @include respond-to(mobile) {
                    display: block;
                }
            }
        }

        &-choose {
            color: $mid-gray;
            display: block;
            font-size: rem(17);
            font-weight: 500;
            position: relative;
            margin-left: 24px;

            @include respond-to(tablet_plus) {
                border-top: 1px solid $second-gray;
                padding: 25px 0;
                margin-left: 0;
            }

            @include respond-to(mobile) {
                color: $black;
                order: 1;
                margin-bottom: 6px;
            }

            &-text {
                vertical-align: middle;
            }

            .input-radio {
                position: absolute;
                left: -24px;
                top: 5px;

                @include respond-to(tablet_plus) {
                    vertical-align: middle;
                    position: static;
                }
            }

            .product-price {
                display: inline;
                vertical-align: middle;

                .price-standard {
                    text-decoration: line-through;
                }
            }
        }
    }

    &-message {
        @extend %clearfix;

        .form-row {
            margin: 0;
        }

        .field-wrapper {
            margin-bottom: 7px;
        }

        label {
            cursor: default;
            color: $black;
            font-size: rem(19);
            font-weight: 500;
            margin-bottom: 14px;
            text-align: center;
        }

        .input-textarea {
            border-color: $sections-header-border;
            height: 172px;
        }

        &-remain {
            color: $sections-header-border;
            font-size: rem(14);
            margin-bottom: 20px;
            text-align: right;
        }
    }

    &-button-submit {
        @include respond-to(tablet_plus) {
            float: right;
        }

        @include respond-to(mobile) {
            margin-bottom: 16px;
            text-align: center;
        }

        button {
            @include respond-to(mobile) {
                width: auto;
            }
        }
    }

    &-button-cancel {
        @include respond-to(mobile) {
            text-align: center;
        }

        @include respond-to(tablet_plus) {
            float: left;
        }

        button {
            @include respond-to(mobile) {
                width: auto;
            }
        }
    }
}

.giftwrap-add-products {
    margin-bottom: 50px;

    &-title {
        color: $black;
        font-size: rem(28);
        margin-bottom: 5px;
        text-align: center;
    }

    &-sub-title {
        text-align: center;
        color: $dim-gray;
        margin-bottom: 20px;
    }

    &-btn-submit {
        @include respond-to(mobile) {
            margin-bottom: 16px;
            text-align: center;
        }

        @include respond-to(tablet_plus) {
            float: right;
        }

        button {
            @include respond-to(mobile) {
                width: auto;
            }
        }
    }

    &-btn-cancel {
        @include respond-to(tablet_plus) {
            float: left;
        }

        @include respond-to(mobile) {
            text-align: center;
        }

        button {
            @include respond-to(mobile) {
                width: auto;
            }
        }
    }

    &-list {
        @extend %clearfix;
        margin-bottom: 50px;

        @include respond-to(tablet_plus) {
            margin-left: auto;
            margin-right: auto;
            width: 840px;
            @include flexbox();
            @include flex-horizontal();
        }

        .gift-wrap-product-wrapper {
            border-bottom: 1px solid $sections-body-border;
            overflow: hidden;
            padding: 25px 40px;
            position: relative;

            @include respond-to(tablet_plus) {
                box-sizing: border-box;
                float: left;
                width: 50%;

                &:nth-child(odd) {
                    border-right: 1px solid $sections-body-border;
                }

                &:last-child {
                    border-right: none;
                }
            }

            @include respond-to(mobile) {
                border-top: 1px solid $sections-body-border;
                padding-left: 0;
                padding-right: 0;
            }

            .gift-wrap-product-choose {
                float: left;
                font-size: rem(20);
                width: 10%;

                @include respond-to(mobile) {
                    margin-top: 45px;
                }

                .compare-check {
                    position: absolute;
                    visibility: hidden;

                    &:checked + .fa-circle-o:before {
                        content: "\f192";
                    }
                }
            }

            .item-image {
                float: left;
                font-size: 0;
                width: 35%;

                @include respond-to(tablet_plus) {
                    height: 130px;
                    overflow: hidden;
                }

                img {
                    max-width: 100%;
                }
            }

            .item-details {
                float: right;
                width: 50%;

                .produt-brand {
                    color: $nobel;
                    display: block;
                    font-size: rem(18);
                    font-weight: 500;
                    text-transform: uppercase;
                }

                .produt-name {
                    color: $nobel;
                    display: block;
                    font-size: rem(14);
                    margin-bottom: 4px;
                }

                .product-price {
                    color: $nobel;
                    font-size: rem(14);
                    font-weight: 700;

                    .price-standard {
                        text-decoration: line-through;
                    }
                }

                .item-quantity {
                    color: $dim-gray;
                    font-size: rem(14);
                }

                .item-quantity-details {
                    @extend %clearfix;
                    margin-top: 5px;

                    @include respond-to(mobile) {
                        float: none;
                        padding: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.all-giftwraps-list {
    &-title {
        margin-bottom: 50px;
        text-align: center;

        .text {
            color: $black;
            display: inline-block;
            font-size: rem(20);
            font-weight: 500;
            position: relative;

            &:before {
                border-top: 1px solid $black;
                content: "";
                display: block;
                margin: 0 auto 38px;
                width: 80%;

                @include respond-to(mobile) {
                    margin-bottom: 20px;
                    width: 65%;
                }
            }

            &:after {
                border-top: 1px solid $black;
                content: "";
                display: block;
                margin: 38px auto 0;
                width: 80%;

                @include respond-to(mobile) {
                    margin-top: 20px;
                    width: 65%;
                }
            }
        }
    }

    .giftwraps-actions {
        text-align: center;
    }

    &-tr {
        display: inline-block;
        width: 25%;
        vertical-align: top;

        @include respond-to(tablet) {
            width: 50%;
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &-td {
        display: block;
    }

    .giftwrap-name {
        color: $black;
        font-weight: 500;
        margin-bottom: 15px;
        text-transform: uppercase;
        text-align: center;
        min-height: 3em;
    }

    .product-tile {
        margin-bottom: 10px;
        overflow: hidden;
        text-align: center;
    }

    .product-image {
        height: 180px;
        margin: 0 0 10px;
        padding: 0 20px;
        text-align: center;
        width: 100%;

        img {
            margin-top: 0;
            max-width: 100%;
            max-height: 100%;
        }

        .thumb-link {
            display: block;
            height: 100%;
            position: relative;
            vertical-align: bottom;
        }
    }

    .whishlist_instore-actions {
        display: none;
    }

    .product-description-link {
        float: right;
        padding: 0;
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }

    .product-brand {
        padding-top: 0;
        max-height: none;
        line-height: normal;
    }

    .product-name {
        max-height: none;
        line-height: normal;
        margin: 0 0 4px;
    }

    .product-pricing {
        font-size: rem(14);
        font-weight: 700;
    }
}

.select-saved-addresses {
    margin-bottom: 30px;

    &_select {
        margin-bottom: 20px;
    }

    &_button {
        color: $nobel;
        background-color: $white;
        border: 1px solid $charcoal;
        margin-right: 30px;

        @include respond-to(mobile) {
            margin: 0 0 20px 0;
        }
    }

    &_form {
        .form-row-button {
            width: auto;
        }

        .save-shipping-address {
            float: right;

            @include respond-to(mobile) {
                float: none;
                margin-bottom: 15px;
            }
        }
    }
}

.billing {
    .submit-billing {
        float: right;

        @include respond-to(mobile) {
            float: none;
            margin-top: 15px;
        }
    }
}

.billing-address-select.form-error {
    background-color: $lighter-gray;
    border: 1px solid $error;
    color: $error;
    display: block;
    font-size: rem(14);
    font-weight: 400;
    padding: 10px 8px;
    text-transform: uppercase;
}

.is-not-authenticated-customer {
    .js-form-row-field-ID {
        display: none;
    }
}

.phone-help-container {
    li {
        list-style: disc;
        margin-left: 20px;
    }
}

.js-show-phone-help {
    font-weight: 500;
}

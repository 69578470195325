// adverts
.advert-page {
  text-align: center;
  background: $white;
  position: relative;
  z-index: 1;
  display: flex;
  padding-top: 30px;
  align-items: center;

    .advert-page-title {
        background: $white;
        font-size: rem(14);
        padding: 5px 0;
        position: absolute;
        width: 100%;
        top: 0;

        p {
            margin: 0;
        }
    }

    .component-image {
        display: inline-block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 80%;
    }
}

.advert-page-video {
    position: relative;
    z-index: 1;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    .article-page-inlinevideo-iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

.advert-container {
    width: 100%;

    &.advert-container-mobile {
        @include respond-to(m_desktop) {
            display: none;
        }
    }

    &.advert-container-desktop {
        @include respond-to(m_tablet_down) {
            display: none;
        }
    }
}

.advert-link {
    display: block;
    margin: 0 auto;
    max-width: 80%;

    @include respond-to(m_desktop) {
        max-width: 60%;
    }
}

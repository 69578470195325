.flix-media-wrapper{
    width: 100%;
    float: left;
    padding: 0 5%;
    @include respond-to(mobile){
        padding: 0;
    }
    .flix-media-header{
        margin-top: 10px;
        & + .flix-media-content{
            margin-bottom: 60px;
        }
        &.hidden{
            margin-top: 0;
            & + .flix-media-content{
                margin-bottom: 0;
            }
        }
        h2{
            font-family: futura-pt,Arial,sans-serif;
            color: $night-rider;
            font-size: rem(14);
            font-weight: 500;
            letter-spacing: 1px;
            padding: 14px 0px;
            text-transform: uppercase;
            border-bottom: 1px solid $very-light-gray;
            @include respond-to(mobile){
                text-align: center;
            }
        }
    }
}
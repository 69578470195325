.primary-content {
    dl {
        clear: both;
        overflow: hidden;

        dt {
            clear: both;
            float: left;
            margin: 2% 0 0;
            min-width: 15%;
        }
        
        dd {
            float: left;
            margin: 2% 0 0;
            padding: 0 0 0 5%;
        }
    }
}

.col-1 {
    float: left;
    margin: 4px 0;
    width: 48%;
}

.col-2 {
    float: right;
    margin: 4px 0;
    width: 48%;
}

.section-header {
    border-top: 1px solid $sections-header-border;
    border-bottom: 1px solid $sections-header-border;
    padding: 10px 0 10px 10px;
    font-weight: 500;
}

.section-header-note {
    font-size: .9em;
    font-weight: 400;
    text-transform: none;
}

.back {
    display: block;
    margin: 0 0 10px;
}

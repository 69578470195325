.slider {
    display: flex;
    flex-direction: column;
}

.slider__items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;

    li button {
        width: 10px;
        height: 10px;
        font-size: 0;
        padding: 0;
        margin: 0 5px;
        border-radius: 50%;
        border: 1px solid $grey01;
        background-color: $grey02;
    }

    li.slick-active button {
        width: 18px;
        height: 18px;
        margin: 0 5px;
        border-radius: 50%;
        background-color: $pink;
    }
}

.slick-arrow {
    display: block;
    color: $black;
    background-color: $transparent;
    
    @include respond-to(mobile) {
        background: none;
    }

    &:hover {
        opacity: 0.6;
        color: $black;
        background-color: transparent;
        transition: opacity .2s ease-in-out;
    }

    &:before {
        font-size: rem(30);
    }
}

.slick-prev {
    @include icon(arrow-circle-left);
}

.slick-next {
    @include icon(arrow-circle-right);
}
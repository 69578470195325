#product-nav-container {
    display: none;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;

    div {
        float: left;
        text-align: center;
        width: 77px;
    }

    img {
        max-height: 100%;
        max-width: 80%;
    }

    span {
        display: block;
        height: 65px;
    }

    .divided span {
        border-right: 1px solid $gainsboro;
        padding-right: 5px;
    }
}

.primary-content { //This nesting is necessary to avoid applying styles for QV
    .product-wrapper {
        @include full-page-border;

        @include respond-to(mobile_strong_over) {
            margin-bottom: 96px;
        }

        @include respond-to(mobile_strong) {
            margin-bottom: 30px;
        }
    }
}

.pdp-main {
    @extend %clearfix;
    @include shared-promo-flag;

    margin-top: 5px;
    position: relative;

    .slot-panels-container {
        margin-left: 0;
    }

    .product-image-container {
        position: relative;
    }

    .product-image {
        .promo-flag {
            @include respond-to(mobile) {
                display: none;
            }
        }
    }

    .product-col-2 {
        .promo-flag {
            @include respond-to(tablet_plus) {
                display: none;
            }
        }
    }

    .pl-trustmark {
        position: absolute;
        top: 0;
        right: 20px;

        .PL-mark {
            width: 40px !important;
            height: 40px !important;
            background-size: 40px 40px!important;
        }
    }

    .product-col-1 {
        float: left;
        width: 49%;

        @include respond-to(mobile) {
            @include flexbox();
            position: relative;
            float: none;
            width: 100%;
            margin-top: 20px;
            -webkit-box-orient: vertical;
            -webkit-flex-flow: column;
            flex-flow: column;
        }
    }

    .product-col-mobile {
        display: none;

        @include respond-to(mobile) {
            display: block;
            text-align: center;
        }
    }

    .product-col-2 {
        float: right;
        padding-right: 5%;
        width: 45%;

        @include respond-to(mobile) {
            float: none;
            padding: 0;
            width: 100%;
        }

        .product-chanel-title {
            @include respond-to(mobile) {
                text-align: center;
            }
        }
    }

    .yotpo {
        @include respond-to(mobile) {
            display: inline-block;
        }

        .yotpo-stars {
            margin-right: 6px;

            .sr-only {
                display: none;
            }
        }

        .yotpo-nav-content > div.yotpo-active,
        input.yotpo-default-button.write-question-button.write-first-question-button {
            margin-left: 0 !important;
        }

        .yotpo-icon-star {
            &::before {
                color: $black;
            }
        }

        .yotpo-bottomline  {
            .yotpo-icon-empty-star,
            .yotpo-icon-half-star {
                color: $black;
            }
        }

        .text-m {
            &.write-review-btn-hidden {
                &:before { content: ""; }
                &:after  { content: ""; }
            }
        }
    }

    .product-name {
        margin-bottom: 12px;

        @include respond-to(mobile) {
            display: inline-block;
            margin: 0;
            padding: 0 10px;
        }
    }

    .product-detail {
        @include respond-to(mobile) {
            text-align: center;
        }

        .product_actions-instore_link,
        .product_actions-wishlist_link {
            @include product_actions-button;

            &:hover {
                @include respond-to(desktop_below) {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }

        .product_actions-wishlist_link {
            @include shared-wishlist;
        }
    }

    .product-name-brand {
        display: block;
        font-size: rem(20);
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 0 5px;
    }

    .product-collection {
        display: block;
        font-size: rem(14);
        margin: 0 0 10px;
    }

    .product-name-title {
        display: block;
        font-size: rem(19);
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 1.6em;
        margin: 0 0 6px;
        text-transform: none;
    }

    .product-number {
        color: $very-light-gray;
        font-size: rem(6.5);
        margin-bottom: .5rem;
    }

    .product-price {
        font-size: rem(20);
        font-weight: 500;
        margin: 20px 0 11px;

        @include respond-to (mobile) {
            margin: 0 0 15px 0;
        }

        .product-sales-price {
            color: $black;
            font-size: rem(19);

            + .product-standard-price.rrp-price {
                color: #999999;
                font-size: 60%;
                text-transform: uppercase;
            }
        }

        .price-sales,
        .salesprice {
            color: #B22E2E;
            font-weight: 700;
            font-size: rem(19);

            &.has-rrp-price {
                color: $black;
            }

            &:only-of-type {
                color: $black01;
            }

            @include respond-to (mobile) {
                font-size: rem(16);
            }

            ~ .price-standard {
                color: #999999;
                font-size: 60%;
                text-transform: uppercase;
                text-decoration: line-through;

                &.rrp-price {
                    text-decoration: none;
                }

                @include respond-to(mobile) {
                    display: block;
                }
            }
        }

        .salesprice {
            display: inline;
        }

        .now-sell-price {
            color: #B22E2E;
            font-weight: 700;
            font-size: rem(19);

            .price-sale-comment {
                display: none;
            }

            ~ .product-standard-price {
                color: #999999;
                font-size: 60%;
                text-transform: uppercase;
                text-decoration: line-through;
            }
        }
    }

    .available-delivery-msg {
        border: 1px solid $very-light-gray;
        color: $black02;
        font-size: rem(11);
        line-height: 1.5;
        padding: 9px 12px;
        text-align: left;
        width: 85%;

        @include respond-to(m_desktop) {
            margin: 26px 0;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        .available-delivery-msg-title {
            font-size: rem(14);
            font-weight: 700;
        }
    }

    .primary-content & { //This nesting is necessary to avoid applying styles for QV
        .product-detail {
            margin-bottom: 95px;

            @include respond-to(mobile) {
                margin-bottom: 50px;
            }
        }

        &:not(.product-detail-chanel) {
            .available-delivery-msg {
                @include respond-to(mobile) {
                    display: none;
                }
            }

            .add-to-cart-wrapper .available-delivery-msg { // Show message under button on Mobile & Tablet
                @include respond-to(mobile) {
                    display: block;
                }
            }

            &.delivery-not-available .add-to-cart-wrapper button { // Hide all buttons when delivery isn't available
                @include respond-to(mobile) {
                    display: none;
                }
            }
        }
    }

    .product-add-giftcard .available-delivery-msg {
        display: block;
    }

    .price_review-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-direction: column;
        align-items: start;

        @include respond-to (mobile) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
            
        }
    }

    .product-review {
        @extend %clearfix;
        margin-bottom: 5px;
        margin-top: 20px;
        overflow: hidden;

        @include respond-to (mobile) {
            margin-top: 20px;
            text-align: center;
        }
    }

    .product-main-attributes {
        .attribute {
            padding: .2em 0;
        }

        .label {
            padding: 0;
        }
    }

    .primary-image,
    .zoom-picture,
    .video-content {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .product-primary-image {
        display: block;
        margin: 0 auto 25px;
        position: relative;
        max-width: 100%;
        text-align: center;

        @include respond-to(mobile) {
            width: 100%;
        }

        .main-image,
        .video-inner {
            @include lazyload-animation;

            position: relative;
            height: 100%;
        }
    }

    .product-tile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .product-actions {
            display: block;
        }
    }

    .product-info {
        padding-top: 1em;

        ul {
            font-size: rem(8);
            list-style: square;
            padding: 0 5em;

            @include respond-to(tablet_plus) {
                padding-bottom: 1em;
                padding-top: 1em;
            }

            &.tab-content-list {
                padding: 0 15px;
                font-size: rem(14);
                margin: 0;
                list-style-type: disc;
            }
        }

        .tabs {
            .tab-content {
                margin-bottom: 0;
                font-size: rem(14);
                font-weight: 400;

                &-description {
                    margin-bottom: 15px;
                    padding-left: 15px;
                }

                .tab-content-description:empty {
                    margin-bottom: 0;
                }
            }

            .slot-panels-container {
                margin-left: 0;
            }

            .tab-label {
                display: block;
                position: relative;
                z-index: 2;
                text-transform: capitalize;
                font-size: rem(12);

                &:before {
                    content: '';
                }
            }
        }
    }

    // Product Add to Cart Area (Availability, Quantity)
    .product-offers {
        padding-top: 1em;
        clear: both;

        .online-stock span {
            float: left;
        }

        .store-stock {
            display: block;
            clear: left;
        }

        .inventory {
            margin-bottom: 26px;
        }

        // Fix order for case when Apple Pay button & widget-live-chat are in one area
        .ios &,
        .safari & {
            .add-to-cart-wrapper {
                display: flex;
                flex-direction: column;
            }

            .add-to-cart,
            .add-to-cart-disabled {
                order: 1;
            }

            .acme-btn {
                order: 3;
            }

            .applepay-button {
                order: 2;
            }
        }
    }

    .add-to-cart {
        &-disabled {
            background-color: $gray;
            cursor: default;
            opacity: 0.7;
            width: 100%;

            &:hover {
                background-color: $suva-gray;
            }
        }
    }

    .add-to-cart-sticky-wrapper {
        @include respond-to(mobile) { // this is to enable sticky-kit for mobile layout only
            &::before {
                content: "js-sticky-enabled";
                display: none;
            }

            &.sticky {
                z-index: 10;
                top: auto !important;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $white;
                padding: 10px 15px;
                width: 100% !important;

            }
        }
    }

    .js-add-to-cart-active {
        @include respond-to(mobile) { // this is to enable size selector pop-up on mobile
            &::before {
                content: "js-size-selector-popup";
                display: none;
            }
        }
    }

    .quantity {
        label {
            margin-bottom: 5px;
        }
        input.error {
            border-top: 1px solid $tutu;
            border-bottom: 1px solid $tutu;
            margin-bottom: 38px;
            -webkit-transition: none;
            transition: none;

            ~ span.error {
                position: absolute;
                top: 100%;
                margin-top: -38px;
                background-color: transparent;
                border: none;
                text-transform: none;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .error-quantity {
        margin-top: 0;
    }

    .quickview-actions {
        display: none;
    }

    .product-actions-giftregistry {
        float: right;
    }

    .product-actions-store {
        float: left;
    }

    .product-actions-store {
        @include respond-to(mobile_min) {
            float: none;
            clear: both;
            display: block;
            margin-bottom: 10px;
        }
    }

    #sf-contextual-widget-live-chat,
    .sfloor-btn-wrapper {
        display: none;
    }

    // Alcohol Delivery
    .alco-delivery-info {
        border: 1px solid $black;
        margin-bottom: 35px;
        padding: 20px;
        text-align: center;
        border-radius: 4px;
        font-size: rem(12);

        &-title {
            font-weight: 500;
            margin-bottom: 10px;
            font-size: rem(12);
        }

        p {
            margin: 0;
        }
    }

    // Gift wrapping message
    .gift-message-box {
        margin: 10px 0 20px;
        padding: 15px 15px 20px;
        background-color: $light-gray02;

        @include respond-to(mobile) {
            text-align: left;
        }

        &__text,
        &__label_medium {
            margin: 0;
            font-size: rem(14);
        }

        &__text {
            @include respond-to(mobile) {
                font-size: rem(12);
            }
        }

        &__label_medium {
            font-weight: 700;
            margin-bottom: 10px;
        }

        &__remain {
            font-size: rem(12);
            color: $light-gray03;
            margin: 0 0 15px;
        }

        .field-wrapper {
            margin: 0;
        }

        .form-row {
            margin: 0 0 15px;
        }

        label {
            @include respond-to(mobile) {
                display: block;
            }
        }

        .label-optional {
            display: none;
        }
    }

    // Product Set Display
    .product-set {
        .product-name {
            font-size: rem(23);
            letter-spacing: 1px;
            line-height: 1;
            margin-bottom: 6px;
        }

        .product-price {
            font-size: rem(22);
            font-weight: 400;
            line-height: 35px;
            margin: 0 0 20px;
        }

        .product-number {
            display: none;
        }

        .ps-description-desktop {
            margin-bottom: 20px;
            font-size: rem(14);
            line-height: 1.5;
        }

        .add-all-to-cart {
            margin-bottom: 24px;
        }

        .product-set-list {
            margin-bottom: 35px;
            clear: both;

            .product-set-item {
                @extend %clearfix;
                border-top: 1px solid $very-light-gray;
                clear: both;
                overflow: hidden;
                padding: 24px 0 24px 150px;

                &:last-child {
                    border-bottom: 1px solid $very-light-gray;
                }
            }

            .product-set-image {
                @include lazyload-animation;

                margin-left: -150px;
                width: 125px;
                height: 125px;
                padding-bottom: 125px;
                float: left;
                position: relative;

                @include respond-to(mobile_mid) {
                    margin-bottom: 20px;
                }

                .product-thumbnail {
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                }
            }

            .product-set-details {
                margin-bottom: 35px;

                @include respond-to(mobile) {
                    text-align: left;
                }
            }

            .product-name {
                font-size: rem(18);
                letter-spacing: 1px;
                margin-bottom: 12px;
                text-transform: none;

                @include respond-to(mobile) {
                    display: block;
                    padding-left: 0;
                }
            }

            .product-number,
            .availability-web {
                display: none;
            }

            .product-price {
                font-size: rem(16);
                font-weight: 500;
                line-height: 1;
                margin: 0 0 30px;

                @include respond-to(mobile) {
                    display: block;
                }
            }

            .product-variations {
                @include respond-to(mobile) {
                    text-align: left;

                    .attribute .value {
                        width: 100%;
                    }
                }

                .toggle {
                    .selected-value,
                    &.expanded > ul {
                        @include respond-to(mobile_tablet) {
                            width: 100%;
                        }
                    }
                }

                > ul {
                    float: left;
                    width: 100%;
                }
            }

            .variation-attributes {
                @include respond-to(mobile) {
                    text-align: left;
                }

                > div {
                    font-size: rem(14);
                    text-transform: none;
                    margin: 0 0 15px;
                }

            }

            .explore-colours {
                font-size: rem(14);
                margin-left: 5px;
            }

            .selected-value {
                white-space: nowrap;

                @include respond-to(touch) {
                    width: 65%;
                }

                span {
                    @include respond-to(touch) {
                        overflow: initial;
                    }
                }
            }

            .inventory + .variation-attributes {
                margin-top: -10px;
            }

            .add-to-cart {
                margin-bottom: 0;
            }
        }

        .product-add-to-cart {
            .label-buy-all {
                display: none;
            }
        }
    }

    // Product Recommendations
    .recommendations {
        clear: left;
        padding: 2em 0;

        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }

        h2 {
            font-size: 2em;
            font-style: italic;
            font-weight: 400;
            margin-left: 14px;
        }

        #carousel-recommendations {
            margin: auto;
            position: relative;
            overflow: hidden;
            width: 250px;

            @media screen and (min-width: 690px) {
                width: 500px;
            }

            @include respond-to(tablet_plus) {
                width: 750px;
            }
        }

        ul {
            list-style-type: none;
            position: relative;
            width: 2000em;
        }

        li {
            float: left;
            width: 250px;
        }

        .product-tile {
            padding: 0 2em;
        }

        .product-name {
            font-size: 1em;
            margin: .2em 0;
            width: 100%;
        }

        .product-price {
            font-size: 1em;
        }

        .jcarousel-clip {
            margin: auto;
            overflow: hidden;
            width: 300px;
        }

        .jcarousel-nav {
            cursor: pointer;
            position: absolute;
            top: 20%;

            &.inactive {
                display: none;
            }
        }

        .jcarousel-prev {
            left: 0;
        }

        .jcarousel-next {
            right: 0;
        }

        .product-tile:hover .recommendation-tooltip {
            display: block !important;
        }
    }

    .product-outofstock,
    .product-variations,
    .pdpForm {
        .is-product-set {
            width: 100%;
        }
    }

    .pdpForm {
        .whishlist_instore-actions {
            display: none;
        }
    }

    &.is-product-set {
        .product-name-title {
            font-size: rem(16);
            letter-spacing: 0;
            font-weight: 400;

            @include respond-to(mobile_strong_over) {
                margin: 0 0 26px;
            }
        }
    }
    .product-col-2.product-set {
        .qty-title {
            width: 160px;
            line-height: 30px;
            font-size: rem(14);
            float: right;
            text-align: center;
            font-weight: 500;
            text-transform: uppercase;

            @include respond-to(mobile) {
                display: none;
            }
        }
    }
    .product-set .product-set-list .product-set-details {
        margin-bottom: 0;
    }

    //Same day delivery
    .delivery-wrapper {
        background-color: $light-gray01;
        display: flex;
        align-items: center;
        padding: 15px 25px;
        margin-bottom: 20px;

        @include respond-to(mobile) {
            margin: 0 -15px 15px;
            text-align: left;
        }

        @include respond-to(touch) {
            padding: 15px 19px;
        }

        @include respond-to(mobile_strong) {
            padding: 15px 15px 15px 20px;
        }

        &::before {
            content: " ";
            background: url("../img/delivery_car.png") center / cover no-repeat;
            width: 80px;
            height: 52px;
            display: inline-block;

            @include respond-to(touch) {
                width: 95px;
                height: 55px;
            }

            @include respond-to(mobile) {
                width: 51px;
                height: 49px;
            }

            @include respond-to(mobile_mid) {
                width: 115px;
                height: 60px;
            }
       }

        &-info {
            padding-left: 25px;

            @include respond-to(touch) {
                padding-left: 20px;
            }

            @include respond-to(mobile_strong) {
                padding-left: 16px;
            }
        }

        &-title {
            font-size: 1.5rem;
            line-height: 22px;
            font-weight: $bold;
            margin-bottom: 8px;

           &-red {
                color: $pink;
           }
        }

        &-body {
            font-size: 1.3rem;
            line-height: 20px;
        }

        &-timer {
            font-weight: $bold;
            color: $pink;
        }
    }

    .keyingredient {
        margin-right: 10px;
        display: inline-block;
        line-height: 2;

        &:last-child {
            margin-right: 0;
        }
    }

    .keyingredient-link {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            border: 0.5px solid $pink;
            visibility: visible;
            transition: all .3s ease-in-out 0s;
            transform-origin: 0%;
            cursor: pointer;
        }

        @include respond-to(m_desktop) {
            &:hover:after {
                visibility: hidden;
                transform: scaleX(0);
                transform-origin: 0% 50%;
            }
        }

        @include respond-to(m_tablet_down) {
            &:active:after {
                visibility: hidden;
                transform: scaleX(0);
                transform-origin: 0% 50%;
            }
        }
    }
}

.pdp-main .video-inner {
    padding-bottom: 100%;
}

.video-inner {
    position: relative;
    height: 100%;
}

.video-content {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.zoomImg,
.primary-image {
    max-width: 100%;
    display: block;
}

.product-image__picture {
    display: block;

    .zoomImg {
        cursor: url(#{$m_zoom-cursor}) 10 10, pointer !important;
        display: block;
    }
}

// COLOR PICKER
.color-picker {
    overflow-y: auto;

    @include respond-to(tablet_plus) {
        margin: 25px 0 20px;
        max-height: 170px;
        display: flex;
        flex-wrap: wrap;
    }

    @include respond-to(mobile) {
        display: none;

        .vto-opened & {
            display: block;
            white-space: nowrap;
            text-align: left;
            font-size: 0;
        }
    }

    .ui-dialog & {
        display: none;
    }

    &-wrapper {
        position: relative;
        max-width: 415px; // To keep 8 items in one row when Windows scroll appears

        @include respond-to(tablet_ext) {
            max-width: 100%;
        }

        @include respond-to(mobile) {
            max-width: none;

            .vto-opened & { // Fix jumping variations
                height: 36px;
                overflow: hidden;
            }
        }
    }

    &-item {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin: 0 15px 15px 0;
        overflow: hidden;

        @include respond-to(m_desktop) {
            &:nth-child(25) { // Add border to .color-picker-wrapper if it contains more than 24 elements
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: $black;
                    height: 1px;
                }
            }
        }
    }

    .selectable {
        padding: 2px;
    }

    .selected {
        border: 1px solid $black;
    }

    .unselectable {
        border: 2px solid $tutu;
        position: relative;

        &::before {
            content: " ";
            position: absolute;
            border-bottom: 2px solid $tutu;
            transform: rotate(-45deg);
            transform-origin: left bottom;
            width: 143%;
            bottom: -2px;
            left: 1px;
            z-index: 1;
        }

        .swatchanchor {
            cursor: default;
        }
    }

    .swatchanchor,
    .swatchanchor-image {
        display: block;
        width: 100%;
        height: 100%;
    }

    .swatchanchor-image-src {
        width: 100%;
    }
}

// --- Hide usual variation and show only color picker when VTO is opened --- //
.color-picker-wrapper {
    .vto-opened & {
        @include respond-to(mobile) {
            display: block !important; // Overwrite utility class .hidden
        }
    }
}

.variation-container .swatches {
    .vto-opened & {
        @include respond-to(mobile) {
            display: none;
        }
    }
}
// END //

.rating {
    margin-top: 5px;
    color: $black;

    .product-info & {
        font-size: 2em;
    }

    i {
        margin-right: 4px;
    }
}

.product-alt-mobile {
    display: none;

    @include respond-to(mobile) {
        display: block;
        text-align: center;
    }
}

.product-variations-list {
    margin: 0;

    .swatches {
        margin: 0;
    }
}

.product-variations,
.product-options {

    @include respond-to(mobile) {
        text-align: center;
        margin-bottom: 20px;
    }

    .attribute {
        margin: 0;
        padding: 0;
        position: relative;
        clear: both;

        + .attribute {
            @include respond-to(mobile) {
                margin-top: 15px;
            }
        }

        h3,
        .label {
            margin-bottom: .3rem;
        }

        .label {
            text-transform: capitalize;
        }

        .value {
            width: 100%;

            @include respond-to(mobile) {
                width: 80%;
                margin: 0 auto;
            }

            .variation-select {
                width: 60%;
                text-transform: capitalize;

                @include respond-to(mobile) {
                    width: 100%;
                    padding-right: 4px;
                }
            }
        }

        .is-explore-colours {
            @include respond-to(desktop_plus) {
                display: inline;
            }
        }

        ul {
            display: inline-block;
            width: 100%;

            &.size {
                width: auto;
            }
        }
    }

    .error {
        .variation-select {
            border-color: $red01;
        }

        .value {
            @include respond-to(mobile) {
                display: flex;
                flex-direction: column;

                .variation-select {
                    order: 1;
                }

                .error-text {
                    order: 2;
                }
            }
        }
    }

    .error-text {
        color: $red01;
        font-size: rem(12);
        margin-top: 3px;

        @include respond-to(mobile) {
            text-align: left;
        }
    }

    .swatches {
        font-size: 0;

        &.color {
            .selected-value {
                display: none;
            }
        }

        li {
            display: inline-block;
            margin: 0 22px 8px 0;
            vertical-align: middle;
            width: 36px;

            @include respond-to(mobile) {
                margin: 0 0 8px 22px;

                &:only-child,
                &:first-child {
                    margin-left: 0;
                }
            }

            .swatchanchor-image {
                display: inline-block;
            }

            &:only-child,
            &:last-child {
                margin-right: 0;
            }

            &.unselectable {
                .product-detail & {
                    opacity: 1 ; // restore opacity & avoid important;
                }

                a {
                    cursor: default;
                    position: relative;
                    overflow: hidden;

                    @include respond-to(mobile) {
                        margin: 0;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        border-bottom: 2px solid rgba($lightest-gray, .75);
                        transform: rotate(-45deg);
                        transform-origin: left bottom;
                        width: 50px;
                        bottom: 0;
                        left: 2px;
                        z-index: 1;
                    }

                    img {
                        opacity: 0.75;
                        border: 2px solid darken($lightest-gray, 5%); // compensate .75 opacity on image
                    }

                    span {
                        opacity: .4; // apply opacity only on text - not on .li.unselectable
                    }
                }
            }
        }

        .swatchanchor {
            cursor: pointer;
            width: 36px;
            height: 36px;
            display: block;
            padding: 0;
            border: 0;
            font-size: 0;
            margin: 0 18px 0 0;

            @include respond-to(mobile_tablet) {
                margin: 0 3px;
            }

            &:hover img {
                border: 2px solid $lightest-gray;
            }
        }

        .selected-value {
            .swatchanchor {
                img,
                &:hover img {
                    border: 2px solid $tutu;
                    opacity: 0.6;
                }
            }
        }

        img {
            margin-top: 0;
            width: 100%;
            height: auto;
        }

        .swatch-image {
            max-width: 100%;
        }

        .selectable {
            a {
                cursor: pointer;
                width: 36px;
                height: 36px;
                display: block;
                margin: 0;
                padding: 0;
                border:0;
                font-size: 0;
            }

            &.selected img {
                border: 2px solid $tutu;
            }

            &:hover img {
                border: 2px solid $lightest-gray;
            }

            &.selected-value {
                a {
                    cursor: default;
                }
            }
        }

        .unselectable.selected {
            a::before {
                border-color: lighten($tutu, 7%); // compensate .75 opacity on image
            }

            img {
                border: 2px solid $tutu;
            }
        }
    }

    .expanded .swatches li {
        &.unselectable {
            .swatchanchor {
                &::before {
                    width: 40px;
                    bottom: 2px;
                    left: 2px;
                }

                &.with-image {
                    &::before {
                        width: 31px;
                    }
                }
            }
        }
    }

    .color {
        li {
            a {
                line-height: 0;
                width: 2.625rem;
                height: 2.625rem;

                @include respond-to(tablet_plus) {
                    width: 2rem;
                    height: 2rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        select {
            background-color: $white;
            border: 1px solid $gainsboro;
            border-radius: 0;
            height: 42px;
            width: 100%;
            margin-left: 0;

            @include respond-to(tablet_plus) {
                height: 32px;
            }
        }
    }

    .toggle {
        .swatches.size,
        > ul {
            display: none;
            max-height: 177px;
            overflow: auto;
        }

        &.expanded {
            .swatches.size,
            > ul {
                display: block;
                position: absolute;
                top: calc(100% - 1px);
                background: $white;
                width: 55%;
                z-index: 2;
                border: 1px solid $tutu;

                @include respond-to(mobile) {
                    width: 80%;
                }

                &.size {
                    top: 66px;

                    .bonus-product-tile & {
                        width: 170px;
                        max-height: 130px;
                        top: 38px;
                        left: 50%;
                        margin-left: -85px;
                        margin-bottom: 0;
                        text-align: left;

                        @include respond-to(mobile) {
                            width: 150px;
                            margin-left: -75px;
                        }
                        @include respond-to(mobile_mid) {
                            width: 130px;
                            margin-left: -65px;
                        }
                    }
                }

                li {
                    display: block;
                    width: 100%;
                    line-height: normal;
                    min-height: 36px;
                    height: auto;
                    margin: 0;
                    padding: 5px;
                    background: $white;
                    -webkit-transition: background .3s ease-in-out;
                    transition: background .3s ease-in-out;
                    color: $charcoal;
                    opacity: 0.4;

                    @include respond-to(mobile_tablet) {
                        text-align: left;
                    }

                    @include respond-to(mobile) {
                        width: 95%;
                        margin: 0;
                    }

                    &:hover {
                        background: $tutu;
                    }

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }

                    &.selectable {
                        cursor: pointer;
                        opacity: 1;
                        box-shadow: none;

                        a {
                            cursor: pointer;
                        }
                    }


                    &.selected-value {
                        display: none;
                    }

                    img {
                        display: inline-block;
                        max-width: 25px;
                        height: auto;
                        margin: 0;
                        vertical-align: middle;
                        padding-top: 0;
                    }

                    a {
                        cursor: default;
                        vertical-align: middle;
                        width: 100%;
                    }

                    a.swatchanchor {
                        @include flexbox();
                        @include align-items(center);

                        height: auto;
                        line-height: normal;
                        width: auto;

                        span {
                            line-height: normal;
                            word-break: break-word;
                        }
                    }

                    .swatchanchor-image {
                        min-width: 35px;
                        position: relative;
                    }

                    span {
                        display: inline-block;
                        font-size: rem(16);
                        vertical-align: middle;
                        padding-left: 4px;
                        line-height: 35px;
                    }

                    &.unselectable {
                         a.swatchanchor {
                            &:before {
                                width: 50px;
                            }

                            &.with-image {
                                &:before {
                                    display: none;
                                }
                            }
                        }

                        .swatchanchor-image {
                            position: relative;
                            overflow: hidden;

                            &:before {
                                content: '';
                                position: absolute;
                                border-bottom: 2px solid rgba($lightest-gray, .75);
                                transform: rotate(-45deg);
                                transform-origin: left bottom;
                                width: 33px;
                                bottom: 0;
                                left: 2px;
                                z-index: 1;
                            }

                            &.with-image {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .selected-value {
                color: $tutu;
            }

            .swatchanchor {
                font-size: rem(16);
                line-height: 36px;
            }
        }

        .selected-value {
            display: inline-block;
            position: relative;
            border: 1px solid $tutu;
            padding: 0px 35px 0px 5px;
            color: $nobel;
            font-weight: 400;
            text-transform: none;
            white-space: nowrap;
            cursor: pointer;
            font-size: rem(14);
            width: 55%;
            line-height: rem(16);

            @include respond-to(1200px) {
                width: 70%;
            }

            @include respond-to(mobile_tablet) {
                text-align: left;
                width: 100%;
            }

            @include respond-to(mobile) {
                width: 100%;
            }

            &:after {
                font-family: $font-icons;
                content: "\e902";
                position: absolute;
                right: 5px;
                top: 50%;
                text-align: center;
                font-size: rem(14);
                font-weight: 700;
                padding: 5px;
                line-height: normal;
                color: $tutu;
                transform: translateY(-50%);
                pointer-events: none;
            }

            img {
                display: inline-block;
                margin: 5px 5px 5px 0;
                max-width: 25px;
                max-height: 25px;
                height: auto;
                vertical-align: middle;
            }

            span {
                @include text-truncate;

                display: inline-block;
                vertical-align: top;
                line-height: 35px;
                padding: 0;
                width: calc(100% - 35px);

                @include respond-to(desktop_over) {
                    vertical-align: middle;
                }
            }
        }

        &.toggle-disabled {
            .selected-value {
                color: $tutu;
            }
        }
    }
}

.bonus-product-tile {
    .product-tile {
        overflow: visible;
    }

    .product-options,
    .product-variations {
        .toggle {
            .selected-value {
                width: 170px;
                display: flex;
                margin: 0 auto;
                padding: 0px 30px 0px 5px;
                font-size: rem(14);

                span {
                    @include text-truncate;
                    width: 100px;
                }

                @include respond-to(mobile) {
                    width: 150px;
                    padding: 0 35px 0 5px;
                }
                @include respond-to(mobile_mid) {
                    width: 130px;
                }
            }

            &.expanded > ul {
                width: 170px;
                max-height: 130px;
                top: 38px;
                left: 50%;
                margin-left: -85px;
                text-align: left;

                @include respond-to(mobile) {
                    width: 150px;
                    margin-left: -75px
                }

                @include respond-to(mobile_mid) {
                    width: 130px;
                    margin-left: -65px;
                }

                img {
                    margin: 0;
                }

                span {
                    width: calc(100% - 30px);
                    word-wrap: break-word;
                    line-height: 16px;
                }
            }
        }
    }
}

.explore-colours {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    margin-left: 25px;
    line-height: 25px;

    @include respond-to(mobile_tablet) {
        display: block;
        margin-top: 15px;
        margin-left: 0;
        font-size: rem(13);
        line-height: 20px;
    }

    a {
        font-size: rem(14);

        @include underlined_link;
    }
}

.pdp-book-appointment {
    border: 1px solid;
    padding: 20px;
    font-size: rem(14);
    text-align: center;

    b {
        display: block;
        text-transform: uppercase;
        font-size: rem(14);
        font-weight: 700;
    }

    a {
        display: block;
        margin-top: 10px;
        font-weight: 500;
        font-size: rem(15);
        text-decoration: underline;

        @include respond-to(mobile) {
            margin: 20px 0 10px;
        }
    }
}

#product-code {
    text-align: right;
    margin-top: 10px;
    color: $dim-gray;
}

/* Explore Colours */
.product-variations {
    &.ui-tabs {
        padding: 0;
    }

    .product-variations-tabs {
        &.ui-tabs-nav {
            float: none;
            font-size: 0;
            height: auto;

            .ui-tabs-anchor {
                border: 1px solid $charcoal;
                color: $charcoal;
                display: block;
                float: none;
                font-size: rem(16);
                line-height: 25px;
                padding: 6px 11px;
                position: relative;
                text-align: center;
                text-transform: uppercase;
                transition: none;
                z-index: 1;
            }

            li {
                background-color: $white;
                border: none;
                margin: 0;
                padding: 0;
                width: 33.333%;
                margin-left: -1px;

                &.ui-state-active {
                    .ui-tabs-anchor {
                        border: 2px solid $nobel;
                        color: $nobel;
                        font-weight: 400;
                        padding: 5px 10px;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .ui-tabs .ui-tabs-panel {
        border: none;
        padding: 30px 0 0;

        .swatches {
            float: none;
            overflow: hidden;
            padding-left: 150px;

            @include respond-to(mobile) {
                padding-left: 0;
            }

            .selectable,
            .unselectable {
                float: left;
                margin: 0 23px 15px 0;
            }

            .product-variations-group-title {
                clear: both;
                float: left;
                margin-left: -150px;
                width: auto;
                line-height: 21px;
                color: $hel-gray;
                font-size: rem(14);

                @include respond-to(mobile) {
                    float: none;
                    margin-left: 0;
                    display: block;
                    text-align: left;
                }
            }
        }
    }
}

.product-variations-selected-color {
    padding-left: 150px;

    @include respond-to(mobile) {
        padding-left: 0;
    }
}

.pdp-call-for-details {
    background: $teal-lightest;
    color: $white;
    padding: 18px 5px;
    margin: 25px 0 15px 0;
    text-transform: uppercase;
    font-size: rem(14);
    text-align: center;

    span {
        margin-right: 20px;
        color: $white;

        @include respond-to(mobile) {
            display: block;
        }
    }

    i {
        @include respond-to(mobile) {
            display: none;
        }
    }
}

/* Out of stock message */
.product-outofstock {
    margin: 30px 0;

    @include respond-to(mobile) {
        text-align: center;

        .wishlist-products-form & {
            width: 100%;
        }
    }

    .oos-link {
        text-decoration: underline;
        color: $nobel;
        cursor: pointer;

        @include respond-to(mobile) {
            display: block;
            margin-top: 20px;
        }

        &:hover {
            color: $dim-gray;
        }
    }

    .product-actions-notify-me {
        display: block;
    }

    .select-error {
        display: none;
        margin-top: 10px;
        color: $red;
    }
}

.oos-label {
    display: inline-block;
    padding: 2px 8px;
    border: 1px solid $error;
    text-transform: uppercase;
    color: $error;
    font-size: rem(12);
    font-weight: 700;
    margin-bottom: 5px;
}

#dialog-container {
    & + .ui-dialog-buttonpane {
        display: block;
    }

    &.notifyme-submitted + .ui-dialog-buttonpane {
        display: block;
    }
}

.notify-container {
    margin-top: 40px;

    @include respond-to(mobile) {
        margin-top: 20px;
    }

    .form-row {
        width: 70%;
        display: inline-block;

        @include respond-to(mobile) {
            margin-left: 5%;
            width: 90%;
        }
    }

    span {
        display: block;
        margin-bottom: 30px;
        text-align: center;
        font-size: rem(14);
    }

    button {
        display: inline-block;
        padding: 11px 23px;
        vertical-align: top;
        margin: 5px 0 0 20px;
        width: 25%;

        @include respond-to(mobile) {
            width: 90%;
            margin: 0 5% 20px;
        }
    }
}

/* Chanel PDP template */
.product-row-brand-logo {
    @include respond-to(mobile) {
        text-align: center;
    }

    a {
        display: block;
    }
}

.product-detail-chanel {
    h1,
    .product-info .tab-content,
    .product-col-2,
    .product-col-1,
    button {
        font-family: Arial, sans-serif;
    }

    .tab-content {
        font-size: rem(15);
    }

    .product-chanel-title {
        font-weight: bold;
        font-size: rem(22);
        text-align: left;
    }

    .product-name-title {
        font-size: rem(14);
        letter-spacing: 0;
    }

    .chanel-collection {
        display: block;
        font-size: rem(16);
        font-family: Arial, sans-serif;
        letter-spacing: 1px;
        font-weight: 700;
    }

    .add-to-cart {
        background: $black;

        &:hover,
        &:focus {
            background: $chanel-black-hover;
        }
    }

    .price-switcher {
        @include respond-to(mobile) {
            display: block;
        }
    }

    .pl-trustmark {
        top: 6.5%;

        @include respond-to(mobile) {
            top: 4%;
        }
    }
}

/* Dior PDP template */
.product-detail-dior {
    .add-to-cart {
        background-color: $black;

        &:hover,
        &:focus {
            background-color: $chanel-black-hover;
        }
    }
}

.about-brand {
    background-color: $white-smoke;
    margin-bottom: 60px;
    padding: 40px 15px 80px;

    &-title {
        position: relative;
        color: $nobel;
        font-size: rem(30);
        font-weight: 300;
        letter-spacing: 1px;
        margin: 0 0 35px;
        padding: 0;
        text-align: center;
        text-transform: uppercase;

        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            width: 150px;
            border-bottom: 1px solid;
            margin-left: -75px;
        }

        span {
            display: inline-block;
            position: relative;

            &:after {
                @include position(absolute, 0, 0, 100%);

                border-bottom: 1px solid;
                content: "";
                margin: 0 auto;
                width: 50%;
            }
        }
    }

    &-content {
        background-color: $white;
        font-size: 0;
        padding: 0;

        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }

    &-image {
        display: inline-block;
        vertical-align: middle;
        width: 50%;

        img {
            max-width: 100%;
        }
    }

    &-info {
        display: inline-block;
        padding: 30px;
        vertical-align: top;
        width: 50%;
    }

    &-info-title {
        color: $night-rider;
        display: inline-block;
        font-size: rem(20);
        font-weight: 500;
        margin: 0 0 20px;
        text-transform: uppercase;
    }

    &-info-description {
        font-size: rem(16);
        margin-bottom: 25px;
    }

    &-info-more {
        border-bottom: 1px solid $dim-gray;
        color: $nobel;
        display: inline-block;
        font-size: rem(13);
        font-weight: 500;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
}

/* Back in stock popup */
.b-notifyme_form-result {
    text-align: center;

    h4 {
        font-size: rem(16);
        text-transform: none;
    }
}

.notifyme-submitted + .ui-widget-content .ui-dialog-buttonset {
    display: none;
}

// How it wear Carousel
.product-recommendations {
    @include full-page-border;

    clear: both;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 95px;

    @include respond-to(mobile) {
        margin-bottom: 70px;
    }

    @include respond-to(mobile_mid) {
        margin: 0 -15px 70px;
    }

    .main-title {
        width: 100%;
        margin-bottom: 30px;
        letter-spacing: 0.48px;
    }

    .recommendations-image-wrapper {
        @include respond-to(tablet_plus) {
            width: 40.7%;
            margin-right: 20px;
            max-height: 588px;
        }

        @include respond-to(mobile) {
            width: 100%;
            margin-bottom: 35px;
        }

        @include respond-to(mobile_mid) {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 100%;
        }

        img {
            height: 100%;
            width: 100%;

            @include respond-to(mobile) {
                width: 100%;
            }

            @include respond-to(mobile_mid) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: contain;
            }
        }
    }

    .recommendations-list-wrapper {
        @include respond-to(desktop_plus) {
            width: 57.1%;

            &.with-overflow {
                border-bottom: 1px solid $grey01;
                height: 590px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        @include respond-to(mobile_mid) {
            padding: 0 15px;
        }
    }

    .recommendations-list {
        display: flex;
        flex-direction: column;

        .recommendations-item {
            border-top: 1px solid $grey02;

            &:last-child {
                border-bottom: 1px solid $grey02;;
            }
        }

        .product-tile {
            flex-direction: row;
            justify-content: flex-start;
            padding: 15px 0 15px;

            @include respond-to(mobile_mid) {
                flex-wrap: wrap;
                padding-bottom: 40px;;
            }

            .product-image {
                width: 19%;
                order: 1;
                margin-bottom: 0;

                @include respond-to(mobile_mid) {
                    width: 45%;
                    padding-top: 20px;
                    margin-right: 5px;
                }
            }

            .product-brand {
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 12px;
                padding-top: 0;

                @include respond-to(mobile_mid) {
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 10px;
                    padding-top: 0;
                }
            }

            .product-name {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 17px;

                @include respond-to(mobile) {
                    padding: 0;
                }

                @include respond-to(mobile_mid) {
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            .product-pricing {
                font-size: 14px;
                line-height: 22px;

                @include respond-to(mobile_mid) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .product-description-link {
                order: 2;
                width: 50%;
                text-align: left;

                @include respond-to(mobile_mid) {
                    padding: 0;
                }
            }

            .product-actions {
                display: none;
            }

            .button-dual_functional {
                display: block;
                order: 3;
                margin: 21px 0 0 30px;
                width: 35%;

                @include respond-to(mobile_mid) {
                    width: 40%;
                    align-items: right;
                    margin-left: 46%;
                    margin-top: -20px;
                }

                .bticons-zoom,
                .shop_now-button {
                    padding: 10px 26px;
                    line-height: 21px;
                    display: block;
                    width: 100%;

                    @include respond-to(mobile_mid) {
                        padding: 7px 0;
                        margin-left: 0;
                        font-size: 12px;
                        line-height: 15px;
                    }

                    &::before {
                        content: attr(data-alt-title);
                        font-family: $font-main;
                    }

                    @extend .button;
                    @extend .coloured;
                }
            }

            .try-on-badge,
            .promo-flag {
                display: none;
            }

            &.brand-chanel {
                outline: none;
            }
        }
    }
}

//Also in this Range & Recently Viewed on PDP
.pdp-main {
    .aitr-product-recommendations {
        @include full-page-border;

        margin-bottom: 95px;

        @include respond-to(mobile) {
            margin-bottom: 50px;
        }
    }

    .aitr-product-recommendations,
    .pdp-last-visited,
    .pdp-full-width-carousel-slot {
        @include full-page-content;

        clear: both;
        margin-top: 60px;

        .product-tile-wrapper {
            @include respond-to(m_desktop) {
                padding: 0 17%;
            }

            @include respond-to(m_tablet_down) {
                padding: 0 15px;
            }
        }

        .product-description-link {
            @include respond-to(m_tablet_down) {
                &:hover {
                    color: $black01;
                }
            }
        }

        .product-actions-quickview {
            border-right: 0;
        }

        // Button withdual functional - QV or ADD TO BAG
        .button-dual_functional {
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 45px;

            .bticons-zoom,
            .shop_now-button {
                @extend .button;

                padding: 15px 10px;
                line-height: 1;
                display: block;
                width: 100%;

                &::before {
                    content: attr(data-alt-title);
                    font-family: $font-main;
                    font-size: rem(14);
                }
            }
       }

        .product-tile {
            padding-bottom: 100px;
            position: relative;

            .product-actions {
                position: absolute;
                bottom: 1px;
                width: 100%;
                height: 34px;
                margin-bottom: 0;

                @include respond-to(m_tablet) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                // Bitton Icon Zoom
                .product-actions-quickview .bticons-zoom {
                    @include respond-to(m_tablet) {
                        display: flex;
                        align-items: center;
                        padding: 0 12px;
                        border-radius: 4px;
                        border: 1px solid $black01;
                        height: 100%;
                        min-height: 34px;

                        &::before {
                            content: "\e922";
                            font-size: rem(16);
                            color: $black01;
                        }

                        &:hover {
                            background-color: $black01;

                            &::before {
                                color: $white;
                            }
                        }
                    }

                    @include respond-to(desktop_below) {
                        &::before {
                            font-size: rem(12);
                        }
                    }
                }

                .product-actions-item {
                    &.product-actions-wishlist {
                        height: 100%;

                        @include respond-to(mobile_strong) {
                            text-align: center;
                            width: 100%;
                        }

                        @include respond-to(m_desktop) {
                            max-width: 80%;
                        }

                        @include respond-to(desktop_over) {
                            flex-grow: 1;
                        }
                    }
                }
            }

            .product-actions-wishlist-link {
                @include shared-wishlist;
                @include product_actions-button;

                padding: 0 10px;
                height: 100%;

                @include respond-to(m_desktop) {
                    justify-content: space-between;
                }

                @include respond-to(m_tablet_down) {
                    justify-content: center;
                }

                &::before {
                    content: "Add to wishlist";
                    display: inline-block;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    line-height: 1.2;

                    @include respond-to(tablet_regular) {
                        font-size: rem(9);
                    }
                }

                &.wishlist-checked .bticons-heart-filled,
                &.wishlist-checked:hover .bticons-heart-filled,
                .bticons-heart-outline {
                    @include respond-to(desktop_below) {
                        display: none;
                    }
                }
            }
        }

        .try-on-badge,
        .promo-flag {
            display: none;
        }

        .slick-arrow {
            position: static;
            margin: 0;
            padding: 5px;
            overflow: hidden;

            &::before {
                display: block;

                @include respond-to(mobile_strong_over) {
                    font-size: rem(50);
                    width: 50px;
                }

                @include respond-to(m_mobile_down) {
                    font-size: rem(24);
                }
            }
        }

        .slick-prev {
            @include icon(arrow-circle-left-large);

            order: 2;

            @include respond-to(mobile_strong_over) {
                margin-right: 14px;
            }

            @include respond-to(mobile_strong) {
                margin-right: 60px;
            }
        }

        .slick-next {
            @include icon(arrow-circle-right-large);

            order: 3;
        }

        .slick-list {
            width: 100%;
            margin-bottom: 25px;
        }

        .slick-slider {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            height: auto;
            align-self: stretch;

            > div {
                height: 100%;

               .product-carousel-item {
                    height: 100%;
                }
            }
        }
    }
}

/* Get the look */
.product-recommendations-carousel {
    clear: both;
    margin: 70px 0;

    .product-actions {
        display: none;
    }
}

/* Check store availability */
.store-container {
    .bticons-logo {
        text-align: center;
        font-size: rem(29);
    }

    h3 {
        display: block;
        width: 100%;
        margin-top: 15px;
        text-align: center;
        font-size: rem(18);

        &:after {
            content: '';
            border-bottom: 1px solid;
            width: 22%;
            display: block;
            margin: 15px auto;
        }
    }

    h4 {
        font-size: rem(15);
        font-weight: 700;
        text-align: center;
    }

    h5 {
        margin-top: 5px;
        text-align: center;
    }

    a {
        text-align: center;
        display: block;
        text-decoration: underline;
        font-size: rem(13);
    }
}

.store-detail-inner {
    padding: 0 100px;

    @include respond-to(mobile) {
        padding: 0 5%;
    }

    .store-detail {
        display: inline-block;
        width: 28%;
        margin: 0 2%;
        vertical-align: top;
        min-height: 230px;

        @include respond-to(mobile) {
            display: block;
            width: 100%
        }

        .store-image {
            text-align: center;
        }

        img {
            max-height: 130px;
            max-width: 100%;
        }
    }
}

/* Shop all links */
.product-shop-all {
    margin: 35px 0 60px;
    text-align: center;

    .button {
        margin: 0 10px;
        background-color: $light-gray01;
        color: $black01;
        min-width: 200px;
        width: auto;
        font-size: rem(12);

        &::after {
            display: none;
        }

        @include respond-to(mobile) {
            margin: 15px 0;
        }
    }
}

.primary-content {
    > .cart-recommendations {
        @include respond-to(tablet_plus) {
            width: 65%;
        }
    }
}

/* YML section */
.cart-recommendations {
    .carousel li {
        width: 200px; // fix for initialisation of carousel
    }

    .pt_cart & {
        clear: both;

        @include respond-to(mobile) {
            display: block;
            padding: 0;
        }
    }
}

/* Gift card */
.product-add-giftcard {
    margin-top: 25px;

    .char-count,
    .select-gift-radio {
        display: none;
    }

    .button-label {
        width: 18%;
        display: inline-block;
        vertical-align: top;
        margin: 0 1% 20px 0;
        padding: 12px 0;
        background: $second-gray;
        border: 1px solid $tutu;
        text-align: center;

        @include respond-to(mobile) {
            width: 17.5%;
            font-size: rem(14);
        }

        &:hover {
            font-weight: 500;
            background: $lightest-gray;
        }
    }

    .select-gift-radio:checked + .button-label {
        color: $white;
        background: $black;
        font-weight: 500;
    }

    .form-row label {
        margin-bottom: 10px;

        @include respond-to(mobile) {
            display: block;
        }
    }
}

.pdp-gift-card {
    .product-gift-subtitle {
        font-size: rem(14);

        @include respond-to(mobile) {
            display: block;
            text-align: center;
            margin-top: 20px;
            font-weight: 500;
        }
    }

    &.pdp-alternative {
        .add-to-cart {
            font-size: rem(18);
        }
    }
}

.featured-in-panels {
    @extend %clearfix;
    @include flexbox();

    @include respond-to(mobile) {
        display: block;
    }

    &.slot-panels {
        .asset {
            &-image {
                width: 100%;
            }

            &-text {
                min-height: 90px;
            }

            &-link {
                height: 100%;
            }
        }
    }
}

/* VideoJS */
.main-video {
    &.product-image {
        @include respond-to(mobile) {
            margin: 0 auto;
        }

        .ui-dialog-content .product-col-1 & {
            margin: 0 auto;
            max-width: 470px;

            @include respond-to(m_mobile_down) {
                margin: 50px 0 auto;
                max-width: 450px;
            }
        }

        .desktop .ui-dialog-content .product-col-1 & {
            max-width: 100%;
        }
    }

    .video-js {
        @include respond-to(mobile_tablet) {
            max-width: 100%;
            height: 100%;
            margin: 0 auto;
        }
    }
}

.video-js {
    padding-top: 100%;

    .vjs-big-play-button {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 72px;
        border: 0;
    }
}

.prod-feedback-txt {
    clear: both;
    margin-bottom: 10px;

    .product-detail .product-variations & {
        display: none;
    }

    .product-delivery-details-message + &,
    .content-asset + &,
    .inventory + & {
        margin: 10px 0 20px;
        font-size: rem(14);
    }
}

.price-sales__now-text {
    display: none;
}

.brand_bio {
    &-wrapper {
        @include out-of-wrapper;

        clear: both;
        background-color: $light-gray01;
        margin-bottom: 90px;
        text-align: center;
    }

    &-inner {
        @include respond-to(mobile_strong_over) {
            padding: 95px 20px;
        }

        @include respond-to(mobile_strong) {
            padding: 30px 25px;
        }
    }

    &-title {
        margin-bottom: 30px;
        font-size: rem(20);
        letter-spacing: 0.5px;
    }

    &-description {
        line-height: 22px;

        @include respond-to(m_desktop) {
            max-width: 500px;
            margin: 0 auto;
        }
    }
}

.positive-change {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $light-gray01;
    padding: 30px 25px;

    @include respond-to(m_mobile_down) {
        padding: 15px;
    }

    &-icon {
        min-width: 80px;
        height: 90px;
        margin-right: 30px;
        background-image: url("https://cdn.media.amplience.net/i/bta/positive-change-logo-1911");
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 0;

        @include respond-to(m_mobile_down) {
           margin-right: 15px;
        }
    }

    &-info {
        line-height: 1.4;
        text-align: left;
        font-size: rem(12);
    }

    &-title {
        font-size: rem(12);
        font-weight: 700;
        text-transform: none;
    }

    &-title,
    &-text {
        margin-bottom: 5px;
    }

    &-link {
        @include link_underline;
    }
}

// How it wear with hotspots map
.howtowear_map {
    &-static { // Styles for static content on page - NOT DIALOG
        @include respond-to(mobile_strong_over) {
            margin-bottom: 95px;
        }

        @include respond-to(mobile_strong) {
            margin-bottom: 30px;
        }

        @include full-page-border;

        .main-title {
            font-size: rem(24);

            @include respond-to(mobile_strong) {
                text-align: left;
                font-size: rem(20);
            }
        }

       .howtowear_map-content {
            position: relative;

            @include respond-to(mobile_strong) {
                margin-bottom: 30px;
            }
        }

        .howtowear_map-image {
            max-width: 100%;
        }

        .howtowear_map-hotspot {
            width: 37px;
            height: 37px;
            background: $white;
            border: 2px solid $black01;
            border-radius: 50%;
            font-size: rem(24);
            text-align: center;

            @include respond-to(mobile_strong) {
                display: none;
            }
        }
    } //End styles for static content on page - NOT DIALOG

    &-dialog { // Styles only for DIALOG
        &.ui-dialog {
            background-color: $light-gray01;

            @include respond-to(m_desktop) {
                width: 50% !important;
            }

            @include respond-to(m_tablet_down) {
                width: 70% !important;
            }

            .ui-widget-header {
                background-color: $light-gray01;
            }

            .ui-dialog-content {
                padding: 15px;
            }

            .ui-dialog-titlebar {
                width: 0;
                height: 0;
                margin: 0;
                padding: 0;
                position: static;
            }

            .ui-dialog-title {
                display: none;
            }

            .ui-dialog-titlebar-close {
                background: $white;
                border-radius: 4px;
                width: 40px;
                height: 40px;
                padding: 20px 17px 20px 23px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::before {
                    font-size: rem(10);
                    font-weight: 900;
                }
            }
        }

        &-image,
        .product-tile-wrapper {
            @include respond-to(mobile_strong_over) {
                width: 49%;
            }
        }

        &-image {
            border-radius: 4px;
            object-fit: none;

            @include respond-to(mobile_strong) {
                display: none;
            }
        }

        img {
            @include respond-to(touch) {
                max-width: none;
            }
        }

        .product-tile-wrapper {
            @include respond-to(desktop_below) {
                padding: 40px 0 0;
            }

            @include respond-to(desktop_over) {
                padding-left: 6%;
                padding-right: 6%;
            }
        }

        .product-tile {
            @include respond-to(mobile_strong_over) {
                flex-direction: column;
            }

            .product-image {
                width: 255px;
                margin: 0 auto 25px;
            }
        }
    } // End styles only for DIALOG

    &-desktop {
        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    &-item {
        display: flex !important;
        justify-content: space-between;

        @include respond-to(mobile_strong) {
            margin-bottom: 15px;
        }
    }

    .product-tile-wrapper {
        @include respond-to(mobile_strong) {
            width: 100%;
        }
    }

    .product-tile {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 0;
        width: auto;

        @include respond-to(mobile_strong) {
            flex-direction: row;
            justify-content: flex-end;
            text-align: left;
        }

        .product-image {
            @include respond-to(mobile_strong) {
                width: 39%;
                margin: 0;
            }
        }

        .thumb-link {
            border-radius: 4px;
        }

        .product-actions {
            order: 3;

            @include respond-to(mobile_strong) {
                display: none;
            }
        }

        .button-dual_functional,
        .product-description-link {
            @include respond-to(mobile_strong) {
                width: 55%;
                margin-left: 6%;
            }
        }

        .product-description-link {
            @include respond-to(mobile_strong) {
                margin-bottom: 0;
                text-align: left;
                padding: 0;
            }

            @include respond-to(m_tablet_down) {
                &:hover {
                    color: $black01;
                }
            }
        }

        .product-name {
            padding: 0;
        }

        .product-actions-item {
            width: 100%;
            display: block;

            &.product-actions-quickview {
                display: none;
            }
        }

        .button-dual_functional {
            display: block;
            margin-bottom: 15px;

            @include respond-to(mobile_strong_over) {
                margin: 0 auto 15px;
            }
        }

        .bticons-zoom,
        .shop_now-button {
            @extend .button;

            display: block;
            line-height: 1;
            width: 100%;
            margin: 0 auto;

            @include respond-to(mobile_strong_over) {
                max-width: 210px;
                padding: 15px 10px;
            }

            @include respond-to(mobile_strong) {
                padding: 10px;
            }

            &::before {
                content: "Add to bag";
                font-family: $font-main;
                text-transform: uppercase;
                font-size: rem(14);
            }
        }

        .product-actions-wishlist-link {
            @include shared-wishlist;
            @include product_actions-button;

            max-width: 210px;
            margin: 0 auto;

            @include respond-to(mobile_strong) {
                display: none;
            }

            &::before {
                content: "Add to wishlist";
                display: inline-block;
                font-weight: 500;
                letter-spacing: 0.5px;
                line-height: 1.2;
            }
        }

        .try-on-badge,
        .promo-flag {
            display: none;
        }

        .product-brand {
            font-weight: 800;

            @include respond-to(mobile_strong_over) {
                font-size: rem(20);
            }

            @include respond-to(mobile_strong) {
               font-size: rem(14);
            }
        }

        .product-pricing {
            font-size: rem(14);
            font-weight: 800;

            .product-sales-price,
            .product-standard-price {
                display: inline-block;
            }

            .product-sales-price {
                margin-right: 10px;
            }
        }
    }

    .loyalty-points {
        font-size: rem(8);
        color: $hel-gray;
        font-weight: 500;
        display: block;
        margin: 5px 0 10px;
    }

    .bticons-heart-outline,
    .bticons-heart-filled {
        font-size: rem(12);
        font-weight: 700;
    }
}

.more_category {
    text-align: center;

    @include respond-to(mobile_strong_over) {
        margin-bottom: 90px;
    }

    @include respond-to(mobile_strong) {
        margin-bottom: 30px;
    }

    &::before {
        content: "";
        display: block;
        border: 1px solid $lighter-gray;
        margin-left: -100%;
        margin-right: -100%;
        clear: both;

        @include respond-to(mobile_strong_over) {
            margin-bottom: 96px;
        }

        @include respond-to(mobile_strong) {
            margin-bottom: 30px;
        }
    }

    &-title {
        font-size: rem(24);
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 32px;

        @include respond-to(mobile_strong) {
            font-size: rem(20);
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include respond-to(mobile_strong) {
            align-items: center;
            flex-direction: column;
        }
    }

    &-item {
        @include respond-to(mobile_strong_over) {
            margin: 0 25px 10px 0;

            &:last-child {
                margin-right: 0;
            }
        }

        @include respond-to(mobile_strong) {
            margin-bottom: 15px;
            min-width: 220px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-link {
        background-color: $light-gray01;
        border-radius: 4px;
        display: block;
        font-size: rem(12);
        font-weight: 500;
        letter-spacing: 1px;
        padding: 10px 20px;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background-color: $grey03;
            color: inherit;
        }
    }
}



.klarna_placement #credit_strip_badge::part(osm-container) {
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    background:white;
    border: 0;
    padding: 0; 
}

.klarna_placement #top_strip_badge::part(osm-container){
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin-top: 12px;
    background:white;
    border: 0;
    padding: 0; 
    justify-content: left;
}

.klarna_placement #top_strip_badge::part(osm-badge) , #credit_strip_badge::part(osm-badge) {
    height: 24px;
}

.klarna_placement #top_strip_badge::part(osm-message),#credit_strip_badge::part(osm-message) {
    flex-direction: column;
    align-items: start;
    font-size: 14px;
}

.klarna_placement #top_strip_badge::part(osm-cta),#credit_strip_badge::part(osm-cta) {
    display: block;
    font-size: 14px;
}

@media screen and (max-width: 800px) {
.klarna_placement #credit_strip_badge::part(osm-cta) {
    margin: 4px auto;
}
}


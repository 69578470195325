/* Sidebar navigation */
.secondary-navigation {
    .tab {
        .tab-label {
            display: block;
            font-weight: 500;
            font-size: rem(14);
            letter-spacing: 0;
            transition: all 0.3s ease-in-out;
            cursor: default;

            @include respond-to(mobile) {
                text-transform: uppercase;
            }
        }

        ul {
            margin: 0;

            li {
                position: relative;

                &::after {
                    @include respond-to(m_desktop_large) {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        padding: 10px 0;
                        z-index: -1;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin-top: -10px;
                        height: 100%;
                        box-sizing: content-box;
                    }
                }
            }

            li:hover {
                &::after {
                    @include respond-to(m_desktop_large) {
                        background-color: #eee;
                    }
                }
            }

            a {
                padding-left: 25px;
                display: block;

                &:hover {
                    color: #000;
                }
            }
        }

        .tab-content {
            margin-bottom: 0;

            @include respond-to(m_desktop_large) { // To see hover effect on first elements
                overflow: visible;
            }
        }

        .tab-content {
            font-size: rem(14);
            line-height: 1.6;

            @include respond-to(m_desktop) {
                height: auto;
                opacity: 1;
                padding: 0 15px;
                transition: all 0.35s;
            }

            @include respond-to(m_tablet_down) {
                height: 0;
                opacity: 0;
                padding: 0;
                text-align: left;
                transition: all 0.35s;
                overflow: hidden;
            }
        }

        input[type="checkbox"] {
            &:not(.input_toggle) {
                & ~ label.tab-label {
                    font-size: rem(16);
                    border-top-color: rgba(0, 0, 0, 0.1);

                    @include respond-to(m_desktop) {
                        padding: 25px 0 20px;
                    }

                    @include respond-to(m_tablet_down) {
                        font-size: rem(18);
                        padding: 20px;
                    }
    
                    &::after {
                        font-size: rem(20);
                        color: #D0D0D0;

                        @include respond-to(m_desktop) {
                            display: none;
                        }
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }


                &.tab-switch:checked {
                    & + label.tab-label {
                        background-color: transparent;
                    }

                    & + label + .tab-content {
                        @include respond-to(m_desktop) {
                            padding: 0 15px;
                        }

                        @include respond-to(m_tablet_down) {
                            font-size: rem(18);
                            padding: 15px 30px 30px;
                        }
                    }
                }
            }
        }
    }

    .toggle,
    .toggle-nav {
        position: relative;
        display: block;
        border-top: 1px solid $very-light-gray;
        padding: 12px 25px 12px 0;
        margin: 0;
        font-weight: 500;
        text-transform: none;
        cursor: pointer;
        transition: color .2s ease-in-out;

        &:hover {
            color: $dim-gray;
        }

        @include respond-to(mobile) {
            text-transform: uppercase;
            font-weight: 400;
        }

        &:after {
            content: '\e912';
            position: absolute;
            right: 5px;
            display: block;
            font-size: rem(12);
            top: 50%;
            margin-top: -10px;
            @include font-icomoon;

            @include respond-to(mobile) {
                margin-top: -7px;
            }
        }

        &.expanded {
            &:after {
                content: '\e90f';
            }
        }
    }

    .toggle-content a {
        display: block;
        padding: 0 15px 7px 35px;
        font-weight: 300;
    }
}

.is-wishlist-default-available {
    .wishlist-menu-vip,
    .wishlist-menu-handpicked {
        display: none;
    }

    &.is-wishlist-handpicked-available {
        .wishlist-menu-handpicked {
            display: block;
        }
    }

    &.is-wishlist-vip-available {
        .wishlist-menu-vip {
            display: block;
        }
    }
}
.payment-list {
    border-top: 1px solid $very-light-gray;

    &__link {
        margin: 10px 0;
        color: $white;
    }
}

.payment__title {
    font-size: rem(18);
    font-weight: 500;
    margin-bottom: 10px;
}
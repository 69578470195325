.search-suggestion {
    @extend %clearfix;

    background-color: $white;
    position: absolute;
    right: 0;
    top: 4.3rem;
    width: 332px;
    max-height: 70vh;
    z-index: 5;
    overflow: auto;
    border-radius: 4px 4px 0 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    padding: 25px 15px 0;

    @include respond-to(mobile_strong) {
        height: calc(var(--vh) - #{$header-height-mobile});
        width: 100%;
        box-shadow: none;
    }

    @include respond-to(m_desktop) {
        top: 3.5rem;
    }

    @include respond-to(mobile_strong_over) {
        .is-sticky & {
            top: 40px;
            left: 50%;
            width: 430px;
            transform: translateX(-50%);
        }
    }
}

.search-suggestion__phrase {
    font-size: rem(12);
    line-height: 1;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $grey01;
}

.search-suggestion__phrase-text {
    color: $grey01;
}

.search-suggestion__phrase-link {
    text-transform: capitalize;
    font-weight: $bold;
    color: $black02;
}

.search-suggestion__item {
    color: $black;
    margin-top: 20px;
}

.search-suggestion__item:first-child {
    margin: 0;
}

.search-suggestion__item--popular {
    .search-suggestion__item-title {
        font-size: rem(12);
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $grey01;
    }
}

.search-suggestion__item--products {
    .search-suggestion__item-title {
        display: none;
    }
}

.search-suggestion__item-hit {
    display: block;
    font-size: rem(12);
    color: $black02;
    margin-bottom: 15px;

    &:last-child {  // For fix case when list appears empty
        border-bottom: 1px solid $grey01;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
}

.search-suggestion__item--popular {
    padding-bottom: 15px;
    text-transform: capitalize;
}

.search-suggestion__item--category span {
    font-size: rem(12);
}

.search-suggestion__item-hit-category,
.search-suggestion__item-hit-parent {
    color: $black02;
}

.search-suggestion__item-hit-category {
    font-weight: $bold;
}

.search-suggestion__item-hit-separator {
    color: $grey01;
}

.search-suggestion__item-hit-link {
        display: flex;
}

.search-suggestion__item-img {
    display: inline-block;
    width: 120px;
    height: 69px;
    vertical-align: top;
    margin-right: 10px;
}

.search-suggestion__item-text {
    width: 50%;
    font-size: rem(12);
    font-weight: $bold;
}
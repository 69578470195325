@charset "UTF-8";


/**
*
* THis is a combination of the following third party CSS files
* fonts.css
* /lib/jquery/ui/jquery-ui.min.css
* /lib/jquery/slick/slick.css
* /lib/fullpage/jquery.fullPage.min.css
* /lib/videojs/video-js.min.css
* /lib/videojs/video-js.skin.css
*
**/

@import "composite/jquery-ui.min";
@import "composite/slick";
@import "composite/jquery.fullPage.min";
@import "composite/video-js.min";
@import "composite/video-js.skin";
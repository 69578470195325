nav[role="navigation"] {
    @extend %clearfix;
    background-color: $white;
    z-index: 1;

    @include respond-to(mobile_strong) {
        left: 0;
        transform: translateX(-100%);
        margin-top: 0;
        position: fixed;
        width: $header-nav-width-mobile;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        display: none;
        height: var(--vh) !important;

        .menu-active & {
            display: block;
        }

        // this is a marker for JS to let it know that Hamburger menu rendered
        &::before {
            content: "js-hamburger";
            display: none;
        }
    }

    @include respond-to(mobile_strong_over) {
        color: $night-rider;
        position: relative;
    }
}

.menu-toggle {
    width: auto;
    background-color: transparent;
    border: none;
    color: $night-rider;
    display: block;
    float: left;
    margin-left: 0.2rem;
    padding: 0.8rem;
    position: absolute;
    z-index: 3;

    @include respond-to(mobile_strong_over) {
        display: none;
    }

    &:hover {
        background-color: transparent;
        color: $dim-gray;
    }

    .menu-icon {
        display: block;
        clear: both;
        font-size: rem(18);
    }

    span {
        display: block;
        font-size: rem(8);
        line-height: 1rem;
        font-weight: 500;
    }
}

.menu-category {
    @extend %clearfix;
    list-style: none;
    margin: 0;
    padding: 0;

    @include respond-to(mobile_strong_over) {
        border-top: 1px solid $very-light-gray;
        border-bottom: 1px solid $very-light-gray;
        margin: 0 auto;
        width: 100%;

        &.level-1 {
            display: block;
            text-align: center;
            font-size: 0;

            @include respond-to(mobile_stronger) {
                padding: 0 3px;
            }

            .level-2 {
                text-align: left;
            }

            .level-2-content {
                @include respond-to(mobile_strong_over) {
                    max-width: 1260px;
                    margin-right: auto;
                    margin-left: auto;
                    overflow: hidden;
                }
            }

            .menu-category-columns-1 {
                @include respond-to(mobile_strong_over) {
                    max-width: 260px;
                }
            }

            .menu-category-columns-2 {
                @include respond-to(mobile_strong_over) {
                    max-width: 520px;
                }
            }

            .menu-category-columns-3 {
                @include respond-to(mobile_strong_over) {
                    max-width: 780px;
                }
            }
        }
    }

    @include respond-to(desktop_over) {
        &.level-1 {
            display: block;
        }
    }

    li {
        @extend %clearfix;
        border-bottom: 1px solid $tutu;
        list-style: none;

        @include respond-to(mobile_strong_over) {
            display: inline-block;
            border: none;

            &:hover > a {
                background-color: transparent;
            }
        }
    }

    a {
        color: $black;
        display: inline-block;
        font-size: rem(9);
        padding: 1rem;
        overflow: visible;
        width: 100%;

        @include respond-to(mobile_strong_over) {
            font-size: rem(7.5);
            width: auto;
        }

        @include respond-to(desktop_plus) {
            padding: 1rem 0;
        }
    }

    .brand-item {
        clear: both;
        color: $nobel;
        float: none;
        width: 100%;
        font-size: rem(12);
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 1px;
        padding-bottom: 5px;
        text-transform: uppercase;
        -webkit-transition: background-color 0.3s, color 0.3s;
        transition: background-color 0.3s, color 0.3s;

        @include respond-to(mobile_strong) {
            border-bottom: 1px solid $tutu;
            line-height: 24px;
            padding: 12px 22px 12px 18px;
            font-weight: 400;

            &:hover {
                background-color: $lightest-gray;
            }
        }

        i {
            display: inline-block;
            float: right;
            font-size: rem(8);
            font-weight: 700;
            line-height: 2.4rem;
        }
    }

    .view-all-brands {
        @include respond-to(mobile_strong) {
            display: none;
        }
    }
}

.menu-active .menu-category .brand-item {
    @include respond-to(mobile_strong_over) {
        font-size: rem(10);
    }
}

.mobile-nav-utility {
    &.nav-back {
        padding: 8px 25px;
        font-size: rem(12);
        line-height: 2rem;
        text-transform: uppercase;
        color: $black;
        cursor: pointer;
        border-bottom: 1px solid $tutu;

        i {
            display: inline-block;
            float: left;
            line-height: 2rem;
            font-size: rem(8);
            font-weight: 700;
            padding-right: 1rem;
        }
    }

    &.upper-level-link {
        display: flex;
        justify-content: space-between;
        border-bottom: 4px solid $pink;
        padding: 0 22px 0 18px;

        a {
            padding: 12px 0;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;

            &:hover {
                background-color: transparent;
            }
        }

        .view-all-link {
            text-transform: capitalize;
            justify-content: flex-end;
            max-width: 70px;
        }
    }
}

.level-1 {
    @include respond-to(mobile_strong) {
        position: absolute;
        top: 8.1rem;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-x: hidden;
        font-weight: 500;
    }

    .menu-brand-assets li {
        @include respond-to(mobile_strong_over) {
            a {
                padding: 0;
                text-transform: none;
                letter-spacing: 1px;
            }
        }
    }

    > li {
        @extend %clearfix;
        // override background-color for small viewport
        background-color: transparent;
        line-height: 2rem;

        @include respond-to(mobile_strong_over) {
            flex-grow: 1;
        }

        .is-tablet & {
            > a:hover {
                color: $nobel;
                background-color: transparent;
            }

            &.active > a {
                background-color: $white-smoke;
                color: $dim-gray;
            }
        }

        .sticky-wrapper.is-sticky .is-tablet & {
            > a:hover {
                color: $white;
                background-color: transparent;
            }

            &.active > a {
                background-color: $white-smoke;
                color: $nobel;
            }
        }

        .menu-item-toggle {
            position: absolute;
            right: 0;
            color: $nobel;
            cursor: pointer;
            line-height: 2rem;
            font-size: rem(10);
            padding: 0.8rem 0;
            width: 2.1rem;

            @include respond-to(mobile_strong_over) {
                display: none;
            }
        }

        a {
            float: left;
            width: 100%;
            padding: 0.6rem 2rem;
            font-size: rem(12);
            line-height: 2.4rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: background-color 0.3s, color 0.3s;

            @include respond-to(mobile_strong_over) {
                float: none;
                padding: 0.6rem 1rem;
                font-weight: 400;
                line-height: 19px;

                &.has-sub-menu {
                    width: 100%;
                }

                &:hover {
                    background-color: $white-smoke;
                    color: $dim-gray;
                }
            }

            @include respond-to(mobile_stronger) {
                font-size: rem(11);
                padding: 6px;
            }

            @include respond-to(mobile_strong) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 22px 12px 18px;
            }

            i {
                display: inline-block;
                float: right;
                font-size: rem(8);
                font-weight: 700;
                line-height: 2.4rem;
                padding-right: 1rem;

                @include respond-to(mobile_strong) {
                    padding-right: 0;
                    margin-right: 0;
                }
            }
        }

        > a {
            @include respond-to(mobile_strong_over) {
                font-weight: 500;
            }
            @include respond-to(desktop_over) {
                padding: 10px 20px;
                font-size: rem(12);
            }
        }

        .level-2 {
            li {
                display: block;
                line-height: 16px;
            }
            a {
                @include respond-to(mobile_strong_over) {
                    padding: 0;
                    text-transform: none;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.level-2 {
    @extend %clearfix;
    clear: left;
    color: $nobel;
    background-color: $white;
    display: none;

    .menu-category-hover li:hover & {
        display: block; // Show menu-content-wrapper on desktop
    }

    .level-1 > .active & {
        display: block; // Show menu-content-wrapper on mobile

        @include respond-to(mobile_strong) {
            pointer-events: auto;
            z-index: 2;
            left: 0;
        }
    }

    @include respond-to(mobile_strong) {
        overflow: hidden;
        overflow-y: auto;
        pointer-events: none;
        font-weight: 400;
        position: fixed;
        top: 78px;
        left: 100%;
        bottom: 0;
        width: 100%;
        transition: left 500ms ease;

        &.nested-item-active {
            height: 0;
            overflow-y: hidden;
        }

        .menu-brand-assets a {
            border-bottom: 1px solid $tutu;
        }
    }

    @include respond-to(mobile_strong_over) {
        background-color: $white;
        border: 1px solid $very-light-gray;
        color: $nobel;
        position: absolute;
        overflow: auto;
        left: 0;
        right: 0;
        z-index: $z-nav-dropdown;
        max-height: 70vh;


        > ul {
            float: left;
            padding-left: 0;

            > li {
                float: none;
                min-width: 12em;

                > a {
                    padding: 0;
                    font-size: rem(14);
                    font-weight: 700;
                    color: $nobel;

                    &:hover {
                        color: $dim-gray;
                        background-color: transparent;
                    }
                }
            }
        }

        .menu-vertical {
            > li {
                display: block;

                > a {
                    font-size: rem(12);
                    font-weight: 700;
                    text-transform: uppercase;
                    padding: 1rem 0 0.1rem 0;
                }

                &:first-child {
                    a {
                        padding-top: 0;
                    }
                }
            }
        }

        .menu-horizontal {
            float: none;

            > li {
                border-right: 1px solid $gainsboro;
                float: left;
                padding-right: 2rem;

                &:last-child {
                    border-right: none;
                }
            }
        }

        .menu-brand-assets {
            float: left;
            width: 20%;
            padding: 1.5rem;

            .menu-featured-brands-asset,
            .menu-designer-brands-asset {
                padding: 1.2rem 1.2rem 0 1.2rem;
                display: table; //clearfix (can't use %clearfix inside media query)
                width: 100%;

                > span {
                    display: block;
                    margin-bottom: 0.4rem;
                    font-size: rem(14);
                    font-weight: 700;
                    text-transform: uppercase;
                }

                li {
                    display: block;
                }
            }

            .view-all-brands {
                padding: 0 1.5rem 2rem 1.5rem;
                text-transform: uppercase;

                a {
                    display: inline-block;
                    width: auto;
                    font-size: rem(12);
                    padding: 0;
                    text-transform: capitalize;

                    @include link_underline();
                }
            }
        }

        .menu-categories-columns-wrapper {
            @extend .clearfix;
            float: left;
            width: 80%;
            padding: 1.5rem 0;

            .menu-category-column {
                float: left;
                width: 33.33333333%;
                padding: 1rem 1.5rem;
            }

            &.menu-categories-col-1 {
                .menu-category-column {
                    width: 100%;
                }
            }

            &.menu-categories-col-2 {
                .menu-category-column {
                    width: 50%;
                }
            }
        }

        &.cat-dropdown-template-2 {
            &.is-brands-col-exist {
                .menu-content-wrapper {
                    width: 80%;
                }
            }

            .menu-content-wrapper {
                @extend .clearfix;
                width: 100%;

                &:last-child {
                    padding-top: 0;
                }

                > div {
                    clear: both;
                    font-size: rem(14);
                    font-weight: 700;
                    text-transform: uppercase;

                    &:first-child {
                        padding: 1.6rem 0;
                    }
                }

                .asset {
                    @extend .clearfix;
                    width: 50%;
                    float: left;
                    padding-bottom: 1.5rem;

                    .link {
                        overflow: hidden;
                    }

                    picture {
                        display: block;
                        float: left;
                        width: 40%;
                    }

                    h5,
                    p {
                        float: right;
                        clear: right;
                        width: 60%;
                        padding-left: 1.5rem;
                        font-size: rem(12);
                    }

                    h5 {
                        padding-top: 2rem;
                        font-weight: 500;
                    }
                }

                p {
                    border: none;
                    margin: 1rem 0;
                    text-transform: none;
                }
            }
        }

        .banner {
            float: left;
        }

        &.menu-categories-col-1 {
            .menu-categories-columns-wrapper {
                width: 55%;
            }

            .menu-content-wrapper {
                width: 45%;
            }
        }

        &.menu-categories-col-2 {
            .menu-categories-columns-wrapper {
                width: 66.66666666%;
            }

            .menu-content-wrapper {
                width: 33.33333333%;
            }
        }

        &.is-brands-col-exist {
            .menu-categories-columns-wrapper {
                width: 60%;
            }

            &.menu-categories-col-1 {
                .menu-brand-assets {
                    width: 33.33333333%;
                }

                .menu-categories-columns-wrapper {
                    width: 33.33333333%;
                }

                .menu-content-wrapper {
                    width: 33.33333333%;
                }
            }

            &.menu-categories-col-2 {
                .menu-brand-assets {
                    width: 25%;
                }

                .menu-categories-columns-wrapper {
                    width: 50%;
                }

                .menu-content-wrapper {
                    width: 25%;
                }
            }
        }
    }

    @include custom-respond-to($break_touch, $break_desktop) {
        max-height: 60vh;
    }

    > ul {
        @extend %clearfix;
        padding-bottom: .5em;
        padding-left: 2.1rem;
    }

    .menu-content-wrapper {
        display: none;

        .slot-panels-container {
            margin-left: 0;
        }

        .menu-category-hover li:hover & {
            display: block; // Show menu-content-wrapper on desktop
        }

        .level-1 > .active & {
            display: block; // Show menu-content-wrapper on mobile
        }
        
        @include respond-to(mobile_strong_over) {
            float: right;
            width: 20%;
            padding: 1.5rem;
        }

        .slot-panels-container {
            img {
                @include respond-to(mobile_strong) {
                    border-radius: 0;
                }
            }
        }

        picture {
            @include respond-to(mobile_strong) {
                width: 50%;
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        p {
            display: inline-block;
            margin: 9px 0 30px 0;
            padding: 0;
            font-size: rem(12);
            text-transform: capitalize;

            @include respond-to(mobile_strong_over) {
                @include link_underline();
            }

            @include respond-to(mobile_strong) {
                width: 50%;
                text-align: center;
                padding: 0 15px;
                margin: 0;
                font-size: rem(8);
            }
        }

        h4 {
            margin: 11px 0 1.2rem;
            padding: 0;
            font-size: rem(16);
            font-weight: 500;
            text-transform: uppercase;
        }

        a {
            @include respond-to(mobile_strong) {
                padding: 0;
                border-bottom: 1px solid $tutu;
            }
        }

        [data-cgid="brand"] & {
            p::after {
                display: none;
            }

            b,
            strong {
                display: block;
            }
    
            a,
            .link {
                @include respond-to(mobile_strong_over) {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .banner {
        display: none;

        @include respond-to(mobile_strong_over) {
            display: block;
        }

        img {
            vertical-align: top;
        }
    }

    .menu-categories-columns-wrapper {
        .menu-category-column {
            margin-bottom: 0;
        }
    }
}

.level-3 {
    display: none;
    color: $nobel;
    background-color: $white;
    height: 100%;

    .menu-brand-assets & {
        @include respond-to(mobile_strong_over) {
            background-color: transparent;
        }
    }

    .level-2 .active & {
        z-index: 3;
        display: block;

        @include respond-to(mobile_strong) {
            pointer-events: auto;
            left: 0;
        }
    }

    @include respond-to(mobile_strong) {
        pointer-events: none;
        overflow-x: hidden;
        overflow-y: auto;
        font-weight: 400;
        position: fixed;
        top: 78px;
        left: 100%;
        bottom: 0;
        width: 100%;
        height: calc(100% - 78px);
        transition: left 500ms ease;
    }

    @include respond-to(mobile_strong_over) {
        display: block;

        a {
            font-weight: normal;
            font-size: rem(14);
            text-transform: none;
            padding: 0;
        }

        li {
            float: none;
        }
    }
}

.is-sticky {
    nav[role="navigation"] {
        position: absolute;
        top: 0 !important; // Override js that used for standart header
        z-index: 3;
        float: left;
        width: $header-nav-width-mobile;
        background-color: transparent;

        @include respond-to(mobile_strong_over) {
            position: static;
            width: auto;
            width: -webkit-calc(100% - 20rem - 11.5rem);
            width: calc(100% - 21rem - 11.5rem);
        }

        @include respond-to(mobile_strong) {
            position: fixed;
            top: 4.2rem;
        }

        @include respond-to(mobile_stronger) {
            width: 100%;
        }
    }

    .menu-utility-user {
        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    .menu-category {
        border: none;

        &.level-1 {
            > li > a {
                @include respond-to(mobile_strong_over) {
                    padding: 8px;
                    font-size: rem(10);
                    color: $white;
                    white-space: nowrap;
                }

                @include respond-to(tablet_ext) {
                    padding: 8px 5px;
                }

                @include respond-to(widedesktop_over) {
                    padding: 1rem 1.2rem;
                    font-size: rem(14);
                    color: $white;
                }

                &:hover {
                    color: $nobel;
                }
            }
        }
    }

    .menu-toggle {
        color: $white;

        @include respond-to(mobile_strong) {
            margin-top: 1.2rem;
        }
    }
}

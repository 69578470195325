.icon-instore {
    &::before {
        font-size: rem(12);
    }
}

.instore-item {
    padding: 25px 0;
    border-top: 1px solid $light-gray02;

    &.expanded {
        .instore-item_details {
            padding: 10px;
            margin-top: 10px;
            background-color: $light-gray02;
            height: auto;
            transition: all 1s ease;
        }

        .instore-item_toggler {
            &::before {
                content: "-";
            }
        }
    }

    &_toggler {
        position: relative;
        cursor: pointer;
        padding-right: 30px;

        &::before {
            content: "+";
            font-size: rem(21);
            font-weight: 500;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    &_details {
        margin: 0;
        padding: 0;
        height: 0;
        overflow: hidden;
        transition: all 1s ease;
        display: flex;
        justify-content: space-between;

        @include respond-to(m_mobile_down) {
            flex-direction: column;
        }
    }

    .item_hours-title,
    .item_contacts-title {
        margin-bottom: 5px;
    }

    .item_hours,
    .item_contacts {
        word-break: break-all;
        font-size: rem(14);

        @include respond-to(m_tablet) {
            width: 49%;
        }
    }

    .item_hours {
        @include respond-to(m_mobile_down) {
            margin-bottom: 30px;
        }
    }

    .left {
        float: left;
        margin: 0 10px 2px 0;
    }

    .right {
        clear: right;
        margin: 0 0 2px 0;
    }

    .dotted {
        display: none;
    }

    &_title {
        font-size: rem(16);
        text-transform: capitalize;
    }

    &_address {
        margin: 0 0 10px;
        font-size: rem(16);
    }

    &_badge {
        color: $white;
        font-size: rem(12);
        text-transform: uppercase;
        font-weight: 500;
        padding: 5px 12px;
        letter-spacing: 0.5px;
        display: inline-block;
        border-radius: 4px;
    }

    .badge-red {
        background-color: $red;
    }
    
    .badge-yellow {
        background-color: $yellow;
    }
    
    .badge-green {
        background-color: $green;
    }

    .badge-light-grey {
        background-color: $grey02;
    }
}

.tumbler-wrapper {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter_stores-label {
    margin-right: 10px;
    font-size: rem(12);
}
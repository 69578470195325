.cross-sell-dialog {
   &.ui-dialog {
      max-width: 44.5%;

      @include respond-to(m_desktop) {
         min-width: 840px;
      }
   
     @include respond-to(mobile_stronger) {
         bottom: 0;
      }

      @include respond-to(m_tablet_down) {
         max-width: 100%;
         left: 0 !important;     // rewrite js style
         top: 0 !important;
         right: 0;
      }

      @include respond-to(tablet_ext) {
         max-width: 83%;
      }

      @include respond-to(m_tablet_down) {
         min-height: 100vh !important;
      }
   
      .ui-widget-header {
         padding: 37px 40px 23px; 

         @include respond-to(mobile_strong) {
            padding: 30px 20px;
         }

         .ui-dialog-title {
            @include icon(checked);

            font-size: rem(22);
            text-transform: none;
            text-align: left;
            font-family: $font-main;
            font-weight: $medium;
            padding: 0 0 0 35px;
            position: relative;
            margin: 0;

            &::before {
               color: $white;
               position: absolute;
               z-index: 1;
               font-size: rem(14);
               left: 3px;
               top: 50%;
               transform: translateY(-50%);
            }

            &::after {
               content: "";
               height: 20px;
               width: 20px;
               background: $black01;
               border-radius: 50%;
               display: inline-block;
               position: absolute;
               top: 50%;
               left: 0;
               transform: translateY(-50%);
            }
         }
   
         .ui-dialog-titlebar-close {
            top: 50%;
            transform: translateY(-15%);
            width: 17px;
            height: 17px;
            right: 40px;

            @include respond-to(mobile_strong) {
               width: 25px;
               height: 25px;
               right: 15px;
               transform: translateY(-50%);
            }

            &::before {
               line-height: 1;
               font-size: rem(17);

               @include respond-to(mobile_strong) {
                  font-size: rem(15);
                  line-height: 30px;
               }
            }

            .ui-icon-closethick {
               width: 17px;
               height: 17px;

               @include respond-to(mobile_strong) {
                  width: 25px;
                  height: 25px;
               }
            }
         }
      }

      .dialog-content  {
         padding: 0 37px 25px;

         @include respond-to(mobile_strong) {
            padding: 0;
         }
      }
   }

   .cross-sell-content {
      border-top: 1px solid $hel-gray;
      padding-top: 20px;

      .cart-products {
         display: flex;
         flex-wrap: wrap;
         max-width: 100%;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 20px;

         @include respond-to(mobile_strong) {
            padding: 0 20px;
         }

         .cart-product {
            width: 48%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            min-height: 112px;

            @include respond-to(mobile_strong) {
               width: 100%;
               margin-bottom: 30px;
            }

            &-image {
               width: 23.22%;
               max-width: 85px;

               @include respond-to(mobile_stronger) {
                  width: 28.5%;
               }

               @include respond-to(mobile_strong) {
                  width: 28.43%;
               }

               @include respond-to(mobile_mid) {
                  width: 25.53%;
               }

               img {
                  width: 100%;
               }
            }

            &-data {
               width: 57%;
               font-family: $font-main;
               font-size: rem(12);
               font-weight: $regular;

               .brand {
                  font-size: rem(13);
                  font-family: $font-main;
                  font-weight: $bold;
                  margin-bottom: 8px;
                  text-transform: uppercase;
                  color: $black03;
                  line-height: 16px;
               }

              .name {
                  margin-bottom: 12px;
                  line-height: 15px;
                  
                  a {
                     color: $hel-gray;
                  }
               }

               .attribute,
               .qty {
                  line-height: 15px;
                  text-transform: capitalize;
                  color: $hel-gray;
              }
            }
         }
      }

      .cart-subtotal {
         font-size: rem(14);
         line-height: 17px;
         font-family: $font-main;
         font-weight: $bold;
         margin-bottom: 23px;

         @include respond-to(mobile_strong) {
            padding: 0 20px;
         }
      }
   
      .actions {
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         padding-bottom: 20px;
         border-bottom: 1px solid $hel-gray;

         @include respond-to(mobile_strong) {
            padding: 0 20px 30px;
         }

         &-button {
            background-color: $pink;
            color: $white;
            margin-right: 25px;
            padding: 14px;
            text-align: center;
            border-radius: 4px;

            @include respond-to(mobile) {
               margin: 0 0 20px 0;
            }

            &-type2 {
               background-color: transparent;
               border: 1px solid $nobel;
               color: $nobel;

               @include respond-to(mobile) {
                  width: auto;
                  margin: 0;
               }
            }
         }

         .button-type1 {
            width: 33.45%;
            font-size: rem(12);
            font-weight: 500;
            line-height: 15px;
            text-transform: uppercase;

            @include respond-to(desktop) {
               max-width: 255px;
            }

            @include respond-to(m_tablet) {
               min-width: 255px; 
            }

            @include respond-to(mobile) {
               width: 100%;
            }
         }
      }

      .product-recommendations-carousel {
         margin: 30px 0 40px 0;

         @include respond-to(mobile_strong) {
            padding: 0 20px;
         }

         .button-dual_functional {
            width: 90%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
         }

         .bticons-zoom {
            display: block;
            width: 100%;
            padding: 12px;
            border-radius: 4px;
            background-color: $pink;
            color: $white;
            line-height: 1;
            font-weight: 700;
            font-size: rem(15);
            font-family: $font-main;
            letter-spacing: 1px;
            text-transform: uppercase;
            border: 0;

            &::before {
               content: attr(data-alt-title);
               font-family: $font-main;
            }

            @include respond-to(mobile) {
               font-size: rem(12);
            }
         }

         .carousel {
            &-item {
               min-width: 160px;
            }

            .jcarousel-nav {
               top: 50%;
               transform: translateY(-50%);

               &::before {
                  color: $white;
                  z-index: 10;
                  font-weight: $xbold;
                  font-size: rem(18);
               }

               &::after {
                  content: "";
                  height: 30px;
                  width: 30px;
                  background: $black01;
                  border-radius: 50%;
                  display: inline-block;
                  position: absolute;
                  z-index: -1;
                  top: 50%;
                  left: 0;
                  transform: translateY(-38%);
               }
            }

            .jcarousel-prev {
               padding: 12px 15px 12px 16px;
               left: 0;

               &::after {
                  left: 7px;
               }
            }

            .jcarousel-next {
               padding: 12px 15px 12px 11px;
               right: 0;

               &::after {
                  left: -1px;
               }
            }

            .product-image {
               margin: 0 auto 26px;
               width: 160px;
               height: 160px;

               .thumb-link {
                  width: 100%;
                  height: 100%;
               }
            }

            &-list {
               .carousel-item {
                  .product-description-link {
                     padding: 0;

                     .product-brand {
                        font-size: rem(14);
                        color: $black03;
                     }

                     .product-name {
                        font-size: rem(12);
                        line-height: 15px;
                        color: $hel-gray;
                     }

                     .product-pricing {
                        .product-standard-price {
                           font-weight: $medium;
                        }
                     }
                  }
               }
            }
         }
      }

      .cross-sell-recommendations {
         .main-title {
            font-size: rem(18);
            font-family: $font-main;
            font-weight: $medium;
            margin: 0 0 25px 0;
         }
      }
   }
}
/// Contexts
/// @param {String | List} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}


@mixin m_scrollbar() {
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: rgba($black, 0.2);
        border: 6px solid transparent;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb {
        background: $hel-gray2;
        border-radius: 2px;
    }
}

@mixin underlined_link() {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -2px;
        left: 0;
        background-color: $pink;
        visibility: visible;
        transform: scaleX(1);
        transition: all .3s ease-in-out 0s;
    }

    &:hover {
        &:before {
            visibility: hidden;
            transform: scaleX(0);
        }
    }
}

@mixin hidden {
    display: none !important;
    visibility: hidden;
}


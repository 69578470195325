// Template 1(abc)
.article-page {
    position: relative;
    z-index: 1;

    &.image-position {
        &-left {
            .article-page-media {
                @include respond-to(m_desktop) {
                    order: 1;
                }
            }

            .article-page-content {
                @include respond-to(m_desktop) {
                    order: 2;
                }
            }

            .stuck-page-wrapper {
                // 2, 4, 6, 8...
                &:nth-child(2n) {
                    .article-page-media {
                        @include respond-to(m_desktop) {
                            order: 2;
                        }
                    }

                    .article-page-content {
                        @include respond-to(m_desktop) {
                            order: 1;
                        }
                    }
                }
            }
        }

        &-right {
            .article-page-media {
                @include respond-to(m_desktop) {
                    order: 2;
                }
            }

            .article-page-content {
                @include respond-to(m_desktop) {
                    order: 1;
                }
            }

            .stuck-page-wrapper {
                // 1, 3, 5, 7...
                &:nth-child(2n-1) {
                    .article-page-media {
                        @include respond-to(m_desktop) {
                            order: 2;
                        }
                    }

                    .article-page-content {
                        @include respond-to(m_desktop) {
                            order: 1;
                        }
                    }
                }

                // 2, 4, 6, 8...
                &:nth-child(2n) {
                    .article-page-media {
                        @include respond-to(m_desktop) {
                            order: 1;
                        }
                    }

                    .article-page-content {
                        @include respond-to(m_desktop) {
                            order: 2;
                        }
                    }
                }
            }
        }
    }//.image-position-[left/right]

    .article-page-media {
        position: relative;
        overflow: hidden;
        height: 80vh;

        @include respond-to(m_mobile_landscape){
            height: 80vh;
        }
        @include respond-to(m_tablet) {
            height: 800px;
        }

        @include respond-to(m_desktop) {
            height: auto;
        }

        &-inner {
            height: 100%;
            position: relative;

            @include respond-to(m_desktop) {
                height: auto;

                &.is_stuck{
                    bottom: 0px;
                    top: auto !important;
                }
            }
        }

        &-slider {
            height: 100%;

            .slick-slide {
                .slide {
                    display: block !important;
                }
            }
        }

        .component-picture {
            display: block;
            position: relative;
            overflow: hidden;
            height: 80vh;

            @include respond-to(m_mobile_landscape){
                height: 80vh;
            }

            @include respond-to(m_tablet) {
                height: 800px;
            }
    
            @include respond-to(m_desktop) {
                height: 100vh;
            }
        }

        .component-image {
            width: 100%;
            width: auto;
            min-height: 100%;
            min-width: 100%;
            max-width: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            @include respond-to(m_mobile_down) {
                height: 80vh;
            }
        }

        .slick-dots {
            text-align: center;
            margin: 0;
            background: $white;
            border-radius: 12px;
            line-height: 1;
            padding: 0 3px;
            height: 25px;
            position: absolute;
            z-index: 3;
            left: 50%;
            width: auto;
            transform: translateX(-50%);
            bottom: 20px;

            li {
                position: relative;
                display: inline-block;
                height: 25px;
                width: 20px;
                margin: 0;
                padding: 0;
                cursor: pointer;
                font-size: 0;

                &.slick-active {
                    button {
                        &::before {
                            color: $night-rider;
                            opacity: 1;
                            font-size: rem(40);
                        }
                    }
                }

                button {
                    box-shadow: none;
                    border: 0;
                    background: transparent;
                    display: block;
                    height: 25px;
                    width: 20px;
                    outline: none;
                    line-height: 0;
                    font-size: 0;
                    color: transparent;
                    padding: 5px;
                    cursor: pointer;

                    &::before {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        content: "•";
                        width: 20px;
                        height: 25px;
                        font-family: slick;
                        font-size: rem(33);
                        line-height: 25px;
                        text-align: center;
                        color: rgb(167, 167, 167);
                        opacity: 0.75;
                    }
                }
            }
        }

        &.article-page-video {
            overflow: hidden;
            z-index: 1;
        }
    }//.article-page-media

    &.fiftyfifty-page {
        .component-image {
            @include respond-to(m_mobile_down) {
                height: auto;
                min-height: auto;
            }
        }
    }

    .article-page-media-hotspots {
        overflow: visible;
        height: auto;
        z-index: 1;

        @include respond-to(m_tablet) {
            height: auto;
        }

        .hotspot-tile {
            width: 40px;
            height: 40px;
            background-color: rgba($white, 0.9);
            position: absolute;
            border-radius: 50%;
            background-image: url(#{$m_plus});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
            cursor: pointer;

            &:hover {
                background-color: $white;
            }
        }

        .hotspot-close {
            position: absolute;
            top: 140px;
            right: 20px;
            width: 40px;
            height: 40px;
            background-image: url(#{$m_close-black});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
            cursor: pointer;

            @include respond-to(m_tablet) {
                top: 160px;
            }

            @include respond-to(m_desktop) {
                top: 40px;
            }
        }

        .hotspot-img-wrapper {
            position: relative;
            overflow: hidden;
        }

        .hotspots-product-section {

            .product-hotspot {
                position: fixed;
                background-color: $white;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 11000;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                transition: all 0.3s;
                visibility: visible;

                @include respond-to(m_desktop) {
                    position: absolute;
                    left: 100%;
                }

                &.invisible {
                    z-index: -1;
                    opacity: 0;
                    visibility: hidden;
                }

                &-inner {
                    width: 70%;
                    max-width: 500px;
                }

                .button {
                    @include respond-to(mobile_tablet) {
                        width: auto;
                    }
                }
            }

            .component-picture {
                @include respond-to(m_desktop) {
                    display: block;
                    height: auto;
                }
            }

            .component-image {
                width: 100%;

                @include respond-to(m_desktop) {
                    height: auto;
                    position: static;
                    left: auto;
                    transform: none;
                }
            }
        }
    }

    .article-page-content {
        position: relative;
        padding: 15px 0;
        background-color: $white;

        &-slide {
            outline: none;
        }

        @include respond-to(m_tablet) {
            padding: 50px 0;
        }

        @include respond-to(m_desktop) {
            padding: 100px 0;
        }

        .article-page-content-inner {
            padding: 0 40px;
            margin-left: auto;
            margin-right: auto;
 
            .article-page-inline-link{
                text-align: center;
                margin-top: 20px;
                @include respond-to(m_tablet) {
                    width: 83.3333%;
                    margin-left: 16.6667%;
                }
            }
            .magazine-product-carousel-wrapper {
                margin: 0 -40px;
            }
        }

        .article-type {
            font-family: $font-second;
            font-size: rem(18);
            margin: 0;

            &-hide {
                display: none;
            }
        }

        .article-title {
            font-size: rem(30);
            font-weight: 800;

            @include respond-to(m_tablet) {
                font-size: rem(45);
                text-transform: uppercase;
            }

            @include respond-to(m_desktop_large) {
                font-size: rem(64);
            }
        }

        .article-standfirst {
            font-family: $font-second-regular; // TODO: FONT "didot_lt_stdregular"
            font-size: rem(16);

            @include respond-to(m_tablet) {
                font-size: rem(18);
            }

            @include respond-to(m_desktop_large) {
                font-size: rem(22);
            }

            strong,
            b {
                font-weight: normal;
                font-family: $font-second-bold; // TODO: FONT "didot_lt_stdbold"
            }
        }

        .article-copy {
            padding: 30px 0 0;

            @include respond-to(m_tablet) {
                width: 83.33333%;
                margin-left: 16.66667%;
            }

            .caption p i {
                font-size: rem(14);
                color: $hel-gray;
            }

            p {
                @include respond-to(m_tablet) {
                    font-size: rem(14);
                }

                @include respond-to(m_desktop_large) {
                    font-size: rem(18);
                }
            }

            img {
                max-width: 100%;

                @include respond-to(mobile) {
                    width: 100%;
                }
            }

            & + .article-copy {
                padding-top: 0;
            }

            .article-page-inlinevideo {
                margin-top: 0;
            }

            // M-04 Quote
            blockquote {
                font-family: $font-second;
                margin: 30px 0;

                p {
                    font-size: rem(22);

                    @include respond-to(m_tablet) {
                        font-size: rem(24);
                    }
    
                    @include respond-to(m_desktop_large) {
                        font-size: rem(32);
                    }

                    &:before {
                        content:"\201C";
                    }

                    &:after {
                        content:"\201D";
                    }
                }

                cite {
                    display: block;
                    font-size: rem(13);
                    text-align: right;
                    font-family: $font-main;
                    font-style: normal;

                    &::before {
                        content: "— ";
                    }
                }
            }// M-04 Quote
        }

        .article-page-image {
            padding: 30px 0 40px;
        }
    }//.article-page-content

    .article-page-inlinevideo {
        position: relative;
        z-index: 1;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        margin-top: 30px;
        margin-bottom: 40px;

        &-iframe {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}//.article-page



// Template 2 80/20 split page
.eightytwenty-page {
    .eightytwenty-page-media {
        .component-picture {
            @include respond-to(m_desktop) {
                height: calc(100vh - #{$m_header-height-tablet});
            }

            @include respond-to(desktop_over) {
                height: calc(100vh - #{$m_header-height-desktop_over});
            }
        }
    }

    .eightytwenty-page-content {
        @include respond-to(m_desktop) {
            padding-bottom: 0;
            max-height: calc(100vh - #{$m_header-height-desktop_over});
            overflow-x: auto;
        }

        .eightytwenty-title {
            font-size: rem(22);

            @include respond-to(m_desktop_large) {
                font-size: rem(40);
            }
        }

        .eightytwenty-copy {
            width: 100%;
            margin-left: 0;
            padding-top: 0;

            p {
                font-size: rem(16);

                @include respond-to(m_desktop_large) {
                    font-size: rem(18);
                }
            }
        }
    }
}

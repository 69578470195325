.pt_product-search-result {
    .content-panel {
        @include respond-to(mobile) {
            margin: 0 -15px 40px;
        }
    }
}

.pt_product-search-result {
    .search-promo {
        padding: 0;
    }
}

.content-panel--light {
    background-color: $grey03;
}

.content-panel--dark {
    background-color: $black02;
}

.content-panel__inner {
    width: 100%;
    max-width: 1230px;
    padding: 15px 0 20px;
    margin: 0 auto;

    .content-slider {
        overflow: hidden;
        white-space: nowrap;
    }
}

.content-panel__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 25px;
}

.content-panel__caption {
    display: flex;

    @include respond-to(mobile) {
        justify-content: space-between;
        flex: 1 0 auto;
    }
}

.content-panel__title {
    font-size: rem(16);
    font-weight: $bold;
    line-height: 1;
    margin-right: 30px;
}

.content-panel__text--light {
    color: $black02;
}

.content-panel__text--dark {
    color: $white01;
}

.content-slider__item {
    max-width: 275px;
    display: inline-block;
    margin-left: 15px;
    white-space: normal;
}

.content-slider-panel-img {
    width: 275px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.content-slider__title {
    font-size: rem(20);
    font-weight: $medium;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 15px;
}

.content-slider__text {
    font-size: rem(12);
    line-height: 1.5;
    margin: 0 0 12px;
}

.content-panel__link {
    @include link_underline();

    font-size: rem(12);
    line-height: 1;

    &::after {
        top: 100%;
        bottom: auto;
    }
}

.content-panel__arrows {
    display: flex;

    @include respond-to(mobile) {
        display: none;
    }
    
    .arrow-prev,
    .arrow-next {
        display: block;
        position: static;
        padding: 6px;
        margin: 0;
        font-size: 0;
        color: $hel-gray;
        background-color: $transparent;
        
        &:before {
            font-size: rem(18);
        }
        
        &:hover {
            color: $smoke;
            transition: color .2s ease-in-out;
        }
    }
    
    .arrow-prev {
        @include icon(simple-arrow-left);
    }
    
    .arrow-next {
        @include icon(simple-arrow-right);
    }
}

.content-panel--dark {
    .content-panel__arrows {
        .arrow-prev,
        .arrow-next {
            color: $white;

            &:hover {
                color: $dim-gray;
            }
        }
    }
}

.horizontal-wrapper {
    @include respond-to(tablet_plus) {
        .content-panel {
            position: relative;
        }

        .content-panel::before {
            content: '';
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .content-panel__inner {
            position: relative;
            z-index: 2;
        }
    
        .content-panel--dark::before {
            background-color: $black02;
        }
    
        .content-panel--light::before {
            background-color: $grey03;
        }
    }
}
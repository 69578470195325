
//function for convertation pixels to rems
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}


/* Mixin to put content our of wrapper */
@mixin out-of-wrapper {
    margin-left: -100%;
    margin-right: -100%;
    padding-left: 100%;
    padding-right: 100%;
    box-sizing: content-box;

    .menu-active &,
    .mini-cart-active & {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin full-page-content {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

// Mixin for creating advanced full-width content elemens
// (banners etc. which are already inside of max-width wrapper i.e. #main)
// Parameters :
// -------------------------------------------------------------------------
//  $scrollbar-fix    - if true, it will enable width fix (applied only for desktops) that excludes width of scroller bar
//                      (Especially useful in combination with @mixin clip-decorator as it will properly show cut corners)
// -------------------------------------------------------------------------
//  $scrollbar-width  - width of scroller bar (default = 16px)
// -------------------------------------------------------------------------

@mixin full-page-content($scrollbar-fix: false, $scrollbar-width: 16px) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    @if $scrollbar-fix == true {
        .desktop & {
            width: calc(100vw - #{$scrollbar-width});
            margin-left: calc(-50vw + #{$scrollbar-width / 2});
        }

        .desktop.safari & {
            width: calc(100vw - 14px);
            margin-left: calc(-50vw + #{14px / 2});
        }
    }
}

@mixin full-page-border {
    &::after {
        content: "";
        display: block;
        border: 1px solid $lighter-gray;
        margin-left: -100%;
        margin-right: -100%;
        clear: both;
    }

    &::after {
        @include respond-to(mobile_strong_over) {
            margin-top: 96px;
        }
    
        @include respond-to(mobile_strong) {
            margin-top: 30px;
        }
    }
}


/* Mixin for using icons in pseudo-elements */
@mixin font-icomoon {
    font-family: $font-icons;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Mixin to cut off the rest of one-line text with dots */
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Flexbox Mixins */
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-basis($values) {
    -webkit-flex-basis: $values; /* Safari 6.1+ */
            flex-basis: $values;
}

@mixin flex($values) {
    -webkit-flex: $values;
        -ms-flex: $values;
            flex: $values;
}

@mixin order($val) {
    -ms-flex-order: $val;
     -webkit-order: $val;
             order: $val;
}

@mixin align-items($val) {
    -webkit-align-items: $val;
         -ms-flex-align: $val;
            align-items: $val;
}

@mixin justify-content($val) {
    -webkit-justify-content: $val;
        -ms-justify-content: $val;
            justify-content: $val;
}

@mixin align-content($val) {
    -webkit-align-content: $val;
        -ms-align-content: $val;
            align-content: $val;
}

@mixin align-self($val) {
    -webkit-align-self: $val;
        -ms-align-self: $val;
            align-self: $val;
}

@mixin flex-vertical() {
    -webkit-flex-flow: column;
    -ms-flex-direction: column;
    flex-flow: column;
}

@mixin flex-wrap() {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

@mixin flex-horizontal() {
    -webkit-flex-flow: row wrap;
    -ms-flex-direction: row;
    flex-flow: row wrap;
}

@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin position($position: absolute, $left: auto, $right: auto, $top: auto, $bottom: auto) {
    position: $position;
    left: $left;
    right: $right;
    bottom: $bottom;
    top: $top;
}

@mixin appearance($type: none, $moz_type: none) {
    -webkit-appearance: $type;
    -moz-appearance: $type;
    -ms-progress-appearance: $type;
    appearance: $type;

    @if $moz_type != none {
        -moz-appearance: $moz_type;
    } @else {
        -moz-appearance: none;
    }
}

@mixin shared-wishlist {
    .bticons-heart-filled {
        display: none;
    }

    &.wishlist-checked {
        .bticons-heart-outline {
            display: none;
        }
        .bticons-heart-filled {
            display: inline;
        }
    }
}

@mixin product_actions-button {
    border: 1px solid $black01;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    color: $black01;
    letter-spacing: 1px;
    padding: 9px 10px 9px;
    font-size: rem(10);
    line-height: 1.1;

    @include respond-to(tablet_ext) {
        font-size: rem(9);
    }

    .bticons-heart-outline,
    .bticons-heart-filled {
        &:before {
            font-weight: 600;
            font-size: rem(12);
        }
    }

    &:hover {
        background-color: $black01;
        color: $white;
    }
}

@mixin shared-promo-flag {
    .promo-flag {
        text-transform: uppercase;
        font-size: rem(12);
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        @include respond-to(mobile) {
            position: static;
            display: block;
            margin-bottom: 20px;
        }

        span {
            background: $lighter-gray;
            color: $black;
            padding: 5px 10px;
            display: inline-block;
            border-radius: 4px;
        }
    }
}

// mixin to change placeholder style (font\color etc)
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

//----------------------------------------------------------------------
// Brand mixins
//----------------------------------------------------------------------
@import "_brand-mixins";

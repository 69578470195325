// Styles for common elements across the magazine
// --------------------------
@font-face {
    font-family: 'DidotLTStd-Regular';
    src: url("../fonts/didotltstd-roman-webfont.eot");
    src: url("../fonts/didotltstd-roman-webfont.eot?#iefix") format("embedded-opentype"),
             url("../fonts/didotltstd-roman-webfont.woff2") format("woff2"),
             url("../fonts/didotltstd-roman-webfont.woff") format("woff"),
             url("../fonts/didotltstd-roman-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

@font-face {
    font-family: 'DidotLTStd-Bold';
    src: url("../fonts/didotltstd-bold-webfont.eot");
    src: url("../fonts/didotltstd-bold-webfont.eot?#iefix") format("embedded-opentype"),
             url("../fonts/didotltstd-bold-webfont.woff2") format("woff2"),
             url("../fonts/didotltstd-bold-webfont.woff") format("woff"),
             url("../fonts/didotltstd-bold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.magazine-body {
    .top-banner {
        &::after {
            @include respond-to(m_tablet) {
                content: '';
                position: absolute;
                width: 100%;
                bottom: 100%;
                height: 30px;
                z-index: -1;
                background: $white;
            }
        }
    }

    .ie & .slick-slider .slick-track {
        backface-visibility: visible;
    }
}

.magazine {
    .page-content {
        a {
            color: $body-base-color;

            &:hover,
            &:focus {
                color: $link-hover-color;
            }
        }

        button,
        .button,
        .scroll-to-top,
        .buttonstyle,
        input[type="button"] {
            @include respond-to(mobile_tablet) {
                width: auto;
            }
        }

        .button.outlined {
            &:focus {
                color: $body-base-color;
            }

            &:hover {
                color: $link-hover-color;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-main;
        font-style: normal;
        font-weight: 700;
        color: inherit;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 20px;
    }

    h1 {
        font-size: rem(42);
        font-weight: 800;

        @include respond-to(m_tablet) {
            font-size: rem(48);
        }
    }

    h2 {
        font-size: rem(20);

        @include respond-to(m_tablet) {
            font-size: rem(40);
        }
    }

    h3 {
        font-size: rem(19);

        @include respond-to(m_tablet) {
            font-size: rem(20);
        }
    }

    h4 {
        font-size: rem(18);
    }

    h5 {
        font-size: rem(17);

        @include respond-to(m_tablet) {
            font-size: rem(20);
        }
    }

    h6 {
        font-size: rem(16);
    }

    p {
        margin-bottom: 16px;
        margin-top: 0;
        line-height: 1.5;
        font-size: rem(14);
    }

    strong,
    b {
        font-weight: 700;
        line-height: inherit;
    }

    .text-center {
        text-align: center;
    }

    .BT-quote {
        font-family: $font-second;
        text-align:center;

        @include respond-to(m_tablet) {
            font-size: rem(24);
        }
    }

    #main {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

    .primary-content {
        @include respond-to(m_tablet) {
            width: 100%;
            float: none;
        }
    }

    .footer,
    .breadcrumb {
        display: none;
    }

    //barba
    .barba-container {
        background: $white;
    }

    #barba-wrapper.expanded .article-page{
        width: 100%;
        height: calc(100vh - 172px);
        @include respond-to(desktop_below){
            height: calc(100vh - 162px);
        }
        @include respond-to(touch){
            height: calc(100vh - 164px);
        }

        @include respond-to(mobile_tablet){
            height: calc(100vh - 151px);
        }

        @include respond-to(mobile_strong){
            height: calc(100vh - 124px);
        }

    }

    // Preloader
    .preloader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background-color: $white;
        z-index:2000;

        &:after {
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100px;
            height: 100px;
            background: url(#{$m_preloader}) no-repeat center center;
            display: block;
            opacity:0.2;
            animation: flickerAnimation 1.5s infinite linear;
        }
    }

    @keyframes flickerAnimation {
        0%   { opacity:0.2; }
        50%  { opacity:1; }
        100% { opacity:0.2; }
    }

    .animate-flicker {
        animation: flickerAnimation 1s infinite;
    }

    //page nav
    .pagenav {
        position: fixed;
        z-index: 300;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        opacity: 1;
        transition: all linear .2s;

        @include respond-to(m_tablet) {
            width: 80px;
            height: 80px;
        }

        @include respond-to(touch_over) {
            bottom: auto;
            position: fixed;
            width: 60px;
            height: 120px;
            opacity: 0.4;
        }

        &:hover {
            opacity: 0.8;
        }

        &.next {
            right: -30px;
            border-top-left-radius: 60px;
            border-bottom-left-radius: 60px;
            background: url(#{$m_arrow-right-white})  rgba($black,0.2) no-repeat 25% 50%/16px 16px;

            @include respond-to(m_tablet) {
                right: -40px;
                border-top-left-radius: 80px;
                border-bottom-left-radius: 80px;
                background-size: 24px 24px;
            }

            // > 1024px
            @include respond-to(touch_over) {
                right: -30px;
                border-radius: 0;
                border-top-left-radius: 120px;
                border-bottom-left-radius: 120px;
                background: url(#{$m_arrow-right-white})  $black no-repeat 30px 45px/20px 32px;

                &:hover {
                    right: 0;
                }
            }
        }

        &.prev {
            left: -30px;
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px;
            background: url(#{$m_arrow-left-white})  rgba($black,0.2) no-repeat 75% 50%/16px 16px;

            @include respond-to(m_tablet) {
                left: -40px;
                border-top-right-radius: 80px;
                border-bottom-right-radius: 80px;
                background-size: 24px 24px;
            }

            // > 1024px
            @include respond-to(touch_over) {
                left: -30px;
                border-radius: 0;
                border-top-right-radius: 120px;
                border-bottom-right-radius: 120px;
                background: url(#{$m_arrow-left-white})  $black no-repeat 12px 45px/20px 32px;

                &:hover {
                    left: 0;
                }
            }
        }
    }

    .navnextslideout {
        // > 1024px
        @include respond-to(touch_over) {
            right: 0;
            animation: navnextslideout 1.2s linear;
        }
    }

    .navprevslideout {
        // > 1024px
        @include respond-to(touch_over) {
            left: 0;
            animation: navprevslideout 1.2s linear;
        }
    }


    @keyframes navnextslideout {
        0% {
            right: 0;
            opacity: .5
        }

        80% {
            right: 0;
            opacity: .5
        }

        100% {
            right: -30px;
            opacity: .2
        }
    }

    @keyframes navprevslideout {
        0% {
            left: 0;
            opacity: .5
        }

        80% {
            left: 0;
            opacity: .5
        }

        100% {
            left: -30px;
            opacity: .2
        }
    }

    .article-page-product-image {
        .component-image {
            margin: auto;
        }
    }

    //image captions
    .image-caption {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        background-color: rgba($white, 0.9);
        position: absolute;
        z-index: 2;
        left: 20px;
        top: 20px;
        cursor: pointer;
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
        font-family: $font-second;
        font-size: rem(20);

        @include respond-to(m_tablet) {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: rem(24);
        }

        &:hover {
            background-color: rgba($white, 1);
        }

    }

    .image-caption-popup-wrapper {
        position: relative;
        max-width: 700px;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
    }

    .image-caption-text {
        position: absolute;
        z-index: 10;
        width: 300px;
        font-size: rem(16);
        background: rgba($night-rider, 0.6);
        padding: 5px 10px;
        color: $white;
        border: 0;
        top: 55px;
        left: 20px;

        @include respond-to(m_mobile_portrait) {
            width: 50%;
        }

        @include respond-to(m_tablet) {
            top: 65px;
        }

        p {
            font-size: rem(14);
            margin-bottom: 0;
        }

        a {
            color: $white;
            font-weight: 600;
            text-transform: uppercase;
            display: block;
            padding: 3px 0;
        }

        .button.outlined {
            width: auto;
            background: transparent;
            text-align: left;
            border: none;
            font-size: inherit;
            color: inherit;
            line-height: 1.4;
        }
    }

    .image-caption-product {
        position: absolute;
        z-index: 10;
        width: 100%;
        top: 0;
        height: calc(100% - 124px);

        @include respond-to(m_tablet) {
            max-width: 600px;
            height: 70%;
            margin-top: -100px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .caption-close-button {
            position: fixed;
            z-index: 1;
            right: 15px;
            top: 15px;
            background-color: $white;
            border-radius: 50%;
            font-size: 0;
            width: 40px;
            height: 40px;
            padding: 0;
            border: none;
            background-image: url(#{$m_close-black});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 30%;
        }

        .article-product-inline-list {
            max-height: 100%;
            overflow-y: auto;
            padding: 55px 15px;
            background: $white;
            -webkit-overflow-scrolling: touch;
            img{
                display: block;
                margin: 0 auto;
            }
        }

        .article-product-inline {
            text-align: center;

            @include respond-to(m_tablet) {
                text-align: left;
            }
        }

        .article-product-inline-img {
            margin-bottom: 40px;

            @include respond-to(m_tablet) {
                margin-bottom: 0;
            }
        }

        .article-inline-product-brand {
            font-size: rem(14);
            font-weight: 500;
            text-transform: uppercase;
        }

        .article-product-inline-title {
            font-weight: normal;
        }

    }

    .event ,
    .article-section-line {
        border-bottom: 1px solid $lighter-gray;
        padding-bottom: 5px;
    }

    .caption {
        &-image-wrapper {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            margin: 30px auto 40px;
            overflow: hidden;
        }

        &-image {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .article-product-inline {
        width: 100%;
        padding: 30px 0;

        & > .row {
            align-items: center;
        }

        &-txt {
            text-align: center;
            padding: 0 10px;
            max-width: 250px;
            margin: 0 auto;

            .button {
                margin: 0;

                @include respond-to(m_mobile_portrait) {
                    font-size: rem(10);
                    padding: 12px 12px;
                }
            }
        }

        &-title {
            font-size: rem(14);
            text-transform: none;
            font-weight: 600;
            font-family: $font-main;
            margin-bottom: 10px;

            @include respond-to(m_tablet) {
                font-size: rem(16);
            }
        }

        &-brand {
            font-weight: 600;
            display: block;
            margin-bottom: 10px;
        }

        &-desc {
            font-size: rem(12);
            line-height: 1.3;
            color: $hel-gray2;

            @include respond-to(m_tablet) {
                font-size: rem(14);
            }
        }
    }

    .product-stack-tile {
        width: 100%;
        align-items: center;

        &:nth-child(2n) {
            flex-direction: row-reverse;
        }
    }

    .product-stack-image {
        background-color: $white;
        text-align: center;
        position: relative;
        padding-top: 50%;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            max-height: 100%;
            max-width: 100%;
        }
    }

    .article-productstack-txt {
        text-align: center;
        padding: 0 10px;
        max-width: 250px;
        margin: 0 auto;

        .article-productstack-title {
            font-size: rem(14);
            text-transform: none;
            font-weight: 500;
            margin-bottom: 10px;

            @include respond-to(m_tablet) {
                font-size: rem(16);
            }
        }

        .article-productstack-brand {
            display: block;
        }

        .article-productstack-desc {
            font-size: rem(12);
            line-height: 1.3;
            color: $mid-gray;

            @include respond-to(m_tablet) {
                font-size: rem(14);
            }
        }

        .button {
            margin: 0;

            @include respond-to(m_mobile_down) {
                font-size: rem(10);
                padding: 12px 12px;
            }
        }
    }

    .scroll-to-shop {
        text-align: center;
        background-color: #F8F5F4;
        padding: 5%;
        font-weight: 500;

        &::before {
            content: '';
            background-image: url(#{$m_scroll-icon});
            width: 17px;
            height: 42px;
            display: inline-block;
            vertical-align: middle;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

//magazine share button
.magazine-share {
    $msh_toggle-size: 50px;
    $msh_dot-size: 4px;
    $msh_dot-gutter: 2px;

    position: fixed;
    bottom: 15px;
    right: 15px;
    width: $msh_toggle-size;
    height: $msh_toggle-size;
    background-color: $night-rider;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    z-index: 300;

    &::before {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($white, 0.3);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s linear;
    }

    @include respond-to(m_tablet) {
        bottom: 30px;
        right: 30px;
    }

    &.expanded {
        &::before {
            opacity: 1;
            visibility: visible;
        }

        .magazine-share-toggle-content {
            opacity: 1;
            visibility: visible;
        }
    }

    &.menu-close {
        .magazine-share-toggle-content {
            opacity: 1;
            visibility: visible;
        }

        .magazine-share-list,
        .page-feature-text,
        &::before {
            opacity: 0;
            visibility: hidden;
        }

        .page-feature-link {
            background-image: url(#{$m_close});
        }
    }

    &-buttons {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;
        border-radius: 50%;
        width: $msh_dot-size;
        height: $msh_dot-size;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            content: '';
            background-color: $white;
            border-radius: 50%;
            width: $msh_dot-size;
            height: $msh_dot-size;
            cursor: pointer;
        }

        &::before {
            bottom: 100%;
            margin-bottom: $msh_dot-gutter;
        }

        &::after {
            top: 100%;
            margin-top: $msh_dot-gutter;
        }
    }

    &-toggle-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s linear;
    }

    &-list {
        position: absolute;
        bottom: 100%;
        width: 100%;
        margin: 0;
    }

    &-link {
        display: block;
        width: 80%;
        padding-top: 80%;
        margin: 10px auto;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: $night-rider;
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center;
    }

    &-google { background-image: url(#{$m_google-plus});}
    &-facebook { background-image: url(#{$m_facebook});}
    &-twitter { background-image: url(#{$m_twitter});}

    .page-feature-link {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: $night-rider;
        background-image: url(#{$m_opened-book});
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
    }

    .page-feature-text {
        position: absolute;
        right: 100%;
        margin-right: 10px;
        font-size: rem(14);
        font-weight: 500;
        line-height: 1.6;
        padding: 0 5px;
        white-space: nowrap;
        background-color: $night-rider;
        color: $white;
        box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.5);
        top: 50%;
        transform: translateY(-50%);
    }
}

// Magazine Page Layout
.magazine_page-layout {
    position: relative;
    width: 100%;
    height: calc(100vh - #{$m_header-height-default});
    background-color: $white;
    overflow-y: auto;
    z-index: 1;
    -webkit-overflow-scrolling: touch;

    &.feature-page {
        @include respond-to(m_mobile_portrait) {
            height: auto;
        }
    }

    @include respond-to(m_tablet) {
        height: calc(100vh - #{$m_header-height-tablet});
    }

    @include respond-to(mobile_stronger) {
        height: calc(100vh - #{$m_header-height-mobile_stronger});
    }

    @include respond-to(desktop_over) {
        height: calc(100vh - #{$m_header-height-desktop_over});
    }
}

// Page title
.page-title {
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 20px;
    color: $body-base-color;

    b,
    strong {
      font-family: $font-main;
      font-weight: 700;
    }

    span.page-title-logo {
        display: block;
        text-indent: -9999px;
        background-image: url(#{$m_logo-default});
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;
        height: 16px;

        @include respond-to(m_tablet) {
            height: 25px;
        }
    }
}

// Page Lead
.page-lead {
    font-family: $font-main;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    font-size: rem(20);
    margin-bottom: 16px;

    @include respond-to(m_tablet) {
        font-size: rem(40);
        font-weight: 800;
    }

    span.page-lead-issue {
        display: block;
        font-family: $font-main;
        font-weight: 600;
        line-height: 1.3;
        font-size: rem(12);

        @include respond-to(m_tablet) {
            font-size: rem(18);
        }
    }
}

// article-productcarousel
.article-productcarousel {
    $slick-dot-size-width: 20px;
    $slick-dot-size-height: 25px;
    $slick-dot-font-size: 40px;

    width: 100%;
    padding-bottom: 30px;

    .article-productcarousel-item {
        outline: none;

        .article-productcarousel-img {
            margin-bottom: 30px;

            img {
                display: block;
                margin: 0 auto;
            }
        }

        .article-productcarousel-txt {
            text-align: center;
            padding: 0 10px;
            max-width: 250px;
            margin: 0 auto;

            .article-productcarousel-txt-wrapper {
                min-height: 70px;
            }

            .article-productcarousel-title {
                font-size: rem(14);
                text-transform: none;
                font-weight: 600;
                margin-bottom: 10px;

                @include respond-to(m_tablet) {
                    font-size: rem(16);
                }
            }

            .article-productcarousel-brand {
                display: block;
            }

            .article-productcarousel-desc {
                font-size: rem(12);
                line-height: 1.3;
                color: $hel-gray2;

                @include respond-to(m_tablet) {
                    font-size: rem(14);
                }
            }

            .button {
                margin: 0;
            }
        }
    }

    .slick-dots {
        list-style: none;
        text-align: center;
        padding: 0;
        background: $white;
        border-radius: 12px;
        line-height: 1;
        padding: 0 3px;
        height: $slick-dot-size-height;
        margin: 30px auto;
        display: block;

        li {
            position: relative;
            display: inline-block;
            height: $slick-dot-size-height;
            width: $slick-dot-size-width;
            margin: 0;
            padding: 0;
            cursor: pointer;

            button {
                box-shadow: none;
                border: 0;
                background: transparent;
                display: block;
                height: $slick-dot-size-height;
                width: $slick-dot-size-width;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 5px;
                cursor: pointer;

                &:hover,
                &:focus {
                    outline: none;

                    &:before {
                        opacity: .75;
                    }
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "•";
                    width: $slick-dot-size-width;
                    height: $slick-dot-size-height;
                    font-size: rem(33.33333);
                    font-family: slick;
                    line-height: $slick-dot-size-height;
                    text-align: center;
                    color: #a7a7a7;
                    opacity: .75;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }

            &.slick-active button:before {
                color: $night-rider;
                opacity: 1;
                font-size: rem(40);
            }
        }
    }
}


//article-slideout
.article-slideout {
    background: #f6f6f6;
    border-top: 1px solid $gainsboro2;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 15px;
    width: 100%;
    z-index: 4;
    transition: transform 0.3s;
    transform: translateY(0) translateX(0);

    @include respond-to(m_tablet) {
        padding: 30px;
    }

    @include respond-to(m_desktop) {
        background: $white;
        top: 0;
        bottom: auto;
        height: 100%;
        padding: 80px 30px 30px 30px;
        width: 25%;
    }

    @include respond-to(m_desktop_large) {
        width: 16.66667%;
    }

    &.article-slideout-closed {
        transform: translateY(100%);

        @include respond-to(m_desktop) {
            transform: translateX(-100%) translateY(0);
        }
    }

    &.direction-right {
        left: auto;
        right: 0;

        &.article-slideout-closed {
            @include respond-to(m_desktop) {
                transform: translateX(100%) translateY(0);
            }
        }

        .close-slideout {
            @include respond-to(m_desktop) {
                left: 15px;
                right: auto;
            }
        }
    }

    .close-slideout {
        position: absolute;
        right: 15px;
        top: 8px;
        width: 16px;
        height: 16px;
        background-image: url(#{$m_close-black});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;

        &:hover {
            opacity: .5;
        }

        @include respond-to(m_desktop) {
            top: 50px;
        }
    }

    .article-slideout-list {
        overflow-x: auto;
        max-width: 100%;

        @include respond-to(m_desktop) {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            max-height: 100%;
        }
    }

    .article-slideout-cta {
        text-align: center;
        font-weight: 600;
        font-size: rem(16);
        text-transform: uppercase;
        margin: 0 0 10px 0;

        @include respond-to(m_desktop) {
            font-size: rem(22);
            margin: 0 0 20px 0;
            padding-bottom: 50px;
            border-bottom: 1px solid $lighter-gray;
            position: relative;
            z-index: 1;

            &:after {
                content: '';
                position: absolute;
                z-index: 0;
                width: 100%;
                height: 50px;
                left: 0;
                bottom: 0;
                background: url(#{$m_chevron-down}) no-repeat center center/20px 20px;
            }
        }
    }

    .article-slideout-container {
        display: flex;

        @include respond-to(m_desktop) {
            flex-direction: column;
        }
    }

    .article-slideout-item {
        width: 130px;
        min-width: 130px;
        display: block;
        padding-right: 30px;

        @include respond-to(m_tablet) {
            width: 215px;
            min-width: 215px;
        }

        @include respond-to(m_desktop) {
            padding: 0;
            width: 100%;
            min-width: 0;
        }

        .article-slideout-img {
            margin-bottom: 5px;
            background: $white;

            @include respond-to(m_tablet) {
                margin-bottom: 10px;
            }

            @include respond-to(m_desktop) {
                margin-bottom: 15px;
            }

            img {
                display: block;
                max-height: 100px;
                margin: 0 auto;

                @include respond-to(m_tablet) {
                    max-height: 170px;
                }

                @include respond-to(m_desktop) {
                    max-height: none;
                }
            }
        }

        .article-slideout-txt {
            text-align: center;
            padding: 0 10px;
            max-width: 250px;
            margin: 0 auto;

            .article-slideout-title {
                font-size: rem(14);
                text-transform: none;
                font-weight: 600;
                margin: 0;

                @include respond-to(m_tablet) {
                    font-size: rem(16);
                }

                @include respond-to(m_desktop) {
                    margin-bottom: 10px;
                }
            }

            .article-slideout-brand {
                display: block;
            }

            .article-slideout-desc {
                font-size: rem(12);
                line-height: 1.3;
                color: $hel-gray2;

                @include respond-to(m_tablet) {
                    font-size: rem(14);
                }
            }

            .button {
                margin: 0;
            }
        }
    }
}

.media-slider-pagination {
    background:$white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    text-align: center;
    line-height: 50px;
    font-family: $font-second;
    font-size: rem(14);
    white-space: nowrap;
    left: 20px;
    top:430px;

    @include respond-to(m_tablet_down) {
        display: none;
    }

    @include respond-to(m_tablet) {
        top: 630px;
    }


    @include respond-to(m_desktop) {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: rem(16);
        bottom: 20px;
        top: auto;
        right: 20px;
    }

    .image-position-left .stuck-page-wrapper:nth-child(2n) & {
        @include respond-to(m_desktop) {
            right: auto;
            left: 20px;
        }
    }

    .image-position-right .stuck-page-wrapper:nth-child(2n-1) & {
        @include respond-to(m_desktop) {
            right: auto;
            left: 20px;
        }
    }
}

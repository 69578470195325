//utility classes

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.menu {
    float: left;
    margin: 0;
    padding: 0;

    li {
        float: left;
        list-style: none outside none !important;
    }
}

// helper-classes
.mb20 {
    margin-bottom: 20px;
}

.mt20 {
    margin-top: 20px;
}

.content-center {
    text-align: center;
}

.content-right {
    text-align: right;
}

// grid
.layout-grid {
    .row {
        @extend %clearfix;
        width: 86%;
        margin: 0 auto;
    }
    .columns {
        float: left;
        padding-left: .9375rem;
        padding-right: .9375rem;
    }

    .text-center {
        text-align: center;
    }

    img {
        max-width: 100%;
    }

    .large-1{width:4.16667%}
    .large-2{width:8.33333%}
    .large-3{width:12.5%}
    .large-4{width:16.66667%}
    .large-5{width:20.83333%}
    .large-6{width:25%}
    .large-7{width:29.16667%}
    .large-8{width:33.33333%}
    .large-9{width:37.5%}
    .large-10{width:41.66667%}
    .large-11{width:45.83333%}
    .large-12{width:50%}
    .large-13{width:54.16667%}
    .large-14{width:58.33333%}
    .large-15{width:62.5%}
    .large-16{width:66.66667%}
    .large-17{width:70.83333%}
    .large-18{width:75%}
    .large-19{width:79.16667%}
    .large-20{width:83.33333%}
    .large-21{width:87.5%}
    .large-22{width:91.66667%}
    .large-23{width:95.83333%}
    .large-24{width:100%}
}

.dialog-content .row {
    width: 100%;
}

.sentence-case {
    display: inline-block;
    width: 80%;
    width: calc(100% - 28px);
    float: right;
    text-transform: lowercase;

    @include respond-to(mobile) {
        width: calc(100% - 48px);
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.toggle,
.js-toggle {
    cursor: pointer;
}

.hidden {
    display: none !important;
}

.hidden-desktop {
    @include respond-to(touch_over) {
        display: none !important;
    }
}

.hidden-tablet {
    @include respond-to(tablet_ext) {
        display: none !important;
    }
}

.hidden-mobile {
    @include respond-to(mobile) {
        display: none !important;
    }
}

.hidden-desktop-nav {
    @include respond-to(desktop_plus) {
        display: none !important;
    }
}

.hidden-tablet-nav {
    @include respond-to(mobile_stronger) {
        display: none !important;
    }
}

.hidden-mobile-nav {
    @include respond-to(mobile_strong) {
        display: none !important;
    }
}

.visible-desktop {
    @include respond-to(touch) {
        display: none !important;
    }
}

.visible-mobile {
    @include respond-to(tablet_plus) {
        display: none !important;
    }

    .top-banner &,
    .footer &  {
        @include respond-to(mobile_strong_over) {
            display: none !important;
        }
    }
}

.no-wrap {
    white-space: nowrap;
}

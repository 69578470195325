// Styles for cover page of the magazine
// --------------------------

//Cover Page Content Wrapper
.cover-page {
    &-wrapper {
        color: $white;
        padding: 15px;

        @include respond-to(m_tablet) {
            padding: 30px;
        }

        &#{$theme1-class} {
            color: $theme1-color;
        }

        a {
            color: inherit;
        }
    }//.cover-page-wrapper

    .cover-page-title {
        color: inherit;
        font-family: $font-main;
        font-weight: 700;
        font-size: rem(20);
        text-shadow: 0 2px 10px rgba(51, 51, 51, 0.2),
                     0 0 2px rgba(0, 0, 0, 0.1);

        @include respond-to(m_tablet) {
            font-size: rem(40);
            font-weight: 800;
        }

        @include respond-to(m_desktop) {
            font-size: rem(60);
        }

        span.page-title-logo {
            background-image: url(#{$m_logo-white});
            width: 100%;
            height: 23px;

            @include when-inside(#{$theme1-class}) {
                background-image: url($theme1-logo);
            }

            @include respond-to(m_mobile_landscape) {
                height: 27px;
            }

            @include respond-to(m_tablet) {
                width: 565px;
                height: 55px;
            }

            @include respond-to(m_desktop) {
                width: 700px;
                height: 68px;
            }

        }
    }//.cover-page-title

    .cover-page-lead {
        position: absolute;
        z-index: 2;
        bottom: 60px;
        left: 0;
        width: 70%;
        font-size: rem(48);
        margin-bottom: 15px;
        margin-left: 15px;
        text-shadow: 0 2px 10px rgba(51,51,51,0.2),
                     0 0 2px rgba(0,0,0,0.1);

        @include respond-to(m_mobile_landscape) {
            bottom: 0;
        }

        @include respond-to(m_tablet) {
            font-size: rem(70);
            margin-bottom: 30px;
            margin-left: 30px;
            font-weight: 800;
        }

        span.page-lead-issue {
            font-size: rem(20);

            @include respond-to(m_tablet) {
                font-size: rem(37.5);
                font-weight: 500;
            }
        }
    }//.cover-page-lead

    // Cover Page Background
    .cover-page-bg-container {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100vw;
        overflow: hidden;
        z-index: -1;

        .component-image,
        .component-video {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-width: 100%;
            min-height: 100%;
        }

        .component-image {
            @include respond-to(touch) {
                max-width: none;
            }
        }

        .component-video {
            display: none;

            @include respond-to(m_tablet) {
                display: block;
            }

            & ~ .component-picture,
            & ~ .component-image {
                @include respond-to(m_tablet) {
                    display: none;
                }
            }
        }
    }
}//.cover-page


//checkout progress bar styles
$co-progress-bar-indicator-size                  : 32px;
$co-progress-bar-indicator-border-size           : 1px;
$co-progress-bar-indicator-border-color          : #444444;
$co-progress-bar-indicator-border-color-current  : $pink;

.checkout-progress-indicator {
    margin: 75px auto;
    height: auto;
    padding: 0;
    font-size: 0;

    @include respond-to(mobile_strong) {
        margin-top: 35px;
        width: 90%;
        max-width: 600px;
    }

    .checkout-progress-step {
        height: auto;
        color: $nero2;
        padding: 0 12px;
        margin: 0;
        font-size: rem(14);

        @include respond-to(mobile_strong) {
            width: 20%;
            padding: 12px 3px 0;
        }

        @include respond-to(mobile_min) {
            min-width: 56px;
            padding: 12px 0 0;  
        }

        > .link {
            padding: 0;
        }

        &:before {
            display: none;
        }

        i,
        span {
            vertical-align: middle;
        }

        i {
            display: inline-block;
            width: $co-progress-bar-indicator-size;
            height: $co-progress-bar-indicator-size;
            border: $co-progress-bar-indicator-border-size solid $co-progress-bar-indicator-border-color;
            color: $co-progress-bar-indicator-border-color;
            line-height: #{$co-progress-bar-indicator-size - $co-progress-bar-indicator-border-size*2};
        }

        span {
            margin-left: 10px;
            padding: 5px;
            font-size: rem(13);

            @include respond-to(mobile) {
                display: inline-block;
            }

            @include respond-to(mobile_strong) {
                display: block;
                margin-left: 0;
                font-size: rem(10);
                padding: 5px 0;
            }

            a {
                padding: 0;
            }
        }

        &.is-current,
        &.is-clickable {
            i {
                border-color: $co-progress-bar-indicator-border-color-current;
                color: $co-progress-bar-indicator-border-color-current;
            }
        }

        &.is-current {
            i {
                @include respond-to(mobile_strong) {
                    transform: scale(1.3);
                }
            }
        }

        &.is-clickable {
            i {
                background-color: $co-progress-bar-indicator-border-color-current;
                position: relative;
                color: transparent;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    color: $white;
                    background-image: url(../images/ic_done_white_24px.svg);
                    background-size: 24px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }

        &:first-child {
            i {
                @extend .bticons-bag;
                position: relative;
                font-family: $font-icons;
                color: transparent;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    color: $white;
                    background-image: none;
                }
            }
        }
    }
}
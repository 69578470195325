.registration-selection {
    input,
    label {
        display: inline;
        float: none;
    }
}

/* Create account success */
.account-create-success {
    text-align: center;

    a {
        display: block;
        width: 200px;
        margin: 0 auto 10px;
    }

    h3 {
        display: inline-block;
        margin-top: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid;
        text-transform: none;
    }

    p {
        margin-bottom: 40px;
    }

    img {
        max-width: 100%;
    }

    .button {
        width: 200px;
        margin: 50px auto;
    }
}

.create-account-header-banner {
    background: $ghost;
    text-align: center;

    @include respond-to(mobile) {
        margin-left: -100%;
        margin-right: -100%;
        padding-left: 100%;
        padding-right: 100%;
    }

    .pt_account_unregistered & {
        margin-left: -100%;
        margin-right: -100%;
        padding-left: 100%;
        padding-right: 100%;
    }

    h2 {
        padding: 35px 0;
    }
}
.store-locator-header {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    font-size: 1.75em;
    font-family: $font-second;
    margin: 20px 0 0;
    padding: 20px 0;
}

.store-information {
    background: none repeat scroll 0 0 $gainsboro;
    border-bottom: 1px solid $white;
}

.store-name {
    font-weight: bold;
    margin: 0 0 10px;
}

.store-map {
    border-bottom: 1px solid $gainsboro;
}

.store-locator-details {
    float: left;
    width: 44%;

    img {
        max-width: 100%;
    }

    h1,
    p {
        margin: 0.25em 0;
    }
}

.store-locator-details-map {
    float: right;
    width: 50%;
    img {
        max-width: 100%;
    }
}

.store-map-marker {
    @include respond-to(mobile) {
        height: 400px;
    }
}

/* Stores list */
.pt_store-locator {
    .primary-content {
        width: 100%;
        float: none;
    }
    
    .store-locator-content {
        @extend %clearfix;
        margin: 50px 0;
    }
    
    .page-hit-tile-wrapper {
        float: left;
        width: 21%;
        margin: 0 2% 40px 2%;
        
        @include respond-to(mobile) {
            width: 100%;
            margin: 0 0 40px 0;
        }
        
        img {
            max-width: 100%;
        }
        
        .button {
            width: 100%;
            background-color: $white;
            color: $black;
            text-decoration: underline;
            font-size: rem(13);
        }
        
        h2 {
            font-size: rem(16);
            font-weight: 500;
            text-align: center;
        }
    }
    
    .page-hit-tile-description {
        text-align: center;
        margin-top: 6px;
    }
}
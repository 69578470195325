.js .order-history-table .hidden {
    display: none;
}

.confirmation {
    .actions {
        padding: .5em 1%;

        a {
            margin-right: 2rem;
        }
    }
}

.confirmation-message {
    margin-bottom: 60px;
    text-align: center;

    @include respond-to(mobile) {
        margin-bottom: 0;
    }

    @include respond-to(print) {
        margin-bottom: 20px;
    }

    .title {
        font-size: rem(32);
        margin-bottom: 10px;
    }

    .order-reference {
        font-size: rem(18);
        color: $dim-gray;
    }

    & + .login-create-account.login-box {
        padding-right: 0;
    }
}

.confirmation {
    padding-top: 40px;

    &.create-account {
        .login-create-account {
            margin-bottom: 65px;

            @include respond-to(print) {
                display: none;
            }
        }

        .login-box-asset {
            width: 100%;
            text-align: center;
            padding: 40px 0 30px;
            margin-bottom: 30px;
            background: $lighter-gray;

            @include respond-to(mobile) {
                float: none;
                margin-top: 30px;
                padding: 40px 20px 30px;
                width: auto;
            }

            .title {
                font-size: rem(21);
                font-weight: 500;
                margin-bottom: 10px;

                @include respond-to(mobile) {
                    font-size: rem(19);
                }
            }

            ul {
                font-size: rem(16);

                li {
                    margin-bottom: 10px;
                }

                li:before {
                    content: '-';
                    padding-right: 5px;
                }
            }
        }

        .login-box-content {
            width: 100%;
            border-top: 0;

            @include respond-to(mobile) {
                float: none;
                padding-left: 0;
                width: auto;
            }

            p {
                margin-bottom: 0;
                margin-top: 0;
            }

            form {
                @include respond-to(mobile) {
                    @include flexbox;
                    @include flex-vertical;

                    .form-fieldset-password {
                        @include order(1);
                    }

                    .form-fieldset-up-to-date {
                        @include order(2);
                    }

                    .form-fieldset-loyalty {
                        @include order(3);
                    }

                    > .button {
                        @include order(4);
                    }
                }
            }

            label {
                @include respond-to(mobile) {
                    display: block;
                }
            }

            .form-row {
                margin: 0;
            }

            .form-fieldset-loyalty {
                float: right;
                width: 46%;
                margin-bottom: 45px;    

                @include respond-to(mobile) {
                    width: 100%;
                    margin-top: 20px;
                    float: none;
                    margin-bottom: 0;
                }

                legend {
                    margin-bottom: 20px;
                }

                label {
                    display: inline-block;

                    @include respond-to(mobile) {
                        width: 85%;
                        line-height: inherit;
                    }
                }

                .field-wrapper {
                    margin-bottom: 15px;

                    @include respond-to(mobile) {
                        margin-bottom: 20px;
                        line-height: normal;
                    }

                    input[type="checkbox"],
                    input[type="radio"] {
                        @include respond-to(mobile) {
                            display: inline-block;
                            vertical-align: top;
                            margin-top: 5px;
                        }
                    }
                }

                .account-loyality-left {
                    width: 100%;
                    display: block;
                    line-height: 30px;
                }

                .account-loyality-right {
                    display: none;
                }
            }

            .form-fieldset-up-to-date {
                float: left;
                width: 45%;

                @include respond-to(mobile) {
                    width: 100%;
                    float: none;
                    margin-top: 20px;
                }

                legend {
                    margin-bottom: 15px;
                }

                label {
                    margin-bottom: 10px;
                    line-height: 32px;
                    padding-right: 20px;

                    @include respond-to(mobile) {
                        display: inline;
                    }
                }
            }

            .form-fieldset-password {
                float: left;
                width: 45%;

                @include respond-to(mobile) {
                    float: none;
                    width: 100%;
                }

                legend {
                    margin-bottom: 30px;
                }
            }

            .form-row-passwordvalidate {
                margin-bottom: 20px;

                .form-row-passwordvalidate {
                    margin-bottom: 0;
                }

                label {
                    float: left;
                    width: 40%;
                    padding-right: 10px;

                    @include respond-to(tablet) {
                        line-height: 1.5;
                    }

                    @include respond-to(mobile) {
                        width: 100%;
                        float: none;
                        margin-bottom: 10px;
                    }
                }

                .field-wrapper {
                    float: right;
                    width: 60%;

                    @include respond-to(mobile) {
                        width: 100%;
                        float: none;
                    }
                }

                .form-caption {
                    float: left;
                    margin-left: 40%;
                    font-size: rem(13);

                    @include respond-to(mobile) {
                        float: none;
                        margin-left: 0;
                    }
                }
            }

            label {
                line-height: 34px;
                font-size: rem(15);
            }

            .form-caption {
                font-size: rem(14);
                margin-bottom: 10px;
            }

            .button {
                float:left;
                width: 250px;
                margin: 50px auto 0;
                line-height: 35px;
                padding: 15px 40px;

                @include respond-to(tablet) {
                    margin-right: 0;
                }

                @include respond-to(mobile) {
                    float: none;
                }
            }

            .form-fieldset-up-to-date {
                
                .error-form{
                    text-transform: none;
                    font-size: rem(18);
                }

                .form-row {
                    display: inline-block;
                    margin-right: 10px;

                    @include respond-to(mobile) {
                        display: inline-block;
                        margin-right: 0;
                        width: 24%;
                    }
                }

                > label {
                    padding-left: 0;
                    @include respond-to(mobile) {
                        display: block;
                    }
                }

                .label-inline .field-wrapper {
                    float: none;
                    display: inline-block;
                }
            }
        }
    }

    .order-confirmation-details {
        margin-bottom: 40px;

        .secondary-payment .adresss_firstlastname {
            display: none;
        }

        @include respond-to(mobile) {
            @include flexbox;
            @include flex-vertical;

            .main-payment-productlines {
                @include order(1);
            }

            .order-totals-table-container {
                @include order(2);
            }

            .delivery-methods-mobile {
                @include order(3);
            }

            .payment-type {
                @include order(4);
            }

            .payment-confirm-card {
                @include order(5);
            }
        }

        .item-total {
            .remove-coupon-container {
                display: none;
            }
        }

        .print {
            margin-top: -115px;
            float: right;

            @include respond-to(tablet) {
                display: none;
            }

            @include respond-to(mobile) {
                display: none;
            }

            @include respond-to(print) {
                display: none;
            }

            .no-desktop & {
                display: none;
            }
        }

        .shipping-method-list {
            margin-bottom: 25px;
        }

        .main-payment {
            margin-top: 70px;

            @include respond-to(mobile) {
                margin-top: 50px;
            }
        }

        .billing-sub-title {
            display: none;
        }

        .cc-owner {
            display: none;
        }

        .payment-method {
            display: inline-block;
            font-weight: 500;
        }

        .cc-type {
            display: inline-block;
            text-transform: uppercase;
        }

        .cc-number {
            display: inline-block;
        }
    }

    .order-totals-table-container {
        @extend %clearfix;
        margin: 45px 0 25px;
        width: 68%;

        @include respond-to(mobile) {
            margin: 0 0 25px;
            width: auto;
        }

        @include respond-to(print) {
            margin-top: 0;
            width: auto;
        }
    }

    .order-totals-table {
        float: right;
        min-width: 60%;
        position: relative;
        width: auto;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }

        tr > td:first-child:after {
            @include respond-to(mobile) {
                display: none;
            }
        }
    }

    .delivery-address {

        @include respond-to(mobile) {
            margin-bottom: 10px;

            div {
                display: inline;
            }
        }

        @include respond-to(print) {
            margin-bottom: 10px;

            div {
                display: inline;
            }
        }
    }

    .shipping-method-list {
        .action {
            @include respond-to(print) {
                display: none;
            }
        }
    }

    .payment-type {
        clear: both;
    }

    .payment-confirm-card {
        @extend %clearfix;

        @include respond-to(tablet_plus) {
            margin-bottom: 45px;
            width: 68%;
        }

        @include respond-to(print) {
            margin-bottom: 0;
            width: auto;
        }
    }

    .payment-box {
        float: right;
        min-width: 60%;
        position: relative;
        width: auto;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }
    }

    .shipment-title {
        font-size: rem(18);
        font-weight: 700;
        margin-bottom: 15px;
    }
}

/* Print version */
@include respond-to(print) {
    .pt_order-confirmation {
        .header-banner,
        #navigation,
        #mini-cart,
        .header-wishlist,
        .header-search,
        .header-search-icon,
        .menu-toggle,
        .category-switch,
        .breadcrumb,
        .checkout-breadcrumbs-block,
        .hidden-mobile {
            display: none !important;
        }

        .primary-logo {
            position: static;
            margin: 10px auto 0;
        }

        .top-banner {
            height: auto;
        }
    }
}
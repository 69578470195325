//common elements across checkout pages

// REDESIGNED PAGES MARKUP
#main.registration,
#main.personaldetails,
#main.singleshipping,
#main.multipleaddresses,
#main.multishippment,
#main.clickandcollect {
    max-width: 980px;

    @include respond-to(desktop_plus) {
        padding-bottom: 150px;
    }

    .primary-content {
        padding-left: 20px;
        padding-right: 20px;

        @include respond-to(mobile_tablet) {
            width: 100%;
        }

        @include respond-to(desktop_plus) {
            width: 66.6666666667%;
            width: calc((100%/3)*2);
        }
    }

    .summary {
        padding-left: 20px;

        @include respond-to(mobile_tablet) {
            padding: 0;
            width: 100%;
        }

        @include respond-to(desktop_plus) {
            width: 33.3333333333%;
            width: calc(100%/3);
        }
    }

   .checkout fieldset{
   		display: table-cell;
   	}
}

// REDESIGNED FIELDS MARKUP
.registration .registration-form,
.checkout-shipping,
.checkout-multi-shipping,
.checkout-billing,
.theme-redesign .address {
    .form-row {
        margin-top: 16px;
        margin-bottom: 0;
        margin-left: 0;

        @include respond-to(desktop_plus) {
            width: 39%;
            margin-right: 11%;
            float: left;
        }

        &.row-single-field {
            clear: both;

            @include respond-to(desktop_plus) {
                float: none;
            }
        }

        //Dublin Postal Code field
        &.js-dublincode {
            .form-field-tooltip {
                margin-left: 0;
                color: $co-field-error-color;
                margin-bottom: 15px;
            }
        }

        //State field
        &.js-state,
        &.js-postal {
            input {
                @include placeholder {
                    color: transparent;
                }
            }
        }

        &.label-inline {
            position: relative;
            width: auto;

            .field-wrapper {
                position: static;
                width: auto;
            }
        }
    }

    .field-wrapper {
        width: 100%;
        padding-left: 0;
        margin-bottom: 20px;
    }

    .save-shipping-address {
        float: right;
        margin: 50px 65px 20px 0;

        @include respond-to(mobile_tablet) {
            margin: 50px auto 40px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.theme-redesign {
    &.add-edit-address-dialog .address .save-shipping-address {
        margin: 0 5% 0 0;

        @include respond-to(mobile) {
            margin: 10px 0;
        }
    }

    .address {
        @include respond-to(desktop_plus) {
            padding-left: 5%
        }

        .select-address.address-list {
            @include respond-to(desktop_plus) {
                width: 95%;
            }

            label {
                width: auto;
            }
        }

        .form-row {
            @include respond-to(desktop_plus) {
                width: 45%;
                margin-right: 5%;
            }
        }

        .js-dublincode {
            position: relative;
        }

        .form-row-button {
            @include respond-to(desktop_plus) {
                text-align: left;
                width: 95%;
            }
        }
    }
}

.checkout-title,
.delivery-title {
    margin-bottom: 25px;
    font-size: rem(24);
    text-transform: none;
    text-align: left;
}

.delivery-title {
	margin-bottom: 45px;
}

.over18sproduct-block {
    @extend %clearfix;

    clear: both;
    color: $dim-gray;
    margin: 32px 0;

    .form-row {
        display: inline-block;
        margin: 0;

        &.label-inline {
            @include respond-to(mobile_mid) {
                display: inline-block;
                padding-right: 5px;
                vertical-align: top;
                width: calc(100% - 25px);
            }

            span.error {
                @include respond-to(mobile_mid) {
                    top: 100%;
                }
            }
        }
    }

    &.checked .input-checkbox {
        visibility: hidden;
        opacity: 0;
        height: 0;
        width: 0;
    }

    &.checked .form-row.label-inline .field-wrapper {
        width: 30px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 24px;
        background-image: url(../images/ic_done_green_24px.svg);
    }
}

@font-face {
    font-family: 'co-icomoon';
    src:  url('../fonts/checkout-icons/icomoon.eot?ckj3s1');
    src:  url('../fonts/checkout-icons/icomoon.eot?ckj3s1#iefix') format('embedded-opentype'),
          url('../fonts/checkout-icons/icomoon.ttf?ckj3s1') format('truetype'),
          url('../fonts/checkout-icons/icomoon.woff?ckj3s1') format('woff'),
          url('../fonts/checkout-icons/icomoon.svg?ckj3s1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="co-icon"], [class*=" co-icon"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'co-icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.co-icon-lock:before {
    content: "\e897";
}

.co-icon-info_outline:before {
    content: "\e2a3";
}

.co-icon-gift:before {
    content: "\e7ff";
}

.co-icon-lock-outline:before {
    content: "\e900";
}
.my-account {
    &-title {
        font-size: rem(24);
        font-weight: 500;
        margin: 0 0 20px;
        padding: 0;

        .pt_account & {
            display: none;
        }
    }
}

.pt_account_inner {
    .checkoutlogin-error {
        @include hidden;
    }
}

.login-box {
    h3 {
        margin: 0;
    }

    p {
        margin: 1em 0;
        font-size: rem(12);
    }

    .spacy-copy {
        min-height: 0;
    }

    .error {
        margin-left: 0;
    }

    .my-account-subtitle,
    .order-track-title {
        font-size: rem(18);
        font-weight: 500;
    }

    &-content {
        border-top: 1px solid $black;
        margin-top: 12px;
        padding-top: 10px;

        .error-include {
            .error-form[hidden] {
                display: none;
            }
        }
    }

    &.login-account,
    &.login-order-track,
    &.login-create-account {
        padding-right: 50px;
        margin-bottom: 50px;

        @include respond-to(mobile) {
            padding-right: 0;
        }
    }

    &.login-create-account {
        @include respond-to(mobile) {
            margin-top: 30px;
        }
    }

    .form-row.required {
        label {
            &:after {
                color: $pink;
            }
        }
     }
}

.login-rememberme {
    margin-left: 0;
}

.login-oauth p:first-child {
    font-size: 2em;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
}

#OAuthProvider {
    display: block;
    margin-bottom: 1em;
    width: 80%;
}

.js_email-join-header,
.js_email-join-form {
    @include respond-to(tablet_plus) {
        width: 76%;
        margin: 0 auto 30px auto;
    }
}

.js_email-join-header {
    margin-top: 30px;

    h1 {
        font-size: rem(24);
        padding-bottom: 10px;
        margin-bottom: 24px;
        border-bottom: 1px solid $very-light-gray;
    }
}

.new-password-form,
.reset-password-content {
    margin: 0 auto 50px;
    max-width: 550px;
    padding-top: 50px;

    @include respond-to(mobile) {
        max-width: none;
        padding: 15px 0;
    }
}

.primary-content > #PasswordResetForm {
    max-width: 70%;
    margin: 0 auto 20px;
}

#PasswordResetForm {
    fieldset {
        position: relative;
    }

    button {
        float: right;
        top: 0px;
        left: 63%;
    }

    .dialog-action-buttons {
        border-top: 0;
        padding-top: 0;

        @include respond-to(mobile_min) {
            @include flexbox();
            @include flex-vertical();
        }
    }

    p {
        text-align: center;
    }

    .button {
        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .button-cancel {
        @include respond-to(mobile_min) {
            @include order(2);
        }
    }

    .button-submit {
        @include respond-to(mobile_min) {
            @include order(1);
            margin-bottom: 20px;
        }
    }
}

h1 .account-logout {
    font-size: 0.6em;
    float: none;
}

.dialog-action-buttons {
    border-top: 1px solid $very-light-gray;
    margin: 21px 0 0;
    padding: 25px 0 0;

    .button.space-bottom {

        @include respond-to(mobile_tablet) {
            margin-bottom: 25px;
        }
    }
}


#PasswordResetForm .button {
    @include respond-to(mobile) {
        padding: 14px;
    }
}

/* Password reset page */
.password-success {
    text-align: center;

    .error-page-footer-slot .cat-blocks-carousel {
        background: $white;
    }
}

.reset-password-title {
    display: none;
}

.cta-link {
    color: inherit;
    text-decoration: underline;
}

.newslettersubscribe__response {
    .b-account-customer_info {
        display: none;
    }
}
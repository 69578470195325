/* Static pages general styling */
.page-static {
    #secondary {
        width: 21%;

        ul {
            display: none;
        }
    }

    @include respond-to(mobile) {
        @include flexbox();
        @include flex-vertical();

        #primary {
            @include order(2);
        }

        #secondary {
            @include order(1);
            margin-bottom: 30px;
            width: auto;
        }

        .footer {
            @include order(3);
        }
    }

    .tabs {
        margin-bottom: 25px;
    }

    &.page-fullwidth {
        .primary-content {
            width: 100%;
            float: none;
        }
    }
}

/* Content header */
.content-header {
    background: $ghost;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 30px;
    border-radius: 4px;

    h1 {
        font-size: rem(15);
    }

    h2 {
        font-size: rem(22);
    }
}

/* Sitemap */
.content-sitemap {
    li {
        list-style-type: disc;
        margin-left: 40px;
        padding-left: 10px;
    }

    h2 {
        margin: 30px 0 20px;
    }

    ul {
        margin-bottom: 10px;
    }
}

/* Static page one column */
.page-static-onecolumn {
    .primary-content {
        width: 100%;
        float: none;
    }

    ol {
        margin-left: 0;
        text-align: left;
    }

    .form-row.label-inline {
        margin-left: 27%;

        @include respond-to(mobile) {
            margin-left: 0;
        }
    }
}

/* Static page columns for content */
.columns_2 {
    columns: 2;

    @include respond-to(mobile) {
        columns: auto;
    }
}

/* Careers */
.careers {
    &-header {
        background: url('../images/careers/careers-bg.png') no-repeat center top;
        color: $white;
        min-height: 287px;
        padding-top: 100px;
        text-align: center;

        * {
            color: $white;
        }
    }

    &-director {
        width: 72%;
        margin: 0 auto;
        text-align: center;
        font-size: rem(20);

        @include respond-to(mobile) {
            width: 95%;
        }

        &__avatar {
            background-color: $white;
            border-radius: 50%;
            margin: -35px auto 20px;
            max-width: 200px;
            overflow: hidden;
            padding: 10px;

            img {
                border-radius: 50%;
                max-width: 100%;
                vertical-align: top;
            }
        }

        h3 {
            font-size: rem(26);
        }

        .columns_2 {
            text-align: left;
            margin-top: 20px;
        }

        p {
            margin-top: 0;

            & + p {
                margin-top: 18px;
            }
        }
    }
}

.careers-tabs {
    margin: 35px 0;

    .ui-tabs .ui-tabs-panel {
        @include respond-to(mobile) {
            padding: 20px 0;
        }
    }

    .tabs-mobile {
        display: none;
        position: relative;
        text-transform: uppercase;
        border: 1px solid $lighter-gray;
        line-height: 50px;
        padding: 0 15px;

        @include respond-to(mobile) {
            display: block;
        }

        &:after {
            content: '+';
            position: absolute;
            right: 15px;
            top: 0;
            font-size: rem(32);
            font-weight: 300;
        }

        @include respond-to(mobile) {
            &.expanded-mobile {
                border-bottom: 0;

                &:after {
                    content: '-';
                }

                & + ul {
                    display: block;
                    border: 1px solid $lighter-gray;
                    border-top: 0;
                    height: auto;

                    .ui-tabs-active {
                        display: none;
                    }

                    li {
                        clear: both;
                        padding-left: 20px;
                        font-size: rem(12);
                    }
                }
            }
        }
    }

    ul {
        border-top: 1px solid $lighter-gray;
        border-bottom: 1px solid $lighter-gray;
        text-align: center;
        min-height: 43px;

        &.ui-tabs-nav {
            @include respond-to(mobile) {
                display: none;
            }
        }

        &.ui-tabs-nav li {
            display: inline-block;
            position: relative;
            vertical-align: top;
            float: none;
            text-transform: uppercase;
            padding: 5px 20px 10px;
            font-size: rem(15);
            border: 0;

            &.ui-tabs-active {
                &:before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border: inset 0.6rem;
                    border-color: $white transparent transparent transparent;
                    border-style: solid;
                    position: absolute;
                    bottom: -1.2rem;
                    left: 0;
                    right: 0;
                    margin: auto;
                    z-index: 2;
                }

                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border: inset 0.7rem;
                    border-color:  $lighter-gray transparent transparent transparent;
                    border-style: solid;
                    position: absolute;
                    bottom: -1.4rem;
                    left: 0;
                    right: 0;
                    margin: auto;
                    z-index: 1;
                }
            }

            @include respond-to(mobile) {
                display: block;
                text-align: left;
                padding-left: 50px;
            }
        }
    }
}

.careers-stores {
    &.ui-tabs {
        @include respond-to(tablet_plus) {
            .ui-tabs-nav {
                display: none;
            }

            .rows {
                display: block !important;
                margin: 0 auto;
            }
        }
    }
}

.careers-header {

    .main-title {
        font-family: $font-second;
        font-size: rem(21);
        line-height: 1.9;
        margin-bottom: 10px;
        text-transform: none;
    }

    h2 {
        font-size: rem(37);
    }
}

.careers-links {
    margin-top: 45px;
    text-align: center;

    @include respond-to(mobile) {
        display: none;
    }

    a {
        padding: 0 10px;
        font-size: rem(17);

        &:hover {
            text-decoration: underline;
        }
    }
}

.careers-subtitle {
    position: relative;
    text-align: center;
    margin: 55px 0 40px;
    font-size: rem(22);

    @include respond-to(mobile) {
        &:after {
            content: '';
            position: absolute;
            bottom: -10px;
            width: 80px;
            border-bottom: 1px solid;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.careers-content {
    .rows {
        @extend %clearfix;
        width: 85%;
        margin: 0 auto;

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    p {
        font-size: rem(15);
    }
    
    .team-grid li {
        margin-bottom: 1%;
        margin-top: 1%;
        
        @include respond-to(mobile) {
            margin-top: 3%;
            margin-bottom: 3%;
        }
    }

    .cols,
    .columns-1-3,
    .columns-60,
    .columns-40,
    .columns-1-2 {
        float: left;
        padding-left: .9375rem;
        padding-right: .9375rem;

        @include respond-to(mobile) {
            padding: 0;
            float: none;
        }

        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }

        p {
            margin-top: 0;
            font-size: rem(15);
        }
    }

    .column-bordered {
        border: 1px solid $lightest-gray;
    }

    .columns-1-3 {
        width: 33%;
    }

    .columns-1-2 {
        width: 50%;

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .columns-60 {
        width: 60%;
    }

    .columns-40 {
        width: 40%;
    }

    .columns-20 {
        display: inline-block;
        vertical-align: top;
        width: 17%;
        margin: 0 1%;
    }

    .columns-25 {
        display: inline-block;
        vertical-align: top;
        width: 23%;
        margin: 0 1%;
    }

    .career-opportunuties-grid {
        @include flexbox();
        @include flex-wrap();

        h2 {
            position: relative;
            font-size: rem(14);
            text-align: center;
            margin: 10px 0 25px;

            &:after {
                content: '';
                width: 50px;
                border-bottom: 1px solid;
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        img {
            float: left;
            margin-right: 20px;

            @include respond-to(mobile) {
                display: block;
                float: none;
                margin: 0 auto 20px;
            }
        }

        .columns-1-3 {
            width: 31%;
            margin: 0 1% 20px;

            @include respond-to(mobile) {
                width: 100%;
                margin: 0 0 40px 0;
            }
        }

        p {
            @include respond-to(mobile) {
                margin: 10px;
            }
        }
    }

    .buttons-action {
        margin-top: 30px;
        text-align: center;

        .button {
            margin-bottom: 40px;
        }
    }
}

.careers-brands {
    @include out-of-wrapper();
    text-align: center;
    background: $second-lightest-gray;
    margin-top: -100px;
    padding-top: 100px;
    padding-bottom: 60px;

    &-list img {
        @include respond-to(mobile) {
            display: block;
            margin: 10px auto;
        }
    }

    .button {
        margin-top: 30px;

        @include respond-to(mobile) {
            padding: 15px 5px;
        }
    }

    img {
        display: block;
        margin: 0 auto;

        @include respond-to(mobile) {
            display: none;
        }
    }
}

.careers-stores {
    .rows {
        padding-bottom: 60px;
        margin-bottom: 50px;
        border-bottom: 1px solid $gainsboro;

        @include respond-to(mobile) {
            @include flexbox();
            @include flex-wrap();
            padding-bottom: 30px;
        }
    }

    .careers-store-img {
        @include respond-to(mobile) {
            @include order(1);
            margin-bottom: 20px;
        }
    }

    .careers-store-content {
        @include respond-to(mobile) {
            @include order(2);
        }
    }

    .columns-60,
    .columns-40 {
        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .column-right-border {
        border-right: 1px solid $gainsboro;

        @include respond-to(mobile) {
            border-right: 0;
        }
    }

    .row-flex {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
    }

    .rows.row-outline {
        @include out-of-wrapper();
        width: 100%;
        background: $second-lightest-gray;
        text-align: center;
    }

    .row-inner {
        width: 70%;
        margin: 0 auto;
        padding-top: 55px;

        @include respond-to(mobile) {
            width: 100%;
        }
    }
}

.careers-history {
    @include respond-to(mobile) {
        @include flexbox();
        @include flex-vertical();

        .column-left {
            @include order(2);
        }

        .column-right {
            @include order(1);
            margin-bottom: 30px;
        }
    }
}

.careers-team {
    margin-top: -130px;
    padding: 100px 0;
    text-align: center;
    background: $lighter-gray;

    @include respond-to(mobile) {
        @include out-of-wrapper();
    }

    .rows {
        width: 100%;
    }

    h4 {
        font-size: rem(15);
        margin-top: 10px;
        font-weight: 500;
    }

    a {
        @include respond-to(mobile) {
            font-size: rem(14);
        }
    }

    p {
        font-size: rem(14);
        padding: 0 30px;
        min-height: 4em;

        @include respond-to(mobile) {
            padding: 0 10px;
        }
    }

    img {
        max-width: 100%;
    }

    .team-grid .columns-20 {
        @include respond-to(mobile) {
            width: 45%;
            vertical-align: top;
        }
    }

    .columns-25 {
        @include respond-to(mobile) {
            display: inline-block;
            width: 45%;
            margin-bottom: 50px;
            vertical-align: top;

            h4 {
                font-size: rem(13)
            }

            p {
                padding: 0;
                font-size: rem(12);
                min-height: 4em;
            }
        }
    }
}

#careers-tab-our-team {
    .careers-subtitle {
        @include respond-to(mobile) {
            font-size: rem(17);

            &:after {
                display: none;
            }
        }
    }
}

.careers-team-popup {
    .careers-team-left {
        width: 25%;
        display: inline-block;
        vertical-align: top;

        @include respond-to(mobile) {
            width: 100%;
            text-align: center;
        }

        img {
            max-width: 100%;
        }
    }

    .careers-team-right {
        width: 72%;
        margin-left: 2%;
        display: inline-block;
        vertical-align: top;

        @include respond-to(mobile) {
            width: 100%;
            margin-left: 0;
        }
    }
}

/* Stores page */
.store-header,
.store-bt2-header {
    background: $gainsboro;
    text-align: center;
    padding: 20px 0 25px;
    @include out-of-wrapper();

    h2 {
        color: $black;
        display: inline-block;
        font-family: $font-second;
        font-size: rem(28);
        line-height: 1.9;
        position: relative;
        text-transform: none;

        &:after {
            content: "";
            border-bottom: 1px solid;
            width: 50%;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: absolute;
            top: 100%;
        }
    }

    h3 {
        color: $black;
        font-size: rem(35);
        letter-spacing: 1px;
        margin-top: 10px;
    }
}

.stores-tabs {
    margin: 35px 0;
    border-bottom: 1px solid $very-light-gray;
    text-align: center;

    @include respond-to(mobile) {
        border: 1px solid $lighter-gray;
    }

    .store-current {
        display: none;
        text-transform: uppercase;
        padding: 15px 20px;
        text-align: left;
        cursor: pointer;
        position: relative;

        @include respond-to(mobile) {
            display: block;

            &:after {
                @include font-icomoon();
                border-bottom: 0;
                content: "\e912";
                float: right;
                font-size: rem(13);
                position: relative;
                line-height: 23px;
            }
        }

        &.expanded {
            &:after {
                content: "\e90f";
            }

            & + .store-tabs-content {
                @include respond-to(mobile) {
                    display: block;
                }
            }
        }
    }

    .store-tabs-content {
        font-size: 0;
        
        @include respond-to(mobile) {
            display: none;
        }
    }

    ul.store-tabs-content {
        margin: 0;
    }

    .store-tab {
        display: inline-block;
        text-transform: uppercase;
        padding: 15px 8px;
        font-size: rem(14);

        @include respond-to(mobile) {
            display: block;
            text-align: left;
            padding-left: 50px;
        }

        &.is-selected {
            position: relative;
            border: 1px solid $very-light-gray;
            border-bottom: none;
            color: $black;
            font-weight: 500;

            @include respond-to(mobile) {
                display: none;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                height: 0;
                border-bottom: 1px solid $white;
                bottom: -1px;
                left: 0;
                right: 0;
                margin: 0;
                z-index: 2;
            }
            &:after {
                content: "";
                display: none;
                width: 0;
                height: 0;
                border: inset 0.7rem;
                border-color:  $lighter-gray transparent transparent transparent;
                border-style: solid;
                position: absolute;
                bottom: -1.4rem;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
            }
        }

        a {
            color: $dark-gray;

            &:hover {
                color: $nobel;
            }

            @include respond-to(mobile) {
                color: $nobel;
                font-size: rem(14);
            }
        }

        .store-tab-chinese-img {
            vertical-align: bottom;
        }
    }
}

.store-details-anchor-links {
    margin-bottom: 45px;
    text-align: center;
    display: none;

    a {
        position: relative;
	    margin: 0 15px;
        font-size: rem(14);
        font-weight: 400;
        color: $black;

        @include underlined_link;

        &:hover {
            color: $dark-gray;
        }
    }
    
    &-mobile { // if the block not be used in the future - remove it
        display: none;

        a {
            display: block;
        }
        
        .store-details-links {
            display: none;
            padding: 0 20px;
            border: 1px solid $lighter-gray;
            border-top: 0;
            
            
            a {
                padding: 15px 0;
                text-transform: uppercase;
                font-size: rem(15);
            }
        }
        
        .store-link-current {
            position: relative;
            display: block;
            padding: 15px 20px;
            border: 1px solid $lighter-gray;
            text-transform: uppercase;
            text-align: left;
            cursor: pointer;
            
            &:after {
                @include font-icomoon();
                border-bottom: 0;
                content: "\e912";
                float: right;
                font-size: rem(13);
                position: relative;
                line-height: 23px;
            }
            
            &.expanded {
                border-bottom: 0;
                
                &:after {
                    content: "\e90f";
                }
                
                & + .store-details-links {
                    display: block;
                }
            }
        }
    }
}

.store-services,
.store-details-general {
    .main-title {
        font-size: rem(20);
        font-weight: 500;
        margin-bottom: 50px;

        span:after {
            top: auto;
            bottom: -10px;
            width: 30%;
        }
    }
}

.store-details-general {
    .carousel {
        margin-bottom: 40px;

        .jcarousel-prev {
            left: 10px;
        }

        .jcarousel-next {
            right: 10px;
        }

        .jcarousel-nav {
            background: rgba($black, .6);
            color: $white;
        }
    }
}

.store-description {
    text-align: center;
}

.store-description-title {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-size: rem(31);
    margin-bottom: 20px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto 10px;
        width: 35%;
        bottom: -20px;
        border-bottom: 1px solid;
    }
}

.store-description-text p {
    font-size: rem(14);
    margin: 10px 0;
}

.store-details-info {
    margin: 80px 0;
    
    @include respond-to(mobile) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .col-1-3 {
        border: 2px solid $second-light-gray;
        display: inline-block;
        width: 31%;
        margin: 0 1%;
        font-size: rem(12);
        word-wrap: break-word;

        @include respond-to(mobile) {
            display: block;
            width: 100%;
            margin: 0
        }
    
        &.store-payment-types,
        &.store-hours {
            @include respond-to(mobile) {
                display: none;
            }
        }
    }
}

.store-details-inner {
    @include flexbox();

    @include respond-to(mobile) {
        display: block;
    }
}

.store-hours {
    padding: 15px 30px;
    background: $white;

    h5 {
        font-size: rem(14);
        font-weight: 500;
        text-transform: none;
        margin: 10px 0 5px;
    }

    h6 {
        font-size: rem(14);
        margin: 15px 0 10px;
    }
}

.store-column-right {
    padding: 30px;

    p {
        margin: 10px 0;
        font-size: rem(15);
        line-height: 23px;
    }

    h6 {
        font-size: rem(12);
        font-weight: 500;
    }
}

.store-payments p {
    margin: 0;
}

.store-services {
    margin-bottom: 150px;

    @include respond-to(mobile) {
        margin-bottom: 40px;
    }

    .tabs-mobile {
        display: none;
        position: relative;
        text-transform: uppercase;
        padding: 10px;
        border: 1px solid $lightest-gray;

        &:after {
            content: '+';
            position: absolute;
            top: 7px;
            right: 15px;
            font-size: rem(21);
        }

        @include respond-to(mobile) {
            display: block;
        }

        &.expanded-mobile {
            @include respond-to(mobile) {
                border-bottom: 0;

                &:after {
                    content: '-';
                }

                & + .toggle-content {
                    display: block;
                    border: 1px solid $lightest-gray;
                    border-top: 0;
                }
            }
        }
    }

    .toggle-content {
        display: block;

        @include respond-to(mobile) {
            display: none;
        }

        &.expanded-mobile {
            @include respond-to(mobile) {
                border: 1px solid $lightest-gray;
                border-top: 0;
            }
        }
    }

    .row {
        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .columns {
        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }
    }

    .ui-tabs .ui-tabs-nav {
        height: auto;
        border-top: 1px solid $lighter-gray;
        border-bottom: 1px solid $lighter-gray;
        text-align: center;

        li {
            float: none;
            display: inline-block;
            vertical-align: top;
            padding: 5px 5px 5px;
            font-size: rem(12);
            border-bottom: 0;

            @include respond-to(mobile) {
                display: block;
                padding: 8px 5px;
                float: none;
                width: 100%;
                text-align: left;
            }

            &.ui-tabs-active {
                @include respond-to(mobile) {
                    display: none;
                }
            }

            a {
                @include respond-to(mobile) {
                    float: none;
                    transition: none;
                    -webkit-transition: none;
                }
            }
        }

        .ui-state-active {
            border-bottom: 0;
        }

        .ui-tabs-active {
            position: relative;
            color: $black;
            font-weight: 500;

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 0.6rem;
                border-color: $white transparent transparent transparent;
                border-style: solid;
                position: absolute;
                bottom: -16px;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 2;
            }
            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 0.7rem;
                border-color:  $lighter-gray transparent transparent transparent;
                border-style: solid;
                position: absolute;
                bottom: -19px;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
            }
        }
    }
}

.store-opening-hours {
    font-size: rem(14);
    margin-bottom: 30px;

    div {
        min-height: 19px;
    }

    .left,
    .right {
        padding:1px 0.5em 1px 0;
        float: right;
        background: $white;
    }

    .dotted{
        border-bottom: 1px dotted $dim-gray;
        margin-bottom: 3px;
    }

    .left{
        float:left;
        clear:both;
    }
}

.storepage-brands {
    padding: 40px 0 70px;
    background: $second-lightest-gray;
    text-align: center;
    @include out-of-wrapper();

    .button {
        margin-top: 30px;
    }
    
    .departments-list {
        @include respond-to(mobile) {
            display: none;
        }
    }
    
    .collapse-to-dropdown.expanded .departments-list {
        @include respond-to(mobile) {
            display: block;
            margin-top: -1px;
            border: 1px solid $lightest-gray;
            border-top: 0;
        }
    }
    
    .selected-value {
        display: none;
        
        @include respond-to(mobile) {
            display: inline-block;
            position: relative;
            border: 1px solid $lightest-gray;
            border-bottom: 0;
            background: $white;
            padding: 0 5px;
            color: $nobel;
            font-weight: 400;
            text-transform: none;
            cursor: pointer;
            font-size: rem(16);
            width: 100%;
            line-height: 35px;
            
            &:after {
                font-family: 'icomoon';
                content: "\e902";
                position: absolute;
                right: 0px;
                top: 5%;
                width: 30px;
                text-align: left;
                font-size: rem(14);
                font-weight: 700;
                padding: 5px;
                height: 70%;
                line-height: 24px;
                color: $tutu;
            }
        }
    }

    .ui-tabs {
        @include respond-to(mobile) {
            padding: 0;
        }
        
        .ui-tabs-nav {
            text-align: center;
            height: 37px;

            @include respond-to(mobile) {
                display: none;
                height: auto;
            }

            li {
                float: none;
                display: inline-block;
                border-bottom: 0;
                font-weight: 400;

                @include respond-to(mobile) {
                    display: block;
                    text-align: center;
                    margin: 0;
                }
                
                &.ui-tabs-active a {
                    border: 1px solid $lightest-gray;
                    border-bottom: 0;
                    background: $second-lightest-gray;
                    z-index: 1;
                    font-weight: 500;
                    
                    @include respond-to(mobile) {
                        border: 0;
                        background: $white;
                    }
                }
                
                a {
                    @include respond-to(mobile) {
                        width: 100%;
                        background: $white;
                        font-weight: 400;
                    }
                }
            }

            .ui-tabs-anchor {
                transition: none;
                -webkit-transition: none;
                padding: 8px 10px;
            }
        }

        .ui-tabs-panel {
            background: $second-lightest-gray;
            border: 0;
            border-top: 1px solid $lightest-gray;

            .brand {
                display: inline-block;
                width: 33%;
                padding: 3px 0;
                vertical-align: top;
                text-align: center;
                text-transform: uppercase;
                font-size: rem(17);

                @include respond-to(mobile) {
                    width: 100%;
                }

                span {
                    display: block;
                    font-size: rem(14);
                }
            }
        }
    }
}


/* Personal shopping */

.store-personal-shopping {
    padding-top: 55px;
    text-align: center;
    background: $second-lightest-gray;
    @include out-of-wrapper();

    .shopping-grey  {
        background: $second-lightest-gray;
        padding: 55px 0;
        @include out-of-wrapper();
    }

    .shopping-white {
        padding: 55px 0;
        background: $white;
        @include out-of-wrapper();
    }

    h2 {
        display: inline-block;
        position: relative;
        font-family: $font-second;
        font-size: rem(20);
        text-transform: none;
        margin-bottom: 15px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 65%;
            bottom: -10px;
            border-bottom: 1px solid;
        }
    }

    h3 {
        font-size: rem(30);
        margin-top: 10px;
    }

    .layout-grid {
        margin-top: 30px;
    }

    .columns {
        @include respond-to(mobile) {
            width: 100%;
            float: none;
            margin-bottom: 50px;
        }
    }

    .personal-shopping-img img {
        border-radius: 50%;
    }

    .personal-shopping-name {
        font-family: $font-second;
        font-size: rem(21);
        font-weight: 700;
        margin-top: 10px;
        text-transform: none;
    }

    .personal-shopping-title {
        margin-top: 5px;
        text-transform: none;
    }

    .store-personal-shopping-more-info {
        font-size: rem(14);
        text-decoration: underline;
        text-transform: uppercase;
    }

    .personal-booking-info {
        margin-top: 50px;

        h5 {
            font-weight: 500;
        }

        p {
            margin: 0 auto;
            font-size: rem(14);
            max-width: 65%;
        }

        .note {
            margin-top: 0;
            color: $mid-gray;
        }
    }
}

/* Be inspired page */
.is-folder-page-beinspired {
    .breadcrumb,
    .searchresults {
        display: none;
    }
}

.isotope-gutter-sizer {
    width: 1%;

    @include respond-to(mobile) {
        width: 4%;
    }
}

.page-inspired {
    margin: 40px 0 80px;

    @include respond-to(mobile) {
        margin-top: 20px;
        margin-bottom: 175px;
    }

    .content-asset img {
        @include respond-to(mobile) {
            margin-bottom: 20px;
        }
    }

    .be-inspired_header {
        margin-bottom: 35px;
    }

    .be-inspired_header_title {
        font-family: $font-third;
        font-size: rem(45);
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;

        span {
            font-family: $font-second;
            font-size: rem(30);
            letter-spacing: 0;
            text-transform: lowercase;
            line-height: 1;
            position: relative;
            z-index: 1;
            top: -5px;
            display: inline-block;

            &:after {
                content: "";
                border-bottom: 1px solid;
                width: 100%;
                margin: 0 auto;
                left: 0;
                right: 0;
                position: absolute;
                bottom: 1px;
            }
        }
    }

    .isotope-container {
        position: relative;
    }

    .category-links {
        margin: 5px auto 25px;
        text-align: center;

        @include respond-to(mobile) {
            display: none;
        }

        li {
            display: inline;
            margin: 0 15px;
            cursor: pointer;
            list-style: none;

            @include respond-to(mobile) {
                display: block;
                text-align: left;
            }
        }
    }

    .beinspired-social-icons {
        position: absolute;
        right: 0;
        top: 0;

        @include respond-to(mobile) {
            margin: 15px auto;
            position: static;
            text-align: center;
        }

        dl {
            @extend %clearfix;
            margin: 0;
        }

        dt {
            clear: none;
            display: inline-block;
            float: none;
            font-size: rem(15);
            font-weight: 500;
            letter-spacing: 1px;
            margin: 0 10px 0 0;
            min-width: 0;
            text-transform: uppercase;
        }

        dd {
            display: inline-block;
            float: none;
            margin: 0;
            padding: 0;
        }

        a {
            margin-right: 5px;
        }
    }

    .js-isotope-filter-wrapper {
        width: 100%;
        position: relative !important;
    }

    .beinspired-grid {
        font-size: 0;
        width: 100%;
        margin-bottom: 50px;
        position: relative !important;
        top: 0 !important;

        .asset {
            float: left;
            width: 24%;

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        .is-featured,
        .col-lg-6 {
            float: left;
            width: 49%;

            @include respond-to(mobile) {
                display: inline-block;
                width: 99%;
            }

            .element-header {
                &-inner {
                    background: $white;
                    width: 70%;
                    margin: 0 auto;
                    padding: 20px;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);

                    .element-header-underline,
                    h3 {
                        color: $black;
                    }

                    p {
                        color: $zorro;
                        line-height: 1.3;
                    }
                }
            }

            .element-header-content {
                h3 {
                    color: $white;
                    font-size: rem(76);

                    @include respond-to(mobile) {
                        font-size: rem(54);
                    }
                }

                .element-header-underline {
                    font-size: rem(28);
                    margin-top: 9%;
                }
            }
        }

        .col-lg-3 {
            float: left;
            width: 24%;

            @include respond-to(mobile) {
                width: 48%;
            }

            .element-header-content {
                h3 {
                    color: $white;
                    font-size: rem(38);

                    @include respond-to(mobile) {
                        font-size: rem(27);
                    }
                }

                .element-header-underline {
                    font-size: rem(14);
                    margin-top: 9%;
                }
            }
        }

        .element-header {
            position: relative;
            text-align: center;
            color: $white;

            &-content {
                position: absolute;
                top: 15%;
                left: 0;
                right: 0;
                padding: 0 4%;

                @include respond-to(mobile) {
                    top: 12%;
                }
            }

            h3 {
                color: $white;
                font-size: rem(38);

                @include respond-to(mobile) {
                    font-size: rem(27);
                }
            }

            &-inner {
                background: $white;
                width: 90%;
                margin: 0 auto;
                padding: 15px 10px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

                .element-header-underline,
                h3 {
                    color: $black;
                }

                p {
                    color: $zorro;
                    line-height: 1.3;
                }
            }

            &-underline {
                display: block;
                margin-top: 25px;
                font-size: rem(14);
                letter-spacing: 1px;
                text-transform: uppercase;
                text-decoration: underline;
            }
        }

        .element-item {
            font-size: rem(14);
        }

        .element-header img {
            width: 100%;
            vertical-align: top;
        }

        .element-content {
            padding: 25px 20px 35px;
            background: $second-lightest-gray;
            text-align: center;

            p {
                margin-top: 10px;
                color: $zorro;
                line-height: 1.3;
            }
        }
    }

    .dropdown-mobile {
        display: none;
        width: 100%;
        position: relative;
        text-transform: uppercase;
        border: 1px solid $lighter-gray;
        line-height: 50px;
        padding: 0 15px;

        @include respond-to(mobile) {
            display: block;
            right: 0;
            width: 100%;
        }

        &:after {
            content: '+';
            position: absolute;
            right: 15px;
            top: 0;
            font-size: rem(32);
            font-weight: 300;
        }

        @include respond-to(mobile) {
            margin-top: 0;

            &.expanded-mobile {
                border-bottom: 0;

                &:after {
                    content: '-';
                }

                & + ul {
                    display: block;
                    border: 1px solid $lighter-gray;
                    border-top: 0;
                    padding: 0 0 20px;
                    height: auto;
                    position: relative !important;
                    left: 0 !important;
                    top: 0 !important;
                    margin-top: 0;

                    .is-checked {
                        display: none;
                    }

                    li {
                        display: block;
                        clear: both;
                        padding-left: 20px;
                        font-size: rem(16);
                        text-align: left;
                    }
                }
            }
        }
    }
}

.ie .page-inspired {
    margin-bottom: 40px;

    .beinspired-grid {
        top: 0 !important;
        margin-top: 120px;
    }

    .category-links {
        margin-top: -100px;
    }
}

/* What's on page */

.page-whatson {
    margin-bottom: 120px;

    @include respond-to(mobile) {
        margin-bottom: 225px;
    }

    .content-center {
        @include out-of-wrapper;

        padding-top: 20px;
        background: $second-lightest-gray;

        p {
            max-width: 65%;
            margin: 25px auto 0;
        }
    }

    .isotope-container {
        margin-bottom: 40px;
    }

    .js-isotope-filter-area {
        @extend %clearfix;
        @include out-of-wrapper;

        position: relative !important;
        padding-top: 20px;
        margin-bottom: 20px;
        background: $second-lightest-gray;
    }

    .js-isotope-filter-wrapper {
        position: relative;
        margin: 0 0 20px;

        @include respond-to(tablet_plus) {
            width: 32%;
            float: left;
            margin: 0 2% 20px 0;
        }
    }

    .js-isotope-filters-clear-all {
        display: block;
        margin: 0 0 20px;
        font-size: rem(14);

        @include respond-to(tablet_plus) {
            width: 32%;
            float: right;
            line-height: 20px;
        }
    }

    .whatson-grid {
        position: relative !important;
        top: 0 !important;

        .asset {
            width: 24%;
            margin: 0.5%;

            @include respond-to(mobile) {
                display: inline-block;
                width: 100%;
            }

            img {
                width: 100%;
            }

            &.half-wide,
            &.is-featured {
                width: 49%;

                @include respond-to(mobile) {
                    display: inline-block;
                    width: 100%;
                }

                .element-header {
                    @include respond-to(tablet_plus) {
                        height: 335px;
                        overflow: hidden;
                    }
                }
            }
        }

        .element-header {
            position: relative;
            text-align: center;
            color: $white;

            &-content {
                position: absolute;
                top: 60px;
                padding: 0 10px;

                @include respond-to(mobile) {
                    top: 30px;
                }
            }

            img {
                max-width: 100%;
                vertical-align: bottom;
            }

            h3 {
                color: $white;
                font-size: rem(38);

                @include respond-to(mobile) {
                    font-size: rem(27);
                }
            }

            &-inner {
                background: $white;
                width: 70%;
                margin: 0 auto;
                padding: 20px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

                .element-header-underline,
                h3 {
                    color: $black;
                }

                p {
                    color: $zorro;
                    line-height: 1.3;
                }
            }

            &-underline {
                display: block;
                margin-top: 25px;
                font-size: rem(14);
                letter-spacing: 1px;
                text-transform: uppercase;
                text-decoration: underline;
            }
        }

        .element-item {
            font-size: rem(14);
        }

        .element-content {
            padding: 25px 20px 35px;
            background: $second-lightest-gray;
            text-align: center;

            h3 {
                margin-bottom: 20px;
            }

            p {
                margin-top: 10px;
                color: $zorro;
                line-height: 1.3;
            }
        }

        .whats-on-cta {
            display: block;
            margin-bottom: 20px;
            background-color: $white;
            border-color: $nobel;
            color: $nobel;
            font-size: rem(14);

            &:hover {
                color: $hel-gray;
            }
        }

        .element-link {
            display: block;
            font-size: rem(12);
        }
    }

    .dropdown {
        display: block;
        width: 100%;
        position: relative;
        text-transform: uppercase;
        background-color: $white;
        border: 1px solid $lighter-gray;
        line-height: 50px;
        padding: 0 15px;
        cursor: pointer;
        font-size: rem(14);

        &:after {
            content: '+';
            position: absolute;
            right: 15px;
            top: 0;
            font-size: rem(32);
            font-weight: 300;
        }

        & + ul {
            display: none;
            border: 1px solid $lighter-gray;
            border-top: 0;
            padding: 0 0 10px;
            margin: 0;
            height: auto;

            @include respond-to(tablet_plus) {
                position: absolute;
                left: 0;
                right: 0;
                z-index: 1;
                background-color: $white;
            }

            li {
                display: block;
                clear: both;
                padding-left: 20px;
                padding-bottom: 5px;
                font-size: rem(14);
                text-align: left;
                text-transform: capitalize;
                cursor: pointer;
            }
        }

        &.expanded {
            border-bottom: 0;

            &:after {
                content: '-';
            }

            & + ul {
                display: block;

                .is-checked {
                    display: none;
                }
            }
        }
    }
}

.whatson-page-wrapper {
    h1 {
        @include out-of-wrapper;
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        text-align: center;
        background: $second-lightest-gray;

        .whatson-page-heading {
            display: block;
            font-size: rem(30);
            font-weight: 500;
            margin-top: 10px;
            text-transform: capitalize;
        }

        .whatson-page-heading-small {
            display: inline-block;
            position: relative;
            font-family: $font-second;
            font-size: rem(20);
            line-height: 1.9;
            text-transform: none;

            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                width: 50%;
                margin: 0 auto 15px;
                border-bottom: 1px solid;
            }
        }
    }

    > .button {
        margin-bottom: 20px;
        letter-spacing: 0;
        font-size: rem(12);
        font-weight: 500;
    }

    .button-back i {
        font-size: rem(10);
        margin-right: 6px;
        color: $hel-gray;
        opacity: 1;
        font-weight: 700;
    }

    .whatson-page-content {
        @extend %clearfix;
        margin-bottom: 40px;
    }

    .whatson-page-image {
        float: left;
        width: 36%;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    .whatson-page-info {
        float: right;
        width: 56%;
        text-align: center;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }

        h2 {
            font-size: rem(24);
            font-weight: 500;
            padding-bottom: 30px;
            margin-bottom: 30px;
            text-transform: capitalize;
            border-bottom: 1px solid $very-light-gray;

            @include respond-to(mobile) {
                font-size: rem(36);
                padding-top: 20px;
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .whatson-page-text {
        margin-bottom: 40px;
    }

    .whatson-page-cta {
        margin-bottom: 20px;

        .button {
            width: 80%;

            @include respond-to(mobile) {
                width: 100%;
            }

            span {
                display: block;
                margin-top: 5px;
            }
        }

        div.button {
            cursor: default;
        }
    }
}

.is-folder-page-whatson {
    .breadcrumb,
    .searchresults {
        display: none;
    }
}

.whatson-featured {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    background: rgba($black, 0.7);
    padding: 10px;
    text-transform: uppercase;
    font-size: rem(14);
    font-weight: 600;
}

.page-whatson .whatson-grid .is-featured {
    .element-item {
        position: relative;
    }

    .element-header {
        position: static;
    }

    .whatson-featured {
        display: block;
    }
}

/* Brand index page */

.top-brand-index {
    padding: 30px;
    background: $sections-body-border;
    text-align: center;
    @include out-of-wrapper();

    .content-asset > div {
        @include respond-to(mobile) {
            display: none;
        }
    }
}

.brand-index-info {
    margin-top: 40px;

    @include respond-to(mobile) {
        margin-top: 0;
        padding-bottom: 60px;

        @include flexbox;
        @include flex-vertical;
    }

    &.submitted-mobile {
        .brands-etc,
        .alphabet,
        .brands-alphabet {
            @include respond-to(mobile) {
                display: block;
            }
        }
    }

    .brands-refine-by {
        float: left;
        padding-top: 25px;
        margin-left: 8%;

        @include respond-to(mobile) {
            display: none;
        }

        &.mobile {
            display: none;

            @include respond-to(mobile) {
                display: block;
                margin-left: 0;
                @include order(2);
            }
        }
    }

    .brands-refinements {
        float: left;
        width: 80%;

        @include respond-to(mobile) {
            float: none;
            display: block;
            width: 100%;
            padding-top: 0;
            @include order(3);
        }

        .brand-form-col {
            width: 44%;
            margin: 0 2%;
            display: inline-block;

            @include respond-to(mobile) {
                display: block;
                margin: 0;
                width: 100%;
            }

            label {
                padding-bottom: 5px;

                @include respond-to(mobile) {
                    display: none;
                }
            }

            select {
                @include respond-to(mobile) {
                    margin: 10px 0;
                }
            }
        }
    }

    .alphabet {
        clear: both;
        text-align: center;
        margin: 30px 0;
        padding-top: 50px;
        font-weight: 800;

        @include respond-to(mobile) {
            display: none;
            padding-top: 10px;
        }

        span {
            cursor: pointer;
            padding: 0 10px;

            @include respond-to(mobile) {
                line-height: 30px;
            }

            &:hover {
                background: $lighter-gray;
            }
        }

        .letter-disabled {
            cursor: default;
            color: $lightest-gray;

            &:hover {
                background: none;
            }
        }
    }

    .find-brands-button {
        display: none;

        @include respond-to(mobile) {
            display: block;
            margin-bottom: 20px;
        }
    }

    .separate-line {
        display: block;
        text-align: center;

        span {
            position: relative;
            font-size: rem(30);
            font-weight: 800;

            &:after,
            &:before {
                content: '';
                position: absolute;
                border-bottom: 1px solid $lightest-gray;
                top: 18px;
                width: 600px;
            }

            &:before {
                right: 100%;
                margin-right: 15px;
            }

            &:after {
                left: 100%;
                margin-left: 15px;
            }
        }
    }

    .brands-etc {
        text-align: center;
        cursor: pointer;
        margin: 20px 0 50px;

        @include respond-to(mobile) {
            display: none;
        }
    }

    .brands-alphabet {
        margin-bottom: 65px;
        text-align: center;

        @include respond-to(mobile) {
            display: none;
        }

        .brands-by-alphabet {
            clear: both;
        }

        /* margin collapse reset */
        .brands-by-alphabet:before,
        .brands-by-alphabet:after {
            content: ' ';
            display: table;
        }

        .brand {
            display: block;
            width: 33.33%;
            float: left;
            margin: 2px 0;
            font-size: rem(14);
            text-transform: uppercase;
            vertical-align: top;
            text-align: center;

            @include respond-to(mobile) {
                display: block;
                width: 100%;
            }

            a,
            span {
                display: block;
            }
        }
    }

    .featured-brands-title {
        display: block;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid $lightest-gray;
        text-align: center;
        text-transform: uppercase;
    }

    .featured-brand {
        margin: 2px 0;
    }

    .featured-brands-inner {
        padding: 5px 7px;
        background: $sections-body-border;
        @include out-of-wrapper();
    }
}

.be-inspired-story-archive-search {
    @extend %clearfix;
    position: absolute;
    left: 0;
    width: 25%;

    @include respond-to(mobile) {
        position: static;
        margin-bottom: 15px;
        width: auto;
    }

    &__form {
        position: relative;
    }

    &__field[type="text"] {
        border-left: none;
        border-right: none;
        border-top: none;
        padding-right: 48px;

        &:focus {
            background-color: $white;
        }
    }

    &__button {
        background-color: transparent;
        color: $black;
        padding: 9px;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: auto;
    }

    &.expanded {
        .be-inspired-story-archive-search__form {
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            top: 20px;
        }
    }

    .clearable.x + .be-inspired-story-archive-search__button {
        display: none;
    }
}

.be-inspired-search-suggestions {
    background-color: $white;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    padding-bottom: 15px;

    &__title {
        font-size: rem(12);
        font-weight: 700;
        margin-bottom: 55px;
        text-align: center;
        text-transform: uppercase;
    }

    &__value {
        display: none;
    }

    &__container {
        @include flexbox();
    }

    &__column {
        @include flex(1);
        border-left: 1px solid $dim-gray;
        padding-left: 20px;

        &:first-child {
            border-left: none;
            padding-left: 8px;
        }
    }

    &__label {
        font-weight: 500;
    }

    &__list-by-month {
        margin-bottom: 0;
    }

    &__list-result {
        margin-bottom: 0;
    }
}

.customer-store-info {
    @extend %clearfix;
    background: $lighter-gray;
    padding: 25px 75px;
    margin-bottom: 35px;

    @include respond-to(mobile) {
        padding: 20px 15px;
    }

    &__title {
        text-align: center;
        margin-bottom: 5px;
    }

    &__description {
        margin-bottom: 25px;
        text-align: center;
    }

    &__contacts {
        float: left;
        width: 45%;
        background-color: $white;
        border: 2px solid $second-light-gray;
        font-size: rem(12);
        padding-bottom: 20px;
        padding-top: 5px;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            margin-bottom: 25px;
        }

        h5 {
            text-transform: uppercase;
            margin-top: 20px;
        }
    }

    &__opening-hours {
        float: right;
        width: 45%;
        font-size: rem(15);
        word-wrap: break-word;

        @include respond-to(mobile) {
            float: none;
            width: auto;
        }

        h6 {
            margin-top: 0;
        }
    }

    &__form-title {
        font-weight: 500;
        margin-bottom: 15px;
    }

    &__form {
        margin-bottom: 35px;
    }

    .store-opening-hours {
        margin-bottom: 0;
    }
}

.page-cs-header {
    background: $ghost;
    text-align: center;
    margin-bottom: 30px;

    h2 {
        padding: 20px 0;
    }

    p {
        display: block;
        width: 80%;
        margin: 0 auto;
        padding-bottom: 20px;
    }
}

.pt2-landing {
    .homepage-slides .slide {
        img {
            width: 100%;
        }
    }

    @include respond-to(mobile) {
        .fwb-panel .fwbp-text-table {
            height: auto;

            .fwbp-text-cell {
                height: auto;
            }
        }
    }

    .slot-carousel {
        margin-bottom: 30px;
    }

    .carousel {
        .product-tile,
        .product-tile-wrapper {
            height: auto;
        }
    }

    .bottom-content-bt2 {
        div {
            @extend %clearfix;
            padding-bottom: 30px;

            img {
                width: 36%;
                display: block;
                padding-bottom: 20px;
            }

            p {
                width: 60%;
            }

            &:nth-child(2n+1) {
                p {
                    float: right;

                    &.float-left {
                        float: left;
                    }
                }

                img {
                    float: left;

                    &.float-right {
                        float: right;
                    }
                }
            }

            &:nth-child(2n) {
                p {
                    float: left;

                    &.float-right {
                        float: right;
                    }
                }

                img {
                    float: right;

                    &.float-left {
                        float: left;
                    }
                }
            }

            .button {
                display: none;
            }

            @include respond-to(mobile) {
                img,
                &:nth-child(2n+1) img,
                &:nth-child(2n) img {
                    float: none;
                    width: auto;
                    max-width: 100%;
                    margin: 20px auto 30px auto;
                    padding: 0;
                }

                p,
                &:nth-child(2n+1) p,
                &:nth-child(2n) p {
                    float: none;
                    width: 100%;
                }

                .button {
                    display: block;
                    clear: both;
                    margin: 20px auto;
                }
            }
        }
    }
}

/* Competition page */
.field-competition-checkout {
    @include flexbox();
    @include flex-wrap();

    label.tertiary-style {
        width: 90%;
        display: inline-block;
    }


    input.error {
        width: 15px;
        height: 15px;

        & + span.error {
            width: 100%;
            margin-top: 5px;
            border-top: 1px solid $error;
            @include order(3);

            & + label {
                display: block;
            }
        }
    }
}

.competition-form-wrapper {
    .form-row.label-inline:last-child {
        .field-wrapper {
            width: 100%;
        }
    }
}

/* Static pages tabs */
.page-static .tabs .tab-label {
    @include respond-to(mobile) {
        padding-right: 30px;

        &:after {
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -12px;
        }
    }
}

/* Corporate gifting */
[data-asset-id="brown-thomas-corporate-gifting"] {
    .wrapper .address {
        margin: 0;
    }
}
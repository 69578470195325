// Magazine variables
//----------------------------------------
// Default settings
$m_logo-default       : '../img/logo-black.svg';
$m_logo-white         : '../img/logo.svg';
$m_preloader          : '../img/bt.svg';
$m_arrow-right        : '../img/arrow-right.svg';
$m_arrow-right-white  : '../img/arrow-right-white.svg';
$m_arrow-left-white   : '../img/arrow-left-white.svg';
$m_chevron-down       : '../img/chevron-down.svg';
$m_chevron-down-white : '../img/chevron-down-white.svg';
$m_facebook           : '../img/facebook.svg';
$m_twitter            : '../img/twitter.svg';
$m_google-plus        : '../img/googleplus.svg';
$m_opened-book        : '../img/opened-book.svg';
$m_plus               : '../img/plus.svg';
$m_close              : '../img/close.svg';
$m_close-black        : '../img/close-black.svg';
$m_scroll-icon        : '../img/scroll-icon.svg';
$m_zoom-cursor        : '../img/bt-zoom-cursor.svg';
$m_menu_border_color  : #ccc;

//header height
$m_header-height-default: 124px;
$m_header-height-tablet: 163px;
$m_header-height-mobile_stronger: 150px;
$m_header-height-desktop_over: 171px;

// Theme 1 settings
$theme1-class : '.dark-theme';
$theme1-color : #333;
$theme1-logo : $m_logo-default;


$font-second-regular : 'DidotLTStd-Regular', 'Times New Roman', Times, serif;
$font-second-bold    : 'DidotLTStd-Bold', 'Times New Roman', Times, serif;

.greeting_wrap {
    display: none;
}

.l-account-dashboard {
    .nav,
    .user_surname {
        display: none;
    }

    .greeting_wrap {
        display: inline;
    }
}

.l-account-dashboard.pt_account {
    .primary-content {
        width: 100%;
    }
}

.b-account {
    &-dashboard_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 70px;
        margin-bottom: 70px;

        @include respond-to(tablet_plus) {
            padding: 0 40px 70px;
        }

        @include respond-to(m_desktop) {
            gap: 30px 2%;
            padding: 0 0 70px;
        }
    }
}

.b-dashboard_tile {
    background-color: #f5f5f5;
    display: flex;

    @include respond-to(m_desktop) {
        width: 32%;
        padding: 60px 40px;
        flex-direction: column;
        text-align: center;
    }

    @include respond-to(m_tablet_down) {
        width: 100%;
        align-items: center;
        margin-bottom: 15px;
        padding-right: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.b-dashboard_image {
    @include respond-to(m_desktop) {
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
    }

    img {
        display: block;
    }
}

.b-dashboard_tile-title {
    font-size: rem(20);

    @include respond-to(m_desktop) {
        margin-bottom: 15px;
    }

    @include respond-to(m_tablet_down) {
        width: 75%;
        text-align: center;
    }
}

.b-dashboard_tile-text {
    font-size: rem(16);
    margin: 0;

    @include respond-to(m_tablet_down) {
        display: none;
    }
}
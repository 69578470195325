.b-account-orderhistory {
    margin-bottom: 50px;
    
    .ui-tabs {
        padding: 0;

        .ui-tabs-nav {
            font-size: 0;
            height: auto;
            position: relative;
            text-align: center;

            @include respond-to(mobile_strong) {
                margin: 0 -20px;
            }

            &:after {
                border-bottom: 1px solid #999;
                bottom: 0;
                content: "";
                position: absolute;
                width: 100%;
                z-index: -1;
                border-collapse: initial; // fix bottom full-width line to appear
            }

            .ui-tabs-tab {
                display: inline-block;
                float: none;
                font-size: rem(14);
                margin: 0;
                border: 1px solid #999;
                border-left: none;
                background-color: #D9D9D9;

                @include respond-to(mobile_strong) {
                    width: 50%;
                }

                &:first-child {
                    border-left: 1px solid #999;

                    @include respond-to(mobile_strong) {
                        border-right: 1px solid #999;
                    }
                }

                &.ui-tabs-active {
                    background-color: #fff;
                    border-bottom-color: #fff;
                    margin: 0;
                    padding: 0;

                    .ui-tabs-anchor {
                        font-weight: 500;
                    }
                }
            }

            .ui-tabs-anchor {
                display: block;
                float: none;
                padding: 15px 50px;
                text-transform: uppercase;
                letter-spacing: 1px;

                @include respond-to(mobile_strong) {
                    padding: 15px 20px;
                }
            }
        }
    }
}

.b-order_status {
    font-size: rem(12);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px 10px;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 15px;
    display: inline-block;

    &.black {
        background-color: #000;
    }

    &.primary {
        background-color: #DC8699;
    }
}

// Recent Orders
.b-account-recent_orders {
    margin-bottom: 70px;
    text-align: center;

    .b-recent_orders-title {
        font-size: rem(20);
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 40px;
    }

    .b-orders_list {
        @include respond-to(mobile_stronger) {
            gap: 35px 4%;
        }
    
        @include respond-to(desktop_plus) {
            gap: 35px 2%;
        }
    }

    .b-orders_item {
        @include respond-to(mobile_stronger) {
            width: 48%;
        }
    
        @include respond-to(desktop_plus) {
            width: 32%;
        }
    }

    .button {
        width: auto;
        font-size: rem(14);
        padding: 15px 37px;
    }
}

.b-no_orders {
    text-align: center;
    
    .button {
        padding: 15px 35px;
        font-size: rem(14);
    }
}
.masonry {
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    max-width: 1280px;
    margin: 0 auto 65px;
    padding: 0 50px;

    @include respond-to(mobile) {
        flex-direction: column;
        padding: 0;
    }

    img {
        display: block;
        width: 100%;
        border-radius: 4px;
    }
}

.hp-new-arrivals {
    .masonry {
        @include respond-to(tablet_plus) {
            margin-top: 65px;
        }
    }
}

.masonry__left {
    display: flex;
    flex-direction: column;
    width: 63%;
    background-color: white;

    @include respond-to(mobile) {
        width: 100%;
        padding: 0 3%;
    }

    .masonry__info-description {
        text-align: center;
    }
}

.masonry__slider {
    margin-bottom: 60px;

    .asset {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}

.masonry__product-slider {
    white-space: nowrap;

    .bt-product-carousel-item {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
    }

    .product-tile {
        @include respond-to(mobile_strong_over) {
            width: 315px;
        }
    }

    .product-image {
        margin-top: 15px;
    }

    .slider__items {
        margin-top: 0; 
    }

    .thumb-link {
        max-width: 315px;
    }

    .slide {
        @include respond-to(mobile) {
            width: 47.2vw;
        }
    }

    .slick-arrow {
        @include respond-to(mobile) {
            display: none !important;
        }
    }
}

.masonry__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.masonry__info-title {
    font-size: rem(20);
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.masonry__info-description {
    font-size: rem(14);
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.5;
}

.masonry__link {
    margin-bottom: 10px;
    font-size: rem(12);
    white-space: nowrap;

    @include link_underline();

    @include respond-to(tablet_plus) {
        font-size: rem(14);
    }

    &:hover {
        color: inherit;
    }
}

.masonry__caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.masonry__caption-title {
    font-size: rem(16);
    font-weight: 800;
    text-transform: uppercase;
}

.masonry__product {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 660px;
    margin: 0 auto 60px;
    
    .masonry__caption {
        border-bottom: 1px solid $nobel;
    }

    @include respond-to(mobile) {
        margin: 0 auto 60px;
        max-width: 100%;
    }
}

.masonry__product-slider {
    .slick-list {
        margin-bottom: 15px;
    }
}

.masonry__banner {
    display: flex;
    flex-direction: column;
    max-width: 660px;
    margin: 0 auto;

    @include respond-to(mobile) {
        max-width: 660px;
        margin: 0 auto 60px;
    }
}

.masonry__right {
    display: flex;
    flex-direction: column;
    width: 37%;
    padding-left: 60px;

    @include respond-to(mobile) {
        width: 100%;
        background-color: $lighter-gray;
        padding: 30px 0 50px;
    }
}

.masonry__feed {
    .masonry__caption {
        padding-bottom: 30px;

        @include respond-to(mobile) {
            margin: 0 3%;
        }
    }

    .masonry__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
    }
    
    .masonry__info-label {
        text-transform: uppercase;
        color: $pink;
        font-size: rem(12);
        margin-bottom: 5px;
        font-weight: 700;
    }

    .masonry__info-title {
        font-weight: 400;
    }

    .masonry__info-description {
        margin-bottom: 15px;
        color: $hel-gray;
    }
}

.masonry__feed-item-outer {
    @include respond-to(mobile) {
        flex: 0 0 auto;
        padding: 0 2.5%;
        max-width: 275px;
    }

    @include respond-to(tablet_plus) {
        margin-top: 50px;
        display: inline-block;
        max-width: 100%;
        width: 100%;
        
        &:first-child {
            margin-top: 0;
        }
    }
}
.magazine-body {

    .fp-enabled.mobile.ios & {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #fp-nav {
        position: fixed;
        z-index: 3;
        margin-top: -32px;
        bottom: 10px;
        background: $white;
        padding: 3px 0;
        border-radius: 12px;
        opacity: 1;
        -webkit-transform: translate3d(0,0,0);
        right: 41px;
        bottom: 150px;
        top: auto;

        @include respond-to(m_tablet_down) {
            display: none;
        }

        &::before,
        &::after {
            display: none;
        }
    }

    .fp-slidesNav{
        position: absolute;
        z-index: 4;
        left: 50%;
        opacity: 1;
        -webkit-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
    .fp-slidesNav.bottom {
        bottom: 17px;
    }
    .fp-slidesNav.top {
        top: 17px;
    }
    #fp-nav ul,
    .fp-slidesNav ul {
      margin: 0;
      padding: 0;
    }
    #fp-nav ul li,
    .fp-slidesNav ul li {
        display: block;
        width: 12px;
        height: 12px;
        margin: 7px;
        position:relative;
    }
    .fp-slidesNav ul li {
        display: inline-block;
    }
    #fp-nav ul li a,
    .fp-slidesNav ul li a {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-decoration: none;
    }
    #fp-nav ul li a.active span,
    .fp-slidesNav ul li a.active span,
    #fp-nav ul li:hover a.active span,
    .fp-slidesNav ul li:hover a.active span{
        height: 12px;
        width: 12px;
        margin: -6px 0 0 -6px;
        border-radius: 50%;
        background: $black;

     }
    #fp-nav ul li a span,
    .fp-slidesNav ul li a span {
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        height: 8px;
        width: 8px;
        border: 0;
        background: #ccc;
        left: 50%;
        top: 50%;
        margin: -4px 0 0 -4px;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }
    #fp-nav ul li:hover a span,
    .fp-slidesNav ul li:hover a span{
        background: #777;
    }
    #fp-nav ul li .fp-tooltip {
        position: absolute;
        top: -2px;
        color: $white;
        font-size: rem(14);
        font-family: arial, helvetica, sans-serif;
        white-space: nowrap;
        max-width: 220px;
        overflow: hidden;
        display: block;
        opacity: 0;
        width: 0;
        cursor: pointer;
    }
    #fp-nav ul li:hover .fp-tooltip,
    #fp-nav.fp-show-active a.active + .fp-tooltip {
        -webkit-transition: opacity 0.2s ease-in;
        transition: opacity 0.2s ease-in;
        width: auto;
        opacity: 1;
    }
    #fp-nav ul li .fp-tooltip.right {
        right: 20px;
    }
    #fp-nav ul li .fp-tooltip.left {
        left: 20px;
    }
}

.fullpage {
    &.magazine_page-layout {
        overflow: hidden;
    }
    .component-picture {
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 100%;
    }

    .component-image {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        left: 50%;
        transform: translateX(-50%);
    }
}

.fullpage-container-section {
    width: 100%;
}

.fullpage-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}


//Template 4 full page gallery
.fullpage {
    position: relative;
    z-index: 1;
}


.fullpage-pagination {
    font-family: $font-second;
    font-size: rem(14);
    white-space: nowrap;
    color: $white;
    position: relative;
    padding-bottom: 50px;
    background: url(#{$m_chevron-down-white}) no-repeat center center/20px 20px;
    display: block;

    &.endpag {
        background: none;
    }
}


.fullpage-container {
    height: 100%;
    background: no-repeat top center/cover;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .fullpage-content {
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0)
        );
        text-align: center;
        padding: 100px 25px 30px;


        .fullpage-content-title {
            color: $white;
            text-shadow: 0 2px 10px rgba(51,51,51,0.2), 0 0 2px rgba(0,0,0,0.1);
            margin: 0;

            @include respond-to(m_desktop_large) {
                font-size: rem(72);
            }
        }

        .fullpage-content-copy {
            color: $white;
            text-shadow: 0 2px 10px rgba(51,51,51,0.2), 0 0 2px rgba(0,0,0,0.1);

            p {
                font-size: rem(14);

                @include respond-to(m_tablet) {
                    font-size: rem(16);
                }
            }
        }
    }
}


.fullpage-inlinevideo-wrapper {
    width: 65%;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: -100px auto 0;

    @include respond-to(m_desktop) {
        margin: -120px auto 0;
        width: 50%;
    }
}


.fullpage-inlinevideo {
    position: relative;
    z-index: 1;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
    }

}


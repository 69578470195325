.item-shipping-address {
    .select-address {
        width: 100%;
        margin: 0;
    }

    .edit-address {
        margin-top: 12px;

        @include respond-to(mobile) {
            text-align: right;
        }
    }
}

.formactions {
    margin-top: 20px;
    text-align: right;
}

.item-list .multi-shipment-row {
    border-bottom: none;
}

.minishipments-method {
    padding-top: 10px;
}

.pt_checkout #cart-table .cart-row .shipment-label {
    font-weight: 700;
    margin-top: 35px;
    font-size: 1.4em;
}


.checkout-billing.address,
.main-payment-steps,
.checkout-shipping.address {
    .header-shipping-item {
        position: relative;

        &:before {
            position: absolute;
            right: 9px;
            top: 4px;
            content: '+';
            font-size: rem(22);
            z-index: -1;

            @include respond-to(mobile) {
                top: -4px;
            }
        }

        & + .header-shipping-item:before {
            top: 13px;
        }
    }
}

/* shipment methods */
.checkout {
    &.addresses {
        .header-shipping-item.delivery-choose {
            .short-view {
                display: none;
            }

            .full-view {
                display: block;
            }
        }
    }

    &.multiship {
        .shipment-title {
            font-size: rem(18);
            font-weight: 700;
            margin-bottom: 15px;
            text-transform: none;

            @include respond-to(mobile) {
                margin-bottom: 5px;
            }
        }

        .field-select-method {
            background: $lighter-gray;
            margin-bottom: 15px;
            padding: 13px 13px 13px 250px;
            text-align: right;

            @include respond-to(mobile) {
                margin-top: 10px;
                padding: 3px 13px 13px;
                text-align: left;
            }

            &-label {
                float: left;
                font-size: rem(18);
                font-weight: 700;
                line-height: 36px;
                margin-left: -237px;

                @include respond-to(mobile) {
                    display: block;
                    float: none;
                    margin-left: 0;
                }
            }

            .selectbox {
                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        .item-shipping-address {
            @include respond-to(mobile) {
                display: none;
            }

            &-mobile {
                display: none;
                margin-bottom: 5px;

                @include respond-to(mobile) {
                    display: block;
                }
            }
        }

        .item-details {
            .promo,
            .sku {
                display: none;
            }
        }

        .isgift {
            display: none;
        }
    }
}

.add-edit-address-dialog {
    .form-horizontal .form-row-button {
        width: auto;
    }

    .save-button,
    .save-shipping-address {
        float: right;

        @include respond-to(mobile) {
            margin-bottom: 15px;
        }
    }
}

.pdp-main.pdp-alternative {
    margin-bottom: 50px;

    .product-socials {
        dt {
            display: none;
        }

        dd {
            float: none;
            margin-top: 18px;
        }

        dl {
            text-align: center;

            @include respond-to(mobile) {
                margin-top: 30px;
            }
        }

        i {
            font-size: rem(14);
        }

        a {
            padding: 0 10px;
        }
    }

    .product-col-2 {
        float: left;
        width: 40%;
        margin-left: 6%;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
            margin-left: 0;
        }

        .product-name,
        .product-subtitle,
        .product-price {
            @include respond-to(mobile) {
                display: none;
            }
        }
        
    }

    .product-name {
        margin-bottom: 5px;
    }

    .product-name-title {
        font-size: rem(20);
        font-weight: 500;
        text-transform: uppercase;
    }

    .product-subtitle {
        font-size: rem(21);
        font-weight: 300;
        text-transform: none;
    }

    .product-name-brand {
        margin: 0;
    }

    .product-offers .inventory {
        display: none;
    }

    .product-accordion {
        cursor: pointer;

        .toggle {
            display: block;
            border-top: 1px solid $lightest-gray;
            background: $white;
            padding: 15px;
            font-weight: 500;
            letter-spacing: 1px;
            font-size: rem(14);
            text-transform: uppercase;

            i {
                float: right;
            }

            .bticons-minus {
                display: none;
            }

            &.expanded {
                .bticons-minus {
                    display: block;
                }
                .bticons-plus {
                    display: none;
                }
            }

            &:hover {
                background: $light-gray;
                color: $nobel;
            }
        }
        
        .toggle-content {
            padding: 15px 10px;
            line-height: 25px;
        }
    }

    .product-col-1 {
        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }
    }

    .product-primary-image {
        @include respond-to(mobile) {
            margin-top: 25px;
        }
    }
    
    .label-optional {
        display: none;
    }
}
.bonus-item-promo {
    margin-bottom: 2rem;
    visibility: hidden;

    &.active {
        visibility: visible;
    }

    &:nth-child(2n) {
        @include respond-to(mobile_mid) {
            display: none;
        }
    }
}

.cart-promo {
    @extend %clearfix;
    display: table;
    width: 100%;
    position: relative;
    color: $night-rider;
    font-size: rem(14);
    margin: 20px 0;

    @include respond-to(mobile) {
        text-align: center;
    }

    @include respond-to(mobile_mid) {
        margin: 0;
    }

    .bonus-item-details {
        display: table-cell;
        width: 70%;
        padding-right: 1.5rem;

        @include respond-to(mobile) {
            display: block;
            width: 100%;
        }
    }

    .bonus-item-promo-name {
        font-weight: $medium;
        text-transform: uppercase;
        color: $pink;
    }

    .bonus-item-actions {
        display: table-cell;
        font-weight: $medium;
        text-align: right;
        vertical-align: middle;

        a {
            display: inline-block;
            font-weight: $medium;
            text-transform: capitalize;
            white-space: nowrap;

            @include underlined_link;
        }

        @include respond-to(mobile) {
            display: block;
            width: 100%;
            padding: 2rem 0 1rem;
        }
    }
}

.cart-title {
    display: block;
    position: relative;
    margin: 45px 0 93px 0;
    font-size: rem(26);
    text-align: center;
    text-transform: capitalize;

    @include respond-to(mobile) {
        margin: 40px 0;
        font-size: rem(27);
    }

    + .cart-banner-slot {
        @include hidden;
    }
}

.cart-coupon_code {
    margin: 15px 0;

    .add-coupon {
        width: 100%;
    }

    .remove {
        @include link_underline();

        margin-bottom: 15px;
        display: inline-block;
        font-weight: $bold;
    }

    .coupon-error {
        text-transform: none;
        border: none;
        background-color: transparent;
    }
}

.rowcoupons {
    position: relative;
    background: $light-gray;
    border: 1px solid $pink;
    color: $night-rider;
    margin: 20px 0;
    padding: 10px;
    word-break: break-all;

    @include respond-to(mobile) {
        display: none;
    }

    &-couponcodes-label {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .coupon {
        position: relative;
    }

    .item-total {
        display: block;
        margin-top: 15px;
        font-size: rem(19);
    }

    .item-details {
        font-size: rem(19);
    }

    button {
        position: absolute;
        bottom: -10px;
        right: 6px;
        width: 20px;
        height: 20px;
        padding: 20px 0;
        background: none;
        color: $night-rider;
        font-size: 0;

        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 100%;
            border-bottom: 1px solid;
            margin-left: 5px;
        }
    }
}

.cart-content-wrapper {
    @extend %clearfix;

    .error-form {
        @include respond-to(mobile) {
            align-items: end;

            .fa-exclamation-triangle {
                margin-top: 3px;
            }
        }
    }
}

.cart-banner {
    margin-bottom: 50px;
    text-align: center;

    @include respond-to(mobile) {
        margin: 30px 0;
    }

    &.stores-restricted {
        color: $black;
    }

    .btplus-custom-quickview-cta {
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: $medium;
    }

    .button {
        text-transform: none;
        font-size: rem(13);
        letter-spacing: 0;
        line-height: 1.2;
    }

    &.bonus-select {
        .button {
            background-color: $pink;
        }
    }

    &.bonus-edit {
        .button {
            background-color: $gray;
        }
    }
}

.cart-items-form {
    @extend %clearfix;
    margin: 1rem 0;

    @include respond-to(tablet_plus) {
        width: 100%;
    }

    @include respond-to(mobile) {
        @include flexbox();
        @include flex-vertical();
    }

    .item-list {
        width: 65%;
        float: left;

        @include respond-to(mobile) {
            width: 100%;
            float: none;
            -webkit-order: 2;
            order: 2;

            .cart-content-banner {
                display: none;
            }
        }

        > div {
            @extend %clearfix;
        }
    }

    .line-item-wrapper {
        @extend %clearfix;

        padding: 35px 0;
        border-bottom: 1px solid $grey02;

        &:first-child {
            border-top: 1px solid $grey02;
        }

        > span {
            display: block;
            float: left;
            margin-bottom: 1.5rem;

            &.item-total { // Price visible only on desktop in right part
                float: right;
                width: 20%;
                padding-top: 10px;

                @include respond-to(mobile_strong) {
                    display: none;
                }

                .price-adjusted-total {
                    font-size: rem(14);
                    margin-bottom: 5px;

                    @include respond-to(mobile_stronger) {
                        font-size: rem(13);
                    }
                }

                .price-total,
                .price-adjusted-total span {
                    font-weight: $medium;
                }

                .bonus-item {
                    text-transform: uppercase;
                    font-weight: $medium;
                }

                .price-unadjusted {
                    font-size: rem(12);
                    margin-bottom: 5px;
                    color: $charcoal;
                    font-weight: $regular;
                    text-decoration: line-through;
                    text-transform: uppercase;

                    &.rrp-price {
                        text-decoration: none;
                    }

                    @include respond-to(mobile_stronger) {
                        font-size: rem(11);
                    }
                }
            }
        }

        .item-image {
            @include respond-to(mobile_strong_over) {
                width: 15%;
            }

            @include respond-to(mobile_strong) {
                width: 45%;
                padding: 0 10px;
            }

            img {
                width: 100%
            }
        }

        .item-details {
            display: flex;
            flex-wrap: wrap;

            @include respond-to(mobile_strong_over) {
                padding: 0 0 0 20px;
                width: 65%;
            }

            @include respond-to(mobile_strong) {
                flex-direction: column;
                font-size: rem(12);
                width: 45%;
            }

            .product-list-item {
                @include respond-to(mobile_strong_over) {
                    width: 60%;
                    padding-top: 15px;
                }

                @include respond-to(mobile_strong) {
                    display: flex;
                    flex-direction: column;
                }

                > div {
                    padding-bottom: 0.5rem;
                    font-size: rem(14);

                    @include respond-to(mobile_stronger) { // 768px - 992px
                        font-size: rem(13);
                    }

                    @include respond-to(mobile_strong) { // <= 767px
                        font-size: rem(12);
                    }
                }

                .product-brand {
                    font-weight: $bold;

                    @include respond-to(mobile_strong_over) { // >= 768px
                        padding-bottom: 1rem;
                        font-size: rem(16);
                    }

                    @include respond-to(mobile_strong) { // <= 767px
                        margin-bottom: 10px;
                    }
                }

                .name {
                    font-style: normal;

                    @include respond-to(mobile_strong_over) {
                        padding-bottom: 1rem;
                    }

                    @include respond-to(mobile_strong) {
                        margin: 0;
                    }

                    a {
                        color: $hel-gray;
                    }
                }

                .sku {
                    display: none;
                }

                .cart-price-display { // Price visible only for mobile
                    @include respond-to(mobile_strong_over) {
                        display: none !important; // Override inline selector .visible-mobile
                    }

                    @include respond-to(mobile_strong) {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        order: 3;

                        .label {
                            display: none;
                        }
                    }

                    .price-promotion {
                        @include respond-to(mobile_strong) {
                            display: flex;

                            .price-standard {
                                order: 2;
                                margin-left: 5px;
                                color: $charcoal;
                                font-weight: $regular;
                                text-decoration: line-through;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .promo-adjustment {
                        @include respond-to(mobile_strong) {
                            display: none;
                        }
                    }

                    .price-unadjusted {
                        @include respond-to(mobile_strong) {
                            color: $charcoal;
                            font-weight: $regular;
                            text-decoration: line-through;
                            text-transform: uppercase;
                        }
                    }

                    .price-adjusted-total,
                    .price-total {
                        @include respond-to(mobile_strong) {
                            margin-bottom: 5px;

                            .value {
                                color: $black01;
                                font-size: rem(14);
                                font-weight: $medium;
                            }
                        }
                    }
                }

                .label {
                    font-weight: $bold;
                    text-transform: capitalize;

                    @include respond-to(mobile_strong) {
                        font-size: rem(12);
                    }
                }

                .value {
                    font-weight: $regular;
                    color: $hel-gray;

                    @include respond-to(mobile_strong) {
                        font-size: rem(12);
                    }
                }

                .attribute {
                    @include respond-to(mobile_strong) {
                        margin: 0;
                    }
                }

                .over18sproduct_i {
                    display: inline-block;
                }

                .product-custom-delivery-cta {
                    font-size: rem(12);
                    color: $hel-gray;
                    margin-bottom: 10px;

                    @include respond-to(mobile_strong) {
                        order: 6;
                    }

                    @include respond-to(mobile) {
                        margin: 12px 0 0;
                    }
                }
            }

            .item-edit-details {
                text-decoration: underline;
                font-size: rem(15);
            }

            > .item-stock-info { // Hide duplicated stock messege for mobile
                @include respond-to(mobile_strong) {
                    display: none;
                }
            }
        }

        .product_actions-wishlist_link {
            @include shared-wishlist;

            font-size: rem(12);
            width: 100%;
            margin-top: 10px;
            flex: 0 0 auto;
        }

        .button-remove-item {
            background-color: transparent;
            font-size: rem(12);
            color: $black01;
            padding: 0;
            text-transform: capitalize;
            font-weight: normal;
            margin-top: 15px;
            text-align: left;
            width: auto;

            span {
                @include underlined_link;
            }
        }

        .item-quantity {
            display: none;
        }

        .item-quantity-details {
            @include respond-to(mobile_strong_over) {
                width: 35%;
            }

            @include respond-to(mobile_strong) {
                margin-top: 20px;
            }

            &.without-qty-input {
                @include respond-to(tablet_plus) {
                    padding-top: 20px;
                }
            }

            .quantity-block {
                @extend %clearfix;
                margin-bottom: 1rem;
                font-weight: $bold;
                position: relative;
                font-size: rem(14);

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .label {
                    display: none;
                }

                span.error {
                    position: absolute;
                    bottom: -50px;
                    background: none;
                    padding: 0;
                    border: 0;
                    max-width: 220px;
                    font-size: rem(10);
                }

                .label-quantity-alt {
                    display: inline;
                }

                .error-quantity {
                    margin-top: 55px;
                    margin-bottom: 0;
                    max-width: 135px;
                }
            }

            .item-availabilitymsg {
                display: block;
                font-size: rem(14);
                line-height: 14px;
                padding-top: 10px;
                color: $error;
            }

            .hamper-quantity-exceeded input.js-quantity {
                color : red;
            }

            .error-quantity {
                margin-top: 10px;
                margin-bottom: 10px;
                clear: both;
                font-weight: normal;
            }
        }

        .item-user-actions {
            @extend %clearfix;
            button,
            a {
                display: block;
                background-color: transparent;
                color: $hel-gray;
                font-family: $font-main;
                font-size: rem(14);
                line-height: 2.1rem;
                font-weight: $regular;
                letter-spacing: 0;
                outline: none;
                padding: 0;
                margin-bottom: 5px;
                text-transform: none;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: transparent;
                    color: $nobel;
                }

                @include respond-to(mobile) {
                    float: left;
                    width: auto;
                    padding-left: 5rem;

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }

        .home-delivery,
        .item-delivery-options,
        .item-price,
        .product-availability-list {
            display: none;
        }

        .item-price {
            @include respond-to(mobile) {
                display: block;

                .label {
                    font-weight: $bold;
                }
            }
        }

        .promo {
            clear: both;
            width: 100%;
            padding: 1rem;
            margin-bottom: 10px;
            background-color: $white-smoke;

            a {
                font-weight: $medium;
            }
        }

        &.line-items-row {
            .item-quantity-details {
                padding-top: 2rem;
            }
        }

        .product-description {
            font-size: rem(14);
        }

        // Selected gift bonus
        &.line-item-bonus {
            .item-price {
                @include respond-to(mobile) {
                    display: none;
                }
            }

            .item-details {
                margin-bottom: 0;

                .item-quantity-details {
                    @include respond-to(mobile_strong_over) {
                        display: block;
                    }
                }

                .bonus-item {
                    display: none;

                    @include respond-to(mobile) {
                        display: block;
                    }

                    .label {
                        font-weight: $bold;
                    }

                    .value {
                        text-transform: uppercase;
                    }
                }
            }

            .bonusproducts {
                margin-top: 10px;
                order: 3;

                a {
                    color: $charcoal;
                    font-size: rem(15);
                    text-decoration: underline;
                }
            }

            .item-image {
                @include respond-to(mobile_strong) {
                    img {
                        width: 80%;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }

            .item-quantity-details {
                display: none;
            }
        }

        &.brand-gift_card {
            .item-details {
                .product-list-item {
                    .quantity {
                        @include respond-to(mobile_strong) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .item-stock-info {
        width: 100%;
        font-size: rem(14);

        @include respond-to(mobile_strong_over) {
            margin: 2px 0 5px;
        }

        @include respond-to(mobile_strong) {
            margin: 0 0 13px;
        }

        .in-stock-msg,
        .low-stock-msg {
            &::before {
                margin-right: 3px;
            }
        }
    }

    .line-item-bonus,
    .bonus-product {
        .item-details {
            .product-list-item {
                order: 1;

                .bonus-item {
                    font-size: rem(14);
                    font-weight: $bold;

                    @include respond-to(mobile_strong) {
                        font-weight: $bold;
                        padding-bottom: 20px;
                    }
                }
            }

            .product-description {
                order: 3;
                width: 55%;
                word-break: break-word;
                color: $grey04;

                @include respond-to(mobile_strong) {
                    width: 100%;
                }
            }

            .without-qty-input {
                order: 2;
                padding-top: 10px;

                @include respond-to(mobile_strong) {
                    display: none;
                }
            }
        }

        .product_actions-wishlist_link {
            display: none;
        }
    }

    .bonus-product {
        padding: 15px 0 35px;

        &-title {
            font-size: rem(14);
            font-weight: $bold;
            color: $nobel;
            line-height: $h3-line-height;
            text-transform: none;
            padding-bottom: 15px;
            border-bottom: 1px solid $grey02;
            margin-bottom: 50px;
        }

        & + .bonus-product {
            .bonus-product-title {
                display: none;
            }
        }

        .item-image {
            padding-top: 50px;
        }
    }

    .rowbundle-wrapper {
        clear: both;
        padding-top: 20px;
    }

    .rowbundle {
        @extend %clearfix;
        margin-bottom: 10px;
        padding-bottom: 10px;

        .item-image {
            display: block;
            float: left;
            width: 15%;

            @include respond-to(mobile) {
                display: none;
            }
        }

        .image-container {
            @include respond-to(mobile) {
                float: left;
                width: 20%;
            }
        }

        .name {
            @include respond-to(mobile) {
                float: left;
                width: 80%;
            }
        }

        img {
            margin-bottom: 5px;
            max-width: 80px;

            @include respond-to(mobile) {
                max-width: 100%;
            }
        }

        .item-details {
            display: block;
            float: left;
            padding: 0 0 0 20px;
            width: 40%;

            @include respond-to(mobile) {
                float: none;
                padding-left: 0;
                width: 100%;
            }
        }

        .bundla-brand {
            display: block;
            font-size: rem(16);
        }

        .item-quantity-details {
            float: left;
            padding: 0 2rem 0;
            width: 28%;

            @include respond-to(mobile) {
                float: none;
                padding: 0;
                width: 100%;
            }
        }

        .item-price,
        .item-total {
            display: none;
        }
    }

    .bt-plus-product-list {
        .line-item-wrapper {
            .product-list-item {
                order: 1;

                .quantity {
                    display: none;
                }
            }

            .item-details {
                .name {
                    text-transform: uppercase;
                    font-weight: $medium;
                    font-size: rem(24);
                    padding-bottom: 0;

                    @include respond-to(mobile_strong) {
                        font-size: rem(20);
                    }
                }

                .price-total {
                    body:not([data-locale="en_IE"]) & {
                        display: none;
                    }
                }

                .product-description {
                    order: 3;

                    @include respond-to(mobile_strong) {
                        font-weight: $bold;
                        font-size: rem(12);
                        order: 2;
                    }
                }

                .item-quantity-details {
                    order: 2;
                    padding: 0;
                    display: block;

                    @include respond-to(mobile_strong) {
                        margin-top: 10px;
                        order: 3;
                    }

                    .quantity-block {
                        display: none;
                    }
                }

                .cart-item-delivery-unavailable {
                    margin-top: 15px;
                    max-width: 85%;
                }

                .button-remove-item {
                    order: 4;
                }
            }
        }
    }

    .cart-item-delivery-unavailable {
        font-size: rem(11);
        color: $error;
        max-width: 135px;
        margin-top: 20px;
    }
}

.item-stock-info-wrapper {
    display: none;
}

.cart-empty-content {
    @include respond-to(mobile) {
        -webkit-order: 1;
        order: 1;
    }

    @include respond-to(tablet_plus) {
        float: left;
        width: 65%;

        .cart-banner,
        .cart-items-form {
            width: 100%;
        }
    }
}

.cart-order-totals {
    margin-bottom: 1rem;
    border: 1px solid $grey02;
    padding:40px 25px 25px;
    background: $white;
    border-radius: 4px;
    z-index: 11;

    @include respond-to(mobile) {
        border: none;
    }

    &.fixed {
        position: fixed;

        @include respond-to(mobile) {
            position: static;
        }
    }

    &.positioned {
        position: fixed;
        top: auto !important; // overwrite js styles
        bottom: 320px;

        @include respond-to(mobile) {
            position: static;
        }
    }

    @include respond-to(tablet_plus) {
        float: right;
        width: 32%;
        max-width: 365px;
    }

    + div {
        @include respond-to(mobile) {
            display: none !important; // For avoid jumping empty block on mobile between cart-order-totals & cart-empty-content
        }
    }

    @include respond-to(mobile) {
        position: static !important;
        padding: 40px 0 0;
        border: 0;
        -webkit-order: 1;
        order: 1;

        .is-cart-empty & {
            -webkit-order: 2;
            order: 2;
        }
    }

    h3 {
        margin-bottom: 10px;
        font-size: rem(20);
        font-weight: $medium;

        @include respond-to(mobile) {
            margin-bottom: 15px;
        }
    }

    .cart-order-subtitle {
        @include hidden;
    }

    .cart-summary-delivery .toggle-content {
        display: block;

        @include respond-to(mobile) {
            display: none;
        }
    }

    .cart-summary-promobtn,
    .cart-summary-footer,
    .cart-summary-promobtn + .cart-actions,
    .cart-summary-delivery {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .staff-coupon_title {
        display: none;
    }
}

.cart-summary-delivery {
    margin: 30px 0 45px;

    @include respond-to(mobile) {
        margin: 0;
    }

    h3 {
        margin-bottom: 15px;
        font-size: rem(16);
        font-weight: $bold;
    }

    p {
        margin-top: 0;
        font-size: rem(14);
        color: $charcoal;
        line-height: 1.2;
    }

    table {
        td {
            padding: 10px 0;
            font-size: rem(12);
            text-align: left;
            color: $nobel;
            font-weight: $bold;
        }

        tr > td {
            width: 80%;
            position: relative;

            @include respond-to(mobile) {
                width: 90%;
            }

            &:first-child {
                overflow: hidden;
            }

            & + td {
                text-align: left;
                padding-left: 15px;
                font-weight: $bold;
                color: $nobel;
            }
        }
    }
}

.order-totals-table {
    td {
        padding: .3rem;
        font-size: rem(14);
        text-align: left;
        color: $charcoal;
    }

    tr > td {
        width: 100%;
        position: relative;

        @include respond-to(mobile) {
            width: 90%;
        }

        &:first-child {
            overflow: hidden;
        }

        & + td {
            font-weight: $medium;
            padding-left: 15px;
            text-align: right;
            white-space: nowrap;
        }
    }

    .order-totals-item {
        width: 60%;
    }

    .order-totals-value {
        width: 40%;
    }

    .order-total {
        font-size: rem(10);

        td {
            padding-top: 30px;
            font-size: rem(16);
            color: $nobel;
            text-transform: capitalize;
        }

        .order-value {
            color: $charcoal;
            font-weight: $medium;
        }
    }

    .order-shipping td,
    .order-subtotal td {
        font-weight: $medium;
    }

    .excl-delivery {
        font-size: rem(10);
        font-weight: $bold;
    }

    & + .cart-summary-giftmsg + .cart-actions {
        margin-top: 20px;
    }
}

.cart-payments {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 5px;

    .cart-payments-title {
        font-weight: $bold;
        font-size: rem(16);
        text-transform: none;

        @include respond-to(mobile) {
            margin: 0 0 20px 0;
        }

        @include respond-to(mobile_min) {
            width: 37%;
        }
    }

    &-icons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include respond-to(m_desktop) {
            justify-content: space-between;
            width: 100%;
        }
    }

    .icon {
        width: 45px;
        margin-right: 15px;

        @include respond-to(touch) {
            width: 12%;
        }
    }

    .visa,
    .paypal,
    .applepay {
        width: 35px;
    }

    .visa {
        margin-right: 20px;
    }

    .applepay {
        margin-top: 3px;
    }
}

.order-value {
    white-space: nowrap;
}

.cart-actions {
    @extend %clearfix;

    margin-bottom: 15px;

    @include respond-to(m_tablet_down) {
        margin-bottom: 0;
    }
}

.cart-action-checkout button {
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    font-size: rem(16);
    font-weight: $regular;
}

.cart-summary-giftmsg {
    font-size: rem(14);
    margin: 20px 0;
    color: $charcoal;
}


.cart-summary-loyalty {
    margin-top: 30px;
    font-size: rem(14);

    @include respond-to(mobile) {
        text-align: center;
        margin-top: 0;
    }

    .toggle {
        @include respond-to(mobile) {
            text-align: left;
        }
    }

    h1,
    .h1 {
        font-size: rem(20);
        font-weight: $medium;
        margin-bottom: 5px;
    }

    div {
        line-height: 1.5;
        font-size: rem(14);
        margin-bottom: 10px;
    }
}

.cart-summary-loyalty-mobile {
    margin: 20px 0;
    font-size: rem(13);
    text-align: left;

    h3 {
        margin-bottom: 5px;
        font-size: rem(18);
    }
}

.cart-summary-discount {
    font-size: rem(16);
    font-weight: $medium;
}

.cart-summary-footer {
    min-height: 0;
    border: 1px solid $pink;
    color: $pink;
    background: $second-lightest-gray;
    padding: 15px 10px;

    img {
        max-width: 100%;
    }
}

.cart-summary-promobtn {
    position: relative;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: rem(13);
    border: 1px solid $nobel;
    padding: 12px 25px;
    margin: 27px 0 5px;
    line-height: 25px;

    @include respond-to(mobile) {
        margin-top: 0;
        padding: 15px 0 15px 18px;
        text-align: left;
        border-width: 1px 0 1px 0;
        border-style: solid;
        border-color: $dim-gray;
        line-height: 1;
        font-size: rem(14);
    }

    &:after {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
        content: '+';
        float: right;
        font-size: rem(17);
        line-height: 25px;

        @include respond-to(mobile) {
            font-weight: 300;
            font-size: rem(23);
            right: 20px;
            line-height: 1;
            margin-top: -11px;
        }
    }
}

.cart-action-continue-shopping {
    float: right;

    button.button-text {
        line-height: 3.25em;
        margin-right: 1.0em;
    }
}

.cart-actions-top {
    margin-top: .5rem;
    padding-bottom: 1em;

    .cart-action-continue-shopping {
        float: none;
    }
}

.cart-actions button {
    width: 100%;
    padding: 20px;
    font-size: rem(13);
    font-weight: $medium;
}

.is-cart-empty {
    @extend %clearfix;
    margin-top: 4rem;

    .cart-action-continue-shopping {
        float: none;
        text-align: center;
        margin-bottom: 4rem;
    }

    .product-listing {
        @extend %clearfix;
        background-color: $white-smoke;
        padding: 1rem 0;

        h2 {
            margin-left: 1.3rem;
        }

        .search-result-items {
            margin-top: 1em;
        }
    }

    .cart-empty-content {
        .h1 {
            clear: both;
            margin: 5rem 0 2rem 0;
            text-align: center;

            @include respond-to(tablet_plus) {
                margin: 2.5rem 0;
                text-align: left;
                text-transform: none;
            }
        }
    }
}

.promo-adjustment {
    color: $sangria;
    font-size: rem(14);

    @include respond-to(mobile) {
        flex-basis: 100%;
        order: 2;
    }

    img {
        display: none;
    }
}

ul.product-availability-list {
    margin: 0;
    padding: 0;
    width: 100%; //for IE
    width: max-content;

    li {
        list-style: none outside none;
    }

    .on-order,
    .not-available {
        color: $sangria;
        font-size: 0.9em;
    }
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }

    .not-user {
        margin: .83em 0;
    }
}

#password-reset{
    white-space: nowrap;
}

.bonus-product-dialog {
    min-width: 850px;
    max-width: 850px;

    @include respond-to(tablet_ext) {
        min-width: 98%;
        max-width: 98%;
    }

    @include respond-to(mobile) {
        min-width: 92%;
        max-width: 92%;
    }

    &.ui-dialog {
        .ui-dialog-title:after {
            border-bottom: 0;
        }

        .ui-dialog-titlebar {
            position: absolute;
            top: 15px;
            right: 15px;

            @include respond-to(mobile_mid) {
                top: 0;
                right: 0;
            }
        }

        .dialog-content {
            padding: 0 15px 20px;

            @include respond-to(mobile_mid) {
                padding: 0 0 20px;
            }
        }

        .ui-dialog-titlebar-close {
            height: 33px;

            &::before {
                font-size: rem(20);
            }

            .ui-icon-closethick {
                @include respond-to(mobile_mid) {
                    width: 20px;
                    height: 20px;
                    top: 2px;
                }
            }
        }
    }

    .choice-of-bonus-products {
        .title {
            font-size: rem(29);
            line-height: rem(37);
            font-weight: $medium;
            padding: 0;

            @include respond-to(mobile_mid) {
                margin-top: 55px;
            }

            &::after {
                content: none;
            }
        }

        .subtitle {
            margin: 20px 0 50px;
            text-align: center;
            font-size: rem(14);
            font-weight: $medium;
            line-height: rem(17);
            text-transform: none;
        }

        .bonus-maxcount-error {
            margin-bottom: 30px;
            text-align: center;
            font-size: rem(14);
            font-weight: $medium;
            line-height: rem(17);
        }

        .choice-of-bonus-products {
            color: $pink;
            font-size: 14px;
            margin-bottom: 30px;
        }

        &-grid {
            margin: 0 auto;
            font-size: 0;
            text-align: center;
        }

        .bonus-product-tile {
            width: 20.2%;
            min-width: 165px;
            margin-right: 25px;
            margin-bottom: 45px;

            @include respond-to(mobile_mid) {
                margin-right: 13px;

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            .product-price {
                display: none;
            }

            .product-compare {
                bottom: 0;
                
                label {
                    font-size: 0;
                    text-align: center;
                    overflow: hidden;
                    width: 100%;
                    height: 18px;
                    display: block;

                    &::before {
                        content: "";
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0;
                        margin-right: 0;
                        color: $grey04;
                        font-size: 23px;
                        width: 18px;
                        height: 18px;
                        border: 1px solid $grey04;
                    }
                }

                input[type="checkbox"]:checked {
                    + label {
                        &::before {
                            content: '';
                        }

                        .check {
                            &::before {
                                content: '';
                                position: absolute;
                                left: 46.8%;
                                top: 14px;
                                width: 8px;
                                height: 2px;
                                background: $pink;
                                -webkit-transform: rotate(50deg);
                                transform: rotate(50deg) translateX(-50%);
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                left: 50%;
                                top: 4px;
                                width: 13px;
                                height: 2px;
                                background: $pink;
                                -webkit-transform: rotate(-50deg);
                                transform: rotate(-50deg) translateX(-50%);
                            }
                        }
                    }
                }
            }
        }

        &-grid {
            .product-variations {
                position: initial;
            }

            .attribute.color {
                .value:not(.toggle) .swatches {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    li {
                        margin: 0 0 10px 10px;
                        width: 28px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            .attribute.size {
                .selected-value {
                    .swatch-image {
                        display: none;
                    }

                    span {
                        text-align: left;
                    }
                }
            }
        }
    }

    .choice-of-bonus-products-actions {
        display: flex;
        flex-direction: column;
        width: 42.2%;
        margin: 0 auto 90px;

        .button-fancy-medium {
            font-size: 14px;
            line-height: 48px;
            padding: 0 22px;

            &:first-child {
                background: $pink;
                margin-bottom: 23px;

                @include respond-to(mobile_mid) {
                    margin-bottom: 0;
                }

                &:disabled {
                    background-color: $grey02;
                }
            }
        }

        @include respond-to(mobile_mid) {
            width: 100%;
            padding: 0 20px;
            margin-bottom: 40px;
        }
    }

    .cart-promo {
        @include respond-to(mobile_mid) {
            margin: 0;
        }
    }

    .select-more-msg {
        position: absolute;
        bottom: 77px;
        right: 50%;
        transform: translateX(50%);
        display: block;

        @include respond-to(mobile_mid) {
            right: 0;
            bottom: 27px;
            transform: translateX(-5%);
        }
    }
}

.choice-of-bonus-products {
    .title {
        position: relative;
        display: block;
        width: 100%;
        margin-top: 35px;
        text-align: center;
        font-size: rem(32);
        font-weight: 300;

        &:after {
            content: '';
            position: absolute;
            border-bottom: 1px solid $nobel;
            bottom: -2px;
            width: 110px;
            left: 50%;
            margin-left: -55px;
        }
    }

    .subtitle {
        margin: 18px 0 7px;
        text-align: center;
        font-size: rem(21);
        text-transform: uppercase;
    }

    .message {
        text-align: center;
    }

    &-grid {
        margin-top: 50px;
        font-size: 0;
        text-align: center;
        @include flexbox();
        @include flex-wrap();
        @include justify-content(center);

        &:after {
            @extend %clearfix;
        }

        .bonus-product-dialog & {
            margin-top: 0;
        }

        .bonus-product-tile {
            width: 24%;
            display: inline-block;
            vertical-align: top;
            font-size: rem(14);

            @include respond-to(mobile) {
                width: 49%;
            }

            .product-tile {
                padding-bottom: 25px;

                img {
                    width: 100%;

                    @include respond-to(mobile_mid) {
                        width: 90%;
                    }
                }
            }
        }

        .whishlist_instore-actions {
            display: none;
        }

        .product-name {
            margin-top: 10px;
        }

        .product-compare {
            position: absolute;
            bottom: 80px;
            left: 0;
            right: 0;

            label {
                position: relative;
                // display: none;
            }

            .field-wrapper {
                float: none;
            }
        }

        .product-variations {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;
            margin: 0;

            &.variation-product {
                .label {
                    display: inline-block;
                    text-transform: capitalize;
                }
            }

            .label {
                display: none;
            }

            .swatches li {
                display: inline-block;
                float: none;
            }

            .swatches .selected-value,
            .swatchanchor span {
                display: none;
            }

            .swatchanchor {
                border: 0;
            }

            .swatchanchor {
                display: block;
                width: 26px;
                margin-right: 5px;

                img {
                    height: 24px;
                    width: 24px;
                    max-width: 100%;
                    margin-top: 0;

                    &:hover {
                        border: 2px solid $lightest-gray;
                    }
                }

                &.selected img {
                    border: 2px solid $lightest-gray;
                }
            }

            .color li a {
                width: 28px;
                height: 26px;
            }

            .selected img, img:hover {
                border: 2px solid $lightest-gray;
            }

            .color img {
                border: 0;
            }

            .attribute .value {
                width: 100%;
            }
        }
    }

    &-actions {
        text-align: right;
        margin: 20px;

        @include respond-to(mobile_tablet) {
            button + button {
                margin-top: 20px;
            }
        }
    }

    .bonus-maxcount-error {
        text-align: center;
    }
}

.bt-plus-auth-message {
    position: relative;
    clear: both;
    width: 100%;
    margin: 0 0 80px;
    padding: 10px 30px;
    background-color: $white-smoke;

    &:after {
        content: "";
        position: absolute;
        bottom: -30px;
        left: 0;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid $very-light-gray;
    }

    @include respond-to(mobile) {
        padding: 10px;
        text-align: center;
    }

    a {
        font-weight: $medium;
        text-decoration: underline;
    }
}

.cart-footer .main-title {
    display: none;
}

.paypal-cart-buttons-container {
    margin-top: 20px;

    .paypal-cart-button {
        width: 100%;
    }
}

.order-totals-table {
    .order-total,
    .order-total .order-value,
    .order-total .excl-delivery,
    .order-shipping td,
    .order-subtotal td,
    .order-sales-tax td {
        font-weight: $bold;
        color: $black;
    }

    .order-sales-tax td {
        font-size: rem(10);
    }

    .order-shipping-discount td {
        color: $charcoal;
        font-weight: $medium;
    }

    @include respond-to(mobile) {
        .order-total {
            border-top: 1px solid $sections-body-border;

            td {
                padding-top: 10px;
            }
        }
    }
}

.order-summary-devider {
    padding-top: 45px;
    border-top: 1px solid $very-light-gray;
    font-size: rem(26);
    margin-bottom: 25px;
    padding-bottom: 10px;
    text-align: center;
    text-transform: uppercase;

    @include respond-to(mobile) {
        display: none;
    }
}

.order-totals-table-container {
    margin-bottom: 15px;

    .order-discount td {
        color: $red;
    }

    .loyalty-cart_points {
        font-size: rem(14);
        margin-bottom: 10px;
        line-height: 1.5;
        padding: 0 3px;

        span {
            float: right;
            font-weight: 700;
            text-align: right;
        }
    }
}

.pt_cart {
    .order-totals-table .order-total {
        border-top: none;
    }

    .cart-order-totals {
        @include respond-to(mobile) {
            padding-top: 20px;

            .cart-summary-loyalty {
                display: none;
            }
        }
    }

    .cart-content-wrapper {
        .visible-mobile .cart-banner {
            margin-bottom: 0;
        }

        .items-heading-mob.visible-mobile + .cart-banner {
            @include respond-to(mobile) {
                display: none;
            }
        }

        .item-list .cart-banner{
            @include respond-to(mobile) {
                display: none;
            }
        }
    }
}

.rrp-price {
    text-decoration: none !important;
}
.product-details__info {
    position: relative;
    border: 1px solid $gainsboro3;
    margin-bottom: 50px;

    @include respond-to(mobile_strong) {
        border-right: none;
        border-left: none;
        margin: 0 -15px 20px;
    }

    /* Social icons */
    .socialsharing {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: $white;
        margin: 0;
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .share-icon {
            width: 20px;
            height: 22px;
        }

        .share-icon--facebook {
            @include inline-svg-mono($facebook, $nobel);

            &:hover {
                @include inline-svg-mono($facebook, $pink);
            }
        }

        .share-icon--twitter {
            @include inline-svg-mono($twitter, $nobel);

            &:hover {
                @include inline-svg-mono($twitter, $pink);
            }
        }

        .share-icon--whatsapp {
            @include inline-svg-mono($whatsApp, $nobel);

            &:hover {
                @include inline-svg-mono($whatsApp, $pink);
            }
        }

        .share-icon--pinterest {
            @include inline-svg-mono($pinterest, $nobel);

            &:hover {
                @include inline-svg-mono($pinterest, $pink);
            }
        }

        .share-icon--email {
            @include inline-svg-mono($email, $nobel);

            &:hover {
                @include inline-svg-mono($email, $pink);
            }
        }

        .share-icon--cancel {
            @include inline-svg-mono($cancel, $nobel);

            display: block;
            width: 20px;
            height: 20px;
            margin: 0 auto 10px;
        }

        .socialsharing__cancel {
            text-align: center;
            font-size: rem(12);
            text-transform: capitalize;
            cursor: pointer;
        }
    }
}

.product-details__info-list {
    display: flex;
}

.product-details__info-item {
    height: 73px;
    text-align: center;
    font-size: rem(12);
    color: $black02;
    text-transform: capitalize;
    border-right: 1px solid $gainsboro3;
    padding: 13px 0 15px;
    width: 100%; // Fixed flex item width evently

    &:last-child {
        border-right: none;
    }
}

.product-details__info-item--share {
    cursor: pointer;

    &:hover .product-details__info-icon--share {
        @include inline-svg-mono($share, $pink);
    }
}

.product-details__info-icon {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 auto 10px
}

.product-details__info-icon--exclamation {
    @include inline-svg-mono($exclamation, $red);
}

.product-details__info-icon--tick {
    @include inline-svg-mono($tick, $green01);
    
    display: block;
    margin: 5px auto 4px;
}

.product-details__info-points {
    min-height: 19px;
    display: block;
    font-size: rem(14);
    font-weight: $xbold;
    margin-bottom: 12px;
}

.product-details__info-icon--share {
    @include inline-svg-mono($share, $nobel);
}
.two-columns-header-slot-wrapper  {
    position: relative;
    width: 100%;
    margin: 0 0 65px;

    .slot-assets-list {
        display: flex;
        flex-wrap: wrap;
    }

    .slot-assets-list-item {
        position: relative;
        overflow: hidden;

        @include respond-to(m_mobile_down) {
            height: 375px;
            width: 100%;
        }

        @include respond-to(tablet_regular) {
            width: 100%;
            height: 440px;
        }

        @include respond-to(m_desktop) {
            height: 490px;
            width: 50%;
        }
    }

    .component-image {
        display: block;
        width: 100%;

        @include custom-respond-to($break_touch, $m_breakpoint_desktop) { // 1024px - 1400px
            width: auto;
            max-width: initial;
        }
    }

    .promo-content-wrapper {
        position: absolute;
        color: $white;

        @include respond-to(m_mobile_down) {
            bottom: 10%;
        }

        @include respond-to(m_tablet_down) {
            bottom: 20%;
        }

        @include respond-to(m_desktop) {
            bottom: 30%;
        }

        @include respond-to(m_desktop_large) {
            bottom: 16%;
        }
    }

    .promo-left {
        text-align: left;

        @include respond-to(m_desktop) {
            left: 3%;
        }

        @include respond-to(m_tablet_down) {
            left: 10%;
        }
    }

    .promo-right {
        text-align: right;

        @include respond-to(m_desktop) {
            right: 3%;
        }

        @include respond-to(m_tablet_down) {
            left: 10%;
            right: auto;
            text-align: left;
        }
    }

    .promo-asset-title {
        text-transform: capitalize;
        font-weight: $xbold;
        color: $white;
        margin-bottom: 20px;

        @include respond-to(m_mobile_down) {   // < 768px
            font-size: rem(28);
            max-width: 80%;
        }

        @include respond-to(tablet_regular) { // 768px - 1024px
            font-size: rem(32);
        }

        @include respond-to(desktop) { // 993px - 1260px
            font-size: rem(28);
        }

        @include respond-to(desktop_over) { // >= 1261px
            font-size: rem(32);
        }
    }

    .promo-asset-description {
        font-size: rem(18);
        margin: 0 0 15px;
        line-height: 1.4;

        @include respond-to(m_mobile_down) {   // < 768px
            font-size: rem(14);
        }

        @include respond-to(tablet_regular) { // 768px - 1024px
            font-size: rem(18);
        }

        @include respond-to(desktop) { // 993px - 1260px
            font-size: rem(14);
        }

        @include respond-to(desktop_over) { // >= 1261px
            font-size: rem(18);
        }
    }

    .promo-asset-link {
        @extend .button;

        font-size: rem(18);
        text-transform: capitalize;
        width: auto;
    }
}
.sizeselector {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;

    &-dropdown_wrapper {
        position: relative;
        flex: 1 1 70%;
    }

    &-dropdown {
        @include icon(arrow_bottom);
        
        font-size: rem(12);
        padding: 15px 30px 15px 15px;
        background-color: $light-gray01;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height: 20px;
        border-radius: 4px;
        text-align: left;

        @include respond-to(m_mobile_down) {
            font-size: rem(10)
        }

        &::before {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%) rotate(0);
            transition: all 0.5s ease-in-out;
        }

        &.expanded {
            &::before {
                transform: translateY(-50%) rotate(-180deg);
                transition: all 0.5s ease-in-out;
            }
        }

        &.error {
            border: 1px solid $error;
            line-height: 20px;
            padding: 15px;
        }
    }

    .error-text {
        width: 100%;
        color: $error;
        padding-top: 5px;
        font-size: rem(14);
    }

    &-list {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 1s ease-in-out;
        border: 1px solid $light-gray01;
        background-color: $white;
        border-radius: 0 0 4px 4px;
        border-top: 0;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 3;
        width: 100%;
        transition: height 0.5s ease-in-out;

        &.expanded {
            height: auto;
            opacity: 1;
            padding: 15px;
            transition: height 0.5s ease-in-out;
        }
    }

    &-item {
        font-size: rem(12);
        text-transform: uppercase;
        margin-bottom: 5px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-item_text {
        &:hover {
            font-weight: 600;
        }
    }

    &-similar {
        @include icon(eye);

        position: relative;

        &::before {
            font-size: rem(7);
            margin-right: 3px;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $pink;
            transition: all 0.2s linear;
            visibility: hidden;
            transform: scaleX(0);
        }

        &:hover {
            color: $black01;

            &::after {
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }

    .view_wrapper {
        @include respond-to(m_mobile_down) {
            display: none;
        }
    }

    &-selected {
        font-weight: 600;

        &.unselectable {
            color: #999;
            text-decoration: line-through;
            display: inline-block;
        }
    }

    &-sizeguide-cta {
        @include icon(ruler);

        background-color: $light-gray01;
        margin-left: 15px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;

        &::before {
            font-size: rem(23);
        }

        &:hover {
            &::before {
                color: $red01;
            }
        }
    }

    &-availability {
        color: $dim-gray;
        font-weight: 500;
    }

    .not-orderable {
        color: $dim-gray;
        text-decoration: line-through;
        cursor: default;

        &:hover {
            font-weight: 400;
        }
    }
}

// Dialog popup
.sizeselector-dialog {
    width: 67% !important;

    &.ui-dialog.side_dialog {
        .ui-dialog-content {
            padding: 20px;

            @include respond-to(m_mobile_down) {
                padding: 10px;
            }
        }
    }
}

// Dialog popup on mobile
.sizeselector-dialog-mobile {
    &.ui-dialog {
        position: fixed;
        top: auto !important;
        left: 0 !important;
        right: 0;
        bottom: 0;
        max-width: none;
        max-height: 60vh;
        min-height: 30vh;

        .ui-dialog-content {
            padding: 0;
        }
    }

    .ui-dialog-titlebar {
        display: none;
    }

    .title-panel {
        background-color: $black;
        color: $white;
        font-size: rem(10);
        padding: 0 20px;
        line-height: 30px;
    }

    .sizes-popup-container {
        padding-bottom: 40px;
    }

    .sizes-wrapper {
        max-height: calc(60vh - 120px); // scroll for inner element
        overflow: scroll;
    }

    .sizes-popup-header {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        font-size: rem(14);
        line-height: 50px;
        border-bottom: 1px solid $light-gray04;
    }

    .size-item {
        border-bottom: 1px solid $light-gray04;
        padding: 15px 20px;
        font-size: rem(12);

        &:last-child {
            border-bottom: 0;
        }

        &:nth-child(7) { // make transparent bottom background when more than 6 elements on mobile
            &::after {
                content: "";
                position: fixed;
                z-index: 100;
                bottom: 0;
                left: 1px;
                right: 1px;
                height: 50px;
                background-color: $white;
                opacity: 0.5;
            }
        }
    }

    .sizes {
        margin: 0;
    }

    .size-title {
        font-weight: $medium;
    }

    .cancel-size {
        font-size: rem(10);
    }

    .out-of-stock-size {
        color: $suva-gray;
    }

    .notavailable-msg {
        display: block;
        color: $red01;
        margin-top: 15px;
    }
}
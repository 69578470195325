// STYLES FOR VIRTUAL TRY ON

.try-on-badge {
    border: 1px solid $black;
    border-radius: 4px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        background: url("../img/try-it-on.svg") center / cover no-repeat;
    }

    .carousel & {
        display: none;
    }

    // TRY IT ON PLP
    .pt_product-search-result & {
        padding: 5px 7px 5px 22px;
        margin: 5px auto 10px;
        font-size: rem(8);

        &::before {
            top: 6px;
            left: 5px;
            width: 14px;
            height: 12px;
        }
    }

    // TRY IT ON PDP
    .product-col-mobile &,
    .product-primary-image & {
        padding: 13px 14px 12px 44px;
        margin: 30px auto;
        font-size: rem(12);

        &::before {
            top: 13px;
            left: 14px;
            width: 21px;
            height: 18px;
        }
    }

    .product-primary-image & { // Hide duplicated badge under image for mobile
        @include respond-to(mobile) {
            display: none;
        }
    }
}

.try-on-wrapper {
    position: absolute;
    top: 0;
    z-index: 21;
    background: $black;
    overflow: hidden;

    @include respond-to(tablet_plus) {
        width: 450px;
        left: 65px;
        height: 450px;
    }

    &.try-on-thumbnails {
        @include respond-to(tablet_plus) {
            left: 165px;
            width: 409px;
            height: 409px;
        }

        @include respond-to(mobile_to_desktop) {
            left: 115px;
        }
    }

    .product-detail-dior & {
        @include respond-to(touch_over) {
            top: 135px; // Calc of brand banner
        }

        @include respond-to(tablet_ext) {
            top: 115px;
        }
    }

    .product-detail-chanel & {
        @include respond-to(touch_over) {
            top: 110px; // Calc of brand banner
        }

        @include respond-to(tablet_ext) {
            top: 95px;
        }
    }

    .memomi-iframe {
        width: 133.333%;
        height: 100%;
        border: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include respond-to(m_tablet_down) {
            width: calc((100vh - 100px) * 1.33);
        }
    }

    .close-btn {
        position: absolute;
        top: 13px;
        right: 13px;
        cursor: pointer;
        background: $black;
        padding: 6px 7px 5px;
        border-radius: 4px;

        &::before {
            font-family: $font-icons;
            content: "\e901";
            color: $white;
            font-size: rem(16);
        }
    }

    .vto-opened & {
        @include respond-to(mobile) {
            width: 100%;
            height: calc((100vh - calc(100vh - 100%)) - 220px); // Fix for IOS Viewport height
            position: fixed;
            left: 0;
            right: 0;
        }
    }
}

.vto-label,
.vto-value {
    display: none;
}

// POPUP VIRTUAL TRY ON PDP
.vto-opened {
    @include respond-to(mobile) { // Hide unnecessary elements to imitate Popup
        .header-cookies,
        .header-banner,
        #navigation,
        .primary-logo,
        .menu-toggle,
        .sticky-wrapper .header-search.toggle-content,
        .header-search.toggle-content,
        .header-wishlist,
        .breadcrumb,
        .product-nav-container,
        .product-row-brand-logo,
        .cat-landing-slotbanner,
        .product-chanel-title,
        .product-detail-chanel .chanel-collection,
        .product-detail-chanel .price-switcher,
        .product-detail-chanel .available-delivery-msg,
        .pdp-main .product-name,
        .pdp-main .product-col-mobile,
        .pdp-main .product-col-1,
        .pdp-main .promotion,
        .inventory,
        .product-variations .toggle .selected-value,
        .prod-feedback-txt,
        #sf-contextual-widget-live-chat,
        .product-image-container,
        .product-description,
        .product-offers,
        .gift-message-box,
        .pdp-slot-recommendation,
        .global-recommendations,
        .pdp-recommendations,
        .product-recommendations,
        .product-bundle-list,
        .product-outofstock,
        .product-review,
        .product-review,
        .product-shop-all,
        .footer,
        .applepay-button {
            display: none;
        }

        [data-slot-id="header-slider-panel"] { // Avoid recounting js styles after vto closed
            height: 0;
            overflow: hidden;
        }

        #sf-widget-companion-wrapper {
            display: none !important;
        }

        .top-banner {
            height: auto;
        }

        .mini-cart-active {
            .mini-cart-content {
                top: 0 !important;
            }
        }
    }

    .product-primary-image,
    .pl-trustmark {
        display: none !important;
    }

    #product-content {
        @include respond-to(mobile) {
            position: fixed;
            bottom: 25px;
            left: 0;
            right: 0;
        }
    }

    .product-variations {
        @include respond-to(mobile) {
            text-align: left;
            font-size: rem(14);
            margin: 0 0 20px 15px;

            .label,
            .vto-label,
            .vto-value {
                display: inline-block;
            }
        }

        .attribute ul {
            @include respond-to(mobile) {
                float: none;
            }
        }

        .variation-container {
            @include respond-to(mobile) {
                margin-bottom: 20px;
            }
        }
    }

    .available-delivery-msg {
        @include respond-to(mobile) {
            margin: 0 !important;
        }
    }

    .delivery-not-available .product-variations {
        @include respond-to(mobile) {
            margin-bottom: 15px;
        }
    }

    .pdp-main {
        button.applepay-button {
            @include respond-to(mobile) {
                margin: 15px 0 0 !important;
            }
        }
    }

    .pdpForm {
        @include respond-to(mobile) {
            padding: 0 15px;
            display: block !important;
        }

        .add-to-cart,
        .add-to-cart-disabled {
            @include respond-to(mobile) {
                margin: 0;
            }
        }
    }

    .applepay-button {
        @include respond-to(mobile) {
            margin: 15px 0 0 !important;
        }
    }
}

body.vto-opened { // Fix for show mini cart content
    @include respond-to(mobile) {
        overflow: visible;
    }
}
// Tabs using just CSS, no javascript required
// Using on MA navigation and PDP
.tab {
    input[type="checkbox"]:not(.input_toggle).tab-switch {
        display: none;

        &:checked {
            + label.tab-label {
                background-color: $light-gray;

                &:after {
                    content: "\e90f";
                }
            }

            + label + .tab-content {
                height: auto;
                opacity: 1;
                padding: 15px 15px 30px;
                transition: all 0.35s;
            }
        }
    }

    input[type="checkbox"]:not(.input_toggle) ~ label.tab-label {
        position: relative;
        border-top: 1px solid $very-light-gray;
        color: $night-rider;
        cursor: pointer;
        font-size: rem(14);
        font-weight: 500;
        letter-spacing: 1px;
        padding: 14px 15px;
        margin: 0;
        transition: all 0.25s;

        &:hover {
            background-color: $second-lightest-gray;
        }

        &:after {
            font-family: $font-icons !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            border-bottom: 0;
            content: "\e912";
            font-size: rem(13);
            line-height: 23px;
            border: none;
            position: absolute;
            top: 50%;
            left: auto;
            transform: translateY(-50%);
            right: 15px;
            width: auto;
            height: auto;
        }
    }

    .tab-content {
        height: 0;
        opacity: 0;
        padding: 0;
        text-align: left;
        line-height: 1.6;
        transition: all 0.35s;
        overflow: hidden;
    }
}
.vip-gallery {
    .main-title {
        font-weight: 400;

        @include respond-to(mobile) {
            font-size: rem(29);
        }

        & span:after {
            width: 100px;
            top: auto;
            bottom: -17px;
        }
    }

    .col-1-3 {
        float: left;
        width: 31.33%;
        text-align: left;
        padding: 0 1%;

        @include respond-to(mobile) {
            width: 100%;
            float: none;
        }
    }

    .col-1-2 {
        float: left;
        width: 50%;
    }

    .subtitle {
        position: relative;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-size: rem(26);
        color: $nobel;
        text-align: center;

        &:before {
            content: '';
            position: absolute;
            bottom: -10px;
            width: 15%;
            left: 0;
            right: 0;
            margin: auto;
            border-bottom: 1px solid;
        }
    }

    .text-italic {
        font-family: $font-second;
    }

    .content-wrapper {
        width: 85%;
        margin: 0 auto;

        @include respond-to(touch) {
            width: 95%;
        }
    }
}

.vip-gallery-products .promo-flag {
    display: none;
}

.vip-gallery-banner {
    background: $violet;
    color: $white;
    text-align: center;
    padding: 40px 0 30px;

    h1 {
        display: inline-block;
        position: relative;
        color: $white;
        font-size: rem(20);
        text-transform: none;
        font-family: $font-second;

        &:before {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            width: 50%;
            border-bottom: 1px solid $white;
            margin: auto;
        }
    }

    h2 {
        font-size: rem(34);
        margin-top: 25px;
        color: $white;
    }

    p {
        display: block;
        width: 40%;
        margin: 10px auto;

        @include respond-to(mobile) {
            width: 80%;
        }
    }
}

.vip-gallery-carousel {
    width: 85%;
    margin: 70px auto;

    @include respond-to(mobile) {
        width: 90%;
        margin: 40px auto;
    }

    .carousel .jcarousel-nav {
        color: $white;
        background: $nobel;
    }

    .fwb-panel .fwbp-text h1 {
        @include respond-to(mobile) {
            font-size: rem(35);
        }
    }
}

.vip-products-carousel {
    width: 85%;
    margin: 0 auto;

    .product-actions {
        display: none;
    }
    .content-wrapper {
        @include respond-to(mobile) {
            width: 100%;
        }
    }
}

.vip-content {
    margin-top: 190px;
    padding: 90px 0 70px;
    background: $second-lightest-gray;
    @extend %clearfix;

    @include respond-to(mobile) {
        padding-bottom: 0;
    }

    .col-center {
        text-align: center;

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    .text-italic {
        margin-top: 20px;
        color: $nobel;
        font-size: rem(22);
        font-weight: 600;
    }

    &-photo {
        margin: -220px 0 20px;

        img {
            border-radius: 50%;
        }
    }

    ul {
        margin-top: 30px;
        font-size: 0;

        li {
            width: 50%;
            padding-bottom: 5px;
            display: inline-block;
            vertical-align: middle;
            font-size: rem(15);
            color: $hel-gray;
        }
    }

    .browse-link {
        display: inline-block;
        margin-top: 45px;
        text-transform: uppercase;
        font-size: rem(15);
        padding: 10px;
        color: $nobel;
        border: 1px solid;

        @include respond-to(mobile) {
            width: 170px;
            display: block;
            text-align: center;
            margin: 40px auto 0;
        }
    }

    @include respond-to(mobile) {
        .content-asset {
            @include flexbox();
            
            width: 100%;
            -webkit-box-orient: vertical;
            -webkit-flex-flow: column;
            flex-flow: column;
        }

        .col-center {
            padding: 0 10% 30px;
            -webkit-order: 1;
            order: 1;
        }

        .col-left {
            padding: 30px 10%;
            background: $white;
            -webkit-order: 2;
            order: 2;
        }

        .col-right {
            padding: 0 10%;
            background: $white;
            -webkit-order: 3;
            order: 3;
        }
    }
}

.vip-brands {
    margin-top: 70px;

    .slot-panels-container {
        margin: 70px 0 80px;
    }

    .asset {
        float: left;
        width: 48%;
        margin: 0 1% 55px;
        text-align: center;

        @include respond-to(mobile) {
            width: 100%;
            float: none;
            margin-bottom: 20px;
        }

        img {
            max-width: 100%;
            @include respond-to(mobile) {
                width: 98%;
            }
        }
    }

    .asset-button {
        margin-top: 15px;
        min-width: 220px;
    }
}

.vip-shopping {
    margin-bottom: 70px;
    background: $second-lightest-gray;
    @extend %clearfix;

    @include respond-to(mobile) {
        margin-bottom: 0;
    }

    .col-left {
        text-align: right;

        @include respond-to(mobile) {
            padding: 0;
        }
    }

    .col-right {
        padding-top: 70px;
        text-align: center;

        .subtitle {
            display: block;
            font-size: rem(32);
        }

        p {
            display: block;
            width: 80%;
            margin: 0 auto 25px;

            @include respond-to(mobile) {
                width: 90%;
            }
        }

        b {
            font-size: rem(18);
        }
    }

    td {
        width: 50%;
        padding: 0 10px;
        background: $second-lightest-gray;

        @include respond-to(mobile) {
            display: block;
            width: 100%;
        }
    }

    .content-wrapper {
        @include respond-to(mobile) {
            width: 100%;
        }
    }
}


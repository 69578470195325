//delivery page styles
$co-selected-radio-bg                : #e5e5e5;
$co-delivery-title-bg                : #e5e5e5;
$co-multishipment-table-border-color : $sections-body-border;

.delivery-choose-label {
    @include respond-to(mobile_tablet) {
        float: none;
        padding-right: 0;
        width: auto;
        margin-bottom: 5px;
    }
}

.delivery-choose-label-box {
    display: block;
    padding: 12px 0 12px 50px;
    border: none;
    position: relative;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        z-index: 0;
        border: 2px solid $black;
        border-radius: 50%;
        top: 50%;
        margin: -8px 15px 0;
    }

    &:after {
        display: none;
    }

    .active & {
        font-weight: 400;
        background-color: $co-selected-radio-bg;

        &:after {
            display: block;
            background-color: $black;
            transform: scale(0.5);
        }
    }

    i {
        display: none;
    }
}

.shipping-btplus,
.shipping-method-list {
    @include respond-to(mobile_tablet) {
        width: auto;
    }

    @include respond-to(desktop_plus) {
        width: 89%;
    }
}

.shipping-btplus {
    margin-top: 20px;
}

.no-shipping-methods {
    .alco-delivery-info {
        background-color: $co-delivery-title-bg;
        padding: 10px 5px;
        color: $dim-gray;
        margin: 25px 0;

        p {
            margin: 0;
        }
    }
}

.shipping-method-wrapper {
    &.form-row.label-inline {
        width: 100%;
        padding-left: 0;
        margin-right: 0;
        margin-top: 0;


        .field-wrapper {
            position: relative;
            width: 100%;
            margin-bottom: 0;
            top: 0;
        }
    }

    .field-wrapper {
        .input-radio {
            position: absolute;
            top: 50%;
            left: 25px;
            transform: translateX(-50%) translateY(-50%);
            z-index: 1;
            margin: 0;
            cursor: pointer;

            &:checked + .shipping-method-label {
                background-color: $co-selected-radio-bg;
            }
        }

        .shipping-method-base-info {
            font-weight: 700;
        }

        .shipping-method-label {
            display: block;
            position: relative;
            font-weight: 400;
            padding: 10px 0 10px 50px;
            top: 0;
        }

        .form-caption {
            display: block;
            font-size: 1.6rem;
            line-height: normal;
            color: $hel-gray;
            padding-top: 10px;

            &:before {
                display: none;
            }
        }
    }
}

.singleshipping,
.checkoutmultishipping {
    button.disabled {
        pointer-events: none;
    }
}

// outside dublin address buttons' wrapper
.outside-dublin-buttons {
    .add-dublin-shipping-address {
        @include respond-to(mobile_tablet) {
            margin-top: 20px;
        }
    }
}

.checkoutmultishipping {
    button:not(:first-child) {
        @include respond-to(mobile_tablet) {
            margin-top: 20px;
        }
    }
    .visually-hidden ~ button:not(:first-child) {
        @include respond-to(mobile_tablet) {
            margin-top: 0;
        }
    }
}

.singleshipping {
    .form-row {
        &.form-row-button {
            margin-bottom: 30px;

            @include respond-to(desktop_plus) {
                width: 89%;
            }

            @include respond-to(mobile_tablet) {
                width: auto;
            }
        }
    }

    .hidden-shipping-method-list {
        @include hidden;
    }
}

//multi shipping address page
.checkoutmultishipping {
    .table-div {
        position: relative;
        padding-top: 30px;

        .multishippment & {
            border-top: none;
        }

        .section-header-line {
            display: none;
        }

        .item-details {
            width: 50%;

            @include respond-to(mobile) {
                width: 60%;
            }
        }

        .attribute,
        .item-quantity {
            margin-bottom: 10px;
        }

        .item-quantity {
            width: auto;
            float: none;

            @include respond-to(mobile) {
                display: block;
            }
        }

        .label {
            text-transform: capitalize;
        }
    }

    .title-choose-delivery {
        font-weight: 500;
    }

    .item-shipping-address,
    .item-shipping-address-mobile {
        .edit-address {
            margin-top: 0;
        }

        div:not(.edit-address) {
            .button {
                padding: 0;
                border: none;
                text-transform: none;
                letter-spacing: normal;
                text-decoration: underline;
                background-color: transparent;
                color: $link-default-color;
                margin-top: 10px;
                font-size: rem(16);
                text-align: left;

                @include respond-to(mobile_tablet) {
                    width: auto;
                }

                &:hover {
                    text-decoration: none;
                    background-color: transparent;
                    color: $link-default-color;
                }
            }
        }

        .button {
            margin-top: 30px;
        }
    }

    .formactions {
        margin-bottom: 40px;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }
}

.checkout.multiship {
    .shipment-title {
        background-color: $co-delivery-title-bg;
        padding: 0 25px;
        font-weight: 500;
        display: table;
        line-height: 45px;
        margin-bottom: 15px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            border-bottom: 1px solid $co-delivery-title-bg;
        }
    }

    .field-select-method {
        background-color: transparent;
        padding: 0;
        margin-bottom: 30px;
    }

    .field-select-method-label {
        margin-left: auto;
        float: none;
        width: 59%;
        display: inline-block;
        font-weight: 500;
        text-align: left;
    }

    .selectbox {
        width: 40%;
    }
}

.continuecheckoutbutton {
    .multishippment & {
        margin-bottom: 40px;
    }
}

//click and collect page styles
.clickandcollect {
    .cc-form {
        label {
            display: block;
        }
    }

    .cc-stores-list {
        .form-row {
            width: 96%;
            float: none;
            margin-top: 0;

            @include respond-to(mobile_tablet) {
                width: 100%;
            }
        }

        .cc-stores-list_radio {
            width: 100%;
            text-align: center;

            input[type="radio"] {
                position: absolute;
                left: 10px;
                top: 50%;
                margin-top: 0;
                transform: translateY(-50%);
                z-index: 1;

                &:checked {
                    + .cc-stores-list_text {
                        background-color: $co-selected-radio-bg;
                    }
                }
            }
        }

        .cc-stores-list_text {
            position: relative;
            top: 0;
            display: block;
            padding: 22px 0 22px 50px;
            cursor: pointer;

            @include respond-to(mobile_min) {
                padding: 22px 115px 22px 32px;
            }
        }

        .cc-stores-list_i {
            width: auto;

            .co-icon-info_outline {
                padding-right: 5px;
            }
        }

        .view-store-cnc-info {
            background-color: transparent;
            color: $link-default-color;
            height: 60px;
            line-height: 60px;
            padding: 0 10px;

            &:hover {
                .store-cnc-info-text {
                    text-decoration: none;
                }
            }
        }

        .store-cnc-info-text {
            text-decoration: underline;
        }
    }

    .clickandcollecthipping-bottom {
        .flayer {
            @include respond-to(desktop_plus) {
                width: 96%;
            }
        }
    }

    .instore-continue-button.cc-button {
        margin-top: 30px;
        margin-bottom: 30px;
        display: table;
        margin-left: auto;
        margin-right: 4%;
        float: none;

        @include respond-to(mobile_tablet) {
            margin-right: 0;
            display: block;
        }
    }
}

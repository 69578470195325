.infinite-scroll {
    .search-result-content {
        .product-infinite-scroll-placeholder {
            display: none;

            span {
                display: inline-block;
            }
        }

        .infinite-scroll-loading {
            display: block;
            bottom: -50px;
            font-size: rem(20);
            left: 50%;
            margin-left: -100px;
            position: absolute;
        }
    }
}

.disable-infinite-scroll .search-result-content .page-element {
    display: none;
}

.scroll-to-top {
    @extend .button;
    display: none;
    background: $white;
    border: 1px solid $charcoal;
    bottom: 95px;
    color: $nobel;
    position: fixed;
    right: 20px;
    z-index: 9;
    width: auto;

    @include respond-to(mobile_strong) {
        bottom: 10px;
        font-size: 0;
        padding: 15px 11px;
        right: 10px;
        width: 45px;
    }

    &:before {
        display: block;
        font-size: rem(16);

        @include respond-to(mobile_strong) {
            font-size: rem(20);
        }
    }

    &:hover {
        background: $white;
        color: $hel-gray;
    }
}
@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3265876, 2016-08-11T09:43:51-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: DidotLTStd-Italic by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/didot/lt-std-italic/
 * Copyright: Copyright &#x00A9; 1992, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 * Licensed pageviews: 2,500,000
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3265876
 * 
 * © 2016 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/31d554");
@font-face {
    font-family: 'DidotLTStd-Italic';
    src: url("//cdn-media.amplience.com/brown_thomas/fonts/31D554_0_0.eot");
    src: url("//cdn-media.amplience.com/brown_thomas/fonts/31D554_0_0.eot?#iefix") format("embedded-opentype"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/31D554_0_0.woff2") format("woff2"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/31D554_0_0.woff") format("woff"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/31D554_0_0.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

@font-face {
    font-family: 'f37_bellaregular';
    src: url("../fonts/f37bella-webfont.eot");
    src: url("../fonts/f37bella-webfont.eot?#iefix") format("embedded-opentype"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/f37bella-webfont.woff2") format("woff2"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/f37bella-webfont.woff") format("woff"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/f37bella-webfont.ttf") format("truetype"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/f37bella-webfont.svg#f37_bellaregular") format("svg");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

@font-face {
    font-family: 'icomoon';
    src: url("../fonts/icomoon.eot?4r0ucj");
    src: url("../fonts/icomoon.eot?4r0ucj#iefix") format("embedded-opentype"),
             url("../fonts/icomoonBT.ttf?4r0ucj") format("truetype"),
             url("../fonts/icomoon.woff?4r0ucj") format("woff"),
             url("../fonts/icomoon.svg?4r0ucj#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

[class^="bticons-"], [class*=" bticons-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.bticons-spin {
    -webkit-animation: bticons-spin 2s infinite linear;
    animation: bticons-spin 2s infinite linear; }

@-webkit-keyframes bticons-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

@keyframes bticons-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

.bticons-bag:before {
    content: "\e900"; }

.bticons-close:before {
    content: "\e901"; }

.bticons-down:before {
    content: "\e902"; }

.bticons-email:before {
    content: "\e903"; }

.bticons-facebook:before {
    content: "\e904"; }

.bticons-gift:before {
    content: "\e905"; }

.bticons-google:before {
    content: "\e906"; }

.bticons-grid:before {
    content: "\e907"; }

.bticons-heart-filled:before {
    content: "\e908"; }

.bticons-heart-outline:before {
    content: "\e909"; }

.bticons-instagram:before {
    content: "\e90a"; }

.bticons-left-small:before {
    content: "\e90b"; }

.bticons-left:before {
    content: "\e90c"; }

.bticons-loading:before {
    content: "\e90d"; }

.bticons-location:before {
    content: "\e90e"; }

.bticons-minus:before {
    content: "\e90f"; }

.bticons-pagination:before {
    content: "\e910"; }

.bticons-pinterest:before {
    content: "\e911"; }

.bticons-plus:before {
    content: "\e912"; }

.bticons-question:before {
    content: "\e913"; }

.bticons-right-small:before {
    content: "\e914"; }

.bticons-right:before {
    content: "\e915"; }

.bticons-search-small:before {
    content: "\e916"; }

.bticons-search:before {
    content: "\e917"; }

.bticons-share:before {
    content: "\e918"; }

.bticons-single:before {
    content: "\e919"; }

.bticons-star-filled:before {
    content: "\e91a"; }

.bticons-star-half:before {
    content: "\e91b"; }

.bticons-star-outline:before {
    content: "\e91c"; }

.bticons-twitter:before {
    content: "\e91d"; }

.bticons-up:before {
    content: "\e91e"; }

.bticons-user:before {
    content: "\e91f"; }

.bticons-youtube:before {
    content: "\e920"; }

.bticons-zoom-2:before {
    content: "\e921"; }

.bticons-zoom:before {
    content: "\e922"; }

.bticons-logo:before {
    content: "\e923"; }

/*# sourceMappingURL=fonts.css.map */
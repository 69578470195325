//.col-xxs-{num} - column for mobile devices {>= 0}
//.col-s-{num} - column for mobile devices {>= 416px}         (m_mobile_landscape)
//.col-sm-{num} - column for tablet devices {>= 768px}        (m_tablet)
//.col-md-{num} - column for desktop {>= 1024px}              (m_desktop)
//.col-lg-{num} - column for large desktop {>= 1400px}        (m_desktop_large)
//.col-xlg-{num} - column for extra large desktop {>= 1900px} (m_desktop_xlarge)

// Magazine grid sustem
// Grid system
//
// Available classes col-xs-[], col-sm-[], col-md-[], col-lg-[], col-xlg-[]
//
// Markup:
// <div class="wrap">
//    <div class="row">
//      <div class="col-xs-12"><div style="padding: 15px; border: 1px solid #333; background: #ccc;">12</div></div>
//    </div>
// </div>
// <div class="wrap">
//    <div class="row">
//      <div class="col-xs-11"><div style="padding: 15px; border: 1px solid #333; background: #ccc;">11</div></div>
//      <div class="col-xs-1"><div style="padding: 15px; border: 1px solid #333; background: #ccc;">1</div></div>
//    </div>
// </div>


/// Grid gutter xs
/// @type Length
$grid-gutter-xs: 0;

/// Grid gutter s
/// @type Length
$grid-gutter-s: 0;

/// Grid gutter sm
/// @type Length
$grid-gutter-sm: 0;

/// Grid gutter md
/// @type Length
$grid-gutter-md: 0;

/// Grid gutter lg
/// @type Length
$grid-gutter-lg: 0;

/// Grid gutter xlg
/// @type Length
$grid-gutter-xlg: 0;

/// Column count
/// @type Length
$grid-columns: 12;
/// Make grid columns

@mixin make-grid-columns($i: 1, $list: '.col-xs-#{$i}, .col-s-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xlg-#{$i}') {
    @for $i from (1 + 1) through $grid-columns {
        $list: '#{$list}, .col-xs-#{$i}, .col-s-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xlg-#{$i}';
    }

    #{$list} {
        position: relative;
        min-height: 1px;
        border-left: $grid-gutter-xs solid transparent;
        border-right: $grid-gutter-xs solid transparent;

        @include respond-to ('m_mobile_landscape') {
            border-left: $grid-gutter-s solid transparent;
            border-right: $grid-gutter-s solid transparent;
        }

        @include respond-to ('m_tablet') {
            border-left: $grid-gutter-sm solid transparent;
            border-right: $grid-gutter-sm solid transparent;
        }

        @include respond-to ('m_desktop') {
            border-left: $grid-gutter-md solid transparent;
            border-right: $grid-gutter-md solid transparent;
        }

        @include respond-to ('m_desktop_large') {
            border-left: $grid-gutter-lg solid transparent;
            border-right: $grid-gutter-lg solid transparent;
        }

        @include respond-to ('m_desktop_xlarge') {
            border-left: $grid-gutter-xlg solid transparent;
            border-right: $grid-gutter-xlg solid transparent;
        }
    }
}

/// Grid remove vertical spacing
@mixin grid-remove-vertical-spacing($index, $class: s) {
    @for $i from 0 through $index {
        @if ($i > 0 AND  $i < 12) {
            .col-#{$class}-#{$i} {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

/// Calc grid column
@mixin calc-grid-column($index, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col-#{$class}-#{$index} {
            width: percentage(($index / $grid-columns));
        }
    }

    @if ($type == offset) {
        .col-#{$class}-offset-#{$index} {
            margin-left: percentage(($index / $grid-columns));
        }
    }
}

/// Loop grid columns
@mixin loop-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-grid-column($i, $class, $type);
    }
}

/// Create grid for specific class
@mixin make-grid($class) {
    @include loop-grid-columns($grid-columns, $class, width);
    @include loop-grid-columns($grid-columns, $class, offset);
}

/// Tiles mixins
@mixin vertical-spacings() {
    margin-top: $grid-gutter-xs * 2;

    &:first-child {
        margin-top: 0;
    }

    @include respond-to ('m_mobile_landscape') {
        margin-top: $grid-gutter-s * 2;

        &:first-child {
            margin-top: 0;
        }
    }

    @include respond-to ('m_tablet') {
        margin-top: $grid-gutter-sm * 2;

        &:first-child {
            margin-top: 0;
        }
    }

    @include respond-to ('m_desktop') {
        margin-top: $grid-gutter-md * 2;

        &:first-child {
            margin-top: 0;
        }
    }

    @include respond-to ('m_desktop_large') {
        margin-top: $grid-gutter-lg * 2;

        &:first-child {
            margin-top: 0;
        }
    }

    @include respond-to ('m_desktop_xlarge') {
        margin-top: $grid-gutter-xlg * 2;

        &:first-child {
            margin-top: 0;
        }
    }
}

@include make-grid-columns();

.col-xs-12 {
    @include vertical-spacings;
}

@include make-grid('xs');

@include respond-to('m_mobile_landscape') {
    @include make-grid('s');
    @include grid-remove-vertical-spacing($grid-columns, 's');
}

@include respond-to('m_tablet') {
    @include make-grid('sm');
    @include grid-remove-vertical-spacing($grid-columns, 'sm');
}

@include respond-to('m_desktop') {
    @include make-grid('md');
    @include grid-remove-vertical-spacing($grid-columns, 'md');
}

@include respond-to('m_desktop_large') {
    @include make-grid('lg');
    @include grid-remove-vertical-spacing($grid-columns, 'lg');
}

@include respond-to('m_desktop_xlarge') {
    @include make-grid('xlg');
}


.magazine {
    .wrap {
        @include vertical-spacings;

        position: relative;
        overflow: hidden;
        &.horizontalscroll{
            @include respond-to ('mobile_strong'){
                overflow-y:auto;
            }
        }
    }

    .row {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -#{$grid-gutter-xs};

        @include respond-to ('m_mobile_landscape') {
            margin: 0 -#{$grid-gutter-s};
        }

        @include respond-to ('m_tablet') {
            margin: 0 -#{$grid-gutter-sm};
        }

        @include respond-to ('m_desktop') {
            margin: 0 -#{$grid-gutter-md};
        }

        @include respond-to ('m_desktop_large') {
            margin: 0 -#{$grid-gutter-lg};
        }

        @include respond-to ('m_desktop_xlarge') {
            margin: 0 -#{$grid-gutter-xlg};
        }
    }
}

$ref-range-track-color: #bcbcbc;

.search-refinements-container {
    .horizontal-wrapper & {
        @include respond-to(tablet_plus) {
            padding-top: 40px;
            border-top: 1px solid $very-light-gray;
        }
    }
    
    @include respond-to(mobile_min) {
        max-width: calc(100% - #{$ref-close-size});
    }

    @include respond-to(m_tablet_down) {
        &__close {
            // To catch clicks for req: 'Users should also be able to tap the empty space under the X to close'
            // To be revised from FE
            width: 1500px;
            height: 100%;
            position: fixed;
            z-index: 1;
        }
    }

    .vertical-wrapper & {
        @include respond-to(m_desktop) {
            height: auto !important;
        }
    }

    .close {
        @include respond-to(mobile_min) {
            width: $ref-close-size;
            right: -$ref-close-size;
            left: auto;
        }

        &:before {
            @include respond-to(mobile_min) {
                right: 50%;
                margin-right: -15px;
            }

            @include respond-to(not_mobile_min) {
                right: 15px;
            }
        }
    }

    .loader .loader-indicator {
        @include respond-to(mobile) {
            display: none !important;
        }
    }
} //.search-refinements-container

.search-refinements-active {
    @include respond-to(m_tablet_down) {
        transform: translateX(0);

        &::after {
            top: $ref-close-size;
            width: $ref-overlay-width;
        }
    }

    @include respond-to(m_tablet_down) {
        // To be revised from FE
        &.ref-level-1 {
            .refinement-header,
            .search-refinement-item-tools-counter,
            .search-refinement,
            .refinement-header-box__inner {
                display: none;
            }

            .refinement-header-return-link {
                display: block;
            }
        }
    }
}

.refinement-header-return-link {
    display: none;
    font-size: rem(12);
    line-height: 1;
    color: $grey04;
    border-bottom: 1px solid $grey02;
    padding: 12px 0 12px 15px;

    .refined-clear-all-icon {
        margin-right: 5px;
    }
}

.search-refinements-inner {
    @include respond-to(m_tablet_down) {
        padding: 0;

        // To be revised from FE
        .refinements__item {
            &.hidden-refinement {
                .scrollable-refinement,
                .search-brand-refinements-container,
                .search-refinement-price-range {
                    display: none;
                }
            }
        }
    }
}

// Refinements Header Block
.refinement-header-box {
    text-align: left;

    .refined-clear-all-desktop {
        display: flex;
        flex-wrap: wrap;

        @include respond-to(m_tablet_down) {
            display: none;
        }
    }
}

.refined-clear-all__icon {
    @include inline-svg-mono($cancel, $white);

    display: inline-block;
    width: 12px;
    height: 9px;
    font-family: $font-main;
    font-size: rem(12);
    vertical-align: middle;
}

.search-refinements-inner {
    @include respond-to(m_tablet_down) {
        padding-bottom: 85px;
        display: flex;
        flex-direction: column;
    }

    .refinement-header-box {
        @include respond-to(m_tablet_down) {
            order: 1;
        }
    }

    .search-refinement {
        @include respond-to(m_tablet_down) {
            order: 2;
        }
    }

    .refinements__wrapper {
        @include respond-to(m_tablet_down) {
            order: 3;
        }
    }
}

.refinement-header-category-name,
.refinement-header-products-count {
    display: block;
    margin-bottom: 5px;
}

.refinement-header-products-count {
    color: $dim-gray;
}

.refinement-header-category-name {
    display: none;
    text-transform: uppercase;
    font-size: rem(16);
    
    @include respond-to(m_desktop) {
        font-weight: 700;
    }
}

.refinement-header-products-count {
    display: none;
    font-weight: 400;
}

.refinement-header-box__inner {
    .refined-clear-all__button {
        @include respond-to(m_tablet_down) {
            font-size: rem(12);
            text-transform: capitalize;
            color: $grey04;
        }
    
        @include respond-to(m_desktop) {
            display: inline-block;
            font-size: rem(10);
            color: $grey04;
            text-transform: uppercase;
            border: 1px solid $grey03;
            border-radius: 4px;
            padding: 10px;
        }
    }
}

.search-refinement {
    .refined-clear-all__button {
        display: inline-block;
        font-size: rem(10);
        color: $white;
        background-color: $black01;
        line-height: 1;
        text-transform: uppercase;
        border-radius: 4px;
        padding: 10px 12px 10px 10px;
    }
} 

.refined-clear-all__icon + .refined-clear-all__text,
.refined-clear-all__text + .refined-clear-all__icon {
    margin-left: 10px;
}

.refined-clear-all__text {
    vertical-align: middle;
}

// Refinements tab name

.refinements {
    .category-banner {
        img {
            width: 100%;
            max-width: 100px;
            display: block;
        }
    }

    .toggle {
        // icon default style and '-'
        &::after {
            // @include respond-to(mobile) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: $ref-wrap-padding;
                width: 11px;
                color: $white;
                font-size: rem(28);
            // }
            @include respond-to(m_desktop) {
                border-bottom-width: 2px;
                color: $black;
            }
        }
    }

    .hidden-refinement {
        .toggle {
            // icon '+'
            &::after {
                // @include respond-to(mobile) {
                    position: absolute;
                    height: auto;
                    width: auto;
                    color: $dim-gray;
                // }
                @include respond-to(m_desktop) {
                    color: $black;
                    font-weight: normal;
                }
            }
        }

        .search-refinement-item-tools-counter {
            @include respond-to(m_desktop) {
                margin-left: 10px;
            }
        }
    }
}

.search-refinement-item-tools-wrapper {
    display: inline-block;
    vertical-align: middle;
}

.search-refinement-item-tools-wrapper {
    @include respond-to(mobile_tablet) {
        width: 72%;
    }
}

.search-refinement-item-tools-clean {
    display: none;
}

.refinement, 
.refinements__item {
    border-bottom-color: $pink;
    padding: 0;
    margin-top: -1px;
    
    @include respond-to(m_tablet_down) {
        border: none;
    }

    h3 {
        position: relative;
        padding: 13px 15px;
        font-weight: $regular;

        @include respond-to(m_tablet_down) {
            font-size: rem(13);
        }

        @include respond-to(m_desktop) {
            font-size: rem(14);
        }

        &:hover {
            color: $pink;
        }
    }

    .toggle {
        color: $pink;

        @include icon(arrow_top, after);

        &:after {
            font-size: rem(9);
            color: $pink;
            line-height: rem(16);
            vertical-align: middle;
            border: none;
            width: auto;
            height: auto;
        }
    }

    &.hidden-refinement {
        border-bottom: 1px solid $grey02;
        border-top: 0;

        .toggle {
            color: $black02;
            
            @include icon(arrow_bottom, after);

            &:after {
                font-size: rem(9);
                color: $grey04;
                right: 20px;
                line-height: 1;
                vertical-align: middle;
                
                @include respond-to(m_tablet_down) {
                    transform: rotate(-90deg);
                }
            }
        }

        .search-brand-refinements-container {
            margin: 0;
        }
    }

    &:not(.hidden-refinement) {
        .search-refinement-item-tools-clean {
            position: absolute;
            bottom: 12px;
            right: 15px;
            color: $grey04;
            
            @include respond-to(m_desktop) {
                color: $dim-gray;
                margin-left: 10px;
            }
        }

        .pricerange-inputs .pricerange-input-min {
            float: left;
        }
        
        .pricerange-inputs .pricerange-input-max {
            float: right;
        }

        .search-brand-refinements-container,
        .search-refinement-price-range {
            max-height: 300px;
            opacity: 1;
            display: block;
            margin-bottom: 26px;
        }
    }

    .pricerange-inputs .pricerange-input-min,
    .pricerange-inputs .pricerange-input-max {
        float: none;
    }

    .search-brand-refinements-container,
    .scrollable-refinement,
    .search-refinement-price-range {
        margin: 0;
        transition: max-height 0.3s ease, margin 0.3s ease, opacity 0.3s ease;
    }
    
    .search-brand-refinements-container,
    .search-refinement-price-range {
        max-height: 0;
        opacity: 0;
        display: none;
    }
    
    .search-brand-refinements-container,
    .scrollable-refinement {
        @include respond-to(m_desktop) {
            margin: 0;
        }
    }

    .search-brand-refinements-filter {
        font-size: rem(12);
        color: $grey04;
        border-color: $grey04;
        padding: 10px 35px 10px 14px;
        
        &::placeholder {
            color: $grey05;
        }
    }
    
    .search-brand-refinements-container {
        overflow: hidden;
        position: relative;
        margin: 29px 15px 0;

        @include respond-to(m_desktop) {
            margin: 15px 15px 0;
        }
    }
    
    .search-brand-refinements-close {
        cursor: pointer;
        position: absolute;
        right: 17px;
        top: 50%;
        z-index: 2;
        background-color: $white;
        transform: translateY(-50%);

        &::before {
            @include inline-svg-mono($cancel);

            display: block;
            font-size: 0;
            width: 20px;
            height: 20px;
        }
    }

    .scrollable-refinement {
        margin: 15px 0;
        padding-bottom: 0;
        max-height: 240px;
        
        @include respond-to(m_tablet_down) {
            max-height: none;
            overflow: visible;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: $requirements-scrollbar-track-color;
            border: 1px solid transparent;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background: $requirements-scrollbar-color;
            border: 1px solid $requirements-scrollbar-color;

            .chanel-section & {
                background: $black;
                border-color: $black;
            }
        }
    }

    .refinement-item {
        .match {
            font-weight: 700;
            color: $black;
        }
    }

    li {
        padding: 4px 0;
    }

    .search-refinement-price-range {
        padding: 29px 15px;

        @include respond-to(m_tablet_down) {
            padding: 29px 27px;
        }
    }
}

.pricerange-inputs {
    margin-bottom: $ref-wrap-padding;

    &:after {
        content:'';
        display: table;
        clear: both;
    }
    .pricerange-input-min,
    .pricerange-input-max {
        position: relative;
        width: calc(50% - #{$ref-wrap-padding/2 + $ref-wrap-padding/4});
    }

    .pricerange-input-max {
        float: right;
    }

    .pricerange-input-min {
        float: left;
        &::after {
            content: '';
            position: absolute;
            left: calc(100% + 12px);
            width: 7px;
            top: 50%;
            height: 1px;
            background: $nobel;
            transform: translateY(-50%);
        }
    }

    input[type="number"] {
        padding-left: 20px;
        background-color: $grey03;
        border: none;
        border-radius: 4px;
    }

    .currency-symbol {
        position: absolute;
        font-size: rem(14);
        line-height: 36px;
        left: 8px;
    }
}

// Price section (range slider)
.search-refinement-price-range {
    margin: 20px 15px;
    
    .ui-widget.ui-slider-horizontal {
        height: 3px;

        @include respond-to(m_desktop) {
            margin: 0 7px;
        }
    }

    .ui-slider-handle {
        background: radial-gradient(circle at center center, $black 49%, $white 50%);
        padding: 3px;
        border-radius: 50%;
        position: relative;
        width: 25px !important;
        height: 25px !important;
        top: -12.5px !important;

        @include respond-to(touch_over) {
            background: radial-gradient(circle at center center, $black 49%, $white 55%);
        }

        &:last-child {
            left: auto;
            right: 0;
            margin-right: -.6em;
        }
    }

    .ui-slider {
        background-color: $ref-range-track-color;
    }

    .ui-slider-range {
        background-color: $pink;
    }
}

// Refinements control buttons
.refinements-control-buttons {
    @include flexbox;
    @include flex-wrap;
    @include justify-content(space-between);

    background-color: $white;
    bottom: 0;
    left: 0;
    padding: 18px 15px;
    width: 100%;
    z-index: 3;
    position: absolute;
    display: none;

    @include respond-to(m_tablet_down) {
        display: block;
    }
    
    @include respond-to(not_mobile_min) {
        max-width: 312px;
    }

    ~ .loader {
        @include respond-to(mobile_top) {
            z-index: 4;
        }
    }

    .button {
        padding: 16px 0;

        .refinements-control-button_text {
            @include respond-to(mobile_min) {
                font-size: rem(12);
            }

            @include respond-to(m_tablet_down) {
                font-size: rem(14);
            }
        }
    }
}

// No results popup
.no-refinements-hits {
    text-align: center;
}

.search-refinement {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    padding: 21px 28px 15px 15px;
}

.search-refinements__item {
    margin: 0 10px 10px 0;
    font-size: rem(10);
    text-transform: uppercase;
    color: $black02;
    border: 1px solid $grey02;
    border-radius: 4px;

    &:first-child {
        margin-left: 0;
    }
}

.refinements {
    @include respond-to(m_desktop) {
        .refinements__item {
            margin-top: -1px;
            border-bottom: 1px solid $pink;
            border-top: 1px solid $pink;
    
            h3 {
                &.toggle {
                    position: relative;
                    padding: 15px 15px 20px;
                    font-size: rem(14);
                    font-weight: normal;
    
                    @include icon(arrow_bottom, after);
    
                    &:after {
                        font-size: rem(9);
                        color: $hel-gray;
                        right: 20px;
                    }
    
                    &.expanded {
                        border-bottom: none;
    
                        @include icon(arrow_top, after);
    
                        &:after {
                            font-size: rem(9);
                            height: auto; //remove if it don't need
                            border: none;
                            right: 20px;
                            color: $black02;
                        }

                        &:hover {
                            color: $pink;
                        }
                    }
                }
            }

            &.hidden-refinement {
                border-bottom: 1px solid $grey02;
                border-top: 0;
                
                .refinements__content {
                    display: none;
                }
            }
        }
    }
}

.scrollable-refinement {
    li {
        margin: 0 15px;
        padding: 0;

        &.selected {
            .refinement-item {
                color: $pink;

                @include respond-to(mobile) {
                    color: $black;
                }

                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

.refinement-item {
    position: relative;
    display: block;
    font-size: rem(12);
    line-height: 21px;
    text-transform: capitalize;
    color: $black02;
    margin-bottom: 20px;
    padding-right: 25px;

    @include respond-to(m_desktop) {
        font-size: rem(14);
        color: $grey04;
        margin-bottom: 19px;
    }

    @include icon(checked);

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid $grey04;
        transform: translateY(-50%);
    }

    &:before {
        position: absolute;
        right: 4px;
        top: 50%;
        font-size: 12px;
        color: $pink;
        transform: translateY(-50%);
        opacity: 0;
    }

    &:hover {
        color: $pink;

        @include respond-to(m_tablet_down) {
            color: $black02;
        }
    }

    .unselectable & { // Filters Not Available for Selection
        color: $gray;
        cursor: default;

        &:hover {
            color: $gray;
        }

        &::after {
            border-color: $gray;
        }
    }
}

.clear-refinement {
    display: none;
}

.search-brand-refinements-container {
    margin-bottom: 15px;
    position: relative;

    &:after {
        @include inline-svg-mono($search, $grey02);

        content: '';
        position: absolute;
        right: 17px;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
    }

    input {
        border-radius: 4px;

        &:focus + .search-brand-refinements-close {
            background-color: $narvik;
        }
    }
}

.search-result-options {
    position: relative;

    @include respond-to(m_desktop) {
        padding: 20px 0 40px;
    }

    @include respond-to(m_tablet_down) {
        margin: 0 -15px 40px;
        padding: 0px 0 40px;
    }

    &__inner {
        position: relative;
        @extend %clearfix;

        @include respond-to(m_tablet_down) {
            height: 60px;
            border-top: 1px solid $grey02;
            border-bottom: 1px solid $grey02;

            &.sticky {
                position: fixed;
                top: 77px;
                width: 100%;
                background: $white;
                z-index: 1000;
    
                .change-image-view,
                .pag-items-show {
                    display: none;
                }
            }
        }

        @include respond-to(mobile_strong) {
            &.sticky {
                top: 113px;
            }
        }

        select {
            font-style: normal !important;

            @include respond-to(m_tablet_down) {
                height: 58px;
                line-height: 58px;
                font-weight: $bold;
                text-transform: uppercase;
                text-align: center;
                padding: 0 35px 0 15px;
                border: none;
                background: none;
            }

            @include respond-to(m_desktop) {
                padding: 0 38px 0 15px;
                border: 1px solid $lighter-gray;
            }
        }

        .sort-by {
            position: relative;
            float: left;
    
            @include respond-to(m_tablet_down) {
                width: 50%;
                border-right: 1px solid $grey02;
    
                &:after {
                    @include respond-to(m_tablet_down) {
                        content: '';
                        position: absolute;
                        top: calc(50% - 3px);
                        right: 16px;
                        display: inline-block;
                        padding: 3px;
                        border: solid $black01;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg) translateX(-50%);
                    }
                }
            }
    
            @include respond-to(m_desktop) {
                min-width: 160px;

                &:before {
                    @include icon(arrow_bottom);
    
                    content: '';
                    width: 20px;
                    height: 19px;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    z-index: -1;
                }
            }
    
            .simple-submit {
                display: none;
            }
        }
    }
    
    select {
        font-size: rem(12);

        @include respond-to(m_desktop) {
            line-height: rem(12);
            background-color: $white;
            border-radius: 4px;
        }
    }

    .search-result-options__controls {
        @extend %clearfix;

        float: left;

        @include respond-to(m_tablet_down) {
            width: 84.5%;
            position: relative;
        }

        @include respond-to(mobile_mid) {
            width: 73%;
        }
    }

    .change-image-view {
        float: left;
        font-size: rem(13);

        @include respond-to(m_tablet_down) {
            margin: 20px 0 0 15px;
            clear: left;
        }

        @include respond-to(m_desktop) {
            margin-left: 30px;
            padding-top: 6px;
        }

        .desktop & {
            @include link_underline();
        }

        /* Fix for "@include link_underline" during touching on devices. 
        On devices after touching hover is keeping and underline doesn't return to initial state. 
        So hover was replaced by active.*/
        .no-desktop & {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
                border: 0.5px solid $pink;
                visibility: visible;
                transition: all .3s ease-in-out 0s;
                transform-origin: 0%;
            }

            &:active {
                cursor: pointer;

                &:after {
                    visibility: hidden;
                    transform: scaleX(0);
                    transform-origin: 0% 50%;
                }
            }
        }
    }

    .refinement-header-products-count {
        float: right;
        margin-bottom: 0;
        font-size: rem(14);
        line-height: rem(26);
        color: $hel-gray;
    }
}

.ref-level-1 .refinement,
.ref-level-1 .refinements__item {
    h3 {
        font-size: rem(14);
        font-weight: $bold;
        color: $black02;
        border-bottom: 1px solid $pink;
        padding: 11px 15px 10px;

        &::after {
            display: none;
        }
    }
}

.search-refinement-item-tools-counter {
    color: $hel-gray;
    margin-left: 10px;

    @include respond-to(m_tablet_down) {
        margin-left: 0;
        color: $black;
        font-size: rem(14);
        font-weight: bold;
    }

    .expanded & {
        color: $pink;
    }

    &.search-refinement-item-tools-selected-pricerange {
        font-size: rem(14);

        @include respond-to(m_tablet_down) {
            display: none;
        }
        
        @include respond-to(m_desktop) {
            font-size: rem(10);
        }
    }
}

.selected-refinements__items {
    @include respond-to(m_tablet_down) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 250px;
    }
}

.selected-refinements__items_empty {
    @include respond-to(m_desktop) {
        display: none;
    }
}

.search-refinement-item-tools-name {
    @include respond-to(m_tablet_down) {
        font-size: rem(14);
        font-weight: bold;
        margin-bottom: 5px;
        display: inline-block;
    }
}

.selected-refinements__item {
    color: $hel-gray;
    font-size: rem(12);
    display: none;

    .toggle & {
        @include respond-to(m_tablet_down) {
            display: inline;
        }
    }
}

.search-brand-refinements-nomatch {
    display: block;
    font-size: rem(12);
    padding: 0 15px;
}

.category-refinement {
    margin-top: 36px;
}

.category-refinement h3.toggle {
    display: block;
    font-size: rem(14);
    font-weight: $bold;
    margin: 0 0 30px 15px;
}

.category-refinement li {
    margin-bottom: 0;
}

.category-refinement .category-level-1 > li {
    font-weight: $medium;
    font-size: rem(12);
    line-height: 1;
    margin: 0 0 27px 30px;
}

.category-refinement .category-level-1 > li .category-link {
    width: auto;
    padding: 0;
}

.category-refinement .category-level-1 li .toggle {
    position: relative;
    padding-right: 43px;

    &::after {
        @include inline-svg-mono($plus);

        content: "";
        width: 10px;
        height: 10px;
        right: 20px;
    }
}

.category-refinement .category-level-1 li .expanded {
    &::after {
        @include inline-svg-mono($minus);
    }
}

.category-level-2 {
    margin: 17px 0 0 22px;
}

.category-level-2 .category-link {
    font-size: rem(12);
    font-weight: $regular;
    margin-bottom: 18px;
}

.horizontal-wrapper {
    .refinements {
        .category-banner {
            @include respond-to(m_tablet_down) {
                margin: 30px 0;
            }

            @include respond-to(m_desktop) {
                margin-bottom: 30px;
            }
        }
    } 

    .primary-content {
        width: 100%
    }

    @include respond-to(m_desktop) {
        .js-search-refinements-container::before {
            content: "horizontal"; // this is a marker for JS to let it know that horizontal refinements rendered
            display: none;
        }

        #secondary {
            &.secondary-content {
                &.refinements {
                    width: 100%;
                }
            }
        }

        .search-refinements-inner {
            margin: 0 -15px;

            &.sticky-refinements {
                position: fixed;
                top: 36px; // height of sticky header
                left: 0;
                right: 0;
                background-color: $white;
                border-bottom: 1px solid $lighter-gray;
                z-index: 10;
                padding: 0 15px;

                @include respond-to(widedesktop_over) {
                    top: 39px; // height of sticky header
                    padding: 0;
                }

                .refinements__wrapper,
                .search-refinement  {
                    max-width: 1230px;
                }

                .refinements__wrapper {
                    margin: 20px auto;
                }

                .search-refinement {
                    margin: 0 auto 20px;
                }
            }
        }

        .search-refinement {
            padding: 0 15px 5px;
        }
       
        .refinements__wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }

        .refinements__item {
            position: relative;
            width: calc(20% - 30px);
            margin: 0 15px 15px;
            border: none;

            &:nth-child(5n) {
                .refinements__content {
                    right: 0;
                }
            }

            &.hidden-refinement {
                border-bottom: 0;

                .toggle::after {
                    @include respond-to(m_desktop) {
                        transform: translateY(-50%);
                        top: 51%;
                    }
                }
            }

            .scrollable-refinement {
                margin-top: 0;
            }

            h3 {
                &.toggle {
                    height: 48px;
                    padding: 15px;
                    border: 1px solid $white;
                    border-bottom: 1px solid $hel-gray;

                    @include respond-to(desktop) {
                        padding: 15px 5px;

                        .search-refinement-item-tools-counter {
                            margin-left: 5px;
                        }

                        &::after {
                            right: 5px;
                        }
                    }
                    

                    &.expanded {
                        border: 1px solid $hel-gray;
                        border-bottom: 1px solid $transparent;

                        @include respond-to(desktop) {
                            &::after {
                                right: 5px;
                            } 
                        }

                        &::before {
                            content: '';
                            width: 100%;
                            position: absolute;
                            left: 0;
                            top: 100%;
                            z-index: 6;
                            border: 3px solid $white;
                        }
                    }
                }
            } 
            
            .refinements__content {
                position: absolute;
                top: 47px;
                min-width: 392px;
                max-width: 100%;
                padding: 10px 15px;
                z-index: 5;
                background-color: $white;
                border: 1px solid $hel-gray;

                @include respond-to(m_desktop) {
                    padding: 20px 15px 27px;
                }
            }
            
            .search-refinement-price-range {
                padding: 40px 10px 50px;

                @include respond-to(m_tablet_down) {
                    padding: 29px 27px;
                }
            }
        } 

        .refinements__wrapper--large {
            .refinements__item {
                width: calc(16.6% - 30px);

                h3.toggle,
                .expanded {
                    padding: 15px 5px;
                    @include respond-to(desktop) {
                        padding: 12px 5px;
                    }

                    &::after {
                        right: 5px;
                    }

                    @include respond-to(desktop) {
                        font-size: rem(11);
                        height: inherit;
                    }
                }

                .search-refinement-item-tools-counter {
                    margin-left: 0;
                }
            }
        }

        .refinement-bottom,
        .refinement-header--desktop,
        .refined-clear-all--vertical-and-mobile {
            display: none;
        }

        .refined-clear-all {
            border: 1px solid $grey03;

            .refined-clear-all__icon {
                display: none;
            }

            .refined-clear-all__text {
                margin-left: 0;
            }
        }
    }
}

.vertical-wrapper {
    .refinements {
        .category-banner {
            margin-top: 30px;

            @include respond-to(m_tablet_down) {
                margin-bottom: 10px;
            }
        }
    }

    .search-refinement {
        @include respond-to(m_desktop) {
            display: none;
        }
    }

    .refinement-header--desktop {
        border-bottom: 1px solid $pink;
        padding-bottom: 15px;
        margin-top: 20px;
    }

    .filtered-refinement-item {
        .search-refinement-item-tools-name,
        .search-refinement-item-tools-counter {
            color: $pink;

            @include respond-to(m_tablet_down) {
                color: $black;
            }
        }
    }
}

.refinement .expanded,
.refinements__item .expanded {
    .search-refinement-item-tools-name {
        @include respond-to(m_tablet_down) {
            color: $black02;
        }
    }
}

.refined-clear-all--horizontal,
.refinement-header--desktop {
    @include respond-to(m_tablet_down) {
        display: none;
    }
}

.refinement-header--mobile,
.refinement-header-return-link {
    @include respond-to(m_desktop) {
        display: none;
    }
}

.refinement-header-box__inner {
    @include respond-to(m_tablet_down) {
        overflow: hidden;
        padding: 27px 15px 22px;
        border-bottom: 1px solid $pink;
    }
}

.refinement-header--mobile {
    @include respond-to(m_tablet_down) {
        float: left;
        font-size: rem(16);
        font-weight: $bold;
        color: $black02;
    }
}

.refined-clear-all--vertical-and-mobile {
    @include respond-to(m_tablet_down) {
        float: right;
    }

    @include respond-to(m_desktop) {
        margin: 20px 0;
    }

    .refined-clear-all__icon {
        @include respond-to(m_tablet_down) {
            display: none;
        }
    }
}

.refinements--chanel {
    .search-refinement-price-range .ui-slider-range {
        background-color: $black01;
    }

    .refinement-item:before {
        color: $black02
    }

    @include respond-to(m_desktop) {
        .refinement-header--desktop,
        .refinement,
        .refinements__item {
            border-bottom-color: $grey01;
        }

        .refinement .toggle,
        .refinements__item .toggle,
        .filtered-refinement-item .search-refinement-item-tools-name,
        .filtered-refinement-item .search-refinement-item-tools-counter,
        .search-refinement-item-tools-counter {
            color: $night-rider;
        }

        .refinement,
        .refinements__item {
            &:not(.hidden-refinement) .toggle:after {
                color: $black01;
            }
        }

        .refinement-item:hover,
        .scrollable-refinement li.selected .refinement-item {
            color: $grey04;
        }
    }

    @include respond-to(m_tablet_down) {
        .refinement-header-box__inner {
            border-bottom-color: $grey02;
        }

        &.ref-level-1 .refinement h3,
        &.ref-level-1 .refinements__item h3 {
            border-bottom-color: $grey01;
        }

        .filtered-refinement-item .search-refinement-item-tools-name,
        .filtered-refinement-item .search-refinement-item-tools-counter,
        .search-refinement-item-tools-counter,
        .scrollable-refinement li.selected .refinement-item {
            color: $black02;
        }

        .refinement,
        .refinements__item {
            .toggle:after {
                color: $grey04;
            }
        }
    }
}


.divisions-ribbon {
    text-align: center;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

    &-wrapper {
        position: relative;
    }

    @include respond-to(tablet_plus) {
        padding: 10px 0 20px;
    }

    @include respond-to(m_tablet_down) {
        padding: 20px 0px;
        width: 105%;
        white-space: nowrap;
        overflow-x: auto;
    }

    .division-item {
        margin-right: 10px;
        padding: 10px 12px 10px 10px;
        font-size: rem(10);
        line-height: 1;
        text-transform: uppercase;
        border: 1px solid $gray;
        border-radius: 4px;
        display: inline-block;
        vertical-align: middle;

        @include respond-to(tablet_plus) {
            margin-bottom: 10px;
        }

        &:hover {
            border-color: $dim-gray;
            color: $black01;
        }

        &.selected {
            background-color: $black01;
            color: $white;
        }
    }

    &.show-all .show-more {
        display: none;
    }

    .show-more {
        @include link_underline($pink);

        @include respond-to(tablet_plus) {
            display: inline-block;
            vertical-align: super;
        }

        @include respond-to(m_tablet_down) {
            display: none;
        }
    }

    &:not(.show-all) {
        .division-item:nth-of-type(n + 5) { // "More" link - shown on desktop only if there are more than 4 divisions
            @include respond-to(tablet_plus) {
                display: none;
            }
        }
    }

    .left-chevron,
    .right-chevron {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 20px;
    }

    .right-chevron {
        @include icon(simple-arrow-right);

        right: -20px;
        background: linear-gradient(to right, rgba($white,0) 0%,rgba($white,1) 100%);
    }

    .left-chevron {
        @include icon(simple-arrow-left);

        left: -20px;
        background: linear-gradient(to left, rgba($white,0) 0%,rgba($white,1) 100%);
    }

    &.scrollable {
        .left-chevron,
        .right-chevron {
            display: block;
        }

        &.at-begin {
            .left-chevron {
                display: none;
            }
        }

        &.at-end {
            .right-chevron {
                display: none;
            }
        }
    }
}
#addresses {
    @extend %clearfix;
    margin-bottom: 25px;

    @include respond-to(mobile) {
        @include flexbox;
        @include flex-vertical;
    }

    .address_title {
        float: left;
        margin-top: 15px;
        font-size: rem(18);
        font-weight: 500;
        line-height: 45px;

        @include respond-to(mobile) {
            @include order(2);
        }
    }

    .address-create {
        @include respond-to(mobile) {
            width: auto;
            @include order(1);
        }
    }

    .address-list {
        @include respond-to(mobile) {
            @include order(3);
        }
    }

    .mini-address-location {
        margin-bottom: 20px;
        font-size: rem(14);
    }

    .address-action-link,
    .adress-actions a {
        color: $nobel;
        font-size: rem(14);
    }
}

.account-address-banner {
    padding: 30px 0;
    margin-bottom: 20px;
    text-align: center;
    background: $my-account-header-banner-bg;
    border-radius: 4px;

    p {
        display: block;
        width: 60%;
        margin: 20px auto 0;
    }
}

.address-create {
    display: block;
    float: right;
    margin: 15px 0 25px;

    @include respond-to(mobile) {
        float: left;
    }
}

.addresslist-header {
    background-color: $white-smoke;
    margin-bottom: 25px;
    padding: 30px 25px;
    text-align: center;
}

.address-list,
.payment-list {
    @extend %clearfix;
    display: block;
    margin: 0 0 25px;
    padding: 0;

    li {
        float: left;
        list-style-type: none;
        margin: 0 25px 0 0;
        padding: 25px 0;
        width: 100%;
        border-top: 1px solid $very-light-gray;

        @include respond-to (mobile) {
            margin: 0 0 25px;
        }

        .mini-address-title {
           display: none;
        }

        .mini-address-title {
            font-size: rem(22);
            font-weight: 500;
            text-transform: uppercase;
        }

        .mini-address-name {
            font-weight: 500;
            margin-bottom: 5px;
        }

        .address-make-default,
        .default-address {
            display: block;
            float: right;

            @include respond-to(mobile) {
                float: left;
                margin: 25px 0;
            }
        }

        .address-make-default {
            @include respond-to(mobile) {
                margin: 20px 0 0;
            }
        }

        .default-address {
            cursor: default;

            @include respond-to(mobile) {
                width: auto;
            }
        }

        .address-delete {
            margin-left: 40px;
        }

        .adress-actions {

            @include respond-to(mobile) {
                display: block;
                float: left;
                width: 100%;
            }
        }

        address {
            font-style: normal;

            br {

                @include respond-to(tablet_plus) {
                    display: none;
                }
            }
        }

        a,
        .button-text {
            line-height: 2rem;
            padding-right: .2rem;
        }
    }

    .cc-owner {
        font-weight: 700;
    }

    .payment-list__item {
        border-top: none;
        margin: 0;
        padding-right: 10px;
        width: 33.333%;

        @include respond-to(mobile) {
            float: left;
            padding-bottom: 0;
            padding-right: 0;
            width: 48%;
            margin: 0 1%;
        }
    }
}

.address-list {
    li {

        &:last-of-type {
            border-bottom: 1px solid $very-light-gray;
            margin-bottom: 25px;
        }
    }
}

/* Add adress popup */
#edit-address-form {
    .address-list .form-row {
        width: 100%;
    }

    .apply-button {
        float: right;
    }

    .dialog-action-buttons {
        margin-left: 27%;

        @include respond-to (mobile) {
            margin-left: 0;
        }

        .button.space-bottom {

            @include respond-to(mobile) {
                margin-bottom: 25px;
            }
        }
    }
}

.edit-adress-form {
    h1.edit-address-title {
        color: $nobel;
        font-size: rem(30);
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;

        &:after {
            border: 0;
        }

        span {
            display: inline-block;
            position: relative;

            &:after {
                border-bottom: 1px solid;
                content: "";
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                top: 100%;
                width: 50%;
            }
        }
    }

    .dialog-action-buttons {
        margin-bottom: 20px;
    }

    .form-caption-before {
        position: relative;

        label,
        .field-wrapper {
            margin-top: 25px;

            @include respond-to(mobile) {
                margin-top: 30px;
            }
        }

        .form-caption {
            position: absolute;
            top: 0;

            @include respond-to(mobile) {
                line-height: 1em;
            }
        }
    }
}

/* Address popup */
.address-popup .ui-dialog-title:after {
    display: none;
}
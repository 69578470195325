#secondary.secondary-content.refinements {
    width: 23%;
    max-width: 100%;
    max-width: 1230px;

    @include respond-to(m_tablet_down) {
        max-width: 100%;
        width: 100%;
    }

    .category-banner {
        @include respond-to(m_tablet_down) {
            text-align: center;
        }
    }
}

#secondary.secondary-content.refinements + .primary-content {
    @include respond-to(m_tablet_down) {
        width: 100%;
    }
}

.category-banner img {
    max-width: 100%;
}

.noresults-refinement-category {
    @include respond-to(mobile) {
        display: none;
    }
}

.secondary-navigation.content-nav {
    background: $white;
    border: 1px solid $very-light-gray;
    padding: 0 10px;
    border-radius: 4px;

    > .toggle-nav {
        border-top: none;
        font-size: rem(14);
        font-weight: 700;

        &::after {
            right: 0px;
            top: 27px;
        }
    }

    @include respond-to(mobile) {
        background: none;
        border: none;
        padding: 0;

        > .toggle-nav {
            border: 1px solid $very-light-gray;
            margin-bottom: 12px;
            padding: 12px 16px;
            font-weight: 500;
            font-size: rem(16);
            background: $light-gray;
            text-transform: none;

            &:hover {
                color: $night-rider;
            }

            &::after {
                right: 15px;
                top: 50%;
            }
        }

        li {
            a {
                position: relative;
                display: block;
                border-top: 1px solid $very-light-gray;
                padding: 12px 16px;
                font-weight: 500;
                margin-bottom: -0.58em; // this will overlap !important derective for li
            }
        }
    }
}

#category-level-1 {
    li {
        color: $night-rider;
        display: block;

        .refinement-link {
            background: none repeat scroll 0 0 transparent;
        }

        &.expandable {
            .active {
                font-weight: bold;
                padding: 0 0 0 6px;
                font-size: rem(13);
            }
        }
    }
}

#category-level-2 {
    a {
        &.active {
            color: $night-rider;
            font-weight: 700;
        }

        &.refinement-link {
            margin: 0 0 0 .4em;
            padding: 0;
        }
    }
}

#category-level-2 {
    padding: .5em .1em 0 .7em;
}

#category-level-3 {
    padding: .3em 0 0;

    a {
        &.refinement-link {
            font-style: normal;
            margin: 0 0 0 .9em;
            padding: 0;
        }

        &.active {}
    }
}

.breadcrumb-refined-by-box {
    margin-bottom: 50px;

    @include respond-to(mobile) {
        margin: 30px 0 10px;
    }
}

.breadcrumb-refined-by {
    display: block;
    font-size: rem(18);
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 16px;
}

.breadcrumb-refined-clear-all {
    float: right;
    font-size: rem(14);
    text-decoration: underline;
    color: $dim-gray;
    cursor: pointer;

    @include respond-to(mobile) {
        font-size: rem(15);
        text-transform: uppercase;
        margin-top: 3px;
    }
}

.breadcrumb-refinement {

}

.breadcrumb-refinement-value {
    display: block;
    font-size: rem(14);

    @include respond-to(mobile) {
        display: inline-block;
        border: 1px solid $gainsboro;
        font-size: rem(15);
        font-weight: 500;
        padding: 5px 10px;
        margin: 0 10px 10px 0;
        vertical-align: middle;
    }

    .bticons-close:before {
        color: $black;
        font-weight: 700;
        font-size: rem(10);
    }
}

.breadcrumb-relax {
    background: none;
    font-style: normal;
    font-size: rem(9);
    font-weight: bold;
    padding: 0 6px 0 0;
}

.breadcrumb-result-text a,
.breadcrumb .searchphraselink {
    background: none;
    padding: 0;
}


.refinement-header-box {
    @extend %clearfix;
    margin: 0;
}

.refinement-header {
    display: block;
    font-size: rem(16);
    font-weight: 700;

    + .refinement-header {
        color: $black;
        font-size: rem(18);
        margin-top: 22px;
    }
}

.refinement-header-expand {
    float: right;
    font-size: rem(14);
    text-decoration: underline;
    color: $dim-gray;
    cursor: pointer;
    display: none;

    @include respond-to(mobile) {
        font-size: rem(15);
        text-transform: uppercase;
    }
}

.refinement {
    @extend %clearfix;
    position: relative;
    border-bottom: 1px solid $gainsboro;
    padding-bottom: 10px;

    &.hidden-refinement {
        padding-bottom: 0;
    }

    &.brand {
        .refinement-item {
            text-transform: uppercase;
        }
    }

    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
        display: none;

        &.category-refinement {
            display: block;
        }
    }

    h3 {
        position: relative;
        font-size: rem(16);
        padding: 15px 2px 15px 0;
        text-transform: capitalize;
        color: $night-rider;
        transition: color .2s ease-in-out;

        &:hover {
            color: $dim-gray;
        }

        @include respond-to(mobile) {
            padding: 13px 2px 13px 0;
            font-weight: 500;
            font-size: rem(12);
        }

        &:after {
            font-size: rem(23);
        }
    }

    ul {
        list-style: none outside none;
        margin: 0;
        padding-bottom: 10px;
    }

    li {
        @extend %clearfix;
        padding: 8px 0;

        &:before {
            @include respond-to(mobile) {
                display: none;
            }
        }
    }

    ul {
        li {
            i {
                color: $gainsboro;
                padding-right: 6px;
            }
        }

        &.refinementColor li,
        &.size li {
            float: none;
        }

        &.display-refinement-color-swatches {
            li a {
                .refinement-swatch,
                img {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: $checkbox-size;
                    height: $checkbox-size;
                    vertical-align: middle;
                }
            }
        }
    }

    .clear-refinement {
        display: none;
        text-decoration: underline;
        font-size: rem(14);
        padding-top: 22px;

        &:hover {
            text-decoration: none;

            a {
                color: $nobel;
            }
        }

        a {
            color: $dim-gray;
        }
    }

    .refinement-top-level {
        padding: 1em;
    }
}

.show-mobile-refinements,
.close {
    display: none;
}

.search-refinements-container .refinement {
    transition: max-height .3s ease;
    max-height: 25000px; // to avoid cropping long list
    overflow: hidden;

    @include respond-to(tablet_plus) {
        .search-refinements-inner {
            padding-bottom: 50px;   // offset for sticky refinement (=height of sticky footer)
        }
    }

    ul {
        transition: max-height .3s ease, margin-top .3s ease, margin-bottom .3s ease;
    }

    &.hidden-refinement {
        max-height: 100px;

        ul {
            max-height: 0;
            margin-top: 0;
            margin-bottom: 0;
            overflow: hidden;
            padding-bottom: 0;
        }
    }
}

.category-refinement {
    margin-top: 20px;
    border-bottom: 0;

    h3.toggle {
        display: none;
    }

    li {
        padding: 0;
        margin-bottom: 6px;
    }

    .category-level-1 {
        .expandable.fa-angle-right:before {
            display: none;
        }

        > li {
            display: block;
            font-weight: 500;
            font-size: rem(14);
            line-height: 24px;

            .category-link {
                padding: 10px;
                display: inline-block;
                width: 100%;
                color: $black02;
            }

            .category-level-3,
            .category-level-4 {
                padding-left: 10px;
                margin-bottom: 0px;
            }
        }
    }

    .category-level-2 {
        li {
            .category-link {
                font-weight: normal;
                padding: 5px 10px 5px 20px;
            }
        }
    }
}

@include respond-to(m_tablet_down) {
    .refinements-overlay {
        box-shadow: rgba(0, 0, 0, 0.74902) -50px 0px;
    }

    .search-refinements-container {
        transform: translateX(-100%);
        transition: transform 0.6s;
        position: fixed;
        top: 0 !important;
        z-index: $z-refinements-mobile;
        background: $white;
        left: 0;
        bottom: 0;
        width: 85% !important;
        height: 100% !important;
        max-width: 312px;

        .close {
            width: calc(var(--vw) - 312px);
            height: $ref-close-size;
            position: absolute;
            top: 0;
            left: 312px;
            font-size: rem(24);
            line-height: $ref-close-size;
            color: $white;
            text-align: center;
            background: $nobel;

            &:before {
                @include inline-svg-mono($cancel, $white);

                content: "";
                width: 30px;
                height: 30px;
                position: absolute;
                top: 50%;
                margin-top: -15px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -$ref-overlay-width;
                width: $ref-overlay-width;
                height: $ref-close-size;
                display: block;
                background: $nobel;
            }
        }
    }

    .search-refinements-active {
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 100%;
            top: 60px;
            width: 800px;
            height: 100%;
            background: rgba($black, .3);
        }
        
        .close {
            display: block;
        }
    }

    .show-mobile-refinements {
        width: 50%;
        height: 58px;
        position: relative;
        float: left;
        display: inline-block;
        padding: 0 35px 0 15px;
        font-size: rem(12);
        font-weight: $bold;
        line-height: 58px;
        text-transform: uppercase;
        border-right: 1px solid $grey02;
        cursor: pointer;
        outline: none;

        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 1px);
            right: 20px;
            display: inline-block;
            padding: 3px;
            border: solid $black01;
            border-width: 0 2px 2px 0;
            transform: rotate(-45deg) translateX(50%);
        }
    }
}

.search-refinements-inner {
    @include respond-to(m_tablet_down) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        padding: 0 20px;
        overflow-x: hidden;
    }
}

.is-sticky .search-refinements-container {
    padding-top: 40px;

    @include respond-to(mobile) {
        padding-top: 0;
    }
}

.scrollable-refinement {
    max-height: 7.1em;
    overflow: auto;

    @include respond-to(mobile) {
        max-height: none;
    }

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: $requirements-scrollbar-track-color;
        border: 4px solid transparent;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb {
        background: $requirements-scrollbar-color;
        border: 1px solid $requirements-scrollbar-color;

        .chanel-section & {
            background: $black;
            border-color: $black;
        }
    }
}

.refinement-view-type {
    float: right;
    text-align: right;
    padding: 22px 2px 0 0;
    font-size: rem(14);
    text-decoration: underline;
    color: $dim-gray;
    cursor: pointer;

    @include respond-to(mobile) {
        display: none;
    }

    &:hover {
        color: $nobel;
        text-decoration: none;
    }

    .collapse-all {
        display: none;
    }

    &.show-collapse {
        .collapse-all {
            display: block;
        }

        .show-all {
            display: none;
        }
    }
}

.refinements {
    &-no-result {
        padding-bottom: 0;

        #category-level-1 li.expandable a.refinement-link {
            padding-left: 0;
        }

        h3 {
            border-top: 1px solid $gainsboro;

            &:first-child {
                border-top: 0;
            }
        }

        .toggle.expanded:after {
            content: '+';
            border-bottom: 0;
            margin-top: -15px;
        }
    }
}

.nav {
    ul {
        border: 0 none;
        list-style: none outside none;
        margin: .58em 0;
        padding: 0;

        li {
            list-style: none outside none !important;
            margin: 0 0 15px;
        }
    }

    .toggle {
        border: 0 none;
        display: block;
        font-size: 1em;
        font-weight: 700;
        margin: .83em 0 .58em;
        text-transform: uppercase;
        
        span {
            background: none repeat scroll 0 0 transparent;
        }
    }
    
    .expanded {
        background-position: left -96px;
    }
}
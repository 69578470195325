.int-country-selector-dialog {
    &.ui-dialog {
        @include respond-to(mobile_strong) {
            left: 0 !important;
            top: auto !important;
            bottom: 0;
            max-width: none;
            position: fixed;
            width: 100% !important;
        }

        .ui-dialog-titlebar-close {
            top: 5px;
            right: 5px;
        }
    }

    .ui-widget-header.ui-dialog-titlebar {
        padding: 0;
    }

    .ui-dialog-title {
        display: none !important;
    }
}

.int-country-selector-wrapper {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 9px;
        left: 10px;
        width: 25px;
        height: 20px;

        @include respond-to(mobile_strong) {
            width: 22px;
        }
    }
}

.int-country-selector-select {
    padding-left: 15px;
}

.int-country-selector-dialog-title {
    color: $black02;
    font-size: rem(16);
    font-weight: 600;
    line-height: 1.5;
    padding-top: 25px;
}

.int-country-selector-dialog-description {
    color: $grey05;
    font-size: rem(12);
    line-height: 1.5;
    margin: 15px 0 20px;
}

.int-country-selector-dialog-button {
    margin: 20px 0;

    .button {
        font-size: rem(12);
        width: 100%;
        padding: 20px;

        @include respond-to(mobile_strong) {
            padding: 15px 20px;
        }
    }
}

.int-country-selector-dialog-policy {
    color: $grey05;
    font-size: rem(10);
    line-height: 1.5;
    margin-top: 5px;
}

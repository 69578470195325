.video-panel {
    @include full-page-border;
    
    margin-bottom: 96px;

    @include respond-to(mobile_strong) {
        margin-bottom: 44px;
    }

    .amp-video-panel.standard {
        margin-bottom: 0;
    }

    &_title {
        font-size: rem(24);
        letter-spacing: 0.6px;
        margin: 32px 0 24px;
        text-transform: inherit;

        @include respond-to(mobile_strong) {
            font-size: 20px;
            margin: 16px 0;
        }
    }

    &_description {
        margin-bottom: 24px;

        @include respond-to(mobile_strong) {
            margin-bottom: 16px;
        }
    }

    &_link {
        @include link_underline($pink);

        font-size: rem(14);
    }
}

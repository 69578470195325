.product-bundle__asset {
    width: 100%;
    float: left;
}

.product-bundle-list {
    float: left;
    width: 100%;
    text-align: center;

    h2 {
        text-transform: capitalize;
        text-align: center; 
        margin-bottom: 35px;
        padding: 0;
        font-weight: 500;
        letter-spacing: 1px;

        span {
            display: inline-block;
            position: relative;
        }
    }
    
    &__item-list {
        display: flex;
        justify-content: center;
        align-items: stretch;
        align-content: flex-start;
        flex-wrap: wrap;
    }

    .product-bundle-item {
        max-width: calc(100% / 6);
        flex-basis: calc(100% / 6);
        margin-bottom: 50px;
        padding: 0 6px;

        a {
            font-size: rem(16);
            text-transform: uppercase;
        }

        @include respond-to(mobile) {
            max-width: 50%;
            flex-basis: 50%;
        }

        .item-name {
            font-size: rem(15);
            text-transform: none;
        }
    }

    .product-bundle-image {
        @include lazyload-animation;

        position: relative;
        width: 65%;
        padding-bottom: 65%;
        margin: 0 auto;

        .product-thumbnail {
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .product-bundle-show-more {
        font-family: $font-main;
        margin-top: 30px;
        margin-bottom: 50px;
        display: inline-block;
        padding: 0 22px;
        letter-spacing: 1px;
        line-height: 45px;
        text-align: center;
        color: $nobel;
        font-weight: 500;
        text-transform: uppercase;
        font-size: rem(15);
        border:1px solid $nobel;
        cursor: pointer;
        transition: .3s;

        &:active,
        &:hover {
            background: $nobel;
            color: $white;
        }
    }

    .product-bundle-details {
        padding: 0 10px;
        margin: 10px 0;

        .product-name-brand {
            display: block;
            font-size: rem(14);
            padding-top: 3px;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 1.1;
            color: $nobel;
        }

        .item-name {
            font-size: rem(12);
            font-weight: 400;
            line-height: 1.4;
            margin: 6px 0;
            color: $hel-gray;
            display: block;
        }
    }
}
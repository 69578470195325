/* My contact preferences */
.preferences-centre {
    margin-top: 25px;
    position: relative;

    .error-form {
        text-transform: none;
        font-size: rem(18);
    }

    .preferences-title {
        display: block;
        margin-top: 15px;
        font-weight: 400;
        font-size: rem(14);
        width: 70%;
        line-height: 1.5;

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .preferences-message {
        margin: 0;

        &-wrap {
            margin: 20px 0;
        }

        &.preferences-message-phone {
            @include hidden;
        }
    }

    .contact-cookies {
        @include hidden;
    }

    .form-row.form-indent {
        display: inline-block;
        margin: 15px 5% 0 0;
    }

    button {
        display: block;
        margin: 20px 0 10px;

        @include respond-to(mobile) {
            margin: 20px auto 10px;
        }
    }

    .label-inline {
        label {
            font-size: rem(14);
        }
    }

    .preference-notes {
        margin-bottom: 30px;
        font-size: rem(14);
        color: $hel-gray;
        width: 75%;

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .preference-category {
        width: 460px;

        @include respond-to(mobile) {
            width: 100%;
        }

        &-columns-view {

            &:after {
                content: '';
                display: block;
                width: 100%;
                clear: both;
            }

            .category-column {
                width: 50%;
                float: left;

                @include respond-to(mobile_min) {
                    width: 100%;
                    float: none;
                }

                &:first-child:last-child {
                    width: 100%;
                    float: none;
                }
            }

            .form-row.form-indent {
                width: 100%;
                margin-right: 15px;
            }
        }

        &-row-view {
            .form-row.form-indent {
                @include respond-to(mobile_mid) {
                    width: 135px;
                }
            }
        }
    }
}
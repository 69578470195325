.breadcrumb {
    font-size: rem(12);
    line-height: 2rem;
    text-align: center;
    padding: 0.5rem;

    @include respond-to(mobile_strong_over) {
        text-transform: lowercase;
    }

    @include respond-to(mobile) {
        .the-search-result &,
        .pt_product-search-noresult & {
            display: none;
        }
    }

    li {
        display: inline-block;
        list-style: none outside none;
    }

    a {
        display: inline-block;
        color: $dim-gray;
        padding: 0.5rem 0 0.5rem 0.4rem;

        &:hover {
            color: $night-rider;
        }
    }
}

.pt_account,
.pt_order,
.pt_wish-list {
    .breadcrumb {
        text-transform: capitalize;
    }
}

.pt_content {
    .breadcrumb {
        text-transform: none;
    }
}

.searchresult-phrase {
    color: $search-result-banner-text-color;
    font-size: rem(15);
    text-align: center;
    padding: rem(15) 0 rem(5) 0;
    margin-bottom: 0;
    display: none;

    @include respond-to(mobile) {
        padding: rem(15) 0 0 0;
    }

    .the-search-result &,
    .pt_product-search-noresult & {
        display: block;
    }

    a {
        color: $nobel;

        &:hover {
            color: $nobel;
        }
    }

    .res-text-breadcrumb {
        display: inline-block;
        color: $nobel;
        position: relative;
        margin: 0 auto;
        padding-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 500;

        &:after {
            content: "";
            border-bottom: 1px solid $search-result-banner-text-color;
            width: 80%;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: absolute;
            top: 100%;
        }

        i {
            display: block;
            margin: 0 auto 1rem auto;
            font-size: rem(30);
            font-weight: bold;
        }
    }

    .phrase-breadcrumb {
        display: block;
        padding: 10px 0 5px;
        font-family: $font-second;
        font-size: rem(24);
        line-height: 35px;
        text-transform: capitalize;
    }

    .suggest-breadcrumb {
        display: inline-block;
        padding: 0 0 1.5rem 0;
    }
}

.paging-current-page {
    left: -999em;
    position: absolute;
}

.breadcrumb-element-wrapper {
    display: inline-block;

    &:last-child {
        .breadcrumb-element {
            color: $black;
            cursor: default;
            font-weight: bold;

            &:after {
                content: '';
                display: none;
            }
        }
    }
}

.pt_product-details {
    .breadcrumb {
        @include respond-to(tablet_plus) {
            padding: 25px 0 10px;
        }
    }

    .breadcrumb-element-wrapper {
        @include respond-to(mobile_strong) { // Creating short breadcrumbs on Mobile
            display: none;
    
            &:nth-last-child(2) {
                display: block;
    
                .breadcrumb-element {
                    color: $black01;
    
                   &::before {
                        content: "< Back to ";
                    }
    
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.breadcrumb-element {
    &::after {
        content: "/";
    }

    &:last-child,
    &.breadcrumb-product-name {
        &:after {
            content: '';
            display: none;
        }
    }

    &::first-letter {
        text-transform: uppercase;
    }
}

.search-top-breadcrumbs {
    display: none;
    padding: 1.5rem 0;
    font-size: rem(14);
    color: $dim-gray;
    text-align: center;

    .the-search-result &,
    .pt_product-search-noresult & {
        display: block;
    }

    a {
        color: $dim-gray;
    }
}

.searchresults {
    display: none;
    text-align: center;
    color: $search-result-banner-text-color;
    padding: 0 0 1.5rem 0;

    .the-search-result &,
    .pt_product-search-noresult & {
        display: block;
    }

    a {
        color: $search-result-banner-link-color;

        &:hover {
            color: $search-result-banner-link-hover-color;
        }
    }
}

.slot-grid-header {
    padding: 0;
    text-align: center;

    &.full-width-block {
        @include full-page-content($scrollbar-fix: true);
    }

    .categories { //CATEGORIES IN TOP BANNER ONLY FOR PLP
        background-color: $westar;
        text-align: center;
        font-size: rem(12);
        position: relative;

        @include respond-to(m_desktop) {
            margin-left: -100%;
            margin-right: -100%;
            padding: 8px 100%;
        }

        @include respond-to(m_tablet_down) {
            margin-left: -15px;
            margin-right: -15px;
            padding: 8px 20px;
        }

        &-list {
            margin: 0;
        }

        li,
        .slick-slide {
            display: inline-block;
            margin: 0 30px 0 0;

            &:last-child {
                margin: 0;
            }
        }

        .category-link {
            display: inline-block;
            line-height: 35px;
            font-weight: $medium;
        }
    }

    .categories-arrow {
        display: none;

        &.next {
            @include icon(simple-arrow-right);

            right: 0;
        }

        &.prev {
            @include icon(simple-arrow-left);

            left: 0;
        }
    }

    .slick-arrow {
        &:not(.slick-disabled) {
            display: block !important;
        }

        &.slick-hidden {
            display: none !important;
        }

        transform: translateY(-50%);
        margin: 0;
        background-color: rgba(232, 227, 222, 1);

        &:before {
            font-size: rem(12);
            font-weight: bold;
        }
    }

    .cat-landing-slotlinks {
        margin: 20px 0 30px;
    
        .slick-arrow {
            border: 0;
            border-radius: 0;
            background-color: transparent;
            
            &::before {
                font-size: rem(32);
            }
    
            &::after {
                display: none;
            }
        }
    
        .slick-next {
            right: -20px;
        }
    
        .slick-prev {
            left: -20px;
        }
    
        .slick-track {
            margin: 0 auto;
        }
    }
    
    .category_item {
        width: 81%;
        margin: 0 auto;
        text-align: center;
        display: none; // hide till slick initialized
    
        &-image {
            margin-bottom: 20px;
            position: relative;
            padding-top: 100%;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    
        &-link {
            &:hover .category_item-title {
                opacity: 0.5;
            }
        }
    }

    .slick-initialized .category_item {
        display: block;
    }
}

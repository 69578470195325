.pt_storefront #main {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

.main-hp {
    @include respond-to(mobile) {
        padding-left: 0;
        padding-right: 0;

        .slot .carousel-wrapper {
            padding-bottom: rem(50);
        }
    }
}


.homepage-slider {
    overflow: hidden;
    position: relative;
    width: 100%;

    .jcarousel-control {
        bottom: 0.5rem;
        position: absolute;
        right: 0.5rem;

        a {
            background: none repeat scroll 0 0 $gainsboro;
            border: 1px solid $very-light-gray;
            display: block;
            float: left;
            height: 24px;
            margin: 0 3px;
            opacity: .5;
            text-indent: -999em;
            width: 24px;

            &.active {
                background-color: $dim-gray;
            }

            &:hover {
                background-color: $dim-gray;
                opacity: .7;
            }
        }
    }
}

.home-bottom-slots {
    margin: auto;
    max-width: 960px;

    .home-bottom-left,
    .home-bottom-center,
    .home-bottom-right {
        clear: none;
        width: 100%;

        @include respond-to(desktop_plus) {
            float: left;
            width: 33.333%;
        }
    }
}

.hp-new-arrivals {
    .slot {
        margin-bottom: 65px;
    }
}

/* Best Sellers */
.hp-best-sellers {
    .slot {
        margin-bottom: 65px;
    }

    .carousel {
        .jcarousel-nav {
            margin-top: 0;
            top: 25%;
            color: $black;
            background-color: $transparent;

            &:hover {
                opacity: 0.6;
                color: $black;
                background-color: $transparent;
                transition: opacity .2s ease-in-out;
            }

            &:before {
                font-size: rem(30);
            }
        }

        .jcarousel-next {
            @include icon(arrow-circle-right);
        }

        .jcarousel-prev {
            @include icon(arrow-circle-left);
        }
    }

    .product-tile {
        height: auto;
    }
}

/* New In & Be Inspired */
// @FIXME
// not sure but this part of code can potentially cause artifacts in many other places (not only "New In & Be Inspired")
// like "slot-panels-container" negative -20px left-margin I found in "header-banner"
.slot-panels {
    .slot,
    &.slot {
        margin-bottom: 65px;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }

    &-container {
        @extend %clearfix;
        margin-left: -40px;

        @include respond-to(tablet) {
            margin-left: -20px;
        }

        @include respond-to(mobile) {
            margin-left: 0;
        }
    }

    .asset {
        float: left;
        padding-left: 40px;
        width: 50%;

        @include respond-to(tablet) {
            padding-left: 20px;
        }

        @include respond-to(mobile) {
            float: none;
            margin: 0 auto 20px;
            padding-left: 0;
            width: 95%;
        }

        &-link {
            background-color: $white;
            display: block;
        }

        &-image_container {
            display: block;
        }

        &-image {
            display: block;
            max-width: 100%;
            border-radius: 4px;
        }

        &-info_container {
            display: block;
            padding: 15px 25px 15px 0px;
            text-align: left;

            @include respond-to(tablet) {
                padding: 18px 10px;
            }
        }

        &-text {
            display: block;
            margin-bottom: 10px;

            @include respond-to(mobile) {
                min-height: 0;
            }
        }

        &-title {
            display: block;
            font-size: rem(22);
            font-weight: 400;
            margin: 5px 0 10px;
            max-height: 58px;
            overflow: hidden;

            @include respond-to(mobile) {
                font-size: rem(20);
                max-height: none;
            }
        }

        &-description {
            color: $hel-gray;
            display: block;
            font-size: rem(14);
            max-height: 42px;
            overflow: hidden;
            text-transform: none;
            line-height: 1.5;

            @include respond-to(mobile) {
                font-size: rem(14);
                max-height: none;
            }
        }
    }

    &-four-box {
        .asset {
            width: 25%;
        }
    }

    &-three-box {
        .asset {
            width: 33.33333333%;
        }
    }

    &-three-box,
    &-four-box {
        .asset {
            padding-left: 6px;

            @include respond-to(mobile) {
                padding-left: 0;
                width: 95%;
                margin: 0 auto 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-title {
                font-size: rem(16);
                max-height: 50px;

                @include respond-to(mobile) {
                    max-height: 50px;
                }
            }
        }

        .slot-panels-container {
            margin-left: -6px;

            @include respond-to(mobile) {
                margin-left: 0;
            }
        }
    }
}

.global-recommendations {
    margin-bottom: rem(50);
}


// Product set
.is-product-set {

    &.is-sku-only .price-sales__now-text {
        display: inline-block;
    }

    &.is-sku-only {
        .product-pricing-now_word,
        .product-pricing-was_word {
            display: none;
        }
        .product-set {
            .size-guide-link {
                position: relative;
                width: 100%;
                text-align: right;
                text-decoration: none;
                font-size: 14px;
                line-height: 24px;
                margin: 10px 0 13px 0;
                padding-right: 15px;

                a {
                    border-bottom: 1px solid $pink;
                }

                @include respond-to(mobile) {
                    text-align: center;
                    margin: 20px 0 20px 0;
                }

                &-inner {
                    border-bottom: 1px solid $pink;
                }
            }

            &-sku-item {
                .product-price {
                    font-size: rem(12);
                    line-height: rem(15);
                    text-transform: capitalize;

                    .price-sales {
                        font-size: rem(12);
                        line-height: rem(15);

                        ~ .price-standard.was-price {
                            font-size: rem(10);
                            color: $charcoal;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .sku-details {
            .product {
                &-price {
                    font-size: rem(20);
                    line-height: rem(26);
                }

                &-sales-price {
                    font-size: rem(20);
                    line-height: rem(26);
                    display: block;
                    color: $black02;
                }

                &-standard-price {
                    display: block;
                    font-size: rem(12);
                    line-height: rem(15);
                    color: $charcoal;
                    text-decoration: line-through;

                    &.rrp-price {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .sku-details {
        .product-name-brand {
            text-transform: uppercase;
            font-size: 2.2rem;
            font-weight: 400;
            margin: 0 0 9px;
        }
    }
    .product-set {
        .pos-rel {
            position: relative;
        }

        .size-guide-link {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30%;
            text-align: center;
            text-decoration: underline;
            text-transform: uppercase;

            @include respond-to(tablet) {
                width: 35%;
            }

            @include respond-to(mobile) {
                position: static;
                width: 100%;
            }

            &__mobile {
                display: none;

                @include respond-to(mobile) {
                    display: block;
                }
            }

            &__desktop {
                @include respond-to(mobile) {
                    display: none;
                }
            }
        }

        .product-primary-image { // Changes order of elements on mobile
            @include respond-to(mobile) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
            }

            .product-thumbnails {
                @include respond-to(mobile) {
                    order: 2;
                }
            }

            .main-image {
                @include respond-to(mobile) {
                    order: 1;
                }
            }
        }

        .product-price {
            font-size: rem(22);
            font-weight: 500;
            line-height: 1;
            text-transform: capitalize;

            & > span:only-of-type {
                color: $black;
            }

            &-now_word {
                display: none;
            }


            .price-sales,
            .salesprice {
                color: $alizarin;
                display: block;

                &.has-rrp-price {
                    color: $nobel;
                }

                ~ .price-standard {
                    color: $night-rider;
                    font-size: rem(15);
                    display: inline-block;

                    &.rrp-price {
                        font-size: rem(10);
                        color: $dim-gray;
                    }
                }
            }
        }

        .product-set-list {
            .product-set-item {
                padding-left: 145px;
                padding-right: 5px;

                @include respond-to(tablet) {
                    padding-left: 90px;
                }
            }

            .product-set-image {
                @include respond-to(tablet) {
                    margin-left: -90px;
                    width: 90px;
                }
            }

            .product-variations,
            .product-options {
                margin-bottom: 20px;
            }

            .product-set-sku-item {
                &:last-child .product-set-sku-details {
                    border-bottom: 1px solid $lighter-gray;
                }

                .product-set-sku-details {
                    border-top: 1px solid $lighter-gray;
                    padding: 2px 0;
                }

                .product-set-error {
                    text-align: right;
                    width: 100%;
                    padding: 0;
                    display: block;

                    span,
                    span.error {
                        display: inline;
                        margin: 0px;
                        padding: 0px;
                        line-height: 22px;
                        font-size: rem(14);
                        font-weight: 400;

                        @include respond-to(tablet) {
                           font-size: rem(15);
                        }
                    }
                }

                .product-set-inner-cont {
                    @include flexbox();

                    align-items: center;
                    justify-content: space-between;
                    background: $white;
                    min-height: 50px;

                    &::after {
                        content: '';
                        min-height: inherit;
                        font-size: 0;
                    }

                    @include respond-to(mobile_mid) {
                        flex-wrap: wrap;
                        position: relative;
                    }

                    &.item-selected {
                        background: #efefef
                    }

                    .item-name {
                        flex-basis: 45%;
                        font-size: rem(15);
                        font-weight: 400;

                        @include respond-to(tablet) {
                            flex-basis: 40%
                        }

                        @include respond-to(mobile) {
                            flex-basis: 50%;
                            text-align: left;
                        }

                        @include respond-to(mobile_mid) {
                            flex-basis: calc(100% - 160px);
                        }

                        @include respond-to(mobile_min) {
                            flex-basis: 65%;
                        }
                    }

                    .product-price,
                    input.error {
                        margin-bottom: 0;
                        min-width: 0;
                    }

                    .product-price {
                        flex-basis: 25%;
                        text-align: center;
                        font-weight: 500;

                        @include respond-to(tablet) {
                            flex-basis: 30%
                        }

                        @include respond-to(mobile) {
                            flex-basis: 20%
                        }

                        @include respond-to(mobile_mid) {
                            flex-basis: calc(100% - 160px);
                            text-align: left;
                        }

                        @include respond-to(mobile_min) {
                            flex-basis: 65%;
                        }

                        .price-standard {
                            &::after {
                                display: none;
                            }
                        }
                    }

                    .item-out-of-stock {
                        flex-basis: 160px;
                        font-size: rem(15);
                        text-align: center;
                    }

                    .item-out-of-stock,
                    .quantity_selector {
                        @include respond-to(mobile_mid) {
                            flex-basis: 30%;
                            width: 30%;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translate(0,-50%);
                        }
                    }

                    .quantity_selector {
                        width: 160px;
                    }
                }
            }
        }
        .product-variations {
            @extend %clearfix;
        }
        .view-larger-image {
            font-size: rem(15);
        }
    }

    .dialog-quickview & {
        .product-col-1 {
            width: 60%;
        }

        .product-col-2 {
            width: 40%;
        }

        .product-description {
            .delivery_same {
                &::before {
                    width: 96px;
                }
            }
        }
    }

    .add-all-skus-to-cart-error {
        color: $red;
        margin: -20px 0 40px;
    }
 }

 .product-set-item {
    .error-quantity {
        text-align: left;
        margin-top: -15px;
    }
}

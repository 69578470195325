strong, b {
    font-weight: $bold;
}

h1, .h1 {
    font-size: rem(28);
    color: $h1-font-color;
    font-style: normal;	
    font-weight: 500;
    line-height: $h1-line-height;
    margin: $h1-margin;
    text-transform: capitalize;
    letter-spacing: 0;
    
    @include respond-to(mobile) {
        font-size: rem($h1-font-size-mobile);
        line-height: $h1-line-height-mobile;
        margin: $h1-margin-mobile;
    }
}

h2, .h2 {
    font-size: rem(24);
    color: $h2-font-color;
    font-style: normal;
    font-weight: 500;
    line-height: $h2-line-height;
    margin: $h2-margin;
    text-transform: capitalize;
    letter-spacing: 0;

    @include respond-to(mobile) {
        font-size: rem($h2-font-size-mobile);
        line-height: $h2-line-height-mobile;
        margin: $h2-margin-mobile;
    }
}

h3, .h3 {
    font-size: rem(22);
    color: $h3-font-color;
    font-style: normal;
    font-weight: 500;
    line-height: $h3-line-height;
    margin: $h3-margin;
    text-transform: capitalize;
    letter-spacing: 0;

    @include respond-to(mobile) {
        font-size: rem($h3-font-size-mobile);
        line-height: $h3-line-height-mobile;
        margin: $h3-margin-mobile;
    }
}

h4, .h4 {
    font-size: rem(18);
    color: $h4-font-color;
    font-style: normal;
    font-weight: 500;
    line-height: $h4-line-height;
    margin: $h4-margin;
    text-transform: capitalize;
    letter-spacing: 0;

    @include respond-to(mobile) {
        font-size: rem($h4-font-size-mobile);
        line-height: $h4-line-height-mobile;
        margin: $h4-margin-mobile;
    }
}

h5, .h5 {
    font-size: rem(14);
    color: $h5-font-color;
    font-style: normal;
    font-weight: 500;
    line-height: $h5-line-height;
    margin: $h5-margin;
    text-transform: capitalize;
    letter-spacing: 0;

    @include respond-to(mobile) {
        font-size: rem($h5-font-size-mobile);
        line-height: $h5-line-height-mobile;
        margin: $h5-margin-mobile;
    }
}

h6, .h6 {
    font-size: rem(12);
    color: $h6-font-color;
    font-style: normal;
    font-weight: 500;
    line-height: $h6-line-height;
    margin: $h6-margin;
    text-transform: capitalize;
    letter-spacing: 0;

    @include respond-to(mobile) {
        font-size: rem($h6-font-size-mobile);
        line-height: $h6-line-height-mobile;
        margin: $h6-margin-mobile;
    }
}

.main-title {
    letter-spacing: $main-title-letter-spacing;
    text-align: center;
    margin: $main-title-margin;
    padding: 0;
    font-weight: 500;
    font-size: rem(20);
    
    @include respond-to(mobile) {
        margin: $main-title-margin-mobile;
    }
    
    span {
        display: inline-block;
        position: relative;
        
        &:after {
            content: "";
            width: $main-title-border-bottom-length;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: absolute;
            top: 100%;
        }
    }
}

.title-double-font {
    font-family: $font-third;
    text-transform: uppercase;

    em {
        font-family: $font-second;
        font-size: 66%;
        font-style: normal;
        text-transform: lowercase;
    }
}

p {
    font-weight: 400;
    font-size: rem(14);
    line-height: 1.5;
    margin-bottom: 20px;
}

ul,
ol,
dl {
    line-height: 1.6;
    margin: 0 0 20px;
    list-style-position: outside;
}

ul {
    list-style: none;
    padding: 0;
}

ol {
    margin-left: 23px;
    padding: 0;
}

li {
    margin: 0;
    padding: 0;
}
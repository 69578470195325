/* Edit account */
.myaccount-header-banner {
    background: $my-account-header-banner-bg;
    text-align: center;
    padding-bottom: 30px;
    border-radius: 4px;

    h2 {
        padding: 20px 0;
    }

    p {
        display: block;
        width: 80%;
        margin: 0 auto;
    }

    &.payment-header-banner {
        padding-top: 25px;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
}

.pt_account .form-edit-account {
    .form-fieldsset-detailspassword .label-optional {
        display: none;
    }

    .field-wrapper {
        @include respond-to(mobile) {
            clear: both;
        }

        &-bidthday {
            padding-top: 5px;

            p {
                margin-top: 0;
                font-size: rem(14);
                color: $hel-gray;
            }
        }
    }

    .form-row-button {
        text-align: left;
    }
}
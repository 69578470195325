.tumbler {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    .tumbler_checkbox {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .tumbler_slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 10px;
    }
    
    .tumbler_slider::before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: $white;
        transition: .4s;
        border-radius: 50%;
    }
    
    .tumbler_checkbox:checked + .tumbler_slider {
        background-color: $pink;
    }
    
    .tumbler_checkbox:focus + .slitumbler_sliderder {
        box-shadow: 0 0 1px $pink;
    }
    
    .tumbler_checkbox:checked + .tumbler_slider::before {
        transform: translateX(20px);
    }
}
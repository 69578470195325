.account-loyality-left {
    display: inline-block;
    float: left;
    width: 75%;
    vertical-align: top;
    margin-bottom: 15px;

    @include respond-to(mobile) {
        float: none;
        width: auto;
    }

    @include respond-to(mobile_min) {
        width: auto;
        margin-bottom: 30px;
    }
}

.account-loyality-right {
    float: right;
    width: 20%;

    img {
        max-width: 100%;
    }

    @include respond-to(mobile) {
        float: none;
        width: auto;
        text-align: center;

        img {
            display: inline-block;
        }
    }

    @include respond-to(mobile_min) {
        float: none;
        width: auto;
    }
}

.loyalty_card {
    &-label {
        max-width: 90%;
    }

    &-text {
        height: 0;
        overflow: hidden;
        line-height: 1.5;
        transition: height 0.5s ease-in-out;
    }

    .input-radio {
        vertical-align: top;
        margin-top: 13px;
    }
}

.loyalty_card-input:checked  ~ .loyalty_card-label .loyalty_card-text {
    height: auto;
    overflow: visible;
    margin-top: 10px;
    transition: height 0.5s ease-in-out;
}

/* My loyalty account */
.loyalty-header {
    background: $ghost;
    margin-bottom: 25px;
    padding: 25px;
    text-align: center;

    h2 {
        padding: 20px 0;
    }

    h2,
    h3 {
        text-transform: none;
    }
}

.loyalty-data-cardnumber .link {
    background-color: $btn-primary-bg;
    border: $btn-primary-border-width solid $btn-primary-border-color;
    color: $btn-primary-color;
    cursor: pointer;
    display: inline-block;
    font-size: rem(15);
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1;
    margin: 0 0 0 10px;
    outline: none;
    padding: 14px 22px;
    text-align: center;
    text-decoration: none;
    text-transform: $btn-text-transform;
    transition: background-color 300ms ease-out;

    @include respond-to(mobile) {
        display: block;
        margin: 20px 0;
    }
}

.loyalty-choose {
    @extend %clearfix;
    margin-bottom: 40px;

    &-title {
        margin-bottom: 25px;
    }

    .loyalty-notregistred-label {
        margin-bottom: 10px;
        text-align: left;
        display: none;

        @include respond-to(mobile) {
            text-align: left;
        }
    }
}

.loyalty-notregistred {
    text-align: center;
}

.loyalty-benefits-cards {
    .slot {
        @include flexbox();

        @include respond-to(mobile) {
            display: block;
            margin: 0;
        }
    }

    .asset {
        flex: 1;
        margin: 0 15px;
        padding: 0 25px 25px;

        @include respond-to(mobile) {
            flex: none;
            margin: 0 0 25px;
            padding: 0;
        }

        .card-logo {
            margin-bottom: 35px;
            max-width: 100%;
            text-align: center;
        }

        h4 {
            margin-bottom: 15px;
        }

        p {
            margin: 0 0 20px;
        }

        ul {
            list-style-type: disc;
            margin: 0 0 20px 17px;
        }

        td {
            vertical-align: middle;
        }
    }
}

.loyalty-registered {
    @extend %clearfix;
}

.loyalty-btn-startshopping {
    width: 60%;
    margin: 0 auto;
    display: block;

    @include respond-to(mobile) {
        width: 100%;
    }
}

.loyalty-col-actions {
    float: left;
    margin: 0 20px 35px 0;
    width: 308px;

    > p {
        margin: 0 0 40px 0;
    }

    @include respond-to(mobile) {
        float: none;
        margin-right: 0;
        width: auto;
    }

    .text-center {
        text-align: center;

        @include respond-to(mobile) {
            text-align: left;
        }
    }

    .button {
        display: block;
        margin-top: 15px;
        text-transform: none;
    }

    .box {
        background: $ghost;
        margin-top: 15px;
        padding: 12px;
    }
}

.loyalty-col-contact {
    float: left;
    padding-top: 61px;
    text-align: center;
    width: 308px;

    @include respond-to(mobile) {
        float: none;
        padding-top: 20px;
        width: auto;
    }

    .box-call {
        font-weight: 500;
        margin-top: 20px;
    }
}

.loyalty-add-card {
    @extend %clearfix;
    margin-bottom: 40px;

    &-form {
        margin-top: 20px;
    }

    &-submit {
        float: right;
    }
}
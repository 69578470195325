$co-order-summary-product-image-width : 90px;
$co-order-summary-details-color       : #9D9D9D;
$co-order-summary-border-color        : #E3E3E3;

.checkout-order-summary {
    float: right;
    width: 100%;
    position: relative;
    border: 1px solid $co-order-summary-border-color;
    padding: 23px;
    max-width: 768px;
    margin: 0 0 100px 0;

    .section-header {
        text-align: center;
        font-size: rem(27);
        font-weight: 400;
        padding: 22px 0 18px 0;
    }

    .mini-cart-product {
        padding: 23px 0;
        border-bottom: 1px solid $co-order-summary-border-color;

        &:last-of-type {
            border: none;
        }
    }

    .mini-cart-image {
        width: $co-order-summary-product-image-width;
        padding: 0 5px 0 0;
    }

    .mini-cart-data {
        width: calc(100% - #{$co-order-summary-product-image-width});
    }

    .mini-cart-subtitle {
        font-size: rem(21);
        padding: 0 0 2px 0;
        line-height: 27px;
        font-weight: 400;
    }

    .mini-cart-name {
        font-size: rem(16);
        padding: 3px 0;
        line-height: 21px;
    }

    .mini-cart-pricing {
        font-size: rem(19);
        font-weight: 500;
        padding: 3px 0;
        line-height: 24px;
    }

    .mini-cart-attributes,
    .mini-cart-qty {
        font-size: rem(16);
        padding: 2px 0;
        color: $co-order-summary-details-color;
        line-height: 21px;
    }

    .checkout-order-totals {
        padding: 0;
    }

    .order-total {
        border-top: 1px solid $co-order-summary-border-color;
        border-bottom: 1px solid $co-order-summary-border-color;
        font-size: rem(21);
    }

    .mini-cart-promos {
        .mini-cart-promo {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .checkout-order-summary-btplus {
        margin-top: 0;

        .payment-btplus {
            margin-top: 23px;
            margin-bottom: 0;
        }
    }

    // styles for order-summary to be always opened on mobile (as per provided design)
    .toggle {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .toggle-content {
        @include respond-to(mobile) {
            display: block;
        }
    }
}
.wishlist-search-form {
    clear: both;
    margin-bottom: 40px;
    min-height: 400px;

    .my-account-subtitle {
        padding-top: 10px;
    }

    p {
        margin-top: 0;
    }
}

.product-name-collection {
    display: none;
}

.wishlist-search,
.registry-search {
    font-size: 0;

    .form-row {
        clear: none;
        display: inline-block;
        padding: 0 10px 0 0;
        vertical-align: top;
        width: 28%;

        @include respond-to(mobile) {
            display: block;
            width: auto;
            padding: 0;
        }
    }

    label {
        display: inline-block;
    }

    .form-row-button {
        padding-right: 0;
        width: 16%;

        @include respond-to(mobile) {
            width: auto;
        }

        label {
            cursor: default;

            @include respond-to(mobile) {
                display: none;
            }
        }
    }

    .field-wrapper {
        margin-bottom: 0;
    }

    .button {
        padding-top: 11px;
        padding-bottom: 10px;
    }

}

.pt_wish-list {
    @extend %clearfix;

    &_inner {
        @include respond-to(mobile) {
            @include flexbox();
            @include flex-vertical();
        }
    }

    .primary-content {
        float: right;
        width: 74%;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            @include order(2);
        }
    }

    #secondary {
        float: left;
        width: 21%;

        @include respond-to(mobile) {
            float: none;
            margin-bottom: 30px;
            width: auto;
            @include order(1);
        }

        @include respond-to(m_tablet_down) {
            &:before {
                content: "js-tablet-nav";
                display: none;
            }
        }
    }

    .list-table-header {
        background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $white-smoke;
        overflow: hidden;
        padding: 2em;

        p {
            color: $nobel;
            float: right;
            margin: 0;
            overflow: hidden;
            width: 40%;
        }
    }

    .primary-content .list-table-header h2 {
        float: left;
    }

    .wishlist-tab {
        @include flexbox();
        text-align: center;
        border: 1px solid $very-light-gray;
        border-width: 1px 0;
        text-transform: uppercase;
        font-size: rem(18);

        a {
            color: $very-light-gray;
        }

        .pseudo-tab {
            width: 33.333%;
            padding: 15px 5px;
            position: relative;

            &.is-active {
                color: $night-rider;
                font-weight: 500;
                font-size: rem(12);

                &:after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background: $white;
                    border: 1px solid $very-light-gray;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                    position: absolute;
                    left: 50%;
                    bottom: -5px;
                }
            }
        }
    }
}

.wishlist-search-result {
    margin-bottom: 0;

    .view {
        text-align: right;
    }
}

.wishlist-products {
    margin-top: 20px;
    margin-bottom: 40px;

    &-item {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $very-light-gray;

        &:last-child {
            border-bottom: 1px solid $very-light-gray;
        }
    }

    .product-tile-wrapper {
        height: auto;
        overflow: visible;
    }

    .product-tile {
        height: auto;
        text-align: left;
        overflow: visible;

        @include respond-to(mobile) {
            text-align: center;
        }

        .promo-flag {
            position: absolute;
            left: 0;
            top: 0;

            @include respond-to(mobile) {
                display: inline-block;
                position: static;
            }
        }

        .promo-flag-wishlist-above {
            @include respond-to(mobile) {
                @include hidden;
            }
        }

        .promo-flag-wishlist-below {
            @include respond-to(tablet_plus) {
                @include hidden;
            }
        }

        .product-image {
            height: auto;

            @include respond-to(tablet_plus) {
                margin-bottom: 0;

                .promo-flag {
                    right: auto;
                    left: 0;
                    top: -10px;
                    padding: 2px 12px;

                    &::after {
                        content: none;
                    }
                }
            }

            @include respond-to(mobile) {
                text-align: center;
            }
        }

        img {
            max-width: 135px;

            @include respond-to(mobile) {
                padding-top: 0;
                margin: 0 auto;
            }

            @include respond-to(tablet_plus) {
                margin-top: 10px;
            }
        }

        .product-name {
            font-size: rem(16);
            text-transform: none;
            max-height: none;
            overflow: visible;

            &-brand {
                display: block;
                font-size: rem(22);
                text-transform: uppercase;
                font-weight: 500;
                color: $nobel;
            }
        }

        .product-variations {
            ul {
                margin-bottom: 0;
            }

            .attribute {
                margin: 5px 0;
                text-transform: capitalize;

                &:last-child {
                    @include respond-to(mobile) {
                        margin: 5px 0 10px;
                    }
                }

                .value {
                    @include respond-to(mobile) {
                        margin: 0;
                        width: 100%;
                    }
                }
            }

            .inventory {
                margin: 5px 0;

                @include respond-to(mobile) {
                    margin: 10px 0 15px;
                }

                &-label {
                    @include respond-to(mobile) {
                        line-height: 1.6;
                        padding-bottom: 3px;
                    }
                }
            }
        }
    }

    .product-variations {
        @include respond-to(mobile) {
            text-align: left;
        }

        .toggle.expanded > ul {
            @include respond-to(mobile) {
                z-index: 10;
                width: 100% !important;
                box-shadow: inset 0px 0px 0px $white; // to fix gap inside variations dropdown
            }
        }

        &.toggle-content {
            .selected-value,
            .toggle.expanded > ul {
                @include respond-to(tablet_plus) {
                    width: 100%;
                }
            }

            .product-add-to-cart {
                margin-top: 33px;
            }
        }

        .label {
            @include respond-to(mobile) {
                text-align: center;
            }
        }
    }

    &-form {
        .in-stock-msg,
        .low-stock-msg {
            text-transform: capitalize;
        }

        @include respond-to(tablet_plus) {
            display: table;
            width: 100%;
        }
    }

    .product-sales-price {
        + .product-standard-price {
            color: $charcoal;
            font-size: rem(12);
            text-transform: uppercase;
            white-space: nowrap;
            text-decoration: line-through;

            span {
                text-decoration: line-through;
            }

            &.rrp-price {
                text-decoration: none;

                span {
                    text-decoration: none;
                }
            }

            .price-standard-qq-coment {
                display: inline-block;
            }
        }
    }

    .wishlist-added,
    .wishlist-remove-item {
        color: $hel-gray;
        font-size: rem(12);

        @include respond-to(mobile) {
            margin: 15px 0;
        }

        @include respond-to(tablet_plus) {
            text-align: right;
            margin-bottom: 25px;
        }

        &-date {
            margin-bottom: 2px;
            font-size: rem(14);
        }
    }

    .product-col-1 {
        @include respond-to(tablet_plus) {
            display: table-cell;
            vertical-align: top;
            width: 15%;
        }
    }

    .product-col-2 {
        @include respond-to(tablet_plus) {
            display: table-cell;
            vertical-align: top;
            padding-right: 10px;
            width: 40%;
        }
    }

    .product-col-3 {
        @include respond-to(tablet_plus) {
            display: table-cell;
            vertical-align: top;
            width: 45%;
        }

        &_left {
            @include respond-to(tablet_plus) {
                display: table-cell;
                width: 172px;
                padding-right: 15px;
                vertical-align: bottom;
            }

            .not-available-msg {
                color: $field-error-text-color;
                font-size: rem(14);
                margin-top: 0;
            }
        }

        &_right {
            @include respond-to(tablet_plus) {
                display: table-cell;
                vertical-align: bottom;
            }

            .error-quantity {
                display: none;
                margin: -23px 0 25px;

                @include respond-to(mobile) {
                    margin: -12px 0 15px;
                }
            }

            button {
                white-space: nowrap;
            }
        }

        .add-to-cart {
            @include respond-to(tablet_plus) {
                white-space: nowrap;
            }
        }
    }
}

.int-delivery-msg {
    color: $error;
    font-size: rem(12);
    padding-right: 45px;
    line-height: 1.5;

    @include respond-to(mobile) {
        padding: 0 45px;
    }
}

.wishlist-added-available {
    color: $dark-gray;
}

.list-share {
    @extend %clearfix;
    border-top: 1px solid darken($white-smoke, 10%);
    padding: 2em;

    .share-option {
        box-sizing: border-box;
        display: block;
        margin-bottom: .5rem;
        float: left;
    }

    .share-options {
        text-align: center;
        margin-bottom: .5rem;
        font-size: 1.7em;
        float: left;
        margin-left: 1rem;
        margin-top: 0.3rem;

        .share-title {
            display: inline;
        }
    }

    .list-title {
        float: left;
    }

    .share-link {
        cursor: pointer;
    }

    .share-link-content {
        display: none;

        &.active {
            display: block;
            float: left;
        }
    }
}

.event-info {
    margin: 1em;
}

.wishlist-header {
    @include out-of-wrapper();
    background-color: $puce;
    color: $white;
    margin-bottom: 65px;
    padding-bottom: 25px;
    padding-top: 25px;
    text-align: center;

    @include respond-to(mobile) {
        margin-bottom: 25px;
        padding-bottom: 40px;
        padding-top: 35px;
    }

    &-container {
        margin: 0 auto;
        max-width: 480px;

        @include respond-to(mobile) {
            margin: 0;
            max-width: none;
        }

        &_subtitle {
            font-family: $font-second;
            font-size: rem(26);

            @include respond-to(mobile) {
                margin-bottom: 11px;
            }
        }

        &_title {
            color: $white;
            font-family: $font-third;
            font-size: rem(76);
            line-height: 1;
            margin-bottom: 8px;
            text-transform: uppercase;

            @include respond-to(mobile) {
                font-size: rem(48);
                margin-bottom: 17px;
            }
        }

        &_description {
            letter-spacing: 1px;
            line-height: 1.6;
            margin: 0;
            opacity: 0.9;
        }
    }
}

.wishlist-search-phrase {
    text-decoration: underline;
}

.mywishlist-flayer {
    @extend %clearfix;
    background-color: $my-account-wishlist-banner-bg;
    margin-bottom: 35px;
    padding: 30px;
    text-align: center;

    @include respond-to(mobile) {
        margin-bottom: 0;
        padding: 15px;
    }

    &_text {
        display: inline-block;
        max-width: 46%;

        @include respond-to(mobile) {
            display: block;
            max-width: none;
        }

        + .mywishlist-flayer_visual {
            display: inline-block;
            max-width: 46%;
            padding-left: 65px;
        }
    }

    &_title {
        font-size: rem(44);
        margin-bottom: 14px;

        @include respond-to(mobile) {
            font-size: rem(35);
            margin-bottom: 12px;
        }

        span {
            text-decoration: underline;
        }
    }

    &_title-vip {
        color: $new-orleans;
        margin-bottom: 0;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }

    &_visual {
        @include respond-to(mobile) {
            width: 50%;
            margin: 0 auto;
            margin-bottom: 9px;
        }
    }

    &_description {
        line-height: 1.6;
        margin-bottom: 22px;

        @include respond-to(mobile) {
            margin-bottom: 16px;
        }
    }

    &_author {
        font-weight: 500;
    }

    &_author-position {
        display: inline-block;
        text-transform: uppercase;
        margin-top: 10px;
        text-decoration: underline;
        font-weight: 500;
    }

    &_subtitle {
        font-size: rem(24);
        margin-bottom: 25px;
        text-transform: uppercase;
    }

    &_categories-title {
        font-size: rem(20);
        font-weight: 500;
        text-transform: uppercase;
    }

    &_links {
        .mywishlist-flayer_item {
            margin-top: 10px;
        }
    }
}

.wishlist-sharing-box {
    @extend %clearfix;
    background-color: $ghost;
    margin-bottom: 35px;
    padding: 35px 25px;

    @include respond-to(mobile) {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    &_sharing {
        float: left;
        width: 55%;
        padding: 0 25px;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            padding: 0;
        }

        .notify-customer-button {
            margin-bottom: 20px;

            @include respond-to(mobile) {
                margin-bottom: 30px;
            }
        }
    }

    &_shipping {
        float: right;
        width: 45%;
        padding: 0 25px;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            padding: 0;
        }

        .input-select {
            margin-top: 4px;

            @include respond-to(mobile) {
                margin-top: 0;
            }
        }

        .wishlist-cta & {
          width: 100%;
          text-align: center;
          padding: 0 25px;
        }
    }

    &_title {
        font-weight: 500;
        margin-bottom: 25px;

        @include respond-to(mobile) {
            font-size: rem(17);
            margin-bottom: 14px;
        }
    }

    .wishlist-share-button,
    .sendtofriend-button {
        @extend .outlined;

        margin-bottom: 20px;

        @include respond-to(mobile_to_desktop) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include respond-to(tablet_plus) {
            max-width: 49.5%;
            min-width: 45%;
            letter-spacing: 0;
            font-size: rem(10);
        }

        @include respond-to(mobile) {
            margin-bottom: 30px;
        }
    }

    .wishlist-share-button {
        @include respond-to(tablet_plus) {
            float: left;
        }
    }

    .sendtofriend-button {
        @include respond-to(tablet_plus) {
            float: right;
        }
    }

    .list-share-description {
        margin-bottom: 16px;
        clear: both;

        @include respond-to(mobile) {
            font-size: rem(14);
            line-height: 1.7;
            margin-bottom: 9px;
        }
    }

    .list-share-link {
        font-weight: 500;

        @include respond-to(mobile) {
            font-size: rem(14);
            margin-bottom: 50px;
            word-break: break-all;
        }
    }
}

.dialog-sendtofriend-wishlist {
    .ui-dialog-buttonpane {
        display: block !important;

        .ui-button-text {
            display: block !important;
        }
    }

    .dialog-content {
        text-align: center;
    }
}

.send-to-friend-wishlist {
    text-align: left;

    .input-textarea {
        resize: vertical;
    }

    .js-form-row-field-friendemailconfirm.error {
        background-color: transparent;
        border: none;
        color: inherit;
        padding: 0;
        text-transform: none;

        .field-wrapper {
            margin-bottom: 0;
        }

        .error-message {
            background-color: $white-smoke2;
            border: 1px solid $error;
            border-top: none;
            color: $error;
            padding: 10px 8px;
            text-transform: uppercase;
            width: 100%;
        }
    }
}

.wishlist-cta {
    overflow: hidden;
    margin: 15px 0;
}

input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

.products__item-count {
    font-size: rem(14);
    letter-spacing: 1px;
    line-height: 1;
    text-align: center;
    color: $grey05;
    margin-bottom: 16px;
}

.products__load-more {
    @include btn-black-border();

    @include respond-to(mobile_strong_over) {
        width: 390px;
        margin: 0 auto;
    }
}
fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

input {
    outline: none;
}

.js .simple-submit {
    display: none;
}

legend {
    font-size: rem($legend-font-size);
    font-weight: $legend-font-weight;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;

    span {
        font-size: rem($legend-span-font-size);
        font-weight: $legend-span-font-weight;
        position: relative;
        text-transform: none;
        padding: 0 .5em;

        em {
            color: $body-base-color;
        }
    }
}

label {
    display: block;
    font-size: rem($label-font-size);
    font-weight: $label-font-weight;
    cursor: pointer;

    .form-row & {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .form-horizontal & {
        float: left;
        width: 100%;
        line-height: 38px;

        @include respond-to(tablet_plus) {
            text-align: right;
            width: 25%;
        }
    }

    .label-inline & {
        display: inline;
        float: none;
        font-size: rem($label-font-size);
        font-weight: $label-font-weight;
        margin-top: 0;
        text-align: left;
        width: auto;
        line-height: normal;
    }

    .label-above & {
        margin-bottom: .3rem;
        width: auto;
    }

    .label-empty & {
        visibility: hidden;

        @include respond-to(mobile) {
            visibility: visible;
        }
    }

    .form-row-field-address2 & {
        &.error {
            visibility: visible;
        }
    }
}

.form-label-text {
    float: left;
    font-size: rem(11);
    font-weight: 700;
    padding: 0.75em 0 0;
    text-align: right;
    width: 25%;
}

.form-row {
    @extend %clearfix;
    margin: .5rem 0 1rem;

    &.required label:after {
        content: '*';
        color: $required-legend-color;
    }

    &.js-dublincode .form-field-tooltip {
        margin-left: 27%;
        padding-top: 0;
        width: auto;
        color: $light-pink;
        font-size: rem(15);

        @include respond-to(mobile) {
            margin: 0;
        }
    }
}

.registration-form .form-row.js-dublincode .form-field-tooltip {
    margin-left: 18%;
}

.form-row-button {
    clear: left;

    .form-horizontal & {
        @include respond-to(tablet_plus) {
            margin-left: 27%;
            width: 62%;
        }
    }

    .checkout-shipping.form-horizontal &,
    .checkout-billing.form-horizontal & {
        width: 73%;
        margin-top: 3rem;
        text-align: right;

        @include respond-to(mobile) {
            width: auto;
        }

        button {
            background-color: $btn-secondary-bg;

            &:hover {
                background: $btn-secondary-hover-bg;
            }

            &.disabled,
            &[disabled] {
                background-color: $btn-disabled-bg;
                color: $btn-disabled-color;
                cursor: default;
                opacity: 0.7;

                &:hover {
                    background-color: $btn-disabled-bg;
                }
            }
        }
    }
}

.form-row-alert {
    clear: left;

    .form-horizontal & {
        @include respond-to(tablet_plus) {
            margin-left: 27%;
            width: 62%;
        }
    }

    .checkout-shipping.form-horizontal &,
    .checkout-billing.form-horizontal & {
        width: 73%;

        @include respond-to(mobile) {
            width: 100%;
        }
    }
}

.field-wrapper {
    box-sizing: border-box;
    margin-bottom: .5rem;
    position: relative;
    width: 100%;

    .form-horizontal & {
        float: left;
        width: 75%;

        @include respond-to(mobile) {
            float: none;
            width: auto;
        }

        @include respond-to(tablet_plus) {
            padding-left: 2%;
        }
    }

    .label-inline & {
        float: left;
        padding-left: 0;
        width: auto;
    }

    .label-above & {
        float: none;
        padding-left: 0;
        width: auto;
    }

    .show-password,
    .hide-password {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        padding: 9px;
        text-decoration: underline;
    }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
    background-color: $field-bg;
    border-radius: 0;
    border: $field-border-width solid $field-border-color;
    box-sizing: border-box;
    color: $field-text-color;
    display: block;
    font-size: rem($field-font-size);
    margin: 0;
    outline: none;
    padding: $field-padding;
    transition: all 0.15s linear;
    width: 100%;

    @include placeholder {
        color: $field-placeholder-text-color;
    }

    &:hover {
        background-color: $field-hover-bg;
        border-color: $field-hover-border-color;
        color: $field-hover-text-color;
    }

    &:focus {
        background-color: $field-focus-bg;
        border-color: $field-focus-border-color;
        color: $field-focus-text-color;
    }

    &:disabled {
        background-color: $field-disabled-bg;
        border-color: $field-disabled-border-color;
        color: $field-disabled-text-color;
        cursor: default;
        opacity: 1;
    }
}

input[type=number] {
    -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.password {
    padding-right: 55px;
}

select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none;
    border-radius: 0;
    background-color: $select-bg;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjEwMDgiIGhlaWdodD0iMTAyNCIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz4KPHRpdGxlLz4KCgo8ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PGcgaWQ9Imljb21vb24taWdub3JlIj4KPC9nPjxwYXRoIGQ9Ik05NTguODEgMjk5LjYxMmMwIDQuMjk2LTQuMjk2IDEyLjg5LTguNTg0IDE3LjE3OGwtNDI5LjA4OCA0MjQuNzc2Yy04LjU4NCA4LjU4NC0yNS43MzUgOC41ODQtMzQuMzI5IDBsLTQyOS4wODgtNDI0Ljc3NmMtOC41ODQtOC41ODQtOC41ODQtMjUuNzM1IDAtMzQuMzI5czI1LjczNS04LjU4NCAzNC4zMjkgMGw0MTEuOTA2IDQxMS45MDYgNDExLjkwNi00MTEuOTA2YzguNTg0LTguNTg0IDI1LjczNS04LjU4NCAzNC4zMjkgMCA0LjI5NiA0LjI5NiA4LjU4NCAxMi44OSA4LjU4NCAxNy4xNzh2MHpNMTAwMS43MjMgMjk5LjYxMmMwIDE3LjE3OC04LjU4NCAzNC4zMjktMjEuNDQ3IDQ3LjE5M2wtNDI5LjA4OCA0MjQuNzc2Yy0yNS43MzUgMjUuNzM1LTY4LjY2NiAyNS43MzUtOTQuMzc1IDBsLTQyOS4wODgtNDI0Ljc3NmMtMTIuODktMTIuODktMjEuNDQ3LTMwLjA0MS0yMS40NDctNDcuMTkzdjBjMC0xNy4xNzggOC41ODQtMzQuMzI5IDIxLjQ0Ny00Ny4xOTMgMjUuNzM1LTI1LjczNSA2OC42NjYtMjUuNzM1IDk0LjM3NSAwbDM4MS44ODcgMzgxLjg4NyAzODEuODg3LTM4MS44ODdjMjUuNzM1LTI1LjczNSA2OC42NjYtMjUuNzM1IDk0LjM3NSAwIDEyLjg5IDEyLjg5IDE3LjE3OCAyNS43MzUgMjEuNDQ3IDQyLjg4NyAwIDAgMCA0LjI5NiAwIDQuMjk2eiIgaWQ9InN2Z18xIiBjbGFzcz0ic2VsZWN0ZWQiIGZpbGw9IiNiZmJmYmYiIGZpbGwtb3BhY2l0eT0iMSIvPjwvZz48L3N2Zz4=);
    background-size: 13px;


    background-position: 96% center;
    background-repeat: no-repeat;
    border: 1px solid $select-border-color;
    padding: 8px 23px 8px 4px;
    font-size: rem(14);
    color: $select-text-color;
    line-height: normal;
    outline: none;
    width: 100%;

    &:hover,
    &:focus {
        background-color: $select-hover-bg;
        border-color: $select-hover-border-color;
    }

    &:disabled {
        background-color: $select-disabled-bg;
        border-color: $select-disabled-border-color;
        cursor: default;
    }

    &::-ms-expand {
        display: none;
    }

    &.error {
        border: 1px solid $error;
    }
}

input[type="checkbox"],
input[type="radio"] {
    margin: 3px 7px 0 0;
}

.form-caption {
    clear: left;
    width: auto;

    .form-horizontal & {
        @include respond-to(tablet_plus) {
            margin-left: 27%;
        }
    }

    .label-above & {
        margin-left: 0;
    }

    .form-indent & {
        margin-left: 0;
    }
}

.form-indent {
    @include respond-to(tablet_plus) {
        margin-left: 27%;
    }
}

.error-message {
    border: 2px solid $error-message-border-color2;
    background-color: $error-message-bg2;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 49.5966%;
}

.error-quantity {
    color: $error-quantity-text-color;
    margin: -20px 0 40px;
    font-size: rem(14);
}

input.error,
input.error:focus {
    background-color: $field-error-bg;
    border: 1px solid $field-error-border-color;
    color: $field-error-text-color;

    &[type="checkbox"] {
        border-bottom: 1px solid $field-error-border-color;
    }
}

span.error,
div.error,
.send-to-friend-wishlist label.error {
    background-color: $error-message-bg;
    border: 1px solid $error-message-border-color;
    border-top: none;
    color: $error-message-text-color;
    display: block;
    font-size: rem(14);
    font-weight: 400;
    padding: 10px 8px;
    text-transform: uppercase;
}

.error-form {
    align-items: center;
    background-color: $error-message-bg;
    border: 1px solid $error-message-border-color;
    color: $error-message-text-color;
    display: flex;
    font-size: rem(12);
    font-weight: 400;
    justify-content: center;
    margin: 5rem auto;
    max-width: 700px;
    padding: 14px 10px;
    text-transform: none;
}

.form-inline {
    form {
        padding: 0 0 2em;
        width: 98%;
    }

    label {
        padding: 0 0 0.75em;
        text-transform: uppercase;
        width: auto;
    }

    input[type="text"], select {
        margin-left: 0;
        width: 95%;
    }

    button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.row-inline {
    display: inline;

    label {
        width: auto;
    }
}

// Required Indicator and Text
.dialog-required {
    display: inline;
    position: relative;
    padding: 0 .5em;

    em {
        color: $required-dialog-legend-color;
        font-size: rem(6.5);
        font-style: normal;
        text-transform: uppercase;
    }
}

.required-indicator {
    display: none;
    color: $required-dialog-legend-color;
    font-size: rem(10);
    padding: 0;

    &:before {
        content: '\2022';
    }
}

.form-field-tooltip {
    float: left;
    margin-left: 3%;
    padding-top: .75em;
    width: 20%;
}

.clearable {
    background: $white url(../images/icon_remove_2.gif) no-repeat right -10px center;
    border: 1px solid $dim-gray;
    padding: 3px 18px 3px 4px;
    border-radius: 3px;
    transition: background 0.4s;

    &.x  {
        background-position: right 5px center;
    }

    &.onX{
        cursor: pointer;
    }

    &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
}

.error-box {
    background: $red01;
    color: $white01;
    padding: 20px 30px;
    margin: 0 auto;
    border-radius: 5px;
    font-size: rem(12);
    display: block;
    line-height: 18px;
}

.cart-coupon_code {
    .field-wrapper {
        input[type="text"],
        input[type="number"] {
            padding: 12px 12px 0;
            height: 50px;
            border-radius: 4px;
            border-color: $dim-gray;
            font-weight: $medium;

            &.invalid,
            &.error {
                border-color: $red01;
            }

            &:disabled {
                background-color: $lighter-gray;
                color: $black01;
                -webkit-text-fill-color: $black01;

                ~ label {
                    font-size: rem(10);
                    line-height: 25px;
                }
            }
        }

        input[type="text"],
        input[type="number"] {
            ~ label {
                pointer-events: none;
                display: block;
                position: absolute;
                top: 0;
                left: 15px;
                line-height: 50px;
                transition: all 0.3s ease-out;
                color: $hel-gray;

                @include respond-to(tablet) {
                    font-size: rem(12);
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50px;
                    left: 0;
                    white-space: nowrap;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
        
                &.active {
                    font-size: rem(10);
                    line-height: 25px;
                }
            }

            &.invalid,
            &.valid,
            &:focus {
                ~ label {
                    font-size: rem(10);
                    line-height: 25px;
                }
            }

            &:-webkit-autofill { //this prefix should be written separately for correctly work
                ~ label {
                    font-size: rem(10);
                    line-height: 25px;
                }
            }

            &:-moz-autofill { //this prefix should be written separately for correctly work
                ~ label {
                    font-size: rem(10);
                    line-height: 25px;
                }
            }
        }
    }
}
.colorselector {
    padding: 10px 20px 10px 10px;
    margin-bottom: 15px;
    background-color: $light-gray01;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-image {
        max-width: 40px;
    }

    &-image_wrapper {
        margin-right: 15px;
        display: flex;
        position: relative;
    }

    &-name {
        text-transform: uppercase;
        font-size: rem(12);
        font-weight: 600;
        letter-spacing: 1px;
        flex: 1 1 auto;
        text-align: left;

        @include respond-to(mobile_strong) {
            font-size: rem(10);
        }
    }

    &-link {
        @include link_underline;

        font-size: rem(12);
    }

    .choose_color_wrapper {
        @include respond-to(mobile_strong) {
            display: none;
        }
    }

    &-input_wrapper {
        margin-bottom: 15px;
        position: relative;

        .colorselector-input {
            background-color: $light-gray01;
            border-radius: 4px;
            border: 0;
            padding: 12px;

            @include respond-to(mobile_strong) {
                padding: 7px 12px;
            }

            &::placeholder {
                color: $black01;
                text-transform: uppercase;
            }

            &:hover,
            &:focus {
                background-color: $light-gray01;
            }
        }

        .bticons-search-small {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            
            &::before {
                font-size: rem(16);
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 3%;
    }

    &-item {
        width: 14%;
        text-align: center;
        margin-bottom: 20px;
        cursor: pointer;

        @include respond-to(m_tablet_down) {
            width: 22%;
        }

        @include respond-to(m_mobile_down) {
            width: 31%;
        }
    }

    &-swatch_wrapper {
        padding: 10%;
        border: 1px solid transparent;
        position: relative;
    }

    &-swatch_image {
        width: 100%;
        display: block;
    }

    &-swatch_name {
        font-size: rem(12);
        display: inline-block;
    }

    &-dialog {
        &.ui-dialog {
            width: 50% !important;

            .dialog-content {
                @include respond-to(mobile_strong_over) {
                    padding: 15px;
                }
        
                @include respond-to(mobile_strong) {
                    padding: 8px;
                }
            }
        }
    }
}

// Disabled swatches
.unselectable {
    .colorselector-image_wrapper {
        opacity: 0.5;
    }

    .colorselector-image_wrapper,
    .colorselector-swatch_wrapper {
        &::after {
            content: "";
            display: block;
            border: 1px solid $white;
            position: absolute;
            top: 50%;
            width: 135%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &.colorselector-item {
        opacity: 0.5;
        cursor: default;
    }

    .colorselector-name {
        color: $dim-gray;
        text-decoration: line-through;
    }
}

// Selected swatches
.selected  {
    .colorselector-swatch_wrapper {
        border-color: $lighter-gray;

        &::after {
            content: "";
            width: 24px;
            height: 24px;
            background-color: $white;
            background-image: url(../img/icons/done_black.svg);
            background-size: 18px;
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid $black01;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .colorselector-swatch_name {
        @include link_underline;
    }
}
//----------------------------------------------------------------------
// Reff App variables
//----------------------------------------------------------------------

//----------------------------------------------------------------------
// Colour Palette
// names taken from http://www.color-blindness.com/color-name-hue/
//----------------------------------------------------------------------
$black: #000;
$black03: #141414;

$cerulean: #00629b;
$charcoal: #8c8c8c;
$citrus: #84bd00;
$dark-gray: #aaa;
$dim-gray: #999;
$gray: #ccc;
$suva-gray: #8e8d8d;
$hel-gray: #666666;
$hel-gray2: #656565;
$mid-gray: #555;
$smoke: #444;
$smokegrey: #4d4d4d;
$zorro: #303030;
$light-gray: #f7f7f7;
$light-gray01: #F8F8F8;
$light-gray02: #F4F4F4;
$light-gray03: #777777;
$light-gray04: #E6E6E6;
$lighter-gray: #eee;
$gainsboro: #e0e0e0;
$gainsboro2: #ddd;
$gainsboro3: #d8d8d8;
$islamic-green: #019328;
$night-rider: #333;
$nobel: #151515;
$rain-forest: #64772d;
$sangria: #bc8c8f;
$teal: #008578;
$teal-lighter: #A9D9C4;
$teal-light: #75969F;
$teal-lightest: #B8D9D5;
$transparent: transparent;
$tutu: #bfbfbf;
$very-light-gray: #d5d5d5;
$lightest-gray: #dbdbdb;
$ghost: #ebebeb;
$white-smoke: #eef1f3;
$white-smoke2: #f1f1f1;
$pink: #DC8699;
$light-pink: #fdb4a9;
$dark-pink: #c16278;
$white: #fff;
$second-gray: #ececec;
$second-black: #3f3f3f;
$chanel-black-hover: #1a1a1a;
$second-light-gray: #ededed;
$second-lightest-gray: #f5f5f5;
$violet:  #80556B;
$rose: #FCB2A7;
$red: #C00;
$error: #f00;
$alizarin: #E72D2D;
$green: #060;
$green01: #19C21B;
$yellow: #ECD97C;
$sections-header-border: #979797;
$sections-body-border: #D9D9D9;
$coloured-hover: #FDB4A9;
$bg-gray: #e5eaed;
$cirkon: #e3e4e5;
$narvik: #F0EDE8;
$nero: #2c2c2c;
$nero2: #222;
$puce: #C68299;
$burnt-orange: #f97529;
$shakespeare: #628EB5;
$new-orleans: #e3d084;
$contessa: #bf6067;
$toast: #a57466;
$whisper: #F8F5F5;
$orient: #1e5862;
$malachite-green: #919476;
$silver: #b6b6b6;
$iron: #ced0d1;
$westar: #E8E3DE;

//----------------------------------------------------------------------
// Fonts
//----------------------------------------------------------------------
$sans-serif: Arial, sans-serif;
$sans-serif-alt: Lato, sans-serif;
$serif: Crete Round, serif;


$font-main          : "aktiv-grotesk-extended", "futura-pt", $sans-serif;
$font-second        : 'DidotLTStd-Italic', 'Times New Roman', Times, serif;
$font-third         : 'f37_bellaregular', 'Times New Roman', Times, serif;

$font-icons         : 'icomoon';


// flag-icon-css
$flag-icon-css-path: '../lib/flag-icon-css/flags';


// z-indexes
$z-popups: 11000;
$z-header-sticky: 9996;
$z-refinements-mobile: 10000;
$z-nav-dropdown: 20;

// Base variables
// ----------------------------------------------------------------------
$body-base-color    : $night-rider;
$body-font-size     : 16px;
$base-font-size     : 10;

// Heading settings
//----------------------------------------------------------------------
// h1
$h1-font-size       : 36;
$h1-line-height     : 1.2;
$h1-font-weight     : normal;
$h1-font-color      : $nobel;
$h1-text-transform  : uppercase;
$h1-margin          : 0;

// h2
$h2-font-size       : 30;
$h2-line-height     : 1.2;
$h2-font-weight      : normal;
$h2-font-color      : $nobel;
$h2-text-transform  : uppercase;
$h2-margin          : 0;

// h3
$h3-font-size       : 24;
$h3-line-height     : 1.2;
$h3-font-weight      : normal;
$h3-font-color      : $nobel;
$h3-text-transform  : uppercase;
$h3-margin          : 0;

// h4
$h4-font-size       : 20;
$h4-line-height     : 1.2;
$h4-font-weight      : normal;
$h4-font-color      : $nobel;
$h4-text-transform  : uppercase;
$h4-margin          : 0;

// h5
$h5-font-size       : 18;
$h5-line-height     : 1.2;
$h5-font-weight      : normal;
$h5-font-color      : $nobel;
$h5-text-transform  : uppercase;
$h5-margin          : 0;

// h6
$h6-font-size       : 16;
$h6-line-height     : 1.2;
$h6-font-weight      : normal;
$h6-font-color      : $nobel;
$h6-text-transform  : uppercase;
$h6-margin          : 0;


// Mobile
// h1
$h1-font-size-mobile    : 26;
$h1-line-height-mobile  : 1.2;
$h1-margin-mobile       : 0;

// h2
$h2-font-size-mobile    : 22;
$h2-line-height-mobile  : 1.2;
$h2-margin-mobile       : 0;

// h3
$h3-font-size-mobile    : 18;
$h3-line-height-mobile  : 1.2;
$h3-margin-mobile       : 0;

// h4
$h4-font-size-mobile    : 16;
$h4-line-height-mobile  : 1.2;
$h4-margin-mobile       : 0;

// h5
$h5-font-size-mobile    : 16;
$h5-line-height-mobile  : 1.2;
$h5-margin-mobile       : 0;

// h6
$h6-font-size-mobile    : 16;
$h6-line-height-mobile  : 1.2;
$h6-margin-mobile       : 0;


// main title settings
$main-title-font-weight          : 300;
$main-title-letter-spacing       : 1px;
$main-title-margin               : 0 0 35px 0;
$main-title-margin-mobile        : 0 0 20px 0;
$main-title-border-bottom-length : 50%;



// Links settings
//----------------------------------------------------------------------
// default link
$link-default-color        : $nobel;
$link-hover-color          : $dim-gray;

// custom link (gen-cta in footer 'More Info', 'Subscribe', 'Register')
$link-custom-bg                   : transparent;
$link-custom-border-color         : $nobel;
$link-custom-border-width         : 0 0 1px 0;
$link-custom-text-color           : $nobel;
$link-custom-padding              : 0;

$link-custom-hover-bg             : transparent;
$link-custom-hover-border-color   : $charcoal;
$link-custom-hover-text-color     : $charcoal;


//----------------------------------------------------------------------
// Form elements settings
//----------------------------------------------------------------------
$field-bg                     : $white;
$field-text-color             : rgba(0, 0, 0, 0.75);
$field-placeholder-text-color : $tutu;
$field-font-size              : 14;
$field-padding                : 9px 8px;

// Fields border
$field-border-width          : 1px;
$field-border-color          : $tutu;


// Fields on hover
$field-hover-bg              : $white;
$field-hover-border-color    : $tutu;
$field-hover-text-color      : rgba(0, 0, 0, 0.75);

// Fields in focus
$field-focus-bg              : $narvik;
$field-focus-border-color    : $dim-gray;
$field-focus-text-color      : $nobel;

// Fields disabled
$field-disabled-bg           : $gainsboro2;
$field-disabled-border-color : $tutu;
$field-disabled-text-color   : rgba(0, 0, 0, 0.75);

// Field Error message
$field-error-bg              : $white;
$field-error-border-color    : $error;
$field-error-text-color      : $error;
// $field-error-font-size    : 11;


//error message
$error-message-bg: $white-smoke2;
$error-message-border-color: $error;
$error-message-text-color: $error;

//error quantity
$error-quantity-text-color: $red;

//error message2
$error-message-bg2: $tutu;
$error-message-border-color2: $sangria;


// Label settings
$label-font-size: 16;
$label-font-weight: 400;

//legend settings
$legend-font-size        : 17;
$legend-font-weight      : 700;
$legend-span-font-size   : 12;
$legend-span-font-weight : 400;


// Select settings
$select-text-color           : rgba(0, 0, 0, 0.75);
$select-bg                   : $white;
$select-border-color         : $tutu;

// Select on hover
$select-hover-bg             : $narvik;
$select-hover-border-color   : $dim-gray;

// Select disabled
$select-disabled-bg           : $gainsboro2;
$select-disabled-border-color : $tutu;



// Required indicator
$required-legend-color          : $pink;
$required-dialog-legend-color   : $sangria;


// Custom checkbox and radiobutton
$checkbox-border-color          : $black;
$checkbox-checked-bg            : $black;
$checkbox-size                  : 16px;
$checkbox-magin-to-label        : 10px;



//----------------------------------------------------------------------
// Buttons settings
//----------------------------------------------------------------------
$btn-text-transform    : uppercase;
$btn-font-family       : $font-main;
$btn-font-weight       : 500;
$btn-font-size         : 15;
$btn-bg                : $nobel;
$btn-color             : $white;
$btn-disabled-bg       : $gray;
$btn-disabled-hover-bg : $suva-gray;
$btn-disabled-color    : $white;
$btn-hover-bg          : $nero;
$btn-hover-color       : $white;
$btn-padding           : 14px 22px;


//Button sorted by sizes
//small
$btn-small-font-size : 12;
$btn-small-padding   : 10px 14px;

//large
$btn-large-font-size      : 14;
$btn-large-padding-bottom : 13px;
$btn-large-padding-top    : 13px;

//tiny
$btn-tiny-font-size : 11;
$btn-tiny-padding   : 4px 6px;


// Button sorted by colors
// Buttons primary
$btn-primary-bg                 : $nobel;
$btn-primary-color              : $white;
$btn-primary-hover-bg           : $nero;
$btn-primary-hover-color        : $white;
$btn-primary-border-width       : 1px;
$btn-primary-border-color       : transparent;
$btn-primary-hover-border-color : transparent;


// Buttons secondary ('.colored' in BT)
$btn-secondary-bg                 : $pink;
$btn-secondary-color              : $white;
$btn-secondary-hover-bg           : $dark-pink;
$btn-secondary-hover-color        : $white;
$btn-secondary-border-width       : 1px;
$btn-secondary-border-color       : transparent;
$btn-secondary-hover-border-color : transparent;

$btn-secondary-inactive-bg                 : $light-pink;
$btn-secondary-inactive-color              : $white;
$btn-secondary-inactive-hover-bg           : $light-pink;
$btn-secondary-inactive-hover-color        : $white;
$btn-secondary-inactive-border-width       : 1px;
$btn-secondary-inactive-border-color       : transparent;
$btn-secondary-inactive-hover-border-color : transparent;


// Buttons tertiary ('.outlined' in BT)
$btn-tertiary-bg                 : $white;
$btn-tertiary-color              : $nobel;
$btn-tertiary-hover-bg           : $white;
$btn-tertiary-hover-color        : $hel-gray;
$btn-tertiary-border-width       : 1px;
$btn-tertiary-border-color       : $nobel;
$btn-tertiary-hover-border-color : $nobel;


// Buttons first auxiliary ('.btn-blue' in BT)
$btn-auxiliary-bg                 : $teal-lightest;
$btn-auxiliary-color              : $white;
$btn-auxiliary-hover-bg           : $teal-lightest;
$btn-auxiliary-hover-color        : $white;
$btn-auxiliary-border-width       : 1px;
$btn-auxiliary-border-color       : transparent;
$btn-auxiliary-hover-border-color : transparent;


// Buttons second auxiliary ('.black' in BT)
$btn-quadruple-bg                 : $black;
$btn-quadruple-color              : $white;
$btn-quadruple-hover-bg           : $black;
$btn-quadruple-hover-color        : $white;
$btn-quadruple-border-width       : 1px;
$btn-quadruple-border-color       : transparent;
$btn-quadruple-hover-border-color : transparent;


//----------------------------------------------------------------------
// Transferred from redesign
//----------------------------------------------------------------------

// COLORS
$black01: #000000;
$black02: #151515;
$white01: #FFFFFF;
$red01: #D50000;

$grey01: #999999; //main gray
$grey02: #CCCCCC; //light gray
$grey03: #EEEEEE; //lighter gray
$grey04: #666666; //dark gray
$grey05: #707070;

// Energy rating colors
$energy-rating-a3: #00892f;
$energy-rating-a2: #2d9f29;
$energy-rating-a1: #9fc011;
$energy-rating-a: #ffca00;
$energy-rating-b: #fb9f06;
$energy-rating-c: #ee4b0d;
$energy-rating-d: #e60b0e;

// FONTS: WEIGHT
$regular: 400;
$medium: 500;
$bold: 700;
$xbold: 800;


//----------------------------------------------------------------------
// Site Header settings
//----------------------------------------------------------------------
$header-top-bannet-bg: $white;
$header-height-mobile: 111px;

//header logo
$header-logo-color: $nobel;
$header-logo-hover-color: $nobel;

//promo banner
$header-banner-height: 28px;  //height of ".header-banner"

$header-promo-banner-bg: $nobel;
$header-promo-banner-text-color: $white;
$header-promo-banner-link-color: $white;
$header-promo-banner-divider-color: $charcoal;

//navigation
$header-nav-button-close-bg: $nobel;
$header-nav-button-close-color: $white;
$header-nav-button-close-hover-color: $very-light-gray;

$header-nav-width-mobile: 66%;

// header search
$header-search-bg: $white-smoke; // bg for header-search block on mobile
$header-search-text-color: $nobel;
$header-search-border-color: $very-light-gray;

// header search desktop
$header-search-bg-desktop: transparent; //bg for header-search block on desktop

// header search field

$header-search-field-width-mobile: 100%; // for mobile
$header-search-field-bg: $white;
$header-search-field-border-color: $tutu;
$header-search-field-border-width: 1px;
$header-search-field-border-radius: 0.4rem;

$header-search-field-width: 10rem; // for tablet/mobile collapsed

$header-search-field-width-tablet: 17rem; // for tablet in focus

// header search field desktop
$header-search-field-width-desktop: 200px; // for desktop in focus
$header-search-field-bg-desktop: $white;
$header-search-field-width-tablet: 170px; // for tablet in focus
$header-search-field-border-color-desktop: $grey05;
$header-search-field-border-width-desktop: 1px;
$header-search-field-border-radius-desktop: 0;

// header search button
$header-search-button-color: $nobel;
$header-search-button--hover-color: $dim-gray;


//----------------------------------------------------------------------
// Site Footer settings
//----------------------------------------------------------------------
$footer-bottom-bg                : $black;
$footer-bottom-color             : $white;
$footer-bottom-links-color       : $white;
$footer-bottom-links-hover-color : $white;

$footer-panel-bg                 : $white-smoke;


//----------------------------------------------------------------------
// Homepage settings
//----------------------------------------------------------------------
$slot-panels-asset-link-bg : $second-lightest-gray;


//----------------------------------------------------------------------
// My account settings
//----------------------------------------------------------------------
$my-account-customer-info-bg          : $white;
$my-account-customer-info-title-color : $nobel;
$my-account-customer-info-text-color  : $nobel;

$my-account-header-banner-bg          : $white-smoke;
$my-account-wishlist-banner-bg        : $ghost;


//----------------------------------------------------------------------
// Search result settings
//----------------------------------------------------------------------
$search-result-banner-bg               : $sangria;
$search-result-banner-text-color       : $nobel;
$search-result-banner-link-color       : $white;
$search-result-banner-link-hover-color : $white-smoke;
$search-result-slot-grid-header-bg     :$very-light-gray;

$requirements-scrollbar-color          : $black;
$requirements-scrollbar-track-color    : $gainsboro;

//product tiles
$product-tile-border-width : 1px;
$grid-tile-width-desktop   : 25%;
$grid-tile-width-tablet    : 33.333%;
$grid-tile-width-mobile    : 50%;

//product tiles asset
$grid-tile-asset-width-desktop : 50%;
$grid-tile-asset-width-tablet  : 66.666%;
$grid-tile-asset-width-mobile  : 100%;

//----------------------------------------------------------------------
// Search result settings
//----------------------------------------------------------------------
$error-page-top-banner-bg                : $sangria;
$error-page-top-banner-text-color        : $white;
$error-page-top-banner-link-color        : $white;
$error-page-top-banner-title-color       : $white;
$error-page-top-banner-title-hover-color : $white-smoke;


//----------------------------------------------------------------------
// Cart settings
//----------------------------------------------------------------------
$cart-banner-bg           : $white-smoke;
$cart-banner-text-color   : $pink;
$cart-banner-border-color : $pink;
$cart-banner-border-width : 1px;
$cart-banner-padding      : 1.5rem;

//minicart
$minicart-width : 40rem;
// mobile specific values of menu and minicart
$minicart-mobile-width: 260px;

//checkout
//click and collect

$cc-store-info-icon-size: 44px;
$cc-stores-list-radio-size: 30px;

$ref-close-size: 70px;
$ref-overlay-width: 1500px;
$ref-wrap-padding: 20px;
$ref-border-color: #c8c8c8;
$ref-check-border-color: #acacac;
$ref-active-tab-bg: $black;
$ref-selected-tab-line-color: $black;

//checkout details page styles
.select-saved-addresses,
.billing-address {
    .js-terms-block {
        .form-row {
            display: inline-block;
            margin-right: 0;
            width: auto;

            &.required {
                label:after {
                    display: none;
                }
            }
        }
    }
}

.billing-address,
.checkout-shipping,
.theme-redesign {
    .address2 {
        + label {
            visibility: visible;
        }
    }
}

.select-saved-addresses {
    @include respond-to(desktop_plus) {
        width: 89%;
    }

    .select-saved-addresses_select {
        position: relative;

        @include respond-to(mobile_mid) {
            margin-bottom: 25px;
        }
    }

    //to reset styles for buttons
    .select-saved-addresses_button,
    .add-new-address_button,
    .add-address_button {
        padding: 0;
        border: none;
        text-transform: none;
        letter-spacing: normal;
        text-decoration: underline;
        background-color: transparent;
        color: $link-default-color;
        margin-top: 10px;

        @include respond-to(mobile_tablet) {
            width: auto;
        }

        &:hover {
            text-decoration: none;
            background-color: transparent;
            color: $link-default-color;
        }
    }

    .select-saved-addresses_button {
        margin-right: 25px;
    }

    .submit-billing {
        display: table;
        margin-top: 40px;
        margin-right: 0;
        margin-left: auto;
    }
}


// checkout login page styles
$co-space1: 12px;
$co-space2: 20px;

.pt_checkout {
    .top-banner {
        height: auto;

        .primary-logo {
            @include respond-to(mobile_strong) {
                font-size: rem(26);
                padding: $co-space2 0;
                width: auto;
            }
        }
    }
}

.checkoutlogin-wrapper {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(space-between);
    min-height: 100vh;
}

.breadcrumb-checkout {
    display: none;
}

.checkoutlogin {
    @extend %clearfix;
    margin: 55px auto 85px;
    text-align: left;

    @include respond-to(mobile_strong) {
        margin: 35px auto 10px;
    }

    .checkoutlogin-title {
        padding-bottom: 25px;
        text-transform: capitalize;
        font-size: rem(35);
        text-align: center;
        line-height: 1;

        @include respond-to(mobile_strong) {
            padding-top: 15px;
            font-size: rem(27);
        }

        &-description {
            display: none;
        }

        .co-icon-lock {
            font-size: rem(32);
        }
    }

    .error-include {
        @include respond-to(mobile_strong) {
            padding: 0 20px;
        }

        @include respond-to(mobile_strong_over) {
            padding: 0 60px;
        }

        @include respond-to(tablet_plus) {
            padding: 0 72px;
        }

    }

    .primary-content {
        max-width: 980px;
        width: 100%;
    }

    .checkoutlogin-col-wrapper {
        padding-top: 25px;

        @include respond-to(mobile_strong_over) {
            @include flexbox;
        }
    }

    .checkoutlogin-error {
        @include hidden;
    }

    .checkoutlogin-redesign {
        @include respond-to(mobile_strong_over) {
            padding-top: 0;
        }
        @include respond-to(desktop_plus) {
            padding-top: 25px;
        }

        .form-row.required label:after {
            content: none;
        }

        .dialog-required {
            display: none;
        }

        .checkoutlogin-guest-wrapper {
            text-align: center;

            @include respond-to(mobile_strong_over) {
                display: none;
            }

            &:hover {
                text-transform: none;
            }
        }

        .checkoutlogin-guest-link {
            font-size: rem(19);
            text-decoration: underline;
        }

        .checkoutlogin-guest-or {
            font-weight: 900;
            margin: $co-space2 0;
            text-transform: uppercase;
        }

        .checkoutlogin-divider {
            display: none;
            width: 80%;
            height: 2px;
            background-color: $nero2;
            margin: $co-space2*3 auto;

            @include respond-to(mobile_tablet) {
                display: block;
            }

            @include respond-to(mobile_mid) {
                width: 86%;
            }
        }
    }

    .col-1,
    .col-2 {
        margin: 0;
        width: 50%;
        padding: 0 $co-space1*2;

        @include respond-to(mobile_strong) {
            width: 100%;
            padding: 0 10px;
        }
    }

    .col-1 {
        @include respond-to(mobile_strong_over) {
            border-right: 1px solid $black;
        }

        @include respond-to(mobile_strong) {
            position: relative;

            &:after {
                content: '';
                display: block;
                width: 83%;
                margin: 0 auto;
                border-bottom: 2px solid $nero2;
            }
        }

        .checkoutlogin-guest-link {
            position: absolute;
            left: 22px;
            top: 35px;
            text-decoration: underline;

            @include respond-to(mobile_strong_over) {
                display: none;
            }

            &:hover {
                text-transform: none;
            }
        }
    }

    .col-2 {
        @include respond-to(mobile_strong) {
            margin-top: 65px;
        }
    }

    .login-box {
        &.login-account {
            padding: 0;
            margin: 0;

            @include respond-to(mobile_strong) {
                margin-bottom: 70px;
            }
        }

        .my-account-subtitle,
        .checkout-subtitle {
            text-align: left;
            font-size: rem(24);
            text-transform: capitalize;
            margin-bottom: $co-space2;
            font-weight: 400;
            line-height: 1;
            padding: 0 $co-space1;

            @include respond-to(mobile_strong_over) {
                margin-left: 8.3333333333%;
                text-align: left;
            }
        }

        .my-account-subtitle {
            @include respond-to(mobile_strong) {
                margin-bottom: 50px;
            }
        }

        .checkout-redesign-registered,
        .checkout-redesign-guest {
            display: block;
            font-size: rem(16);
            margin: 15px 0 0 0;
            text-transform: none;
        }

    }

    .login-box-content {
        padding: 0 $co-space1;
        border-top: none;

        @include respond-to(mobile_strong_over) {
            margin: 0 0 0 8.3333333333%;
            width: 83.3333333333%;
        }
    }

    .checkout-create-account {
        display: none;
    }

    .login-box-content-separator {
        font-size: rem(18);
        margin: 40px 0;
        position: relative;
        text-align: center;

        @include respond-to(mobile) {
            margin: 30px 0;
        }

        &::before {
            border-bottom: 1px solid $very-light-gray;
            content: '';
            left: 0;
            margin-top: 1px;
            position: absolute;
            right: 0;
            top: 50%;
            width: 100%;
            z-index: 0;
        }

        span {
            background: $white;
            display: inline-block;
            padding: 0 10px;
            position: relative;
            z-index: 1;
        }
    }

    .form-row {
        margin: 16px 0 0;
    }

    .field-wrapper {
        margin-bottom: $co-space2;
    }

    .checkout-login-content {
        overflow: hidden;
    }

    .form-row-button,
    .checkout-login-content {
        button {
            width: 100%;
        }
    }

    .form-row-button,
    .checkout-login-content button {
        margin: $co-space2 0 $co-space2*2;
    }

    .guest-message {
        line-height: 1;
    }

    #password-reset {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &.bt-redesign {
        margin: 75px auto 85px;

        @include respond-to(mobile) {
            padding-top: 0;
            margin: 35px auto 75px;
        }

        .checkoutlogin-title {
            @include respond-to(mobile_strong) {
                padding: 0;
            }
        }

        .primary-content {
            margin: 0 auto;
        }

        .checkoutlogin-col-wrapper {
            @include respond-to(mobile_strong_over) {
                display: block;
            }

            @include respond-to(desktop_plus) {
                @include flexbox;
            }
        }

        .checkoutlogin .login-box.login-account {
            @include respond-to(mobile_tablet) {
                margin_bottom: 0;
            }
        }

        .col-1,
        .col-2 {
            @include respond-to(mobile_tablet) {
                width: 100%;
                padding: 0 10px;
            }
        }

        .col-1 {
            border-right: none;

            @include respond-to(desktop_plus) {
                border-right: 1px solid $black;
            }

            &:after {
                @include respond-to(mobile_tablet) {
                    content: none;
                }
            }

            .checkoutlogin-guest-link {
                position: static;
            }
        }

        .my-account-subtitle,
        .checkout-subtitle {
            margin-left: 0;
            text-align: center;

            @include respond-to(desktop_plus) {
                margin-left: 8.3333333333%;
                text-align: left;
            }
        }

        .my-account-subtitle {
            @include respond-to(mobile_strong) {
                margin-bottom: 0;
            }
        }

        .login-box-content {
            margin-left: 0;
            width: 100%;

            @include respond-to(desktop_plus) {
                margin: 0 0 0 8.3333333333%;
                width: 83.3333333333%;
            }
        }

        .field-wrapper .invalid ~ label {
            color: $pink;
        }

        .form-row-button,
        .checkout-login-content button {
            margin: $co-space2 0 $co-space2*2;

            @include respond-to(mobile_tablet) {
                margin: $co-space2 0;
            }
        }
    }
}

.footer-checkout {
    border-top: 1px solid $sections-body-border;
    padding: 0;
    background-color: transparent;
}

.footer-checkout-container {
    padding: 0;
    max-width: none;
}

.footer-checkout-list {
    @include flexbox;
    @include justify-content(space-around);
    margin: auto;
    max-width: 768px;

    &:after {
        display: none;
    }

    @include respond-to(mobile_strong) {
        @include flex-direction(column);
        margin: $co-space2 auto;
    }
}

.footer-checkout-item {
    width: auto;
    margin: auto;
    padding: 0;
    float: none;

    &.fci-right {
        @include order(2);
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &.fci-left {
        @include order(1);
    }

    &.fci-center {
        @include order(3);
    }

    img {
        margin: auto;
        display: block;
    }
}

.footer-checkout-description {
    background-color: $nero;
    color: $white;
    font-size: rem(18);
    padding: 11px 0;
    line-height: 1.5;

    @include respond-to(mobile_strong) {
        font-size: rem(12);
        padding: 5% 2.5%;
    }

    a {
        color: $white;

        &:hover {
            color: $dim-gray;
        }
    }
}

.cc-form {
    @extend %clearfix;
    margin-bottom: 25px;

    &-title {
        font-size: rem(18);
        font-weight: 700;
        margin-bottom: 15px;
        text-transform: none;

        @include respond-to(mobile) {
            margin-bottom: 5px;
        }
    }

    .form-row {
        @extend %clearfix;
        float: left;
        margin: 0;
        width: 47%;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            margin-bottom: 10px;
        }

        &:last-child {
            float: right;

            @include respond-to(mobile) {
                float: none;
            }
        }
    }

    label {
        line-height: 38px;
        margin: 0;
        text-align: left;
        width: auto;
        display: none;

        @include respond-to(mobile) {
            display: block;
            float: none;
        }

        .required-indicator {
            display: none;
        }
    }

    .field-wrapper {
        width: auto;
        margin: 0;
        float: none;
        width: 100%;
        padding-left: 0;
    }
}

.cc-stores-list {
    @extend %clearfix;
    
    &-title {
        font-size: rem(18);
        font-weight: 700;
        margin-bottom: 15px;
        text-transform: none;
    }

    .form-row {
        @extend %clearfix;
        position: relative;
        border-bottom: 1px solid $very-light-gray;
        display: table;
        float: right;
        margin: 0 0 20px;
        width: 46%;

        @include respond-to(mobile) {
            width: 100%;
            float: none;
        }

        &:nth-child(even) {
            float: left;

            @include respond-to(mobile) {
                float: none;
            }
        }
    }

    .cc-stores-list_radio {
        display: table-cell;
        margin-bottom: 0;
        padding-left: 0;
        width: $cc-stores-list-radio-size;
        float: none;
        height: $cc-store-info-icon-size;
        vertical-align: middle;
    }

    input[type="radio"] {
        margin: 5px 0 0;
    }

    .cc-stores-list_text {
        display: table-cell;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        text-align: left;
        width: auto;
        float: none;
        vertical-align: middle;
        padding-right: $cc-store-info-icon-size;
    }

    .cc-stores-list_i {
        position: absolute;
        right: 0;
        bottom: 0;
        width: $cc-store-info-icon-size;
    }

    .view-store-cnc-info {
        background-color: $very-light-gray;
        color: $white;
        display: block;
        height: $cc-store-info-icon-size;
        line-height: $cc-store-info-icon-size;
        text-align: center;
    }
}

.checkout-shipping {
    .flayer {
        background: #eaeae9;
        border-radius: 10px;
        margin: 40px 0;
        padding: 20px;
        text-align: center;

        &_image {
            display: block;
            margin: 10px auto;
            max-width: 100%;
        }
    }

    .instore-continue-button.cc-button {
        margin: 0;
        text-align: right;
        width: auto;
    }
}

.dialog-cc_title {
    font-weight: 500;
    margin-bottom: 10px;
}

.dialog-cc_contact {
    margin-bottom: 15px;
}

.dialog-cc_station {
    margin-bottom: 15px;
}

.dialog-cc_openinghours {
    .store-opening-hours {
        margin-bottom: 0;

        div {
            min-height: 20px;
        }

        .left,
        .right {
            background: $white;
        }
    }
}
.pt_order,
.pt_account {
    &_inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .primary-content {
        width: 66%;
        float: none;

        @include respond-to(m_tablet_down) {
            width: 100%;
            order: 3;
        }
    }

    #secondary {
        width: 27%;
        float: none;

        @include respond-to(m_tablet_down) {
            width: 100%;
            order: 1;
            padding-top: 40px;

            &:before {
                content: "js-tablet-nav";
                display: none;
            }
        }

        @include respond-to(tablet_plus) {
            nav {
                a {
                    font-size: rem(14);
                }
            }
        }
    }
}

.b-account-customer_info {
    margin: 40px 0 60px;
    text-align: center;
    font-weight: 500;
    width: 100%;

    @include respond-to(m_tablet_down) {
        order: 2;
    }

    .b-account-header {
        font-size: rem(32);
    }

    .b-account-greeting {
        display: inline-block;
        font-family: $font-second;
        font-size: rem(24);
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 15px;
        color: #000;

        &:after {
            content: "";
            border-bottom: 1px solid;
            width: 55px;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: absolute;
            top: 100%;
        }
    }
}

.pt_account {
    &_unregistered {
        .primary-content {
            width: 100%;
            padding-top: 30px;
        }
    }

    .form-full-width {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding-top: 30px;

        legend {
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid $tutu;
            text-transform: capitalize;

            @include respond-to(mobile) {
                padding-top: 20px;
                margin-bottom: 10px;
            }
        }

        label {
            width: 16%;
            text-align: left;
            width: 20%;
            font-size: rem(14);

            @include respond-to(mobile) {
                width: auto;
                text-align: left;
                font-size: rem(12);
            }
        }

        .field-wrapper {
            width: 80%;

            @include respond-to(mobile) {
                width: auto;
            }

            label {
                width: 100%;
            }
        }

        .form-row {
            margin: 2rem 0;

            @include respond-to(mobile) {
                margin: 7px 0;
            }

            &.label-inline {

                .field-wrapper {
                    width: auto;
                    margin-left: 18%;

                    @include respond-to(mobile) {
                        margin-left: 0;
                    }
                }
            }
        }

        .form-fieldset-loyalty {
            .form-fieldset-subtitle {
                margin-bottom: 20px;
                line-height: 2;
            }

            .field-wrapper {
                width: 75%;
                padding-left: 0;

                @include respond-to(mobile) {
                    width: auto;
                    float: none;
                    line-height: normal;
                    margin-bottom: 20px;
                }

                &:last-child {
                    @include respond-to(mobile) {
                        margin-bottom: 5px;
                    }
                }

                label {
                    float: none;
                    width: auto;
                    display: inline-block;

                    @include respond-to(mobile) {
                        width: 85%;
                        display: inline-block;
                        line-height: inherit;
                        text-align: left;
                    }
                }

                input[type="checkbox"],
                input[type="radio"] {
                    @include respond-to(mobile) {
                        display: inline-block;
                        vertical-align: top;
                        margin-top: 4px;
                    }
                }
            }

            .form-row.form-row-loyalty {
                height: auto;

                label {
                    float: none;
                    width: auto;
                    margin-bottom: 6px;
                }

                .field-wrapper {
                    padding-left: 0;
                }
            }
        }

        .form-row-one-third {
            display: inline-block;
            vertical-align: top;
            margin: 5px 0;

            label {
                display: none;
            }

            select {
                margin-right: 20px;

                @include respond-to(mobile) {
                    margin-right: 0px;
                }
            }

            .field-wrapper {
                width: 85%;

                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        .form-caption {
            margin-left: 18%;
            color: $hel-gray;
            font-size: rem(14);

            @include respond-to(mobile) {
                margin-left: 0;
            }
        }

        .form-row-button {
            width: 100%;
            text-align: right;

            @include respond-to(mobile) {
                text-align: center;
            }
        }

        .form-fieldset-inline {

            label {
                display: inline-block;
                margin-right: 25px;
            }

            .form-row {
                display: inline-block;
                vertical-align: top;
            }

            .field-wrapper.field-wrapper {
                display: inline;
                margin-left: 0;
            }
        }

        .form-fieldset-block-mobile {
            @include respond-to(mobile) {
                display: block;
            }
        }

        .form-row.form-row-loyalty {
            height: 40px;
            clear: left;
            margin: 30px 0 0;

            .field-wrapper {
                width: 50%;

                @include respond-to(mobile) {
                    width: 100%;
                }
            }

            label {
                line-height: 20px;
            }

            & + .form-caption {
                @include respond-to(mobile) {
                    margin-top: 30px;
                }
            }
        }

        fieldset,
        .form-fieldset-account-birthday {
            margin-bottom: 30px;

            p {
                margin: 10px 0 0;
            }
        }

        .form-submit-footer {
            padding-top: 40px;
            border-top: 1px solid $tutu;

            button {
                margin: 20px 0;

                @include respond-to(mobile) {
                    margin-bottom: 40px;
                }
            }

            a {
                text-decoration: underline;
            }
        }
    }
}

.account-content-wrapper {
    height: 275px;
}

.account-content-inner {
    display: block;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255,255,255, .8);
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @include respond-to(mobile) {
        width: 80%;
    }

    a {
        width: 80%;
        margin: 20px auto 0;
        display: block;
        border: 1px solid;
        padding: 5px;
        font-size: rem(12);
        text-transform: uppercase;
    }

    span {
        display: block;
        font-size: rem(12);
        line-height: 1.5;
        margin-top: 10px;
    }
}

/* User agent styling */
.user-agent-logged {
    margin-top: 30px;
}

.user-agent-header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    text-transform: uppercase;
    color: $black;
    font-weight: 500;
    line-height: 20px;
    background: $pink;
    z-index: 99000;
}

.sticky-wrapper.is-sticky {
    .user-agent-header {
        position: relative;
    }

    .user-agent-logged & .top-banner {
        margin-top: 30px;
    }
}

.header-banner {
    padding-top: 0;
}

.header-banner.with-agent-header {
    padding-top: 30px;

    & + .sticky-wrapper.is-sticky {
        padding-top: 0;
    }
}

/* Subscribe form */
.js_email-join-header {
    text-align: center;
}

.error-box--subscribe {
    max-width: 450px;
    margin-top: 30px;
}
.containter-cart-wish-search {
    @extend %clearfix;
    position: relative;
    z-index: 2;

    @include respond-to(mobile_strong_over) {
        position: absolute;
        top: 0;
        right: 0;

        .is-sticky & {
            position: static;
        }
    }

    @include respond-to(desktop_over) {
        right: 50%;
        margin-right: -630px;

        .is-sticky & {
            margin-right: 0;
        }
    }
}

.menu-utility-user {
    @extend %clearfix;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;

    @include respond-to(mobile_strong) {
        flex-wrap: wrap;
        z-index: 10;
        transform: translate3d(0,0,0);
    }

    @include respond-to(mobile_strong_over) {
        display: block;
        position: absolute;
        top: -5.5rem;
    }

    @include respond-to(desktop_over) {
        left: 50%;
        margin-left: -636px;
    }

    &-auth {

        @include respond-to(mobile_stronger) {
            top: -6.3rem;
            display: flex;
        }
    }

    .int-country-selector {
      cursor: pointer;

        @include respond-to(mobile_strong) {
            order: 3;
            border-bottom: none;
            flex-basis: 100%;
        }

        @include respond-to(mobile_strong_over) {
            font-size: 1.1rem;
            padding: .1em 0 0 1.5em;
        }

        .int-country-selector-locale {
            font-size: rem(11);
            letter-spacing: 0.1rem;
            line-height: 1.8rem;
            pointer-events: none;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                padding-right: 25px;
                background: url("../img/icons/globe.svg") left / contain no-repeat;
            }
        }
    }

    .utility-store {
        > i {
            font-size: rem(15);
        }

        .content-asset {
            float: left;

            .toggle {
                float: left;
                padding-left: 0.6rem;
                font-size: rem(10);
                line-height: 1.8rem;
                letter-spacing: 0.1rem;
                text-transform: uppercase;
            }
        }

        &:hover > i {
            color: $dim-gray;
        }

        @include respond-to(mobile_strong) {
            order: 1;
        }
    }

    li {
        background-color: $white-smoke;
        border-bottom: 1px solid $very-light-gray;
        flex: auto;
        list-style: none;
        padding: 0.8em 1rem;

        @include respond-to(mobile_strong) {
            padding: 1rem 0.8rem;
        }

        @include respond-to(mobile_strong_over) {
            float: left;
            position: relative;
            background-color: transparent;
            border-color: transparent;
            margin: 0;
            padding: 0.1em 0 0 1em;
            text-align: center;

            &:first-child {
                border: none;
            }
        }

        @include respond-to(mobile_stronger) {
            display: flex;
            align-items: center;
        }

        + li {
            @include respond-to(mobile_strong) {
                border-left: 1px solid $very-light-gray;
            }
        }

        i {
            float: left;
            font-size: rem(15);
            padding: 0;
            transition: color .5s ease;
        }

        > i {
            color: $nobel;
        }

        a {
            text-decoration: none;
            color: $nobel;

            &:active {
                color: $dim-gray;
            }

            @include respond-to(mobile_stronger) {
                display: flex;
                align-items: center;
            }

            @include respond-to(mobile_strong_over) {
                color: $nobel;
                transition: color .5s ease;

                &:hover {
                    color: $dim-gray;
                    text-decoration: none;
                }

                i {
                    display: block;
                    float: left;
                    font-size: rem(14);
                    padding: 0;
                }

                span {
                    float: left;
                    padding-left: 0.6rem;
                    font-size: rem(11);
                    letter-spacing: 0.1rem;
                    text-transform: uppercase;
                }
            }
        }

        &.stores a i {
            font-size: rem(16);
            margin-top: -0.1rem;
        }
    }

    .user-info {
        @include respond-to(mobile_strong) {
            order: 2;
        }

        @include respond-to(mobile_strong_over) {
            position: relative;
            transition: color .5s ease;
        }

        > a {
            &:after {
                content: "Login";
                float: left;
                padding-left: 0.6rem;
                font-size: rem(11);
                line-height: 1.8rem;
                letter-spacing: 0.1rem;
                text-transform: uppercase;

                @include respond-to(mobile_strong_over) {
                    display: none;
                }
            }

            &.user-account {
                &:after {
                    content: "Account";
                }
            }

            i {
                font-size: rem(15);

                span {
                    @include hidden;
                }
            }

            span {
                display: none;
                text-align: left;
                word-break: break-word;
                max-height: 50px;
                overflow: hidden;

                @include respond-to(mobile_strong_over) {
                    display: block;
                }

                @include respond-to(mobile_stronger) { // 768px - 992px
                    max-width: 100px;
                    word-break: break-word;
                }

                @include respond-to(desktop_plus) { // >= 993px
                    max-width: 175px;
                }

                @include respond-to(m_desktop) { // >= 1024px
                    max-width: 240px;
                }

                @include respond-to(desktop_over) { // >= 1261px
                    max-width: 350px;
                }
            }
        }

        &.active {
            .user-login {
                color: $citrus;
            }

            .user-panel {
                opacity: 1;
                visibility: visible;
            }
        }

        .user-panel {
            box-sizing: border-box; //See https://code.google.com/p/chromium/issues/detail?can=2&q=max-content&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified&id=522191&thanks=522191&ts=1439925077
            text-align: left;
            transition: all .3s ease;
            position: absolute;
            top: 5rem;
            left: 0.8rem;
            width: 95%;
            border: 1px solid $charcoal;
            background-color: $white;
            visibility: hidden;

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 6px;
                border-color: transparent transparent white transparent;
                border-bottom-style: solid;
                position: absolute;
                top: -1.2rem;
                left: -webkit-calc(50% + 1rem);
                left: calc(50% + 1rem);
                z-index: 10;
            }

            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 7px;
                border-color: transparent transparent $charcoal transparent;
                border-bottom-style: solid;
                position: absolute;
                top: -1.4rem;
                left: -webkit-calc(50% + 0.9rem);
                left: calc(50% + 0.9rem);
                z-index: 2;
            }

            @include respond-to(mobile_strong_over) {
                top: 3rem;
                left: 2rem;
                width: 200px;

                &:before {
                    left: 1rem;
                }

                &:after {
                    left: 0.9rem;
                }
            }

            a,
            .buttonstyle {
                display: block;
                float: left;
                clear: both;
                width: auto;
                padding: 0.8rem;
                font-size: rem(11);
                letter-spacing: 0.1rem;
                text-transform: uppercase;
                color: $mid-gray;
                background: transparent;
            }

            h3 {
                display: none;
                margin: 0;
                padding-bottom: .7em;
                text-transform: uppercase;

                @include respond-to(mobile_strong_over) {
                    display: block;
                }
            }

            @include respond-to(mobile_strong_over) {
                visibility: hidden;
            }

            .user-links {
                @extend %clearfix;
                text-align: center;
            }
        }
    }
}

// menu
.features-nav-flyout {
    position: fixed;
    z-index: 4;
    background-color: $white;
    height: 100vh;
    overflow-x: auto;
    top: 0;
    padding-top: 125px;
    transition: top 0.3s;
    width: 100%;

    &.invisible {
        top: -100vh;
    }
}

.menu-container {
    padding: 80px 15px;
    max-width: 950px;
    margin: 0 auto;

    @include respond-to(m_tablet) {
        padding: 100px 30px;
    }
}

.menu-page-title {
    @extend %clearfix;

    padding-bottom: 30px;
    border-bottom: 1px solid $m_menu_border_color;
    font-size: rem(18);

    @include respond-to(m_tablet) {
        padding-bottom: 10px;
        margin-bottom: 60px;
    }

    span.page-title-logo {
        height: 25px;

        @include respond-to(m_tablet) {
            float: left;
            width: 50%;
        }
    }

    .page-title-text {
        @include respond-to(m_tablet) {
            float: right;
        }
    }
}

.menu-main-links {
    border-bottom: 1px solid $m_menu_border_color;
    margin-bottom: 15px;

    @include respond-to(m_tablet) {
        display: flex;
        flex-flow: row wrap;
    }

    //.menu-main-links-title
    &-title {
        font-size: rem(26);
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        margin-bottom: 20px;

        @include respond-to(m_tablet) {
            font-size: rem(32);
        }

        @include respond-to(m_desktop) {
            font-size: rem(36);
        }

        &:after {
            content: '';
            background: url(#{$m_arrow-right}) no-repeat center right/10px 10px;
            position: absolute;
            z-index: 2;
            width: 20px;
            height: 100%;
        }
    }//.menu-main-links-title
}

.menu-content-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -15px;
    counter-reset: section;
}

.menu-content-listitem {
    position: relative;
    z-index: 1;
    padding: 15px 0;
    border-bottom: 1px solid $m_menu_border_color;
    display: flex;
    flex-flow: row wrap;
    margin: 0 15px;
    width: 100%;

    @include respond-to(m_tablet) {
        width: calc(100% / 2 - 30px);
    }

    @include respond-to(m_desktop) {
        width: calc(100% / 3 - 30px);

        .ie & {
            width: calc(100% / 3 - 31px);
        }
    }

    //.menu-content-listitem-image
    &-image {
        width: calc(100% / 3);
        max-width: 100px;

        img {
            max-width: none;
            width: 80px;
            display: block;

            @include respond-to(m_tablet) {
                width: 100px;
            }
        }
    }

    //.menu-content-listitem-text
    &-text {
        width: calc(100% / 3 * 2);
        padding: 0 15px 0 15px;

        &::after {
            counter-increment: section;
            content: counters(section,".") " ";
            position: absolute;
            right: 0;
            font-size: rem(22);
            font-family: $font-second;
            top: 15px;
        }

        @include respond-to(m_tablet) {
            padding: 0 20px 0 15px;
        }
    }

    //.menu-content-listitem-type
    &-type {
        font-family: $font-second;
        font-size: rem(12);
        margin: 0;
    }

    //.menu-content-listitem-title
    &-title {
        font-size: rem(14);
        font-weight: 700;
    }

    .menu-page-numbers {
        position: absolute;
        right: 0;
        font-size: rem(22);
        font-family: $font-second;
    }
}//.menu-content-listitem

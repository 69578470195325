.pt_product-search-result .primary-content h1.content-header {
    padding-left: 15px;
}

.pt_product-search-result {
    .slot-panels-container {
        .cp-two-image img {
            border-radius: 0;
        }

        img {
            border-radius: 4px;
        }
    }
}

.category-main-banner img {
    max-width: 100%;
}

.no-hits-search-term,
.no-hits-search-term-suggest {
    color: $dim-gray;
    text-transform: capitalize;

    .no-hits-meant &,
    .no-hits-found & {
        color: $nobel;
        text-decoration: underline;
        cursor: pointer;
    }
}

.no-hits-footer {
    font-size: rem(14);
    color: $dim-gray;
    margin-bottom: 25px;

    p {
        font-size: rem(14);
        margin: 0 0 5px 0;
    }

    a {
        color: $tutu;
    }
}

.noresults-recommendations-product {
    margin: 4rem 0;

    .main-title {
        display: inline-block;
        position: relative;
        width: 100%;
        padding: 1.5rem 0;
        margin-bottom: 2rem;
        font-size: rem(16);
        font-weight: 700;
        text-align: center;
        letter-spacing: 0;
        border-top: 1px solid $lightest-gray;
        border-bottom: 1px solid $lightest-gray;

        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 6px;
            border-color: $white transparent transparent transparent;
            border-top-style: solid;
            position: absolute;
            bottom: -12px;
            left: 50%;
            margin-left: -6px;
            z-index: 3;
        }

        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 7px;
            border-color: $lightest-gray transparent transparent transparent;
            border-top-style: solid;
            position: absolute;
            bottom: -14px;
            left: 50%;
            margin-left: -7px;
            z-index: 2;
        }

        span {
            &:after {
                display: none;
            }
        }
    }

    .js-nohits-container & {
        clear: both;
        padding-top: 4rem;
        margin-top: 0;
    }
}

.search-result-bookmarks {
    margin: 15px;

    .first {
        border-right: 1px solid $nobel;
        padding-right: 5px;
    }
}

.browse-categories {
    margin-top: 10px;
}

.search-result-options {
    .items-per-page {
        float: right;
        width: 20.2%;
        padding-top: 7px;
        margin-right: 45px;
        text-transform: uppercase;
        font-size: rem(13);
        text-align: right;

        @include respond-to(desktop-below) {
            width: 22%;
        }

        @include respond-to(touch) {
            padding-top: 50px;
            width: 31%;
            margin-right: 0;
        }

        @include respond-to(portrait) {
            padding-top: 24px;
            width: 41%;
        }

        @include respond-to(mobile) {
            width: 100%;
            font-size: rem(12);
            padding-top: 10px;
        }

        &.active {
            font-weight: 500;
        }

        &-selected {
            padding-left: 5px;
        }
    }

    .sizes-list,
    .sizes-view-all {
        display: inline-block;

        &.active {
            font-weight: 500;
        }
    }

    .sizes-dropdown {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        background: $white;
        z-index: 10;

        .items-per-page-number {
            display: block;
            font-weight: 400;

            &.selected {
                color: $nobel;
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }

    .sizes-list {
        position: relative;
        cursor: pointer;

        &:hover .sizes-dropdown {
            display: block;
        }
    }

    .sizes-view-all {
        margin-left: 8px;
        font-weight: 400;

        &.active {
            font-weight: 500;
        }

        &:before {
            content: '/';
            padding-right: 8px;
        }

        li {
            list-style-type: none;
            display: inline-block;
        }
    }

    form {
        margin-bottom: 0;
    }

    label {
        display: none;
    }

    select,
    .pag-items-show {
        @include respond-to(m_desktop) {
            display: inline-block;
            padding: 0.8rem;
        }
    }

    select {
        @include respond-to(m_desktop) {
            border: 1px solid $tutu;
            font-style: normal !important;
            padding: 8px 8px 8px 6px;
            color: $nobel;
        }
    }

    .pag-items-show {
        font-size: rem(15);
        color: $mid-gray;

        @include respond-to(m_desktop) {
            display: none;
        }

        @include respond-to(m_tablet_down) {
            display: block;
            font-size: rem(12);
            position: absolute;
            right: 20px;
            top: 77px;
        }
    }

    .pag-total-items-show {
        font-size: rem(14);
        color: $grey04;

        @include respond-to(m_tablet_down) {
            display: none;
        }

        @include respond-to(m_desktop) {
            display: inline-block;
            padding-top: 6px;
        }
    }

    .pagination {
        @extend %clearfix;

        @include respond-to(m_tablet_down) {
            float: right;
            min-width: 70px;
            width: 15.5%;
            text-align: right;
        }

        @include respond-to(m_desktop) {
            float: right;
        }

        @include respond-to(mobile_mid) {
            width: 27%;
        }

        .results-hits {
            color: $lightest-gray;
        }

        ul {
            border: 1px solid $tutu;
            border-radius: 5px;
            background-color: $white;
            margin: 0;
            padding-top: .5rem;
            text-align: center;

            @include respond-to(touch) {
                position: absolute;
                left: 0;
                right: 0;
                top: 14px;
            }

            @include respond-to(tablet_plus) {
                background-color: transparent;
                border: none;
                white-space: nowrap;
            }

            @include respond-to(mobile) {
                display: none;
            }
        }

        li {
            border-right: 1px solid $tutu;
            display: inline-block;
            vertical-align: top;

            @include respond-to(tablet_plus) {
                border: none;
                padding: 0 .5em;
            }

            a,
            &.current-page,
            i {
                display: block;
                font-size: rem(10);
                height: 32px;
                line-height: 2.1rem;
                text-align: center;
                width: 32px;

                &:hover {
                    color: $dim-gray;
                }

                @include respond-to(tablet_plus) {
                    font-size: rem(14);
                    height: auto;
                    line-height: inherit;
                    width: auto;
                }

                @include respond-to(mobile) {
                    font-size: rem(14);
                }
            }

            a {
                @include respond-to(mobile) {
                    width: 25px;
                    height: 27px;
                }
            }

            &.current-page {
                display: inline-block;
                padding-top: 1px;
                color: $nobel;
                border-bottom: 1px solid $nobel;
                font-weight: 500;
            }

            &.first-last {
                span {
                    line-height: 22px;
                }

                @include respond-to(mobile) {
                    a {
                        width: auto;
                    }
                }
            }
        }

        .page-next,
        .page-previous {
            text-transform: uppercase;
            font-weight: 500;

            a {
                color: $nobel;
                font-size: rem(14);
            }
        }

        .page-next {
            padding-left: 0.5em;
        }

        .page-last a {
            padding-left: 1.5rem;
        }

        .page-first a {
            padding-right: 1.5rem;
        }

        .page-previous {
            padding-right: 5px;
        }

        .first-last {
            padding: 0;

            &:nth-child(2) {
                a:not(.page-first) {
                    @include respond-to(tablet_plus) {
                        padding-right: .5em;
                    }
                }
            }

            &:nth-last-child(2) {
                a:not(.page-last) {
                    @include respond-to(tablet_plus) {
                        padding-left: .5em;
                    }
                }
            }

            .page-last {
                &:before {
                    content: '...';
                    padding-right: 8px;
                }
            }

            .page-first {
                &:after {
                    content: '...';
                    padding-left: 8px;
                }
            }
        }
    }

    &-bottom {
        .sort-by,
        .toggle-grid,
        .items-per-page,
        .pag-items-show,
        .pag-total-items-show {
            display: none;
        }

        .pagination {
            width: 100%;
        }

        @include respond-to(mobile) {
            margin-bottom: 10px;

            .pagination {
                ul {
                    display: block;
                    border: 0;
                }

                li {
                    border: 0;
                }
            }
        }
    }
}

.search-result-items {
    @include flexbox();
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;

    div[id^="cq_recomm_slot-"],
    .product-list-may-like {
        width: 100%;
    }

    .grid-tile {
        width: $grid-tile-width-desktop;

        @include respond-to(tablet) {
            width: $grid-tile-width-tablet;
        }

        @include respond-to(mobile) {
            width: $grid-tile-width-mobile;
        }

        &.asset {
            position: relative;
            overflow: hidden;
            width: $grid-tile-asset-width-desktop;

            @include respond-to(tablet) {
                width: $grid-tile-asset-width-tablet;
            }

            @include respond-to(mobile) {
                width: $grid-tile-asset-width-mobile;
            }

            .promo-tile-container {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                max-width: 80%;
                margin: 0 auto;
                text-align: center;
                color: $white;
            }

            .promo-tile-subtitle {
                display: inline-block;
                position: relative;
                font-family: $font-second;
                font-size: rem(24);
                margin: 20px;

                &:after {
                    content: "";
                    border-bottom: 1px solid;
                    width: 70%;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    position: absolute;
                    bottom: -0.5px;
                }
            }

            .promo-tile-wrapper {
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
            }

            .promo-tile-content {
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-bottom: 25px;
                font-size: rem(36);

                &-underline {
                    text-transform: uppercase;
                    display: inline-block;
                    border-bottom: 1px solid;
                    letter-spacing: 1px;
                    font-size: rem(16);
                }
            }

            video,
            .promo-tile-image {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            &.full-width {
                width: $grid-tile-asset-width-mobile;

                @include respond-to(tablet_plus) {
                    margin: 30px 0;
                }

                @include respond-to(mobile) {
                    margin: 30px 0 60px;
                }
            }

            .full_promo {
                &-wrapper {
                    display: flex;

                    @include respond-to(mobile) {
                        flex-direction: column;
                        padding: 0 13px;
                    }
                }

                &-info,
                &-image {
                    @include respond-to(tablet_plus) {
                        width: 50%;
                    }
                }

                &-image {
                    @include respond-to(mobile) {
                        margin-bottom: 20px;
                    }
                }

                &-title {
                    @include respond-to(tablet_plus) {
                        margin-bottom: 30px;
                        font-size: rem(26);
                    }

                    @include respond-to(mobile) {
                        margin-bottom: 15px;
                        font-size: rem(28);
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: column;

                    @include respond-to(tablet_plus) {
                        padding: 25px 10px 10px 40px;
                        text-align: left;
                        justify-content: center;
                    }

                    @include respond-to(mobile) {
                        text-align: center;
                    }

                    .button {
                        padding: 12px 20px;
                        font-size: rem(12);

                        @include respond-to(tablet_plus) {
                            max-width: 288px;
                        }

                        @include respond-to(tablet) {
                            max-width: none;
                        }
                    }
                }

                &-text {
                    margin: 0;
                    font-size: rem(16);
                    line-height: 1.2;

                    @include respond-to(tablet_plus) {
                        margin-bottom: 40px;
                    }

                    @include respond-to(mobile) {
                        margin-bottom: 20px;
                        text-align: left;
                    }

                    @include respond-to(mobile_stronger) {
                        text-align: center;
                    }
                }
            }
        }
    }

    .invisible {
        display: none;
    }
}

.search-result-content {
    clear: both;
    border: none;

    .main-title {
        color: $black02;
        font-size: rem(18);
        letter-spacing: .5px;
        margin-bottom: 0;
        padding: 50px 4px;
        position: relative;

        @include respond-to(mobile) {
            letter-spacing: 1.3px;
            padding-bottom: 45px;
        }

        span:after {
            display: none;
        }
    }

    .product-list-may-like {
        .carousel {
            .jcarousel-nav {
                font-size: rem(32);
                top: 30%;

                @include respond-to(mobile) {
                    top: 19%;
                }
            }

            .jcarousel-next {
                right: 0;
            }

            .jcarousel-prev {
                left: -10px;
            }
        }
    }

    .carousel {
        .jcarousel-next {
            right: 15px;
        }

        .jcarousel-prev {
            left: 15px;
        }

        .jcarousel-nav {
            @include respond-to(touch) {
                margin-top: -41px;
                padding: 30px 15px;

                &:hover {
                    color: $hel-gray;
                }
            }
        }

        .product-tile {
            padding-bottom: 40px;

            .product-raiting-wrapper {
                display: block;
                bottom: 5px;
            }
        }

        .product-image {
            height: 200px;
            margin-top: 0;

            @include respond-to(mobile) {
                height: 128px;
            }

            img {
                margin-top: 0;
                max-height: 200px;

                @include respond-to(mobile) {
                    max-height: 128px;
                }
            }
        }

        .product-brand {
            font-size: rem(14);
            padding-top: 3px;
            text-transform: capitalize;
        }

        .product-name {
            font-size: rem(12);
            letter-spacing: .5px;
            margin: 3px 0 8px;
        }

        .product-pricing {
            font-size: rem(12);
        }
    }

    .content-search-grid {
        @extend %clearfix;
        margin-bottom: 8rem;

        @include respond-to(mobile) {
            margin-bottom: 3rem;
        }

        &:first-child {
            margin-top: 6rem;
            @include respond-to(mobile) {
                margin-top: 2rem;
            }
        }

        .content-image-link {
            float: left;

            img {
                width: 100%;
                height: auto;
            }

            video {
                width: 100% !important;
                height: auto !important;
            }
        }

        .content-item-info {
            float: right;
            width: 48%;

            @include respond-to(mobile) {
                clear: both;
                width: 100%;
                margin-top: 3rem;
            }
        }
        &.feature-search-grid {
            .content-image-link {
                max-width: 32%;
            }

            .content-image {
                max-width: 32%;
            }

            .content-item-info {
                width: 62%;
            }
        }

        &.video-search-grid {
            .content-image-link {
                width: 48%;
            }

            .video-js-video-panel {
                display: inline-block;
                padding-left: 20px;

                @include respond-to(mobile) {
                    padding-left: 10px;
                }
            }

            video {
                @include respond-to(mobile) {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .content-item-info {
                width: 48%;

                @include respond-to(mobile) {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }

        @include respond-to(mobile) {
            &.feature-search-grid,
            &.video-search-grid {
                .content-image-link,
                .content-item-info {
                    clear: both;
                    width: 100%;
                }
            }

            &.video-search-grid {
                .content-item-info {
                    padding: 0 15px;
                }
            }
        }

        .content-item-title {
            font-size: rem(36);
            line-height: 3.4rem;
            text-transform: uppercase;
            margin-top: 0;

            @include respond-to(mobile) {
                font-size: rem(32);
                line-height: 3rem;
            }
        }

        .content-item-link {
            text-transform: uppercase;

            a {
                margin-bottom: 10px;
                display: inline-block;
                font-weight: 500;
                border-bottom: 1px solid $nobel;

                &:hover {
                    border-bottom-color: $dim-gray;
                }
            }

            .whats-on-cta {
                margin-bottom: 10px;
            }
        }
    }

    .product-price {
        .price-standard {
            color: $dim-gray;
            font-weight: 100;
            text-decoration: line-through;
        }
    }

    .video-search-grid .vjs-poster {
        background-size: 100% 75%; // to get 16:9 aspect ratio
    }
}

.producthits-wide .search-result-content  {
    border-left: 0;
}

.seo-wrapper {
    margin-bottom: 90px;

    @include respond-to(mobile_strong_over) {
        max-width: 90%;
    }

    .seo-title {
        text-transform: none;

        @include respond-to(m_tablet) {
            font-size: rem(18);
        }

        @include respond-to(m_tablet_down) {
            font-size: rem(16);
        }
    }

    .seo-text {
        margin: 10px 0 30px;
        color: $black02;

        @include respond-to(m_tablet) {
            font-size: rem(14);
        }

        @include respond-to(m_tablet_down) {
            font-size: rem(12);
            margin-bottom: 20px;
        }
    }

    .seo-list_wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .seo-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }

    .seo-list_title {
        margin-bottom: 10px;
        font-weight: $bold;

        @include respond-to(m_tablet) {
            font-size: rem(14);
        }

        @include respond-to(mobile_strong) {
            font-size: rem(12);
        }
    }

    .seo-item {
        font-size: rem(14);
        margin-right: 15px;

        @include respond-to(mobile_strong) {
            margin-bottom: 5px;
        }
    }

    .seo-link {
        @include link_underline;

        &::after {
            bottom: -1px;
        }
    }
}

.search-promo {
    padding: 20px;
}

#results-content {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    padding-top: 30px;
}

.folder-content-list {
    @include flexbox();
    align-items: stretch;
    clear: both;
    flex-wrap: wrap;
    padding: .5em 1%;

    li {
        background-color: $white-smoke;
        border: 1px solid $very-light-gray;
        flex-basis: 100%;
        flex-grow: 1;
        list-style-type: none;
        margin: .25rem;
        padding: 1rem;

        @include respond-to(mobile_min) {
            flex-basis: 40%;
        }
    }

    p {
        line-height: 1.1rem;
    }

    .content-title {
        display: block;
        font-size: 1.2em;
    }

    .readmore {
        font-style: italic;
        padding-left: .3rem;
    }
}

.no-results {
    padding: 20px;
}

.toggle-grid {
    @include respond-to(m_tablet_down) {
        display: block;
        font-size: rem(25);
        height: 60px;
        line-height: 60px;
        text-align: center;
    }

    @include respond-to(m_desktop) {
        display: none;
        position: absolute;
        right: 0;
    }

    i {
        cursor: pointer;
        margin-right: 0.3em;

        &:last-child {
            margin-right: 0;
        }
    }

    [data-option="column"] {
        color: $dim-gray;
    }

    &.wide {
        [data-option="wide"] {
            color: $dim-gray;
        }

        [data-option="column"] {
            color: $lightest-gray;
        }
    }
}

.wide-tiles {
    .search-result-content {
        @include respond-to(mobile) {
            border-left: none;
        }

        .product-detail {
            .minus,
            .plus {
                font-size: 1.4rem;
                height: 37px;
            }
        }
    }

    .search-result-items .grid-tile {
        width: 100%;
        border-right: 0;
        height: auto;
        padding-bottom: 25px;

        @include respond-to(mobile) {
            padding-bottom: 30px;
        }
    }
}

.search-results {
    margin: 4.5rem 0;

    &.no-search-results {}
}

.search-tab-holder {
    @extend %clearfix;
    float: none;
    clear: both;
    margin: 4rem 0 3rem 0;
    border-top: 1px solid $lightest-gray;
    border-bottom: 1px solid $lightest-gray;

    .search-tab-item {
        display: inline-block;
        position: relative;
        width: 33.33333333%;
        padding: 1.5rem 0;
        font-size: rem(16);
        font-weight: 700;
        text-align: center;
        color: $dim-gray;

        &:hover {
            color: $nobel;
            cursor: pointer;
        }

        &.search-tab-active {
            color: $nobel;

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 6px;
                border-color: $white transparent transparent transparent;
                border-top-style: solid;
                position: absolute;
                bottom: -12px;
                left: 50%;
                margin-left: -6px;
                z-index: 3;
            }

            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 7px;
                border-color: $lightest-gray transparent transparent transparent;
                border-top-style: solid;
                position: absolute;
                bottom: -14px;
                left: 50%;
                margin-left: -7px;
                z-index: 2;
            }
        }

        .search-tab-results {
            display: inline-block;
            font-size: rem(14);
            text-transform: none;
        }
    }
}

.no-hits-content-results {
    color: $black;
    margin-top: 0;

    &.no-hits-meant {
        margin-bottom: 20px;
    }
}

.no-hits-help {
    color: $black;
    padding: 0;
    font-size: rem(16);

    p {
        margin: 0;
    }

    ul {
        list-style: disc inside;
    }
}
.no-hits-search,
.error-page-search {
    color: $black;
    padding: 0;
    margin-top: 3rem;

    .no-hits-search-wrap,
    .error-page-search-wrap {
        position: relative;
        width: 20rem;

        input {
            width: 100%;
            padding: 0.6rem 3rem 0.6rem 0.6rem;
            border: 1px solid $charcoal;
            height: auto;
            font-size: rem(11);
            line-height: 1.6rem;
            background-color: $white;

            &::-webkit-input-placeholder {
                text-transform: uppercase;
                font-size: rem(13);
            }

            &::-moz-placeholder {
                text-transform: uppercase;
                font-size: rem(13);
            }

            &:-moz-placeholder {
                text-transform: uppercase;
                font-size: rem(13);
            }

            &:-ms-input-placeholder {
                text-transform: uppercase;
                font-size: rem(13);
            }
        }

        button {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            width: auto;
            padding: 7px 6px 5px 6px;
            background-color: transparent;
            color: $charcoal;
        }
    }
}

.error-page-content {
    .error-page-search {
        text-align: center;

        &-wrap {
            display: inline-block;
            width: 50%;

            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }
}

.error-page-top {
    background-color: $error-page-top-banner-bg;
    color: $error-page-top-banner-text-color;
    font-size: rem(16);
    text-align: center;
    margin-top: 30px;
    padding: 25px 50px 1px 50px;

    @include respond-to(mobile) {
        margin-top: 10px;
        padding: 15px 10px 1px 10px;
    }

    h2 {
        color: $error-page-top-banner-title-color;

        &:hover {
            color: $error-page-top-banner-title-hover-color;
        }
    }

    a {
        color: $error-page-top-banner-link-color;
    }
}

.error-page-footer {
    @include respond-to(desktop_plus) {
        margin-top: 20px;
    }

    &-asset {
        text-align: center;
        margin-bottom: 60px;

        .button {
            @include respond-to(mobile_tablet) {
                margin-bottom: 10px;
            }
        }
    }

    &-slot {
        margin-top: 50px;
        margin-bottom: 65px;

        @include respond-to(mobile_tablet) {
            margin-top: 20px;
        }

        .cat-blocks-carousel {
            padding: 30px 20px 50px 20px;
            background-color: $white-smoke;

            .carousel {
                .jcarousel-nav {
                    background: none;
                }

                .jcarousel-prev {
                    left: -11px;
                }

                .jcarousel-next {
                    right: -11px;
                }
            }

            .carousel-item {
                padding: 0 15px;

                .category-image {
                    display: block;
                    width: 100%;
                }

                h2,
                .category-description,
                .category-shop-link {
                    background-color: $white;
                }

                h2 {
                    display: block;
                    font-size: rem(20);
                    padding: 20px 20px 10px 20px;
                }

                .category-description {
                    padding: 0 20px;
                    color: $hel-gray;
                    word-wrap: break-word;
                }

                .category-shop-link a {
                    display: inline-block;
                    border-bottom: 1px solid;
                    margin: 20px;
                    font-size: rem(13);
                    text-transform: uppercase;
                }
            }
        }
    }
}

.no-results-categories {
    > h3 {
        margin-top: 20px;
        font-size: rem(18);
        font-weight: 700;
        padding: 9px 7px 17px 0;
        border-bottom: 1px solid $gainsboro;
    }

    .toggle.expanded:after {
        content: '-';
    }

    .refinements .hidden-refinement .toggle.expanded + .scrollable-refinement {
        display: block;

        & + .refinement-view-type {
            display: block;
        }
    }
}

/* PLP template 2 */
.cat-banner-wide {
    display: none;
}

.producthits-wide {
    .cat-banner-wide {
        display: block;
    }

    .category-online-subcategories {
        display: none;
    }

    .slot-panels-container .first-asset {
        @include flexbox();

        @include respond-to(mobile) {
            @include flex-vertical();
        }

        .additional-markup-content,
        .plp-header-txt {
            width: 50%;
            text-align: center;

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        .plp-header-txt h1 {
            display: inline-block;
            font-size: rem(18);
            position: relative;

            &:after {
                content: '';
                display: block;
                left: 0;
                right: 0;
                margin: auto;
                width: 70%;
                margin-top: 14px;
                border-bottom: 1px solid;
            }
        }

        .additional-content {
            @include respond-to(mobile) {
                text-align: center;
            }
        }

        .plp-header-txt {
            @include flexbox();
            @include flex-vertical();
            @include align-items(center);
            @include justify-content(center);

            p {
                width: 60%;
                min-width: 320px;
                margin: 13px auto;

                @include respond-to(mobile) {
                    width: 90%;
                    min-width: 0;
                }
            }
        }
    }
}

.footer {
    position: relative;

    &-panels {
        margin-bottom: 50px;
        padding: 50px 0;

        @include respond-to(mobile_strong) {
            margin-bottom: 0;
            padding: 0;
        }
    }

    &-panels-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 1260px;
        padding-left: 15px;
        padding-right: 15px;
        @extend %clearfix;

        @include respond-to(mobile_stronger) {
            max-width: 768px;
        }

        @include respond-to(mobile_strong) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-panel-list {
        @extend %clearfix;
    }

    &-panel {
        float: left;
        padding: 0 15px;
        width: 33.333%;

        @include respond-to(mobile_tablet) {
            display: none;
            float: none;
            padding: 0;
            width: auto;
        }

        a {
            display: block;
        }

        .content-asset {
            background-color: $footer-panel-bg;
            padding: 20px;
            text-align: center;

            @include respond-to(mobile_strong) {
                background-color: transparent;
                padding-right: 0;
                padding-left: 0;
            }
        }

        &.mobile-primary {
            display: block;
        }
    }

    &-panel-title {
        display: block;
        font-size: rem(14);
        font-weight: 500;
        letter-spacing: 1px;
        margin: 0 0 10px 0;
        text-transform: uppercase;
    }

    &-panel-description {
        display: block;
        height: 42px;
        margin-bottom: 12px;
        overflow: hidden;
        font-size: rem(14);
    }

    &-panel-form {
        margin-left: auto;
        margin-right: auto;
        width: 75%;

        @include respond-to(mobile_strong) {
            width: auto;
        }
    }

    .footer-top-block2 {
        .input-text {
            height: auto;

            &:focus {
                @include respond-to(mobile_strong_over) {
                    background-color: $white;
                }
            }
        }

        .gen-cta {
            width: auto;
            font-size: rem(12);
            border: none;
        }

        .content-asset {
            @include respond-to(mobile_tablet) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    // Bottom Footer

    &-links {
        background-color: $footer-bottom-bg;
        bottom: 0;
        color: $footer-bottom-color;
        left: 0;
        min-height: 50px;
        position: fixed;
        right: 0;
        z-index: 100;

        .no-desktop .mini-cart-active & {
            position: static;
        }

        @include respond-to(m_tablet_down) {
            position: static;
        }

        a {
            color: $footer-bottom-links-color;

            &:hover {
                color: $footer-bottom-links-hover-color;
            }
        }
    }

    &-container {
        margin: 0 auto 0;
        max-width: 1260px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include respond-to(m_tablet_down) {
            flex-direction: column;
            max-width: 260px;
        }
    }

    &-position {
        max-height: 50px;
        overflow: hidden;
        padding-top: 13px;
        transition: 0.75s;

        @include respond-to(m_desktop) {
            &.expanded {
                max-height: 500px;

                dt:after {
                    content: "\e90f";
                }

                dd {
                    display: block;
                    visibility: visible;
                }
            }
        }

        @include respond-to(m_tablet_down) {
            max-height: none;
            overflow: visible;
            padding-bottom: 15px;
        }
    }

    &-column {
        @include respond-to(m_tablet_down) {
            .content-asset {
                border-bottom: 1px solid $night-rider;
                margin: 0 0 5px;
                text-align: center;
                display: block;
            }
        }
    }

    dl {
        margin: 0;
    }

    dt {
        cursor: pointer;
        font-size: rem(12);
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;

        @include respond-to(desktop) {
            font-size: 10px;
        }

        @include respond-to(m_tablet_down) {
            margin-bottom: 5px;

            &.expanded {

                & + dd {
                    display: block;
                }

                &:after {
                    content: "\e90f";
                }
            }
        }

        &:after {
            margin-left: 5px;
            font-family: $font-icons !important;
            font-size: rem(10);
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            speak: none;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e912";
            margin-left: 5px;
            display: inline-block;
        }
    }

    dd {
        font-size: rem(14);
        margin: 0 0 13px;

        @include respond-to(m_desktop) {
            visibility: hidden;
        }

        @include respond-to(touch) {
            font-size: rem(12);
        }

        @include respond-to(m_tablet_down) {
            display: none;
            margin-bottom: 12px;
        }

        > ul {
            font-size: rem(12);

            @include respond-to(desktop) {
                font-size: rem(10);
            }

            @include custom-respond-to($break_tablet, $break_touch) {
                font-size: rem(8);
            }

            > li {
                margin: 10px 0;
            }
        }
    }
}

.opening-hours {
    display: none;
    font-size: rem(10);

    @include custom-respond-to($break_tablet, $break_touch) {
        font-size: rem(8);
    }

    &-wrapper {
        width: 155px;

        @include custom-respond-to($break_tablet, $break_touch) {
            width: 135px;
        }
    
        @include respond-to(m_tablet_down) {
            margin: 0 auto;
        }
    
        select {
            background-color: transparent;
            color: $white;
            margin: 10px 0;
    
            &:hover {
                background-color: transparent;
                color: $white;
            }
        }

        option {
            color: $black01;
        }
    }
    
    .content-asset {
        overflow: hidden;
        border-bottom: none;
    }

    .left,
    .right {
        margin-bottom: 10px;
    }

    .left {
        float: left;
        margin-right: 5px;
    }

    .right {
        float: right;
        margin-left: 5px;
    }
}

#onetrust-pc-sdk,
#onetrust-banner-sdk {
    *:focus {
        outline: none !important; // Override external styles
    }
}

#onetrust-pc-sdk .ot-tgl input:focus + .ot-switch {
    outline: none !important;
}
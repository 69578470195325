// form elements styles

//variables for co-redesign
$co-field-color             : #9e9e9e;
$co-field-valide-color      : #4CAF50;
$co-field-error-color       : #F44336;
$co-checkbox-selected-color : #26a69a;

$co-checkbox-size           : 18px;
$co-label-inline-height     : 25px;


// REDESIGNED FIELDS STYLING
.pt_checkout,
.ui-dialog.theme-redesign,
.loyalty-gift-dialog {

    input:not([type]),
    input[type=text],
    input[type=password],
    input[type=email],
    input[type=url],
    input[type=time],
    input[type=date],
    input[type=datetime],
    input[type=datetime-local],
    input[type=tel],
    input[type=number],
    input[type=search],
    textarea.materialize-textarea,
    select,
    .input-select,
    .input-text {
        font-size: rem(16);
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        padding: 0;
        transition: all .3s, box-shadow 0s;
        border: none;
        border-bottom: 1px solid $co-field-color;
        border-radius: 0;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        color: $black;

        &:-webkit-autofill,
        &:focus {
            ~ label {
                font-size: rem(13);
                width: 100%;
                -webkit-transform: translateY(-140%);
                        transform: translateY(-140%);
            }
        }

        &:-webkit-autofill {
            -webkit-box-shadow: inset 0 0 0 1000px $white;
        }

        &:focus {
            border-bottom: 2px solid $nero2;

            + label {
                color: $pink;
            }
        }

        &.validate + label {
            width: 100%;
            pointer-events: none;
        }
    }

    select,
    .input-select {
        padding-right: 20px;

        &.valid,
        &.invalid {
            &:focus {
                border-bottom: 2px solid $nero2;
            }
        }

        &.valid {
            border-bottom: 2px solid $co-field-valide-color;
        }

        &.invalid {
            border-bottom: 2px solid $co-field-error-color;
        }
    }

    input[type=text],
    input[type=tel],
    input[type=email],
    input[type=password],
    input[type=number] {
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 24px;
        padding-right: 25px;

        &::-ms-clear {
            display: none;
        }

        &.valid,
        &.invalid {
            background-position: right center;

            &:focus {
                border-bottom: 2px solid $nero2;
                background-image: none;
            }
        }

        &.valid {
            border-bottom: 2px solid $co-field-valide-color;
            background-image: url(../images/ic_done_green_24px.svg);
        }

        &.invalid {
            border-bottom: 2px solid $co-field-error-color;
            background-image: url(../images/ic_cross_red_24px.svg);
        }
    }

    input[type=password] {
        + .show-password {
            display: none;
        }
    }

    .checkoutmultishipping,
    .field-wrapper,
    .select-saved-addresses_select,
    &.loyalty-gift-dialog {
        span.error,
        div.error {
            position: absolute;
            border: none;
            background: transparent;
            text-transform: none;
            padding: 5px 0 0;
            line-height: 1;
            font-size: rem(13);
        }
    }

    &.loyalty-gift-dialog {
        span.error{
            position: relative;
            text-align: left;
        }
    }

    .field-wrapper {
        label {
            color: $black;
            position: absolute;
            top: 13px;
            left: 0;
            font-size: rem(16);
            cursor: text;
            transition: .2s ease-out;
            text-align: left;
            line-height: 1;

            @include respond-to(mobile) {
                display: block;
            }

            &:not(.label-icon).active {
                font-size: rem(13);
                -webkit-transform: translateY(-140%);
                        transform: translateY(-140%);
            }

            &::before {
                display: block;
                content: "";
                position: absolute;
                top: 60px;
                opacity: 0;
                transition: .2s opacity ease-out, .2s color ease-out;
            }
        }

        select,
        .input-select {
            ~ label {
                font-size: rem(13);
                width: 100%;
                -webkit-transform: translateY(-140%);
                        transform: translateY(-140%);
            }
        }

        .valid,
        .invalid {
            ~ label {
                font-size: rem(13);
                width: 100%;
                -webkit-transform: translateY(-140%);
                        transform: translateY(-140%);
            }
        }

        .invalid {
            ~ label {
                &::before {
                    content: attr(data-error);
                    color: $co-field-error-color;
                    opacity: 1;
                }
            }
        }
    }

    .cart-coupon-code {
        .coupon-error {
            color: $co-field-error-color;
            margin-top: 5px;

            @include respond-to(mobile) {
                margin: 5px 2% 0;
            }
        }
    }
}

.shipping-promos {
    margin: 0;
}


// REDESIGNED DIALOGS STYLING
.ui-dialog.theme-redesign {

    .dialog-action-buttons {
        margin: 35px 0 0;
    }

    .save-shipping-address,
    .cancel-shipping-address,
    .save-button,
    .cancel-button {
        @include respond-to(mobile) {
            display: inline-block;
            width: 49%;
            margin: 10px 0;
        }
    }
}
// horizontal scroll
.horizontalscroll {

    padding-top: 80px;
    padding-bottom: 60px;

    @include respond-to(m_tablet) {
        padding: 0;
        padding-left: 15px;
    }

    > .row {
        @include respond-to(m_tablet) {
            height: 100%;
        }
    }

    .horizontalscroll-page-text {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;

        @include respond-to(m_tablet) {
            padding-top: 30px;
        }

        .horizontalscroll-page-type {
            font-family: $font-second;
            font-size: rem(14);
            margin: 0;
        }

        .horizontalscroll-page-title {
            font-size: rem(22);

            @include respond-to(m_desktop) {
                font-size: rem(30);
                font-weight: 800;
            }

            @include respond-to(m_desktop_large) {
                font-size: rem(44);
            }
        }

        .horizontalscroll-page-copy p {
            color: $hel-gray2;

            @include respond-to(m_desktop) {
                font-size: rem(18);
            }

            @include respond-to(m_desktop_large) {
                font-size: rem(22);
            }
        }
    }

    .horizontalscroll-content {
        @include respond-to(m_tablet) {
            @include m_scrollbar;

            height: calc(100% - 5px);
            overflow-x: auto;
        }

        .scroll-element.scroll-y {
            display: none !important;
        }
    }

    .horizontalscroll-content-grid {
        @include respond-to(m_mobile_down) {
            width: auto;
        }

        @include respond-to(m_tablet) {
            display: flex;
        }

        .horizontalscroll-content-grid-item {
            padding-top: 15px;
            padding-left: 15px;
            padding-right: 15px;
            display: flex;

            @include respond-to(m_tablet) {
                width: 430px;
                min-width: 430px;
                padding-top: 30px;
                display: block;
            }

            @include respond-to(m_desktop) {
                width: 290px;
                min-width: 290px;
            }

            @include respond-to(m_desktop_large) {
                width: 300px;
                min-width: 300px;
            }

            @include respond-to(m_desktop_xlarge) {
                width: 380px;
                min-width: 380px;
            }

            .horizontalscroll-content-grid-item-image {
                @include respond-to(m_mobile_down) {
                    width: 41.66667%;
                }

                img {
                    width: 100%;
                }
            }

            .horizontalscroll-content-grid-item-text {
                padding: 0 0 0 15px;

                @include respond-to(m_tablet) {
                    padding: 15px 0 0 0;
                }

                @include respond-to(m_mobile_down) {
                    width: 58.33333%;
                }

                .horizontalscroll-type {
                    font-family: $font-second;
                    font-size: rem(12);
                    margin: 0;

                    @include respond-to(m_tablet) {
                        font-size: rem(14);
                    }
                }

                .horizontalscroll-title {
                    font-size: rem(16);
                    font-weight: 800;

                    @include respond-to(m_mobile_down) {
                        margin: 0 0 10px 0;
                    }

                    @include respond-to(m_tablet) {
                        font-size: rem(20);
                    }

                }

                .horizontalscroll-copy {
                    font-size: rem(10);

                    @include respond-to(m_tablet) {
                        font-size: rem(14);
                    }
                }

                .button {
                    @include respond-to(mobile_tablet) {
                        width: auto;
                    }
                }
            }
        }
    }
}

$elemSize: 56px;
$elemSizeSmall: 40px;

.quantity_selector {
    width: 100%;

    &-inner_wrapper {
        background-color: #f8f8f8;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 0;
        min-width: 140px;
        padding: 1px;
        position: relative;
        text-align: center;
        user-select: none;
    }

    &-decrease,
    &-increase {
        background-color: #fff;
        border-radius: 4px;
        color: #000;
        font-size: rem(12);
        font-weight: bold;
        height: $elemSize;
        line-height: $elemSize;
        text-align: center;
        transition: 300ms ease-out, background-color 300ms;
        width: $elemSize - 16;

        @include respond-to(touch_over) {
            &:hover {
                color: #fff;
                cursor: pointer;
                background-color: #000;
            }
        }

        @include respond-to(mobile_strong) {
            height: $elemSizeSmall;
            line-height: $elemSizeSmall;
        }
    }

    &-input[type="number"] {
        background-color: #f8f8f8;
        border: 0;
        display: block;
        font-size: rem(14);
        height: $elemSize;
        line-height: $elemSize;
        padding: 9px 4px;
        text-align: center;
        width: calc(100% - #{($elemSize - 16px) * 2});

        @include respond-to(mobile_strong) {
            height: $elemSizeSmall;
            line-height: $elemSizeSmall;
        }
    }

    &-input[type="number"] ~ span.error {
        border-color: $error-message-bg;
        order: 1;
        text-align: center;
        width: 100%;
    }

    span.error {
        text-transform: none;
        font-size: rem(10);
        background-color: $white;
        border: none;
    }
}

.wishlist-products .quantity_selector,
.cart-row .quantity_selector,
.product-set .quantity_selector {
    &-inner_wrapper {
        margin-bottom: 10px;
        min-width: 120px;

        @include respond-to(tablet_plus) {
            max-width: 140px;
        }
    }

    &-decrease,
    &-increase {
        height: $elemSizeSmall;
        line-height: $elemSizeSmall;
        width: $elemSizeSmall;
    }

    &-input[type="number"] {
        height: $elemSizeSmall;
        line-height: $elemSizeSmall;
        width: calc(100% - #{$elemSizeSmall * 2});
    }
}

// TODO need to find better place for this styles
.wishlist-products,
.product-add-to-cart {
    .inventory {
        @include respond-to(mobile_strong) {
            margin-top: 5px;
        }
        
        &-label {
            margin-bottom: 10px;
        }
    }
}

.product-set-sku-details .quantity_selector {
    @include respond-to(mobile_strong_over) {
        max-width: 175px;
    }
}

// Buttons
button,
.button,
.buttonstyle,
input[type="button"] {
    background-color: $btn-primary-bg;
    border: $btn-primary-border-width solid $btn-primary-border-color;
    color: $btn-primary-color;
    cursor: pointer;
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: rem($btn-font-size);
    letter-spacing: 1px;
    line-height: 1;
    margin: 0;
    outline: none;
    padding: $btn-padding;
    text-align: center;
    text-decoration: none;
    text-transform: $btn-text-transform;
    transition: background-color 300ms ease-out, border-color 300ms ease-out;
    border-radius: 4px;

    @include respond-to(mobile_tablet) {
        width: 100%;
    }

    &:hover {
        background-color: $btn-primary-hover-bg;
        color: $btn-primary-hover-color;
        border-color: $btn-primary-hover-border-color;
    }

    &.full-width {
        width: 100%;
    }

    &.small {
        font-size: rem($btn-small-font-size);
        padding: $btn-small-padding;
    }

    &.large {
        font-size: rem($btn-large-font-size);
        padding-bottom: $btn-large-padding-bottom;
        padding-top: $btn-large-padding-top;
    }

    &.tiny {
        font-size: rem($btn-tiny-font-size);
        padding: $btn-tiny-padding;
    }

    &.coloured {
        background: $btn-secondary-bg;
        color: $btn-secondary-color;
        border-width: $btn-secondary-border-width;
        border-color: $btn-secondary-border-color;
        border-radius: 4px;

        &:hover {
            background: $btn-secondary-hover-bg;
            color: $btn-secondary-hover-color;
            border-color: $btn-secondary-hover-border-color;
        }
        
        &.inactive,
        &[disabled] {
            background: $btn-disabled-bg;
            color: $btn-disabled-color;
            border-color: $btn-disabled-bg;
            cursor: default;
            opacity: 0.7;

            &:hover {
                background: $btn-disabled-hover-bg;
                color: $btn-disabled-color;
                border-color: $btn-disabled-hover-bg;
            }
        }
    }

    &.button_grey {
        background: $grey04;
        border-color: $grey04;

        &:hover {
            background: $grey05;
            border-color: $grey05;
        }
    }

    &.black {
        background: $btn-quadruple-bg;
        color: $btn-quadruple-color;
        border-width: $btn-quadruple-border-width;
        border-color: $btn-quadruple-border-color;

        &:hover {
            background: $btn-quadruple-hover-bg;
            color: $btn-quadruple-hover-color;
            border-color: $btn-quadruple-hover-border-color;
        }
    }

    &.btn-blue {
        background: $btn-auxiliary-bg;
        color: $btn-auxiliary-color;
        border-width: $btn-auxiliary-border-width;
        border-color: $btn-auxiliary-border-color;

        &:hover {
            background: $btn-auxiliary-hover-bg;
            color: $btn-auxiliary-hover-color;
            border-color: $btn-auxiliary-hover-border-color;
            opacity: 0.95;
        }
    }

    &.outlined {
        background-color: $btn-tertiary-bg;
        border-color: $btn-tertiary-border-color;
        border-width: $btn-tertiary-border-width;
        color: $btn-tertiary-color;

        &:hover {
            background-color: $btn-tertiary-hover-bg;
            border-color: $btn-tertiary-hover-border-color;
            color: $btn-tertiary-hover-color;
        }
    }

    &[disabled],
    &.disabled {
        background-color: $btn-disabled-bg;
        color: $btn-disabled-color;
        cursor: default;
        opacity: 0.7;
    }

    &.pink {
        background-color: $btn-secondary-bg;
    }
}

.gen-cta {
    background-color: $link-custom-bg;
    color: $link-custom-text-color;
    padding: $link-custom-padding;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    font-size: rem(14);
    letter-spacing: 1px;
    border-style: solid;
    border-width: $link-custom-border-width;
    border-color: $link-custom-border-color;
    display: inline-block;

    @include respond-to(mobile) {
        width: auto;
    }

    &:hover {
        background-color: $link-custom-hover-bg;
        border-color: $link-custom-hover-border-color;
        color: $link-custom-hover-text-color;
    }
}

@mixin btn-main {
    font-family: $font-main;
    font-size: rem(14);
    font-weight: $medium;
    height: 48px;
    border-radius: 4px;
    text-transform: uppercase;
    width: 100%;
    transition: all 0.3s linear;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: center;
    padding: 16px 15px 0;
}

@mixin btn-black-border {
    @include btn-main();

    border: 1px solid $black01;
    color: $black01;

    &:hover {
        border: 1px solid $pink;
    }
}

@mixin link_underline ($border-color: $pink) {
    position: relative;
    
    &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $border-color;
        transition: all 0.2s linear;
        visibility: visible;
        transform: scaleX(1);
    }

    &:hover {
        cursor: pointer;

        &:after {
            visibility: hidden;
            transform: scaleX(0);
        }
    }
}

.applepay-button { // Common styles for Apple Pay button
    display: block;
    -webkit-appearance: -apple-pay-button;

    &:hover {
        padding: 20px !important;
    }
    
    @supports not (-webkit-appearance: -apple-pay-button) { // Hide from Samsung
        display: none;
    }

    .cart-actions & { // Styles for Apple Pay on Cart 
        -apple-pay-button-type: check-out;
        margin: 20px 0 0 0 !important;
    }

    .pdp-main & { // Styles for Apple Pay on PDP 
        -apple-pay-button-type: buy;
        height: 45px;
        width: 100%;
    }

    .dialog-quickview & {
        display: none;
    }
}
// Styles for features page of the magazine
// --------------------------

// feature page
.feature-page {

    > .row {
        height: 100%;
    }

    .feature-page-title {
        font-size: rem(14);

        @include respond-to(m_desktop) {
            font-size: rem(22);
            font-weight: 800;
        }
    }

    .feature-page-sidebar {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-to(m_tablet) {
            padding: 30px;
        }
    }

    .feature-page-content {
        overflow-x: auto;

        @include respond-to(m_tablet) {
            @include m_scrollbar;

            height: calc(100% - 5px);
        }

        //.feature-page-content-grid
        &-grid {
            @include respond-to(m_desktop) {
                display: flex;
                height: 100%;
            }

            //.feature-page-content-grid-item
            &-item {
                display: flex;

                @include respond-to(m_desktop) {
                    flex-direction: column;
                    width: 250px;
                    min-width: 250px;
                }

                //.feature-page-content-grid-item-image
                &-image {
                    overflow: hidden;
                    width: calc(100% / 3);

                    @include respond-to(m_desktop) {
                        flex: 1;
                        width: 100%;
                    }

                    .component-picture,
                    .component-image {
                        display: block;
                        @media screen and (min-height: 900px) {
                            height: 100%;
                            min-width: 100%;
                        }
                    }
                }

                //.feature-page-content-grid-item-text
                &-text {
                    padding: 15px;
                    min-height: 200px;
                    width: calc(100% / 3 * 2);

                    @include respond-to(m_tablet) {
                        height: 250px;
                        padding: 30px;
                    }

                    @include respond-to(m_desktop) {
                        width: 100%;
                    }

                    .feature-type {
                        font-family: $font-second;
                        font-size: rem(14);
                        margin: 0;
                    }

                    .feature-title {
                        font-size: rem(16);
                        text-transform: uppercase;
                        font-weight: 800;
                        color: inherit;

                        @include respond-to(m_desktop) {
                            font-size: rem(20);
                            text-transform: uppercase;
                        }
                    }

                    .feature-copy {
                        font-size: rem(14);
                    }
                } //.feature-page-content-grid-item-text

                &:nth-child(odd) .feature-page-content-grid-item-text {
                    background: $light-gray;
                }
            }//.feature-page-content-grid-item
        }//.feature-page-content-grid
    }//.feature-page-content
}//.feature-page

.jcarousel-wrapper {
    &.jcarousel-wrapper-vertical {
        margin: 4rem auto;
        position: relative;
    }
}

.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;

    ul {
        position: relative;
        text-align: center;
        font-size: 0;
        white-space: nowrap;
        margin-bottom: 0;
    }
    
    &.prev-inactive.next-inactive ul {
        transform: translate3d(0px, 0px, 0px) !important;
    }

    li {
        font-size: rem(16);
        display: inline-block;
        vertical-align: top;
        position: relative;
        white-space: normal;
    }

    .jcarousel-control {
        display: none;
    }

    .jcarousel-nav {
        background: $transparent;
        color: $hel-gray;
        cursor: pointer;
        font-size: rem(28);
        margin-top: -26px;
        padding: 12px;
        position: absolute;
        top: 50%;
        z-index: 2;
        font-family: $font-icons !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include respond-to(touch_over) {
            top: 220px;
            
            &:hover {
                color: $lightest-gray;
            }
        }

        &.inactive {
            display: none;
        }
    }

    .jcarousel-prev {
        left: -12px;

        &:before {
            content: "\e90c";
        }
    }

    .jcarousel-next {
        right: -12px;

        &:before {
            content: "\e915";
        }
    }

    /* vertical carousel */
    &.carousel-vertical {
        position: static;

        .jcarousel-nav {
            top: auto;
            padding: 0;
        }

        .jcarousel-prev,
        .jcarousel-next {
            left: 50%;
            margin-left: -1.5rem;
            height: 1.6rem;
            width: 3rem;
        }

        .jcarousel-prev {
            top: -0.5rem;

            &:before {
                content: "\e91e";
            }
        }

        .jcarousel-next {
            bottom: -2.5rem;

            &:before {
                content: "\e902";
            }
        }

        .jcarousel-list {
            height: 2000rem;
            position: relative;
            list-style: none;
            margin: 0 1.5rem;
            padding: 0;

            @include respond-to(mobile) {
                height: auto;
            }
        }
    }
}

// Product-tile labels in the carousel
.carousel {
    .product-tile {
        padding: 0 6px;

        .product-image {
            height: auto;
        }

        .product-promo,
        .product-raiting-wrapper,
        .product-swatches {
            display: none;
        }
    }
}

// Styles when quickview button is enable
.shop_now-quickview {
    .carousel {
        text-align: center;
    }

    .carousel-list {
        display: inline-flex; // Fix for flex with transform in carousel rotation
        justify-content: center;
    }

    .category-main-content { // Fix for swipe rotation on Tablet
        @media (min-width: 1024px) and (max-width: 1260px) {
            .carousel-list {
                display: block;
            }

            .carousel-item {
                height: 415px;
            }
        }
    }

    .slick-track {
        display: flex;
        min-width: 100%;
    }

    .carousel-item,
    .slick-slide {
        flex-shrink: 0;
        padding-bottom: 50px;
        height: auto;
    }
}

// fwb-panel on carousel
.fwb-panel {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &.repeat-bg {
        background-repeat: repeat-x;
        background-size: auto;
    }

    .fwbp-text-wrapper {
        position: static;

        @include respond-to(tablet_plus) {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0 auto;

            .view-position-bottom & {
                top: auto;
                display: none;
            }

            .view-position-bottom:hover & {
                display: block;
            }
        }
    }

    .fwbp-text-table {
        position: relative;
        width: 100%;
        height: 100%;
        display: table;

        .fwbp-text-cell {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            width: 100%;
            position: relative;

            @include respond-to(tablet_plus) {
                padding: 2.25rem;

                .view-position-bottom & {
                    padding: 0;
                }

                .view-position-left & {
                    text-align: left;
                }

                .view-position-right & {
                    text-align: right;
                }

                .view-position-center & {
                    text-align: center;
                }

                .view-position-center-minimal & {
                    vertical-align: bottom;

                    .fwbp-text {
                        background: transparent;
                    }

                    .small-text,
                    .divider,
                    .h1,
                    h1,
                    p {
                        display: none;
                    }

                    .fwbp-cta {
                        display: block;
                    }
                }
            }
        }
    }

    .fwbp-text {
        background: $ghost;
        display: inline-block;
        padding: 15px;
        position: relative;
        text-align: center;

        @include respond-to(tablet_plus) {
            width: 50%;
            max-width: 350px;
            padding: 40px 20px;
            border-radius: 4px;

            .view-position-bottom & {
                display: block;
                width: auto;
                max-width: none;
                padding: 15px;
            }
        }

        &.orange-bg {
            background: $burnt-orange; /* crusta */
            color: $white;
        }

        &.blue-bg {
            background: $shakespeare; /* hippie blue */
            color: $white;
        }

        &.white-bg {
            background: $white;
            color: $black;
        }

        &.violet-bg {
            background: rgba(98, 80, 124, 0.9);
            color: $white;
        }

        span {
            display: block;
            font-size: rem(14);
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .divider {
            border-style: solid;
            border-top: 0;
            margin: auto;
            margin-bottom: 20px;
            margin-top: 7px;
            width: 80px;

            @include respond-to(mobile) {
                margin-bottom: 10px;
                margin-top: 5px;
            }
        }

        h1,
        .h1 {
            color: inherit;

            @include respond-to(mobile) {
                font-size: rem(18);
                margin: 3px 0 8px;
            }
        }

        p {
            letter-spacing: 1px;
            line-height: 25px;

            @include respond-to(mobile) {
                line-height: 18px;
                margin-bottom: 20px;
                margin-top: 0;
            }
        }

        .fwbp-cta {
            margin: 0;

            .button {
                padding: 12px 24px;

                @include respond-to(mobile) {
                    font-size: rem(12);
                    padding: 10px 16px;
                    width: auto;
                }
            }
        }
    }

    img.fwbp-img {
        display: block;
        margin: 0 auto;
        position: relative;
        max-width: 100%;

        .left-align {
            margin: 0;
        }

        .right-align {
            margin-right: 0;
        }
    }
}

/* slick */
.slick-arrow {
    background: $transparent;
    border: none;
    color: $hel-gray;
    cursor: pointer;
    font-size: 0;
    margin-top: -23px;
    padding: 12px;
    position: absolute;
    top: 50%;
    z-index: 2;
    font-family: $font-icons !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;

    @include respond-to(mobile) {
        width: auto;
    }

    &:hover {
        color: $lightest-gray;
    }

    &:before {
        font-size: rem(22);
    }

    &.slick-disabled {
        display: none;
    }
}

.slick-prev {
    left: 0;

    &:before {
        content: "\e90c";
    }
}

.slick-next {
    right: 0;

    &:before {
        content: "\e915";
    }
}

.slick-dots {
    line-height: 1;
    margin: 20px auto;
    display: block;

    li {
        display: inline-block;
    }

    li:first-child button {
        margin-left: 0;
    }

    .slick-active button {
        background: $pink;
        width: 15px;
        height: 15px;
    }

    button {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid $grey01;
        background: $grey02;
        margin-left: 10px;
        font-size: 0;
        padding: 0;
    }
}

/* product carousels */
.js-product-grid.carousel {
    .jcarousel-prev {
        left: -12px;
        
        @include respond-to(mobile) {
            left: -5px;
        }
    }
    
    .jcarousel-next {
        right: -12px;
        
        @include respond-to(mobile) {
            right: -5px;
        }
    }
}
.bb-bookblock {
    @include respond-to(m_desktop) {
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
}


.bb-page {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
}

.bb-vertical .bb-page {
    width: 50%;
    height: 100%;
    left: 50%;
    -webkit-transform-origin: left center;
    transform-origin: left center;
}

.bb-horizontal .bb-page {
    width: 100%;
    height: 50%;
    top: 50%;
    -webkit-transform-origin: center top;
    transform-origin: center top;
}

.bb-page > div,
.bb-outer,
.bb-content,
.bb-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.bb-vertical .bb-content {
    width: 200%;
}

.bb-horizontal .bb-content {
    height: 200%;
}

.bb-page > div {
    width: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.bb-vertical .bb-back {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.bb-horizontal .bb-back {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
}

.bb-outer {
    width: 100%;
    overflow: hidden;
    z-index: 999;
}

.bb-overlay,
.bb-flipoverlay {
    background-color: rgba($black, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.bb-flipoverlay {
    background-color: rgba($black, 0.2);
}

.bb-bookblock.bb-vertical > div.bb-page:first-child,
.bb-bookblock.bb-vertical > div.bb-page:first-child .bb-back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.bb-bookblock.bb-horizontal > div.bb-page:first-child,
.bb-bookblock.bb-horizontal > div.bb-page:first-child .bb-back {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

/* Content display */
.bb-content {
    background: $white;
}

.bb-vertical .bb-front .bb-content {
    left: -100%;
}

.bb-horizontal .bb-front .bb-content {
    top: -100%;
}

/* Flipping classes */
.bb-vertical .bb-flip-next,
.bb-vertical .bb-flip-initial {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.bb-vertical .bb-flip-prev {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.bb-horizontal .bb-flip-next,
.bb-horizontal .bb-flip-initial {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.bb-horizontal .bb-flip-prev {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

.bb-vertical .bb-flip-next-end {
    -webkit-transform: rotateY(-15deg);
    transform: rotateY(-15deg);
}

.bb-vertical .bb-flip-prev-end {
    -webkit-transform: rotateY(-165deg);
    transform: rotateY(-165deg);
}

.bb-horizontal .bb-flip-next-end {
    -webkit-transform: rotateX(15deg);
    transform: rotateX(15deg);
}

.bb-horizontal .bb-flip-prev-end {
    -webkit-transform: rotateX(165deg);
    transform: rotateX(165deg);
}

.bb-item {
    width: 100%;
    background: $white;
}

/* No JS */
.no-js .bb-bookblock,
.no-js ul.bb-custom-grid li {
    width: auto;
    height: auto;
}

.no-js .bb-item {
    display: block;
    position: relative;
}


/* custom styles */

.bb-custom-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.bb-custom-wrapper .bb-bookblock {
    width: 100%;
    height: 100%;
    -webkit-perspective: 2000px;
    perspective: 2000px;
}

.bb-custom-side {
    width: 50%;
    float: left;
    height: 100%;
    overflow: hidden;
    background: $white;
    /* Centering with flexbox */
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.bb-custom-firstpage h1 {
    font-size: 2.625em;
    line-height: 1.3;
    margin: 0;
    font-weight: 300;
    background: $white;
}

.bb-custom-firstpage h1 span {
    display: block;
    font-size: 60%;
    opacity: 0.3;
    padding: 0 0 0.6em 0.1em;
}

.bb-custom-firstpage {
    text-align: center;
    padding-top: 15%;
    width: 50%;
    float: left;
    height: 100%;
}

.bb-custom-side p {
    padding: 8%;
    font-size: 1.8em;
    font-weight: 300;
}

.bb-custom-wrapper h3 {
    font-size: 1.4em;
    font-weight: 300;
    margin: 0.4em 0 1em;
}

.bb-custom-wrapper > nav {
    width: 100%;
    height: 40px;
    margin: 1em auto 0;
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    text-align: center;
}

.bb-custom-wrapper > nav a {
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 2px;
    background: #1baede;
    color: $white;
    font-size: 0;
    margin: 2px;
}

.bb-custom-wrapper > nav a:hover {
    opacity: 0.6;
}

/* No JS */
.no-js .bb-custom-wrapper {
    height: auto;
}

.no-js .bb-custom-content {
    height: 470px;
}

@media screen and (max-width: 61.75em){
    .bb-custom-side {
        font-size: 70%;
    }
}

@media screen and (max-width: 33em){
    .bb-custom-side {
        font-size: 60%;
    }
}


@function create-svg($name, $fill-color) {
    $path: nth($name, 1);
    $w: nth($name, 2);
    $h: nth($name, 3);

    $fill-color: str-slice(inspect($fill-color), 2);
    $fill-color: '%23' + $fill-color;
    $icon: 'data:image/svg+xml;charset=utf8,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 version%3D%221%22 width%3D%22#{$w}%22 height%3D%22#{$h}%22 viewBox%3D%220 0 #{$w} #{$h}%22%3E%3Cpath fill%3D%22#{$fill-color}%22 d%3D%22#{$path}%22%2F%3E%3C%2Fsvg%3E';
    @return $icon;
}

@mixin inline-svg-mono($name, $fill-color: #151515) {
    $svg: create-svg($name, $fill-color);
    background: transparent url($svg) no-repeat 50% 50%;
    background-size: 100%;
}

@mixin inline-svg-multi($name) {
    $svg: nth($name, 1);
    background: transparent url($svg) no-repeat 50% 50%;
    background-size: 100%;
}

$cancel: ('M14.6 16l1.9-1.8c.3-.3.3-.7 0-1.1L13 10l3.4-3.2c.3-.3.3-.7 0-1.1L14.6 4c-.3-.3-.8-.3-1.2 0L10 7.1 6.6 4c-.3-.3-.8-.3-1.2 0L3.6 5.7c-.3.3-.3.8-.1 1.1L7 10l-3.4 3.2c-.3.3-.3.7 0 1.1L5.4 16c.3.3.8.3 1.2 0l3.4-3.2 3.4 3.2c.3.3.8.3 1.2 0z', 20, 20);
$email: ('M16.8 16.3H3.2c-.9 0-1.6-.7-1.6-1.6V8c0-.1.1-.2.2-.2h.1c.7.6 1.7 1.3 5 3.7l.3.3c.9.7 1.8 1.2 2.8 1.3 1-.2 1.9-.6 2.7-1.3l.3-.3c3.2-2.4 4.3-3.1 5-3.7h.1c.1 0 .2.1.2.2v6.7c0 .9-.7 1.6-1.5 1.6zM10 12.1c-.8-.2-1.5-.6-2.1-1.1-.1-.1-.2-.2-.3-.2-4-2.9-4.6-3.4-5.4-4L2 6.6c-.2-.2-.3-.4-.3-.7v-.6c0-.9.7-1.6 1.6-1.6h13.5c.9 0 1.6.7 1.6 1.6v.6c0 .2-.1.5-.3.6l-.2.1c-.9.7-1.4 1.1-5.5 4.1-.1.1-.2.2-.3.2-.6.6-1.3 1-2.1 1.2z', 20, 20);
$exclamation: ('M10 17.5c-4.1 0-7.5-3.4-7.5-7.5S5.9 2.5 10 2.5s7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5zm0-6c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4zM9 6.1c-.2 0-.4.2-.4.4l.2 4.1c0 .2.2.3.4.3h1.5c.2 0 .4-.1.4-.3l.2-4.1c0-.2-.1-.4-.3-.4H9z', 20, 20);
$facebook: ('M8.1 18.3v-7.5H5.4v-3h2.7V5.6c0-2.5 1.7-3.9 4.1-3.9.8 0 1.6 0 2.4.1v2.7h-1.7c-1.3 0-1.6.6-1.6 1.4v2h3l-.4 3h-2.6v7.5', 20, 20);
$pinterest: ('M10.4 1.9c-3.3 0-6.6 2.2-6.6 5.8 0 2.3 1.3 3.6 2.1 3.6.3 0 .5-.9.5-1.2 0-.3-.8-.9-.8-2.2 0-2.4 1.9-4.4 4.3-4.5h.2C12.3 3.5 14 4.7 14 7c0 1.7-.7 5-2.9 5-.8 0-1.5-.6-1.5-1.4 0-1.2.9-2.4.9-3.7 0-2.2-3.1-1.8-3.1.8 0 .6.1 1.1.3 1.7-.4 1.9-1.4 4.8-1.4 6.8 0 .6.1 1.2.1 1.8.1.1.1.1.2 0 1.6-2.2 1.6-2.7 2.3-5.6.5.7 1.4 1.2 2.3 1.2 3.5 0 5-3.4 5-6.4.1-3.2-2.7-5.3-5.8-5.3z', 20, 20);
$share-hover: ('M14.3 12.1c-.7 0-1.4.2-2 .7l-3.4-2.1c.1-.5.1-.9 0-1.4l3.4-2.1c.6.4 1.3.7 2 .7 1.8 0 3.2-1.4 3.2-3.1-.1-1.8-1.5-3.2-3.3-3.1-1.7.1-3.1 1.4-3.1 3.1 0 .2 0 .5.1.7L7.7 7.6c-.6-.4-1.3-.7-2-.7C4 6.9 2.5 8.2 2.5 10s1.5 3.1 3.2 3.1c.7 0 1.4-.2 2-.7l3.4 2.1c-.1.2-.1.4-.1.7-.1 1.8 1.3 3.3 3.1 3.3 1.8.1 3.3-1.3 3.3-3.1s-1.2-3.3-3.1-3.3z', 20, 20);
$share: ('M14.3 12.1c-.7 0-1.4.2-2 .7l-3.4-2.1c.1-.5.1-.9 0-1.4l3.4-2.1c.6.4 1.3.7 2 .7 1.8 0 3.2-1.4 3.2-3.1-.1-1.8-1.5-3.2-3.3-3.1-1.7.1-3.1 1.4-3.1 3.1 0 .2 0 .5.1.7L7.7 7.6c-.6-.4-1.3-.7-2-.7C4 6.9 2.5 8.2 2.5 10s1.5 3.1 3.2 3.1c.7 0 1.4-.2 2-.7l3.4 2.1c-.1.2-.1.4-.1.7-.1 1.8 1.3 3.3 3.1 3.3 1.8.1 3.3-1.3 3.3-3.1s-1.2-3.3-3.1-3.3z', 20, 20);
$twitter: ('M16.6 6.6V7c0 5.3-4.3 9.7-9.6 9.7h-.1c-1.9 0-3.7-.5-5.2-1.5h.8c1.5 0 3-.5 4.2-1.5-1.5 0-2.7-1-3.2-2.4.2 0 .4.1.6.1.3 0 .6 0 .9-.1-1.5-.2-2.7-1.6-2.7-3.3.5.3 1 .4 1.5.4-1.5-1-2-3-1.1-4.6 1.7 2.1 4.3 3.4 7 3.6.1-.2.1-.5.1-.8 0-1.9 1.5-3.4 3.4-3.4.9 0 1.8.4 2.5 1.1.8-.1 1.5-.4 2.2-.8-.3.8-.8 1.5-1.5 1.9.7-.1 1.3-.3 2-.5-.5.6-1.1 1.2-1.8 1.7z', 20, 20);
$whatsApp: ('M2.5 17.3l1.1-3.7c-.6-1.1-1-2.3-1-3.6C2.6 5.9 6 2.7 10 2.7c2 0 3.8.8 5.3 2.1 1.4 1.3 2.2 3.2 2.2 5.1 0 1.9-.8 3.8-2.2 5.1-2.4 2.3-5.9 2.8-8.8 1.2l-4 1.1zM6.6 15l.2.1c2.4 1.4 5.5 1 7.5-.9 1.2-1.1 1.8-2.6 1.8-4.2s-.7-3.1-1.9-4.2S11.5 4 9.8 4c-3.4 0-6.1 2.6-6.2 6 0 1.1.3 2.2.9 3.2l.1.2-.6 2.2 2.6-.6zm5.3-1.6c-.6 0-1.2-.2-1.8-.5-1.3-.6-2.3-1.5-3.1-2.7-.5-.5-.7-1.2-.8-1.9 0-.6.2-1.1.6-1.5.2-.1.4-.2.6-.2h.4c.1 0 .2 0 .4.3 0 .1.1.3.2.4.2.4.3.8.4.9.1.1.1.2 0 .3-.2.3-.3.5-.5.7-.2.1-.2.2-.1.4.6 1 1.5 1.7 2.5 2.1.1 0 .1.1.2.1s.2 0 .2-.1.5-.5.6-.7c0-.1.1-.1.2-.1h.2c.2.1 1.1.5 1.3.6h.1c.1.1.2.1.3.2.1.3 0 .6-.1.9-.3.4-.7.8-1.3.9-.2-.1-.3-.1-.5-.1z', 20, 20);
$tick: ('M5.4 11.7L.2 6.5c-.3-.3-.3-.8 0-1.1l1.1-1.1c.3-.3.8-.3 1.1 0L6 7.7 13.5.2c.3-.3.8-.3 1.1 0l1.1 1.1c.3.3.3.8 0 1.1l-9.2 9.2c-.3.4-.8.4-1.1.1z', 20, 20);
$search: ('M23 25c-.2 0-.5-.1-.7-.3l-3.9-3.9c-.2-.2-.3-.4-.3-.7v-.6c-1.4 1.1-3.2 1.7-5 1.7-4.5 0-8.1-3.6-8.1-8.1S8.6 5 13.1 5s8.1 3.6 8.1 8.1c0 1.8-.6 3.6-1.7 5h.6c.2 0 .5.1.7.3l3.9 3.9c.4.4.4 1 0 1.3l-1.1 1.1c-.2.2-.4.3-.6.3zM13.1 8.1c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5c0-2.7-2.2-5-5-5z', 30, 30);
$minus: ('M18 12.4c.5 0 1-.4 1-.9v-3c0-.5-.4-.9-1-.9H2c-.6 0-1 .4-1 .9v3c0 .5.4.9 1 .9h16z', 20, 20);
$plus: ('M19 11.5v-3c0-.5-.4-.9-1-.9h-5.5V2.2c0-.5-.4-.9-1-.9h-3c-.5 0-1 .4-1 .9v5.4H2c-.6 0-1 .4-1 .9v3c0 .5.4.9 1 .9h5.5v5.4c0 .5.4.9 1 .9h3.1c.5 0 1-.4 1-.9v-5.4H18c.6 0 1-.4 1-.9z', 20, 20);
$co-sticky-bottom-height: 114px;

#main.payment {
    max-width: 980px;

    @include respond-to(desktop_plus) {
        padding-bottom: 150px;
    }
}

.payment,
.pt_order-confirmation {
    .billingproductlines {
        .table-div {
            .item-details {
                .price-standard {
                    display: none;
                }
                .price-sales {
                    display: none;
                }
            }
            .price-unadjusted{
                color: $charcoal;
                text-decoration: line-through;
                font-weight: 400;
                text-transform: uppercase;
            }
            .item-total{
                @include respond-to(mobile){
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .price-adjusted-total{
                @include respond-to(mobile){
                    order:1;
                    margin-right: 5px;
                }
            }
        }
    }
}

.payment {
    .main-payment-productlines {
        padding-bottom: 0;
    }

    .payment-mobile-accordion {
        .billing-sub-title {
            @include respond-to(mobile) {
                font-size: rem(22);
                font-weight: 400;
                text-align: center;
                margin-bottom: 50px;
            }
        }

        .delivery-address {
            margin-bottom: 30px;
            
            .address {
                @include respond-to(mobile) {
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .primary-content-payment {
        @include respond-to(mobile) {
            position: relative;
        }

        .shipment-title {
            background-color: $co-delivery-title-bg;
            display: table;
            margin-bottom: 0;
            padding: 10px 20px;
            font-weight: 500;

            @include respond-to(mobile) {
                padding: 0 20px;
                line-height: 45px;
            }

            &:after {
                @include respond-to(mobile) {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    border-bottom: 1px solid $co-delivery-title-bg;
                }
            }
        }
    }

    .billingproductlines {
        .table-div {
            .section-header {
                padding: 5px 20px;
                border-color: $co-delivery-title-bg;
            }

            .section-header-product {
                width: 65%;
            }

            .section-header-qty {
                padding: 5px;
                width: 15%;
            }

            .section-header-total {
                padding: 5px 10px;
                width: 20%;
            }

            .item-details {
                @include respond-to(tablet_plus) {
                    width: 45%;
                }
            }

            .item-quantity {
                @include respond-to(tablet_plus) {
                    width: 15%;
                }
            }

            .item-total {
                @include respond-to(tablet_plus) {
                    width: 20%;
                }
            }
        }

        .add-to-giftwrap {
            @include respond-to(tablet_plus) {
                @include flexbox();
                @include justify-content(space-between);

                padding-bottom: 30px;
                border-bottom: 1px solid $sections-body-border;
            }

            &:after {
                @include respond-to(tablet_plus) {
                    display: none !important;
                }
            }

            .gift-wrap-actions-message,
            .button {
                @include respond-to(tablet_plus) {
                    @include align-self(center);
                }
            }

            .gift-wrap-actions-message {
                i {
                    font-size: rem(21);
                    color: $pink;
                }
            }

            .button {
                width: auto;
                padding: 11px 23px;
                min-width: 180px;

                @include respond-to(tablet_plus) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .main-payment-summary {
        .order-totals-table {
            @include respond-to(tablet_plus) {
                min-width: 400px;
            }

            td {
                width: 50%;
            }
        }
        .order-totals-table-container {
            @include respond-to(mobile) {
                margin-bottom: 0;
            }
        }

        .order-summary {
            @include respond-to(mobile) {
                margin-bottom: 0;
            }
        }

        .gift-wrap-actions {
            padding: 20px 0;
            border-top: 1px solid $sections-body-border;
            border-bottom: 1px solid $sections-body-border;
            display: none;

            .gift-wrap-actions-message {
                padding: 0 0 20px;
                text-align: center;

                i {
                    font-size: rem(21);
                    color: $pink;
                }
            }

            .button {
                @include respond-to(mobile) {
                    display: table;
                    width: auto;
                    margin: auto;
                    padding: 11px 23px;
                    min-width: 180px;
                }
            }
        }
    }

    .loyalty-container,
    .gift-container {
        .button {
            @include respond-to(mobile) {
                display: table;
                width: auto;
                margin: auto;
            }
        }
    }

    .checkout-billing {
        border-top: 1px solid $very-light-gray;

        @include respond-to(tablet_plus) {
            max-width: 400px;
            margin-left: auto;
        }

        @include respond-to(mobile) {
            padding-bottom: 15px;
        }

        .order-total {
            .label,
            .order-value {
                display: inline-block;
                width: 49%;
                font-size: rem(17);
                text-transform: uppercase;
                font-weight: 700;
            }

            .order-value {
                text-align: right;
            }
        }

        .form-row-button {
            width: 100%;
            margin-top: 15px;

            @include respond-to(mobile) {
                margin-top: 10px;
            }

            .button-fancy-large {
                width: 100%;
                font-size: rem(21);
                font-weight: 400;
            }

            .co-icon-lock-outline {
                font-size: rem(24);
            }

            span {
                vertical-align: middle;
            }
        }
    }

    .main-payment-method {
        @include respond-to(mobile) {
            height: $co-sticky-bottom-height;
            margin-top: 15px;
        }

        &.is-sticked-bottom {
            .checkout-billing {
                @include respond-to(mobile) {
                    padding-left: 15px;
                    padding-right: 15px;
                    background-color: $white;
                    border-top-color: $black;
                }
            }
        }

        .payment-method-options {
            border-top: none;
            margin-bottom: 15px;

            @include respond-to(mobile) {
                margin-bottom: 10px;
            }
        }
    }

    .billing-address {
        @include respond-to(mobile) {
            margin-top: 5px;
            padding-top: 20px;
            border-top: 1px solid $sections-body-border;
        }

        .address {
            @include respond-to(mobile) {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
}

.is-sticked-bottom {
    .sticky-inner {
        @include respond-to(mobile) {
            position: fixed;
            bottom: 0;
        }
    }
}

.sticky-inner {
    left: 0;
    right: 0;
}
.ui-dialog-content {
    .product-col-1 {
        padding: 0px 15px;
        width: 48%;

        @include respond-to(tablet_regular) {
            float: left;
        }

        @include respond-to(mobile_strong) {
            float: none;
            width: 100%;
        }

        .product-image {
            max-width: 100%;
        }
    }

    .product-col-2 {
        padding: 0px 10px;
        margin-left: 0;
        width: 52%;

        @include respond-to(mobile_strong) {
            float: none;
            width: 100%;
        }
    }

    .pdp-main {
        .product-set-list {
            height: 500px;
            overflow: auto;
        }

        .product-description {
            .delivery-wrapper {
                &::before {
                    width: 103px;
                }
            }
        }
    }

    .pdp-alternative {
        .product-socials {
            display: none;
        }

        .product-price {
            margin-bottom: 10px;
        }

        .product-accordion {
            margin-top: 20px;
        }
    }
}

.zoomPad {
    position: relative;
    float: left;
    z-index: 99;
    cursor: crosshair;
    width: 100%;
}

.zoomPreload {
    -moz-opacity: 0.8;
    opacity: 0.8;
    filter: alpha(opacity = 80);
    color: $night-rider;
    font-size: rem(12);
    font-family: $sans-serif;
    text-decoration: none;
    border: 1px solid $very-light-gray;
    background-color: $white;
    padding: 8px;
    text-align: center;
    background-image: url(../images/zoomloader.gif);
    background-repeat: no-repeat;
    background-position: 43px 30px;
    z-index: 110;
    width: 90px;
    height: 43px;
    position: absolute;
    top: 0px;
    left: 0px;
    *width: 100px;
    *height: 49px;
}

.zoomPup {
    overflow: hidden;
    background-color: $white;
    -moz-opacity: 0.6;
    opacity: 0.6;
    filter: alpha(opacity = 60);
    z-index: 120;
    position: absolute;
    border: 1px solid $very-light-gray;
    z-index: 101;
    cursor: crosshair;
}

.zoomOverlay {
    position: absolute;
    left: 0px;
    top: 0px;
    background: $white;
    z-index: 5000;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 101;
}

.zoomWindow {
    position: absolute;
    left: 110%;
    top: 40px;
    background: $white;
    z-index: 6000;
    height: auto;
    z-index: 10000;
    z-index: 110;
}

.zoomWrapper {
    position: relative;
    border: 1px solid $nobel;
    z-index: 110;
}

.zoomWrapperTitle {
    display: block;
    background: $nobel;
    color: $white;
    height: 18px;
    line-height: 18px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: rem(10);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 120;
    -moz-opacity: 0.6;
    opacity: 0.6;
    filter: alpha(opacity = 60);
}

.zoomWrapperImage {
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 110;

    img {
        border: 0px;
        display: block;
        position: absolute;
        z-index: 101;
    }
}

.zoomIframe {
    z-index: -1;
    filter: alpha(opacity = 0);
    -moz-opacity: 0.80;
    opacity: 0.80;
    position: absolute;
    display: block;
}

.quickview-nav {
    @include respond-to(mobile_strong) {
        display: none;
    }

    .quickview-next {
        float: right;
    }
}

.dialog-quickview {
    @include respond-to(touch) {
        .pdp-main,
        .pdp-main.pdp-alternative.pdp-vip {
            margin: 0;
        }

        .product-shop-all,
        .product-col-mobile {
            display: none;
        }

        .product-col-2 {
            float: right;
            padding-right: 5%;
            margin-bottom: 0;
            width: 50%;
            display: block;
            text-align: left;
        }

        .add-to-cart-wrapper {
            .add-to-cart,
            .add-to-cart-disabled {
                margin: 0;
            }
        }

        .pdp-alternative { // BT PLUS
            .add-to-cart-wrapper {
                width: 100%;
                transform: none;
                margin: 0;
            }
        }
    }

    .ui-dialog-titlebar .ui-dialog-title {
        display: none;
    }

    .product-image {
        display: block;
    }

    .pdp-main {
        margin-top: 0;
        padding-top: rem(35);
    }

    .pdp-main.pdp-alternative.pdp-vip {
        .product-col-1 {
            @include respond-to(touch) {
                width: 50%;
                float: left;
            }
        }

        .product-name,
        .product-price {
            @include respond-to(mobile) {
                display: block;
            }
        }

        .product-name,
        .price-switcher,
        .product-variations,
        .prod-feedback-txt {
            @include respond-to(mobile) {
                float: right;
                width: 46%;
                clear: right;
            }
        }
    }

    .pdp-vip .product-alt-mobile {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .product-name {
        margin: 0;
        padding: 18px 0 16px;
        font-size: rem(16);
        text-align: left;
        font-weight: 500;

        &:after {
            display: none;
        }

        &-brand {
            font-size: rem(23);
            margin-bottom: 5px;
            color: $nobel;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: 400;
            display: block;
        }

        &-title {
            display: block;
            text-transform: capitalize;
            font-weight: 400;
            font-size: rem(18);
            margin-bottom: 6px;
            letter-spacing: 1px;
        }
    }

    .price-switcher {
        @include respond-to(mobile) {
            display: block;
        }
    }

    .price_review-wrapper {
        @include respond-to(mobile_stronger) {
            align-items: flex-start;
        }
    }

    .pdp-main .product-primary-image {
        width: 100%;

        .promo-flag {
            @include respond-to(mobile_stronger) {
                position: absolute;
                display: block;
            }
        }
    }

    .product-col-2 .promo-flag {
        @include respond-to(m_tablet) {
            display: none;
        }
    }

    .pdp-main .product-add-to-cart .inventory {
        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }

    .product-shop-all {
        margin: 40px 0 0;
    }

    .product-price {
        .price-sales {
            font-size: rem(21);
            font-weight: 500;
            margin: 0;
        }
        .price-standard {
            color: $charcoal;
            font-size: rem(13);
            text-transform: uppercase;

            .price-standard-qq-coment {
                display: inline-block;
            }
        }
    }

    .product-variations {
        @include respond-to(touch) {
            .label {
                text-align: left;
            }

            .value {
                margin: 0;

                .swatches {
                    text-align: left;

                    li:first-child {
                        margin-left: 0;
                    }
                }
            }

            .prod-feedback-txt {
                text-align: left;
            }
        }

        > ul {
            margin-bottom: 0;
        }

        .label {
            font-size: rem(16);
            cursor: pointer;
            display: block;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 4px;
            text-transform: capitalize;
        }

        .swatches {
            .selected-value {
                display: none;
            }

            .selectable,
            .unselectable {
                margin-right: 18px;

                @include respond-to(mobile) {
                    margin-left: 0;
                }
            }

            .selectable {
                a {
                    cursor: pointer;
                    height: 36px;
                    display: block;
                    margin: 0;
                    padding: 0;
                    border:0;
                    font-size: 0;
                }
            }

            &.size {
                .selectable a,
                .swatchanchor {
                    font-size: rem(16);
                    line-height: 36px;
                    text-align: center;
                }
            }
        }

        .notify-container {
            span {
                font-size: rem(14);
            }
        }
    }

    .add-to-cart-disabled {
        background-color: $btn-disabled-bg;
        color: $btn-disabled-color;
        cursor: default;
        opacity: 0.7;

        &:hover { // to prevent any hover effect
            background-color: $btn-disabled-bg;
            color: $btn-disabled-color;
        }
    }

    .quick-view-description {
        font-size: rem(14);
        line-height: 1.5;

        @include respond-to(mobile_tablet) {
            display: none;
        }
    }

    .quick-view-description-link {
        @include underlined_link;
        
        text-transform: capitalize;
        font-weight: 500;
        font-size: rem(12);
        margin: 0 auto;
    }

    .quickview-nav {
        clear: both;
    }

    .quickview-prev,
    .quickview-next {
        color: $hel-gray;
        background-color: transparent;
        letter-spacing: 0;
        margin-top: 50px;

        @include respond-to(touch) {
            width: auto;
        }
    }

    .bticons-left {
        padding-right: 14px;
        font-size: rem(16);
    }

    .bticons-right {
        padding-left: 14px;
        font-size: rem(16);
    }

    .whishlist_instore-actions {
        margin-bottom: 50px;
    }

    .quickview-actions {
        @include respond-to(mobile_tablet) {
            clear: both;
            display: block;
            overflow: hidden;

            button {
                width: 100%;

                &.add-to-cart-disabled {
                    &:hover,
                    &:active { // to prevent any hover effect
                        background-color: $btn-disabled-bg;
                        color: $btn-disabled-color;
                    }
                }
            }

            .add-to-cart,
            .whishlist_instore-actions {
                margin-bottom: 20px;
            }

            .product_actions-instore_link,
            .product_actions-wishlist_link {
                @include product_actions-button;
            }

            .product_actions-wishlist_link {
                @include shared-wishlist;
            }
        }
    }
}

/* Chanel quickview */

body .ui-dialog.dialog-quickview[aria-describedby="QuickViewDialog"] .ui-dialog-titlebar-close{
    @include respond-to(mobile_strong){
        background: $white;
        bottom: 0;
        height: 60px;
        padding-bottom: 20px;
        text-indent: 7px;
        width: 60px;
    }
}

#QuickViewDialog {
    .chanel-banner {
        margin-top: 25px;
    }

    .product-chanel-title {
        padding-bottom: 0;
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }

    .product-detail-chanel {
        .product-name {
            padding-top: 0;
        }

        .product-name-title {
            font-size: rem(14);
        }
    }

    .inventory + .prod-feedback-txt {
        @include respond-to(touch) {
            margin-top: 10px;
        }
    }

    .is-product-set .quick-view-description-link {
        margin: 10px 0 35px;
    }

    @include respond-to(mobile_strong) {
        padding: 10px 15px 80px;
        margin-top: -30px;

        .product-col-1 {
            padding: 0;
        }

        .product-col-2 {
            margin-top: 15px;

            .product-name {
                padding: 0 15px 0;

                .product-name-brand {
                    font-size: 100%;
                }

                .product-name-title {
                    font-size: 100%;
                    line-height: 1;
                }
            }

            .product-sales-price {
                font-size: 100%;
                line-height: 1;
            }

            .product-variations .attribute .value {
                width: 80%;
                margin: 0 auto;
            }
        }

        .quickview-actions {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            padding-top: 10px;
            margin-bottom: 80px;

            .product-actions {
                display: none; // Hide icon under button and show right only
            }
        }
    }
}
